import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import psl from 'psl';

import { LoginUserResponse } from '~api/auth/types';
import { HTTP_STATUS_CODES } from '~constants/common';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { logout } from '~store/slices/userSlice';
import { COOKIES_NAMES, getCookie, setAuthCookiesData } from '~utils/cookies';

interface ExtraOptions {
  url: string;
}

export const getBackendUrl = (url: string) => {
  //eslint-disable-next-line
  const pslData = psl.parse(window.location.hostname);

  if (
    (import.meta.env.VITE_MODE as string) === 'production' &&
    pslData &&
    'domain' in pslData &&
    !window.location.host.includes('localhost')
  ) {
    const { domain } = pslData;

    return url.replace('newguys.io', domain || '');
  } else {
    return url;
    // return url.replace('newguys.io', 'semabet.ug');
  }
};

export const BACKEND_URLS = {
  ID: getBackendUrl(import.meta.env.VITE_BACKEND_ID_URL as string),
  CATEGORY: getBackendUrl(import.meta.env.VITE_BACKEND_CATEGORY_URL as string),
  BETSLIP: getBackendUrl(import.meta.env.VITE_BACKEND_BETSLIP_URL as string),
  MARKET: getBackendUrl(import.meta.env.VITE_BACKEND_MARKET_URL as string),
  EVENT: getBackendUrl(import.meta.env.VITE_BACKEND_EVENT_URL as string),
  SNAPSHOT: getBackendUrl(import.meta.env.VITE_BACKEND_SNAPSHOT_URL as string),
  TRANSACTION: getBackendUrl(
    import.meta.env.VITE_BACKEND_TRANSACTION_URL as string,
  ),
  EMAIL: getBackendUrl(import.meta.env.VITE_BACKEND_EMAIL_URL as string),
  CONTENT: getBackendUrl(import.meta.env.VITE_BACKEND_CONTENT_URL as string),
  BONUS: getBackendUrl(import.meta.env.VITE_BACKEND_BONUS_URL as string),
  PAYSTACK: getBackendUrl(import.meta.env.VITE_BACKEND_PAYSTACK_URL as string),
  FLUTTERWAVE: getBackendUrl(
    import.meta.env.VITE_BACKEND_FLUTTERWAVE_URL as string,
  ),
  RELWORX: getBackendUrl(import.meta.env.VITE_BACKEND_RELWORX_URL as string),
  PARTNER: getBackendUrl(import.meta.env.VITE_BACKEND_PARTNER_URL as string),
  JACKPOT: getBackendUrl(import.meta.env.VITE_BACKEND_JACKPOT_URL as string),
  GAMES: getBackendUrl(import.meta.env.VITE_BACKEND_GAMES_URL as string),
} as const;

export const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: (headers, api) => {
      const token = getCookie(COOKIES_NAMES.ACCESS_TOKEN);

      if (!headers.has('Authorization') && token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      if (api.endpoint === 'uploadUserDocument') {
        headers.set('redirect', 'follow');
      }

      return headers;
    },
  });

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const { url } = extraOptions;
  const { dispatch } = api;
  const customBaseQuery = baseQuery(url);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const langId = (api.getState() as any).websiteSettings.language;

  const modifiedArgs = {
    ...(typeof args === 'object' ? args : {}),
    headers: {
      ...(typeof args === 'object' && 'headers' in args ? args.headers : {}),
      'Accept-Language': langId,
    },
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const result = await customBaseQuery(modifiedArgs, api, extraOptions);

  if (
    result.error &&
    result.error.status.toString() === HTTP_STATUS_CODES.UNAUTHORIZED
  ) {
    const refreshResult = await customBaseQuery(
      {
        url: BACKEND_URLS.ID + 'Token/refresh',
        method: 'POST',
        body: {
          accessToken: getCookie(COOKIES_NAMES.ACCESS_TOKEN),
          refreshToken: getCookie(COOKIES_NAMES.REFRESH_TOKEN),
        },
      },
      api,
      extraOptions,
    );

    if (refreshResult.data) {
      const { token, refreshToken, expiration } =
        refreshResult.data as LoginUserResponse;

      setAuthCookiesData({ token, refreshToken, expiration });

      return result;
    } else {
      window.parent.postMessage({ type: 'LOGOUT' }, '*');
      dispatch(logout());
      dispatch(closeDialog());
    }
  }

  return result;
};
