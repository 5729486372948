import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';

export const SearchEmptyResult = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  return (
    <Box
      flexCol
      fullWidth
      alignCenter
      justifyCenter
      css={{
        pt: '123px',
        '@xs_sm': {
          pt: '80px',
        },
      }}
    >
      <Text
        level={isMobileOrTablet ? '12-20' : '14-24'}
        textAlign={'center'}
        color="grayMedium"
      >
        {localized('casino.noResultTitle')}
      </Text>
      <Text
        level={isMobileOrTablet ? '12-20' : '14-24'}
        textAlign={'center'}
        color="grayMedium"
      >
        {localized('casino.noResultDescription')}
      </Text>
      <Text
        level={isMobileOrTablet ? '12-20' : '14-24'}
        textAlign={'center'}
        color="grayMedium"
      >
        {localized('casino.noResultDescription2')}
      </Text>
    </Box>
  );
};
