import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { MAIN_PAGE_TABS } from '~components/molecules/MainHighlights/helpers';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import { USER_PROFILE_NAVIGATION_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import {
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/HeaderComponent/Header/useHeaderMenu';
import { CountLabel } from '~components/organisms/MobileTabComponent/MobileTabSport/components/CountLabel';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks';
import useIsInIframe from '~hooks/useIsInIframe';
import { useNetworkStatus } from '~hooks/useNetworkStatus';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { BetHistoryIcon } from '~icons';
import { InplayIcon, MenuIcon, SportsIcon } from '~icons/menu';
import { BetslipTabIcon, GamesTabIcon } from '~icons/mobileTab';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setPreviousLocation } from '~store/slices/historySlice';
import {
  setActiveSportTabGroup,
  setMobileActiveTab,
  setMobileMainPageTab,
} from '~store/slices/mobileSlice';
import { resetPrematchSlice } from '~store/slices/prematchMenuSlice';
import { QueryParams } from '~utils/url';

import { BetslipTabItem } from './components/BetslipTabItem';
import {
  MenuTabItem,
  MenuTabRoot,
  StyledMobileTabContainer,
  StyledMobileTabItem,
  StyledMobileTabItemCentral,
  StyledMobileTabItemIcon,
  StyledMobileTabItemText,
  StyledMobileTabRoundDecoration,
} from './styled.components';

interface MobileMenuItems {
  value: MOBILE_MENU;
  label: string;
  icon: ComponentType;
  inactive?: boolean;
}

export enum MOBILE_MENU {
  LIVE = 'live',
  SPORT = 'prematch',
  BETSLIP = 'betslip',
  GAMES = 'games',
  MENU = 'menu',
  MY_BETS = 'myBets',
}

export const MobileTabSport = () => {
  const dispatch = useAppDispatch();
  const { menu } = useHeaderMenu();
  const { updateQueryParams } = useRouterQuery();
  const { mobileActiveTab } = useAppSelector((state) => state.mobileState);
  const { counts } = useAppSelector((state) => state.sportsMenuState);
  const { inplay: inplayCount, prematch: prematchCount } = counts;
  const { localized } = useTranslation();
  const isInIframe = useIsInIframe();
  const { navigateWithHistory } = useRouterQuery();
  const { isReconnected } = useNetworkStatus();
  const [marginBottom, setMarginBottom] = useState(false);
  const { menu: menuQuery } = useQueryParams();
  const { pathname } = useLocation();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (isReconnected) {
      setMarginBottom(true);
      setTimeout(() => {
        setMarginBottom(false);
      }, 3000);
    }
  }, [isReconnected]);

  const handleTabChange = useCallback(
    (value: string) => {
      dispatch(setPreviousLocation(null));
      if (mobileActiveTab === MOBILE_MENU.SPORT && !value.length) {
        dispatch(setMobileActiveTab(MOBILE_MENU.SPORT));
        dispatch(setActiveSportTabGroup(SPORT_TAB_GROUPS.UPCOMMING));
        updateQueryParams(
          {
            [QUERY_PARAMS.MENU]: MOBILE_MENU.SPORT,
          } as QueryParams<typeof QUERY_PARAMS>,
          true,
          '/',
        );

        return;
      }

      if (!value) return;
      window.scrollTo(0, 0);

      if (value === MOBILE_MENU.GAMES) {
        const { url } = menu.find(
          ({ icon }) => icon === MENU_ICON_TYPES.Casino,
        ) || { url: '' };

        if (url && url.length > 0) {
          window.location.href = url;
        }

        return;
      }

      if (value === MOBILE_MENU.BETSLIP) {
        navigateWithHistory(`${MOBILE_MENU.BETSLIP}/`);

        return;
      }

      if (value === MOBILE_MENU.MY_BETS) {
        navigateWithHistory(`${USER_PROFILE_NAVIGATION_TABS.HISTORY}/`);

        return;
      }

      if (value === MOBILE_MENU.MENU) {
        dispatch(openDialog(DIALOGS.MENU));

        return;
      }

      if (value !== MOBILE_MENU.BETSLIP) {
        dispatch(setMobileActiveTab(value as MOBILE_MENU));
        if (value === MOBILE_MENU.LIVE) {
          dispatch(resetPrematchSlice());
          updateQueryParams(
            {
              [QUERY_PARAMS.MENU]: MOBILE_MENU.LIVE,
            } as QueryParams<typeof QUERY_PARAMS>,
            true,
            '/',
          );
        } else {
          dispatch(resetPrematchSlice());
          updateQueryParams(
            {
              [QUERY_PARAMS.MENU]: MOBILE_MENU.SPORT,
            } as QueryParams<typeof QUERY_PARAMS>,
            true,
            '/',
          );
        }

        dispatch(setMobileMainPageTab(MAIN_PAGE_TABS.UPCOMING));
      }
    },
    [menu, mobileActiveTab],
  );

  const mobileMenuItemsMemo: MobileMenuItems[] = useMemo(() => {
    const items = [
      {
        value: MOBILE_MENU.LIVE,
        label: localized('mobile.tabMenu.live'),
        icon: InplayIcon,
      },
      {
        value: MOBILE_MENU.SPORT,
        label: localized('mobile.tabMenu.sport'),
        icon: SportsIcon,
      },
      {
        value: MOBILE_MENU.BETSLIP,
        label: localized('mobile.tabMenu.betslip'),
        icon: BetslipTabIcon,
      },
    ];

    return isInIframe
      ? items
      : [
          ...items,
          isUserLoggedIn
            ? {
                value: MOBILE_MENU.MY_BETS,
                label: localized('mobile.tabMenu.myBets'),
                icon: BetHistoryIcon,
              }
            : {
                value: MOBILE_MENU.GAMES,
                label: localized('mobile.tabMenu.games'),
                icon: GamesTabIcon,
                inactive: true,
              },
          {
            value: MOBILE_MENU.MENU,
            label: localized('mobile.tabMenu.menu'),
            icon: MenuIcon,
            inactive: true,
          },
        ];
  }, [isInIframe, isUserLoggedIn]);

  const mobileTabHighlighted = useMemo(() => {
    if (
      menuQuery === MOBILE_MENU.LIVE ||
      mobileActiveTab === MOBILE_MENU.LIVE
    ) {
      return MOBILE_MENU.LIVE;
    }

    if (
      pathname.includes(MOBILE_MENU.SPORT) ||
      pathname === '/' ||
      mobileActiveTab === MOBILE_MENU.SPORT
    ) {
      return MOBILE_MENU.SPORT;
    }

    return '';
  }, [pathname, menuQuery, mobileActiveTab]);

  return (
    <StyledMobileTabContainer marginBottom={marginBottom}>
      <MenuTabRoot onValueChange={handleTabChange} value={mobileTabHighlighted}>
        {mobileMenuItemsMemo.map(({ value, label, icon: Icon }) => (
          <MenuTabItem key={value} value={value}>
            {value === MOBILE_MENU.BETSLIP ? (
              <StyledMobileTabItemCentral>
                <StyledMobileTabItemIcon css={{ height: '32px' }}>
                  <BetslipTabItem>
                    <Icon />
                  </BetslipTabItem>
                </StyledMobileTabItemIcon>
                <StyledMobileTabItemText>{label}</StyledMobileTabItemText>
              </StyledMobileTabItemCentral>
            ) : (
              <StyledMobileTabItem>
                <StyledMobileTabItemIcon>
                  <CountLabel
                    count={
                      value === MOBILE_MENU.SPORT
                        ? prematchCount
                        : value === MOBILE_MENU.LIVE
                          ? inplayCount
                          : 0
                    }
                  >
                    <Icon />
                  </CountLabel>
                </StyledMobileTabItemIcon>
                <StyledMobileTabItemText>{label}</StyledMobileTabItemText>
              </StyledMobileTabItem>
            )}
          </MenuTabItem>
        ))}
      </MenuTabRoot>
      <StyledMobileTabRoundDecoration marginBottom={marginBottom} />
    </StyledMobileTabContainer>
  );
};
