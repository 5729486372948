import { ComponentType, forwardRef } from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import {
  StyledIconContainer,
  StyledSocialMediaWrapper,
} from '~components/atoms/ShareLink/styled.components';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import {
  CopyLinkIcon,
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from '~icons/shareSocialMedias';

import { SOCIAL_MEDIA } from './constants';
import { SocialMediaItem } from './ShareLink';

interface ShareLinkPopoverContentProps {
  socialMediaOptions: SocialMediaItem[];
  title?: string;
  isCopied?: boolean;
}

export const ShareLinkPopoverContent = forwardRef(
  (
    {
      socialMediaOptions,
      title,
      isCopied = false,
    }: ShareLinkPopoverContentProps,
    ref,
  ) => {
    const { localized } = useTranslation();

    type IconsMap = {
      [key in SOCIAL_MEDIA]: ComponentType;
    };
    const iconsMap: IconsMap = {
      [SOCIAL_MEDIA.X]: XIcon,
      [SOCIAL_MEDIA.INSTAGRAM]: InstagramIcon,
      [SOCIAL_MEDIA.FACEBOOK]: FacebookIcon,
      [SOCIAL_MEDIA.WHATSAPP]: WhatsappIcon,
      [SOCIAL_MEDIA.TELEGRAM]: TelegramIcon,
      [SOCIAL_MEDIA.COPY_LINK]: CopyLinkIcon,
    };

    return (
      <Box
        flexCol
        gap={2}
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Text
          level="14-20"
          textTransform="uppercase"
          textAlign="center"
          css={{
            pt: '$4',
          }}
        >
          {title || localized('shareBet')}
        </Text>
        <Separator
          verticalSpace={0}
          shrinkOut={0}
          css={{
            backgroundColor: '$selectContentSeparatorBgColor',
          }}
        />
        <Box
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))',
            gap: '$4',
            p: '$4',
          }}
        >
          {socialMediaOptions.map(({ icon, onClick, label }) => {
            const Icon = iconsMap[icon];

            return (
              <StyledSocialMediaWrapper onClick={onClick} key={label}>
                <StyledIconContainer
                  isCopied={icon === SOCIAL_MEDIA.COPY_LINK && isCopied}
                >
                  <Icon />
                </StyledIconContainer>
                <Text level="10-12">{label}</Text>
              </StyledSocialMediaWrapper>
            );
          })}
        </Box>
      </Box>
    );
  },
);
