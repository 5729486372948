import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { THEMES } from '~constants/ui';
import {
  useCasinoDataLoad,
  useLaunchEventsSocket,
  useListenEventsLoadingSocket,
  useQueryParams,
  useShare,
  useWebsiteSettings,
} from '~hooks';
import { useIframeAuthorization } from '~hooks/useIframeAuthorization';
import { useListenTokens } from '~hooks/useListenTokens';
import { useLoadBannerGroups } from '~hooks/useLoadBannerGroups';
import { useLoadFavouriteMarkets } from '~hooks/useLoadFavouriteMarkets';
import { useLoadSettings } from '~hooks/useLoadSettings';
import { useLoadUserLimits } from '~hooks/useLoadUserLimits';
import { useLoadUserProfile } from '~hooks/useLoadUserProfile';
import { useSetupPlainSockets } from '~hooks/useSetupPlainSockets';
import { useSignalRSockets } from '~hooks/useSignalRSockets';
import { useTransactionsUpdate } from '~hooks/useTransactionsUpdate';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { login, selectUserProfileLanguageId } from '~store/slices/userSlice';
import { setLanguage } from '~store/slices/websiteSettings';
import { COOKIES_NAMES, getCookie } from '~utils/cookies';
import { getTheme } from '~utils/getTheme';

import { semaBetTheme } from '../stitches.config';

type AppInitDataType = {
  isAuthStatusConfirmed: boolean;
};

export const useOnAppInit = () => {
  const dispatch = useAppDispatch();
  const [appInitData, setAppInitData] = useState<AppInitDataType>({
    isAuthStatusConfirmed: false, // auth status is confirmed and profile data is already received (if user is logged in)

    // ...add new parameters that should be confirmed before the content render
  });
  const { onLanguageChange } = useWebsiteSettings();

  useEffect(() => {
    onLanguageChange(1);
  }, []);

  const { verifyEmailToken } = useQueryParams();

  const languageId = useAppSelector(selectUserProfileLanguageId);
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const { loadProfile } = useLoadUserProfile();

  const updateAppInitData = (updatedValue: Partial<AppInitDataType>) => {
    setAppInitData((prevState) => ({
      ...prevState,
      ...updatedValue,
    }));
  };

  const isAppInitDataReady = useMemo(
    () => Object.values(appInitData).every((v) => v),
    [appInitData],
  );

  useCasinoDataLoad();
  useIframeAuthorization();
  useShare();
  useLaunchEventsSocket();
  useListenEventsLoadingSocket();
  useTransactionsUpdate();
  useListenTokens();
  useSignalRSockets();

  useEffect(() => {
    const path = window.location.pathname;

    if (path.startsWith('//')) {
      window.location.href = '/notFound';
    }

    const theme = getTheme();

    if (theme === THEMES.SEMA_BET) {
      document.documentElement.classList.add(semaBetTheme.className);
    }
  }, []);

  const loginUser = async () => {
    const accessToken = getCookie(COOKIES_NAMES.ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      await loadProfile();
      dispatch(login());
    }

    updateAppInitData({ isAuthStatusConfirmed: true });
  };

  useLayoutEffect(() => {
    loginUser();
  }, []);

  useEffect(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken]);

  useSetupPlainSockets();
  useLoadBannerGroups();

  useEffect(() => {
    if (!languageId) return;

    dispatch(setLanguage(languageId));
  }, [languageId]);

  useLoadSettings(setIsSettingsLoaded);
  useLoadUserLimits(isSettingsLoaded);
  useLoadFavouriteMarkets();

  return { isAppInitDataReady };
};
