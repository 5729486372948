import { memo, useEffect, useRef, useState } from 'react';

import { InplayEvent } from '~components/molecules/InplayEvents/InplayEvent';
import { MARKET_STATUS } from '~constants/common';
import { SIGNAL_R_SOCKET_MESSAGES } from '~constants/signalR';
import { useEventsLoadingSocket, useQueryParams } from '~hooks';
import { SOCKET_TYPE } from '~socket-service/constants';
import useSocket from '~socket-service/hooks/useSocket';
import { useAppSelector } from '~store';
import { selectLanguage } from '~store/slices/websiteSettings';
import { Market, SportEvent } from '~types/events';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { groupByMarketId } from '~utils/markets';

export const InplayEventConnection = memo(() => {
  const { eventId } = useQueryParams();
  const { isPrimaryDataLoaded } = useEventsLoadingSocket();
  const {
    listening: listeningSignalR,
    sendMessage: sendSRMessage,
    isSocketReady: isSRSocketReady,
  } = useSocket(SOCKET_TYPE.SIGNALR);
  const {
    listening: listeningWebSocket,
    sendMessage: sendWSMessage,
    isSocketReady: isWSSocketReady,
  } = useSocket(SOCKET_TYPE.WEB);
  const language = useAppSelector(selectLanguage);

  const [eventData, setEventData] = useState<SportEvent | null>(null);
  const [loadingEventId, setLoadingEventId] = useState<string | null>(null);
  const [isEventLoaded, setIsEventLoaded] = useState(false);

  const eventDataRef = useRef<SportEvent | null>(null);

  const updateEventData = (newData: SportEvent | null) => {
    setEventData(newData);
  };

  useEffect(() => {
    eventDataRef.current = eventData;
  }, [eventData]);

  useEffect(() => {
    if (eventId && isPrimaryDataLoaded && isWSSocketReady) {
      setEventData(null);
      setLoadingEventId(eventId);
      sendWSMessage(ACTION_TYPE.GET_IN_PLAY_EVENT, {
        Id: eventId,
        Language: language,
      });
    }
  }, [eventId, isPrimaryDataLoaded, isWSSocketReady]);

  useEffect(() => {
    if (isEventLoaded && eventId && isSRSocketReady) {
      sendSRMessage(
        SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_FIXTURE_MESSAGE,
        eventId,
      );
    }
  }, [eventId, isEventLoaded, isSRSocketReady]);

  useEffect(() => {
    if (!isWSSocketReady || !isSRSocketReady) return;

    const stopListeningWebSocket = listeningWebSocket({
      [ACTION_TYPE.GET_IN_PLAY_EVENT]: (data) => {
        setLoadingEventId(null);
        updateEventData(data);
        setIsEventLoaded(true);
      },
    });

    const stopListeningSignalR = listeningSignalR({
      [SIGNAL_R_SOCKET_MESSAGES.BET_STOP_MESSAGE]: (data) => {
        if (!eventDataRef.current) return;
        const eventId = data.id;

        if (eventDataRef.current?.id !== eventId) return;
        const resultEvent = { ...eventDataRef.current };

        resultEvent.markets = resultEvent.markets?.map((market) => {
          market.status = MARKET_STATUS.SUSPENDED;

          return market;
        });

        updateEventData(resultEvent as SportEvent);
      },
      [SIGNAL_R_SOCKET_MESSAGES.EXTRA_DATA_MESSAGE]: (response) => {
        if (!eventDataRef.current) return;

        const { extraData, id } = response;
        const eventRef = eventDataRef.current;

        if (id !== eventRef.id) return;

        updateEventData({ ...eventRef, extraData } as SportEvent);
      },
      [SIGNAL_R_SOCKET_MESSAGES.MARKET_UPDATE_MESSAGE]: (response) => {
        if (!eventDataRef.current) return;

        const { active, ...updatedEventData } = response;
        const { eventId } = updatedEventData;
        const eventRef = eventDataRef.current;

        if (eventId !== eventRef.id) return;

        const eventCopy = { ...eventRef };

        eventCopy.marketsCount = active;

        const marketIndex = eventCopy.markets.findIndex(
          (market) => market.id === eventData?.id,
        );

        const marketsCopy = [...eventCopy.markets];

        if (marketIndex !== -1) {
          marketsCopy[marketIndex] = updatedEventData;
        } else {
          marketsCopy.push(response);
        }

        eventCopy.markets = marketsCopy;

        updateEventData(eventCopy as SportEvent);
      },
      [SIGNAL_R_SOCKET_MESSAGES.MARKETS_UPDATE_MESSAGE]: (response) => {
        if (!eventDataRef.current) return;

        const { markets, eventId, active } = response;
        const eventRef = eventDataRef.current;

        if (eventId !== eventRef.id) return;

        const eventCopy = { ...eventRef };

        eventCopy.marketsCount = active;

        const marketsCopy = [...eventCopy.markets];

        markets.forEach((market: Market) => {
          const marketIndex = marketsCopy.findIndex(
            (marketItem) => marketItem.id === market.id,
          );

          if (marketIndex !== -1) {
            marketsCopy[marketIndex] = market;
          } else {
            marketsCopy.push(market);
          }
        });

        eventCopy.markets = marketsCopy;

        updateEventData(eventCopy as SportEvent);
      },
    });

    return () => {
      stopListeningSignalR();
      stopListeningWebSocket();
      sendSRMessage(SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_FIXTURE_MESSAGE);
    };
  }, [isWSSocketReady, isSRSocketReady]);

  return (
    <InplayEvent
      eventData={eventData}
      isEventLoaded={isEventLoaded}
      loadingEventId={loadingEventId}
      marketGroups={groupByMarketId(eventData?.markets || [])}
    />
  );
});
