import React from 'react';

import { Box } from '~components/atoms/Box';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { SportSliderItem } from '~components/molecules/PrematchMenu/SportSliderItem';
import { useTopEventsSocket } from '~socket-service/hooks/useTopEventsSocket/useTopEventsSocket';
import { SVGElement } from '~types/general';

import { useLiveMenuGroups } from '../hooks/useLiveMenuGroups';

export const LiveMenuGroupsMobile = () => {
  const { data: topLiveEvents } = useTopEventsSocket({ isLive: true });
  const { groupsMemo, activeGroup } = useLiveMenuGroups({ topLiveEvents });

  if (!groupsMemo.length) {
    return null;
  }

  return (
    <>
      <Box>
        <TouchSlider type="sportGroup">
          {groupsMemo.map(({ id, name, label, icon, onClick }) => {
            const isActive = activeGroup === name;

            return (
              <SportSliderItem
                key={id}
                label={label}
                className={isActive ? 'active' : ''}
                icon={icon as SVGElement}
                onClick={() => onClick && onClick()}
              />
            );
          })}
        </TouchSlider>
      </Box>
    </>
  );
};
