import { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Cross } from '~components/atoms/Cross';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';
import { useDebouncedEffect } from '~hooks/useDebouncedEffect';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { SearchIcon } from '~icons';

interface SearchFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchField = ({ value, onChange }: SearchFieldProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const [searchValue, setSearchValue] = useState(value);

  useDebouncedEffect(
    () => {
      onChange(searchValue);
    },
    [searchValue],
    300,
  );

  return (
    <Box
      css={{
        p: '$4 $5',
        '@md': {
          p: '$3 $4',
        },
        '@xs_sm': {
          p: '$4 $5',
        },
      }}
    >
      <Box
        css={{
          p: '$3',
          borderRadius: '$10',
          background: '$blueDark',
          '@xs_sm': {
            p: '$2',
          },
        }}
      >
        <Box
          flexRow
          alignCenter
          gap={2}
          css={{
            borderRadius: '$6',
            position: 'relative',
            border: '1px solid $grayDarker',
            background: '$black',
            color: !value.length ? '$grayMedium' : '$white',
            p: '$1 $1 $1 $2',
            '& > svg': {
              minWidth: '20px',
              minHeight: '20px',
            },
            '& > div:last-child': {
              top: '2px',
            },
          }}
        >
          <SearchIcon />
          <Input
            css={{
              background: 'transparent',
              height: '36px',
              p: '$1',
              '@xs_sm': {
                height: '28px',
              },
            }}
            value={searchValue}
            placeholder={localized('casino.searchPlaceholder')}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {!!value.length && (
            <>
              {isMobileOrTablet ? (
                <Cross onClick={() => setSearchValue('')} />
              ) : (
                <Button
                  size="small"
                  css={{
                    height: '34px',
                    background: '$grayDark',
                    borderRadius: '$4',
                    border: '1px solid transparent',
                    '&:hover': {
                      boxShadow: 'none',
                      ' & p': {
                        color: '$red !important',
                      },
                    },
                    '&:active': {
                      boxShadow: 'none',
                      border: '1px solid $red',
                    },
                  }}
                  onClick={() => setSearchValue('')}
                >
                  <Text level={'12-20'}>Clear</Text>
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
