import { Bonus } from '~api/bonus/types';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { getLocalizedBonusHistoryStatusOptions } from '../../constants';

export const useBonusHistoryItem = ({ bonus }: { bonus: Bonus }) => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);

  const {
    title,
    totalAmount,
    remainingAmount,
    won,
    maxWin,
    createDate,
    status,
    expireDate,
    betSlipCodes,
  } = bonus || {};

  const givenDate = formatDateTime(createDate, TIME_FORMATS.DATE_TIME_24H);
  const expiresDate = formatDateTime(expireDate, TIME_FORMATS.DATE_TIME_24H);
  const statusOptionsMemo = getLocalizedBonusHistoryStatusOptions(localized);
  const statusName = statusOptionsMemo.find(
    (item) => item.value === status.toString(),
  )?.label;
  const maxWinName =
    maxWin === null
      ? CURRENCY_SYMBOLS[currency]
      : `/ ${commaSeparatedNumber(maxWin)} ${CURRENCY_SYMBOLS[currency]}`;

  return {
    betSlipCodes,
    currency,
    expiresDate,
    givenDate,
    maxWinName,
    remainingAmount,
    status,
    statusName,
    title,
    totalAmount,
    won,
    localized,
  };
};
