import { CSS } from '@stitches/react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { skeleton } from '~utils/keyframes';

interface SkeletonProps {
  css?: CSS;
}

const SkeletonWrapper = styled(Box, {
  border: '1px solid $grayDark',
  borderRadius: '$4',
  background: 'linear-gradient(270deg, $grayDark 1.04%, $gray 100%)',
  animation: `${skeleton} 1s linear infinite`,
  backgroundSize: '200% 200%',
});

export const SkeletonCompact = ({ css }: SkeletonProps) => {
  return <SkeletonWrapper css={css} />;
};
