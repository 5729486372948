import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Input } from '~components/atoms/Input';
import { Text } from '~components/atoms/Typography';
import { usePhoneNumber } from '~hooks/usePhoneNumber';
import { useTranslation } from '~hooks/useTranslation';

import { useCardPinOtpForm } from '../../hooks/useCardPinOtpForm';

export const AddCardPinForm = () => {
  const { localized } = useTranslation();
  const {
    error,
    code,
    addingNewPaymentMethodFormStatus,
    profile,
    timeRemaining,
    isTimerAvailable,
    formatTime,
    onConfirm,
    onBack,
    resendInitialCharge,
    handleChangeCode,
  } = useCardPinOtpForm();
  const { maskPhoneNumber } = usePhoneNumber();

  return (
    <Box fullWidth flexCol alignCenter justifyCenter gap={2}>
      <Text
        css={{
          fontSize: '17px',
          lineHeight: '$24',
        }}
        textTransform="uppercase"
      >
        {localized('pinOtpForm.title', {
          code: addingNewPaymentMethodFormStatus,
        })}
      </Text>
      <Text css={{ mb: '$2', textAlign: 'center' }} level="sm-3">
        {localized('pinOtpForm.description', {
          code: addingNewPaymentMethodFormStatus,
          phoneMask: profile?.phoneNumber
            ? maskPhoneNumber(parseInt(profile.phoneNumber))
            : '',
        })}
      </Text>
      <Box
        fullWidth
        css={{
          position: 'relative',
        }}
      >
        {error && (
          <Text
            css={{
              position: 'absolute',
              top: '-14px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              width: '100%',
              textAlign: 'center',
            }}
            level="xxxxs-1"
            color="red"
          >
            {error}
          </Text>
        )}
        <Input
          onChange={handleChangeCode}
          value={code}
          inputMode="numeric"
          placeholder={localized('pinOtpForm.inputCodePlaceholder', {
            code: addingNewPaymentMethodFormStatus,
          })}
          css={{
            textAlign: 'center',
            background: '$gray',
            width: '100%',
            lineHeight: '$24',
            p: '$1 $2',
            mb: '$2',
            color: error ? '$red' : '$white',
          }}
        />
      </Box>

      <Box flexCol alignCenter justifyCenter gap={1}>
        <Text level="sm-3">{localized('pinOtpForm.sendAgainTitle')}</Text>
        <Text level="sm-3">{localized('pinOtpForm.sendAgainDescription')}</Text>
      </Box>
      <Button
        size="medium"
        fullWidth
        onClick={resendInitialCharge}
        disabled={isTimerAvailable}
        isLoading={false}
        css={{
          position: 'relative',
          marginTop: '$2',
        }}
      >
        <Text level="14-20" fontWeight="bold">
          {localized('buttons.getAgain')}
        </Text>
        <Text
          color="yellow"
          textTransform="none"
          level="12-20"
          as="span"
          css={{
            position: 'absolute',
            right: '12px',
            top: '8px',
          }}
        >
          {isTimerAvailable
            ? localized('signUp.availableInN', {
                minutes: formatTime(timeRemaining),
              })
            : ''}
        </Text>
      </Button>
      <Box
        flexRow
        fullWidth
        gap={2}
        css={{
          marginTop: '$6',
        }}
      >
        <Button variant="secondary" fullWidth onClick={onBack}>
          {localized('buttons.back')}
        </Button>
        <Button fullWidth isLoading={false} onClick={onConfirm}>
          {localized('buttons.confirm')}
        </Button>
      </Box>
    </Box>
  );
};
