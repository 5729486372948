import {
  ResetPasswordFormInputs,
  SigninFormInputs,
  SignupFormInputs,
  SUPPORTED_COUNTRY_CODE,
} from '~components/molecules/Authentication/types';
import { CURRENCY_CODES } from '~constants/common';

export const SIGNUP_FORM_DEFAULT_VALUES: SignupFormInputs = {
  usernameField: '',
  phoneNumberField: '',
  passwordField: '',
  promotionCodeField: '',
  agreeToTermsField: false,
  numberCodeField: '',
};

export const SIGNIN_FORM_DEFAULT_VALUES: SigninFormInputs = {
  usernameField: '',
  passwordField: '',
  emailField: '',
  phoneNumberField: '',
};

export const RESET_PASSWORD_FORM_DEFAULT_VALUES: ResetPasswordFormInputs = {
  phoneNumberField: '',
  numberCodeField: '',
  newPasswordField: '',
};

export const USERNAME_MIN_LIMIT = 3;

export const USERNAME_MAX_LIMIT = 30;

export const SUPPORTED_COUNTRIES_LIST: SUPPORTED_COUNTRY_CODE[] = [
  SUPPORTED_COUNTRY_CODE.NGN,
];

export const PHONE_FIELD_MASKS: { [key in CURRENCY_CODES]: string } = {
  [CURRENCY_CODES.NGN]: '##########',
  [CURRENCY_CODES.UGX]: '##########',
  [CURRENCY_CODES.MZ]: '############',
  [CURRENCY_CODES.UA]: '############',
  [CURRENCY_CODES.TZ]: '############',
  [CURRENCY_CODES.ZMK]: '##########',
  [CURRENCY_CODES.BRL]: '##########',
  [CURRENCY_CODES.INR]: '##########',
};

export const DEFAULT_PHONE_FIELD_MASK = '### - ### - ####';

export const PHONE_FIELD_MASKS_WITH_DASH: { [key in CURRENCY_CODES]: string } =
  {
    [CURRENCY_CODES.NGN]: DEFAULT_PHONE_FIELD_MASK,
    [CURRENCY_CODES.UGX]: DEFAULT_PHONE_FIELD_MASK,
    [CURRENCY_CODES.MZ]: '####-####-####',
    [CURRENCY_CODES.UA]: '####-####-####',
    [CURRENCY_CODES.TZ]: '####-####-####',
    [CURRENCY_CODES.ZMK]: '###-###-####',
    [CURRENCY_CODES.BRL]: '###-###-####',
    [CURRENCY_CODES.INR]: '###-###-####',
  };

export const PHONE_FIELD_PLACEHOLDERS: {
  [key in CURRENCY_CODES]: string;
} = {
  [CURRENCY_CODES.NGN]: '',
  [CURRENCY_CODES.UGX]: '',
  [CURRENCY_CODES.MZ]: '',
  [CURRENCY_CODES.UA]: '',
  [CURRENCY_CODES.TZ]: '',
  [CURRENCY_CODES.ZMK]: '',
  [CURRENCY_CODES.BRL]: '',
  [CURRENCY_CODES.INR]: '',
};

export const PHONE_FIELD_COUNTRY_CODES: {
  [key in CURRENCY_CODES]: string;
} = {
  [CURRENCY_CODES.NGN]: '+234',
  [CURRENCY_CODES.UGX]: '+256',
  [CURRENCY_CODES.MZ]: '+258',
  [CURRENCY_CODES.UA]: '+380',
  [CURRENCY_CODES.TZ]: '+255',
  [CURRENCY_CODES.ZMK]: '+260',
  [CURRENCY_CODES.BRL]: '+55',
  [CURRENCY_CODES.INR]: '+91',
};

export const PHONE_FIELD_COUNTRY_LENGTH: {
  [key in CURRENCY_CODES]: number;
} = {
  [CURRENCY_CODES.NGN]: 10,
  [CURRENCY_CODES.UGX]: 9,
  [CURRENCY_CODES.MZ]: 10,
  [CURRENCY_CODES.UA]: 10,
  [CURRENCY_CODES.TZ]: 10,
  [CURRENCY_CODES.ZMK]: 9,
  [CURRENCY_CODES.BRL]: 9,
  [CURRENCY_CODES.INR]: 10,
};

export const PHONE_LENGTH = 9;

export const NEW_CODE_INTERVAL_MINUTES = 2;

export const REFRESH_TOKEN_SECONDS_BEFORE_EXPIRATION = 10;

export const REFRESH_TOKEN_DAYS_BEFORE_EXPIRATION = 7;

export const INPUT_CODE_LENGTH = 6;
