import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { BannerGroup } from '~api/content/types';
import { Box } from '~components/atoms/Box';
import { getSanitizedHTML } from '~utils/htmlUtils';

interface BannerListProps {
  group: BannerGroup;
  isVeritical?: boolean;
}

export const BannerItem = ({ group, isVeritical }: BannerListProps) => {
  if (!group) {
    return null;
  }

  const { rotationTime, banners } = group;

  if (!banners || banners.length === 0) {
    return null;
  }

  // leave carousel for each item in order to apply all the styles to make banners fit container wrapper
  if (isVeritical) {
    return (
      <Box flexCol>
        {banners.map((banner, index) => {
          return (
            <Box
              key={index}
              css={{
                height: '100%',
                '& div': {
                  height: '100%',
                },
                img: {
                  height: 'inherit',
                },
                '& .slider-wrapper': {
                  height: '100% !important',
                },
                '& ul': {
                  height: '100%',
                },
                '*': {
                  p: 0,
                  m: 0,
                },
              }}
            >
              <Carousel
                key={index}
                dynamicHeight
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
              >
                {[
                  <Box
                    css={{
                      overflow: 'hidden',
                      display: 'inline-block',
                      width: '100%',
                      mt: '$1',
                      '&:first-child': {
                        mt: 0,
                      },
                      '& a': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: getSanitizedHTML(banner),
                    }}
                  />,
                ]}
              </Carousel>
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box
      css={{
        height: '100%',
        '& div': {
          height: '100%',
        },
        img: {
          height: 'inherit',
        },
        '& .slider-wrapper': {
          height: '100% !important',
        },
        '& ul': {
          height: '100%',
        },
        'ul > li': {
          aspectRatio: 1 / 0.25,
        },
        '*': {
          p: 0,
          m: 0,
        },
      }}
    >
      <Carousel
        autoPlay
        stopOnHover
        infiniteLoop
        dynamicHeight
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        interval={rotationTime}
      >
        {banners.map((banner, index) => {
          return (
            <Box
              key={index}
              css={{
                overflow: 'hidden',
                display: 'inline-block',
                width: '100%',
                mt: '$1',
                '&:first-child': {
                  mt: 0,
                },
                '& a': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: getSanitizedHTML(banner),
              }}
            />
          );
        })}
      </Carousel>
    </Box>
  );
};
