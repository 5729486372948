import React, { useMemo, useRef, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { BetslipConfirmationPopover } from '~components/molecules/Betslip/components/BetslipFooter/BetslipConfirmationPopover';
import { StyledConfirmationBackgroundBlur } from '~components/molecules/Betslip/components/BetslipFooter/styled.components';
import { StyledBetslipFooterWrapper } from '~components/molecules/Betslip/components/BetslipStakes/styled.components';
import { useBetslipFooter } from '~components/molecules/Betslip/hooks';
import { useRouterQuery } from '~hooks';
import { useComponentObserver } from '~hooks/useComponentObserver';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { selectIsSingleBetslipType } from '~store/slices/selectors/betslip';
import { setBetslipFooterHeight } from '~store/slices/userUISlice';

import { BetAdjustmentButtons } from './BetAdjustmentButtons';
import { BetslipAggregatedValue } from './BetslipAggregatedValue';
import { BetslipErrors } from './BetslipErrors';
import { BetslipFooterInactiveButton } from './BetslipFooterInactiveButton';
import { BetslipSubmitButton } from './BetslipSubmitButton';

interface BetslipFooterProps {
  isStatic?: boolean;
  quickBet?: boolean;
  isInfoMessage?: boolean;
  handleErrorMessageCloseClick: () => void;
  betslipHasElements: boolean;
}

export const BetslipFooter = ({
  isStatic,
  quickBet = false,
  isInfoMessage = false,
  handleErrorMessageCloseClick,
  betslipHasElements,
}: BetslipFooterProps) => {
  const { navigateBackOrHome } = useRouterQuery();
  const dispatch = useAppDispatch();
  const { isMobileOrTablet } = useMedia();
  const { events } = useAppSelector((state) => state.betslip);
  const isSingleBetslipType = useAppSelector(selectIsSingleBetslipType);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const showConfirmationPopover = useMemo(
    () => !quickBet && isMobileOrTablet && isConfirmOpen,
    [quickBet, isMobileOrTablet, isConfirmOpen],
  );

  const {
    onClick,
    onDeleteClick,
    isDisabled,
    isUserLoggedIn,
    isBetslipChanged,
    localizationPath,
  } = useBetslipFooter(quickBet);

  useComponentObserver(componentRef, (height) => {
    dispatch(setBetslipFooterHeight(height));
  });

  if (
    (!isInfoMessage && quickBet && !events.length) ||
    (isInfoMessage && !isMobileOrTablet)
  ) {
    return null;
  }

  const renderFooter = () =>
    isInfoMessage ? (
      <BetslipFooterInactiveButton
        isQuickBet={quickBet}
        onClick={handleErrorMessageCloseClick}
      />
    ) : (
      <Box flexCol>
        {!isSingleBetslipType && !!events.length && <BetslipAggregatedValue />}
        <BetslipErrors isQuickBet={quickBet} />
        {betslipHasElements && (
          <>
            <Separator
              verticalSpace={0}
              shrinkOut={3}
              css={{ backgroundColor: '$betslipFooterSeparatorBgColor' }}
            />
            <BetAdjustmentButtons />
            <Separator
              verticalSpace={3}
              shrinkOut={3}
              css={{ backgroundColor: '$betslipFooterSeparatorBgColor' }}
            />
          </>
        )}
        {betslipHasElements ? (
          <BetslipSubmitButton
            quickBet={quickBet}
            onClick={onClick}
            onDeleteClick={onDeleteClick}
            isDisabled={isDisabled}
            isUserLoggedIn={isUserLoggedIn}
            isBetslipChanged={isBetslipChanged}
            isConfirmOpen={isConfirmOpen}
            setIsConfirmOpen={setIsConfirmOpen}
            localizationPath={localizationPath}
          />
        ) : (
          isMobileOrTablet && (
            <BetslipFooterInactiveButton
              isQuickBet={quickBet}
              onClick={navigateBackOrHome}
              text={'buttons.startBetting'}
            />
          )
        )}
      </Box>
    );

  return (
    <>
      <StyledBetslipFooterWrapper
        ref={componentRef}
        static={isStatic}
        isConfirmationPopover={showConfirmationPopover}
      >
        {showConfirmationPopover ? (
          <BetslipConfirmationPopover
            setIsOpen={setIsConfirmOpen}
            onConfirm={onClick}
          />
        ) : (
          renderFooter()
        )}
      </StyledBetslipFooterWrapper>
      {/* This is the correct position of blurred background element, if we move it inside StyledBetslipFooterWrapper, it won't work  */}
      {showConfirmationPopover && (
        <StyledConfirmationBackgroundBlur absoluteCenter />
      )}
    </>
  );
};
