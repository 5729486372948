import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useLazyGetInfoPage } from '~api/content/contentQueries';
import { NotFoundPage } from '~components/atoms/NotFoundPage';
import { PageLoader } from '~components/atoms/PageLoader';
import { CHANNEL_TYPES, INFO_PAGE_SECTION_NAMES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { InviteFriendsSection } from '~pages/Info/InviteFriendsSection';
import {
  StyledInfoContentWrapper,
  StyledInfoPageContainer,
} from '~pages/Info/styled.components';
import { CallBackSection } from '~pages/Info/СallBackSection';

interface InfoPageProps {
  pageName?: string;
  embedded?: boolean; // embedded is used for the pages that are shown in the modal (user profile, for ex.)
}

export const InfoPage = ({ pageName, embedded = false }: InfoPageProps) => {
  const { pathname } = useLocation();
  const name = pageName || pathname.split('/').pop();
  const {
    lazyGetInfoQuery,
    lazyGetInfoData,
    lazyGetInfoQueryError,
    lazyGetInfoQueryIsFetching,
  } = useLazyGetInfoPage();
  const htmlCode = lazyGetInfoData?.content || '';
  const { isMobileOrTablet } = useMedia();
  const SECTIONS_MAP: Partial<Record<INFO_PAGE_SECTION_NAMES, ReactNode>> = {
    [INFO_PAGE_SECTION_NAMES.CALLBACK]: <CallBackSection />,
    [INFO_PAGE_SECTION_NAMES.INVITE]: (
      <InviteFriendsSection embedded={embedded} />
    ),
  };
  const topSection = SECTIONS_MAP[name as INFO_PAGE_SECTION_NAMES];
  const isProfilePage = name === INFO_PAGE_SECTION_NAMES.INVITE;

  useEffect(() => {
    if (name) {
      lazyGetInfoQuery({
        name,
        channelType: isMobileOrTablet
          ? CHANNEL_TYPES.MOBILE
          : CHANNEL_TYPES.WEB,
      });
    }
  }, [name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  // If we have predefined top section - we don't show Not Found page even if no content returned from the BE
  if (
    (!topSection && lazyGetInfoQueryError && !lazyGetInfoQueryIsFetching) ||
    !name
  ) {
    return <NotFoundPage />;
  }

  if (!lazyGetInfoData && lazyGetInfoQueryIsFetching) {
    return <PageLoader />;
  }

  return (
    <StyledInfoPageContainer
      embedded={embedded}
      isProfilePage={isProfilePage}
      withPaddings={
        name === INFO_PAGE_SECTION_NAMES.CALLBACK && isMobileOrTablet
      }
    >
      <StyledInfoContentWrapper embedded={embedded}>
        {topSection ? topSection : null}

        <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
      </StyledInfoContentWrapper>
    </StyledInfoPageContainer>
  );
};

export default InfoPage;
