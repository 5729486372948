import { ComponentPropsWithRef, forwardRef } from 'react';
import { CSS } from '@stitches/react';

import { Box } from '~components/atoms/Box';
import { RedCrossIcon, RedCrossIconBorder } from '~icons';

interface CrossProps extends ComponentPropsWithRef<'div'> {
  css?: CSS;
}

export const Cross = forwardRef<HTMLDivElement, CrossProps>(
  ({ css, ...props }, ref) => {
    return (
      <Box
        css={{
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          p: '$2',
          lineHeight: 0,
          ...css,
          '& .crossIcon': {
            width: '$4',
            height: '$4',
            cursor: 'pointer',
          },
          '& .crossIconBorder': {
            pointerEvents: 'none',
            display: 'none',
            position: 'absolute',
            top: '$2',
            left: '$2',
          },
          '& .crossIcon:hover + .crossIconBorder': {
            display: 'block',
          },
        }}
        ref={ref}
        {...props}
      >
        <RedCrossIcon className="crossIcon" />
        <RedCrossIconBorder className="crossIconBorder" />
      </Box>
    );
  },
);
