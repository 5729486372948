import './assets/flags/freakflags.css';

import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { injectGlobalStyles } from 'stitches.config';
import { registerSW } from 'virtual:pwa-register';

import { ErrorPage } from '~components/atoms/ErrorPage/ErrorPage';
import { persistor, store } from '~store';

import i18n from './localization/i18n/config';
import App from './App';

// Removing loading on init
document.querySelector('html')?.removeAttribute('style');

injectGlobalStyles();

// Add Google Tag Manager
if (
  (import.meta.env.VITE_MODE as string) === 'production' &&
  window.location.hostname.includes('semabet')
) {
  TagManager.initialize({
    gtmId: 'GTM-NT6QL4TL',
  });
}

const environment = import.meta.env.VITE_ENVIRONMENT || 'development';

// Initialize Sentry
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, // Adjust this value to control the percentage of transactions captured for performance monitoring
  environment,
  debug: false, // Enable Sentry debug logs
});

const intervalMS = 1000 * 60 * 10;

let isUpdating = false;

registerSW({
  onRegisteredSW(_, registration) {
    const updateServiceWorker = () => {
      if (isUpdating) return;
      isUpdating = true;

      registration.update().finally(() => {
        isUpdating = false;
      });
    };

    setInterval(updateServiceWorker, intervalMS);
  },
});

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <Sentry.ErrorBoundary
          fallback={<ErrorPage title="Oops! Something went wrong." />}
        >
          <Router>
            <App />
          </Router>
        </Sentry.ErrorBoundary>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
);
