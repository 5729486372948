import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BalanceChange,
  BalanceChangeResponse,
  BetAmount,
  BetslipItem,
  BetslipItems,
} from '~api/betslip/types';
import { SportEventItem, SportEventItems } from '~api/sportEvent/types';
import {
  BETSLIP_ERRORS,
  BETSLIP_TOGGLE_TYPES,
  BETSLIP_VALUES_MAP_OPTIONS,
  ODD_ASK_TYPES,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '~components/molecules/Betslip/constants';
import { SystemBetOption } from '~types/betslip';
import { JackpotBetsHistory } from '~types/jackpot';
import { convertArrayToObject } from '~utils/arrayHelpers';
import {
  calculateAggregatedBetBalanceChanges,
  haveBalanceChangesChanged,
} from '~utils/betslip';

export interface BetslipState {
  activeTab: BETSLIP_TOGGLE_TYPES;
  oddAskType: ODD_ASK_TYPES | null;
  stakeType: SPORT_BETSLIP_TYPE_OPTIONS;
  systemBetOption: SystemBetOption | null;
  singleBetsAmountMap: Record<string, BetAmount>;
  aggregatedBetAmount: BetAmount;
  multipleBetBonusAmount: number;
  betErrorCode: number;
  betErrorEvent: string | null;
  betErrorMessage: string | null;
  multipleBetBonusDescriptions: string[];
  duplicatedMultipleBetStakes: string[];
  events: BetslipItems;
  eventsData: SportEventItems;
  rebet: BetslipItems;
  rebetSingleBetsAmountMap: Record<string, BetAmount>;
  rebetStakeType: SPORT_BETSLIP_TYPE_OPTIONS;
  rebetAggregatedBetAmount: BetAmount;
  inactiveEvents: string[];
  betslipErrors: BETSLIP_ERRORS[];
  isProcessing: boolean;
  isProcessed: boolean;
  isProcessingFailed: boolean;
  isBetslipChanged: string[];
  isBetslipHistoryLoading: boolean;
  shareCode: string | null;
  balanceChangesMap: Record<string | BETSLIP_VALUES_MAP_OPTIONS, BalanceChange>;
  balanceChangesOnBetData: BalanceChangeResponse | null;
  stakePossibleWinLoadingMap: Record<
    string | BETSLIP_VALUES_MAP_OPTIONS,
    boolean
  >;
  reloadBetslip: boolean;
  jackpotsBetsHistory: JackpotBetsHistory[];
  selectedJackpotHistoryId: null | string;
  lastPlacedBetslipIds: string[];
  showBetslipNotification: boolean;
}

const initialState: BetslipState = {
  activeTab: BETSLIP_TOGGLE_TYPES.BETSLIP,
  oddAskType: null,
  stakeType: SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE,
  systemBetOption: null,
  singleBetsAmountMap: {},
  aggregatedBetAmount: {},
  betErrorCode: 0,
  betErrorEvent: null,
  betErrorMessage: null,
  multipleBetBonusAmount: 0,
  multipleBetBonusDescriptions: [],
  duplicatedMultipleBetStakes: [],
  events: [],
  eventsData: [],
  rebet: [],
  rebetSingleBetsAmountMap: {},
  rebetStakeType: SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE,
  rebetAggregatedBetAmount: {},
  inactiveEvents: [],
  betslipErrors: [],
  isProcessing: false,
  isProcessed: false,
  isProcessingFailed: false,
  isBetslipChanged: [],
  isBetslipHistoryLoading: false,
  shareCode: null,
  balanceChangesMap: {},
  balanceChangesOnBetData: null,
  stakePossibleWinLoadingMap: {},
  reloadBetslip: false,
  jackpotsBetsHistory: [],
  selectedJackpotHistoryId: null,
  lastPlacedBetslipIds: [],
  showBetslipNotification: false,
};

export const betslipSlice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    toggleBetslipType: (state, action: PayloadAction<BETSLIP_TOGGLE_TYPES>) => {
      state.activeTab = action.payload;
    },
    setStakeType: (
      state,
      action: PayloadAction<SPORT_BETSLIP_TYPE_OPTIONS>,
    ) => {
      state.isBetslipChanged = [];
      if (action.payload !== SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM) {
        state.systemBetOption = null;
      }

      state.stakeType = action.payload;
    },
    setOddAskType: (state, action: PayloadAction<ODD_ASK_TYPES>) => {
      state.oddAskType = action.payload;
    },
    setSystemBetOption: (
      state,
      action: PayloadAction<SystemBetOption | null>,
    ) => {
      state.systemBetOption = action.payload;
    },
    setAdjustedBetAmount: (state, action: PayloadAction<BetAmount>) => {
      if (state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
        const nextSingleBetsAmountMap: Record<string, BetAmount> = {
          ...state.singleBetsAmountMap,
        };

        state.events.forEach(({ selectionId }) => {
          nextSingleBetsAmountMap[selectionId] = {
            ...nextSingleBetsAmountMap[selectionId],
            ...action.payload,
          };
        });
        state.singleBetsAmountMap = nextSingleBetsAmountMap;
      } else {
        state.aggregatedBetAmount = {
          ...state.aggregatedBetAmount,
          ...action.payload,
        };
      }
    },
    setBetslipErrors: (state, action: PayloadAction<BETSLIP_ERRORS[]>) => {
      state.betslipErrors = action.payload;
    },
    setDuplicatedMultipleBetStakes: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.duplicatedMultipleBetStakes = action.payload;
    },
    setEventsData: (state, action: PayloadAction<SportEventItems>) => {
      state.eventsData = action.payload;
    },
    updateSingleEvent: (state, action: PayloadAction<SportEventItem>) => {
      state.eventsData = state.eventsData.map((event) => {
        if (event.id === action.payload.id) {
          return action.payload;
        }

        return event;
      });
    },
    addStake: (state, action: PayloadAction<BetslipItem>) => {
      const { eventData, ...betslipItem } = action.payload;

      state.showBetslipNotification = false;
      state.isProcessed = false;

      if (state.rebet.length) {
        state.rebet = [];
      }

      if (state.events.length === 0) {
        state.singleBetsAmountMap = {};
      }

      if (state.activeTab === BETSLIP_TOGGLE_TYPES.OPEN_BETS) {
        state.activeTab = BETSLIP_TOGGLE_TYPES.BETSLIP;
      }

      if (state.events.length === 1 && state.events[0]) {
        const isDifferentEventId =
          state.events[0].eventId !== betslipItem.eventId;

        if (isDifferentEventId) {
          // We can have isDifferentEventId and SYSTEM bet, in that case - don't change type
          if (state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
            state.stakeType = SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE;
          }
        } else {
          state.stakeType = SPORT_BETSLIP_TYPE_OPTIONS.SINGLE;
        }
      }

      const isEventInBetSlip = state.events.some(
        (event) => event.eventId === betslipItem.eventId,
      );

      const shouldRemoveEvent = state.events.some(
        (event) => event.selectionId === betslipItem.selectionId,
      );

      if (!shouldRemoveEvent && eventData) {
        const isEventDataInBetSlip = state.eventsData.some(
          ({ id }) => id === betslipItem.eventId,
        );

        if (isEventDataInBetSlip) {
          state.eventsData = state.eventsData.map((event) => {
            if (event.id === betslipItem.eventId) {
              const resultEvent = { ...event };
              const eventMarketIndex = event.markets.findIndex(
                (market) => market.id === betslipItem.marketId,
              );

              const eventDataMarket = eventData.markets.find(({ id }) => {
                return id === betslipItem.marketId;
              });

              if (eventMarketIndex !== -1 && event.markets[eventMarketIndex]) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore

                event.markets[eventMarketIndex] = eventDataMarket;
              } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                resultEvent.markets = [...event.markets, eventDataMarket];
              }

              return resultEvent;
            }

            return event;
          });
        } else {
          state.eventsData = [...state.eventsData, eventData as SportEventItem];
        }
      }

      if (
        state.events.length > 1 &&
        state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE
      ) {
        if (isEventInBetSlip) {
          state.events = [
            ...state.events.filter(
              ({ eventId }) => eventId !== betslipItem.eventId,
            ),
            betslipItem,
          ];

          return;
        }
      }

      state.events = [...state.events, action.payload];
    },
    setStakes: (state, action: PayloadAction<BetslipItems>) => {
      if (state.events.length === 0) {
        state.isBetslipChanged = [];
        state.aggregatedBetAmount = {};
        state.multipleBetBonusAmount = 0;
      }

      state.events = [...action.payload];
    },
    removeStakeBySelection: (state, action: PayloadAction<string>) => {
      const selectionId = action.payload;

      // Hide all notifications on stake update
      state.showBetslipNotification = false;
      state.isProcessed = false;

      // Filter out the event with the given selectionId
      state.events = state.events.filter(
        (item) => item.selectionId !== selectionId,
      );

      // Filter out the selectionId from isBetslipChanged
      if (Array.isArray(state.isBetslipChanged)) {
        state.isBetslipChanged = state.isBetslipChanged.filter(
          (item) => item !== selectionId,
        );
      }

      // If there are no events left, reset certain state properties
      if (state.events.length === 0) {
        state.aggregatedBetAmount = {};
        state.multipleBetBonusAmount = 0;
        state.isBetslipChanged = [];
      }

      // Delete the selectionId from singleBetsAmountMap
      if (state.singleBetsAmountMap[selectionId]) {
        delete state.singleBetsAmountMap[selectionId];
      }

      // Delete the selectionId from balanceChangesMap
      if (state.balanceChangesMap[selectionId]) {
        delete state.balanceChangesMap[selectionId];
      }

      if (state.stakeType !== SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
        state.stakePossibleWinLoadingMap = {
          [BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED]: true,
        };
      }
    },
    setSingleBetAmount: (
      state,
      action: PayloadAction<{
        id: string;
        amount: BetAmount;
      }>,
    ) => {
      state.rebet = [];
      const { id, amount } = action.payload;
      const nextSingleBetsAmountMap = { ...state.singleBetsAmountMap };

      nextSingleBetsAmountMap[id] = amount;
      state.singleBetsAmountMap = nextSingleBetsAmountMap;
    },
    setSingleBetAmountMap: (
      state,
      action: PayloadAction<Record<string, BetAmount>>,
    ) => {
      state.rebet = [];
      state.singleBetsAmountMap = action.payload;
    },
    setBetslipProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setBetslipProcessingFailed: (state, action: PayloadAction<boolean>) => {
      state.isProcessingFailed = action.payload;
      state.isProcessing = false;
      state.isProcessed = true;
    },
    setBetslipProcessed: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = false;
      state.isProcessingFailed = false;
      const isProcessed = action.payload;

      state.isProcessed = isProcessed;
      if (isProcessed) {
        state.rebet = state.events;
        state.rebetAggregatedBetAmount = state.aggregatedBetAmount;
        state.rebetSingleBetsAmountMap = state.singleBetsAmountMap;
        state.rebetStakeType = state.stakeType;
        state.events = [];
        state.singleBetsAmountMap = {};
        state.balanceChangesMap = {};
        state.aggregatedBetAmount = {};
        state.multipleBetBonusAmount = 0;
        state.singleBetsAmountMap = {};
        state.multipleBetBonusDescriptions = [];
        state.duplicatedMultipleBetStakes = [];
        state.betslipErrors = [];
        state.inactiveEvents = [];
      }
    },
    setAggregatedBetAmount: (state, action: PayloadAction<BetAmount>) => {
      state.aggregatedBetAmount = action.payload;
    },
    setBetslipChanged: (state, action: PayloadAction<string>) => {
      const selectionId = action.payload;

      !state.isBetslipChanged.includes(selectionId) &&
        state.isBetslipChanged.push(selectionId);
    },
    resetBetslipChanged: (state) => {
      state.isBetslipChanged = [];
    },
    setIsBetslipHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.isBetslipHistoryLoading = action.payload;
    },
    setShareCode: (state, action: PayloadAction<string | null>) => {
      state.shareCode = action.payload;
    },
    setBalanceChangesOnBetData: (
      state,
      action: PayloadAction<BalanceChangeResponse | null>,
    ) => {
      const isSingleBet = state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE;
      const isSystemBet = state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;

      state.balanceChangesOnBetData = action.payload;

      if (isSingleBet) {
        // We filter out unchanged balanceChanges and update only changed ones
        const filteredBalanceChanges =
          action.payload?.balanceChanges?.filter((balanceChange) => {
            const oldBalanceChange =
              state.balanceChangesMap[balanceChange.selectionId];

            return haveBalanceChangesChanged(balanceChange, oldBalanceChange);
          }) || [];

        if (filteredBalanceChanges.length) {
          // Merge newly updated balance changes with the existing ones
          const balanceChangesObject = convertArrayToObject(
            filteredBalanceChanges,
            'selectionId',
          );

          state.balanceChangesMap = {
            ...state.balanceChangesMap,
            ...balanceChangesObject,
          };
        }
      } else {
        const balanceChangesObject = {
          [BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED]:
            calculateAggregatedBetBalanceChanges(
              action.payload?.balanceChanges || [],
              isSystemBet,
            ),
        };

        state.balanceChangesMap = {
          ...state.balanceChangesMap,
          ...balanceChangesObject,
        };
      }
    },
    setBalanceChangesMapItem: (
      state,
      action: PayloadAction<Record<string, BalanceChange>>,
    ) => {
      state.balanceChangesMap = {
        ...state.balanceChangesMap,
        ...action.payload,
      };
    },
    removeBalanceChangesMapItem: (state, action: PayloadAction<string>) => {
      if (state?.balanceChangesMap?.[action.payload]) {
        delete state.balanceChangesMap[action.payload];
      }
    },
    setStakePossibleWinLoadingMap: (
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) => {
      state.stakePossibleWinLoadingMap = action.payload;
    },
    removeAll: (state) => {
      state.stakeType = SPORT_BETSLIP_TYPE_OPTIONS.SINGLE;
      state.events = [];
      state.balanceChangesMap = {};
      state.balanceChangesOnBetData = null;
      state.aggregatedBetAmount = {};
      state.multipleBetBonusAmount = 0;
      state.singleBetsAmountMap = {};
      state.multipleBetBonusDescriptions = [];
      state.duplicatedMultipleBetStakes = [];
      state.betslipErrors = [];
      state.inactiveEvents = [];
      state.isBetslipChanged = [];
      state.systemBetOption = null;
    },
    setBetErrorCode: (state, action: PayloadAction<number>) => {
      state.betErrorCode = action.payload;
    },
    setBetErrorEvent: (state, action: PayloadAction<string | null>) => {
      state.betErrorEvent = action.payload;
    },
    setBetErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.betErrorMessage = action.payload;
    },
    setRebet: (state, action: PayloadAction<BetslipItems>) => {
      state.rebet = action.payload;
    },
    setReloadBetslip: (state, action: PayloadAction<boolean>) => {
      state.reloadBetslip = action.payload;
    },
    setJackpotsBetsHistory: (state, action) => {
      state.jackpotsBetsHistory = action.payload;
    },
    setSelectedJackpotHistoryId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.selectedJackpotHistoryId = action.payload;
    },
    setLastPlacedBetslipIds: (state, action: PayloadAction<string[]>) => {
      state.lastPlacedBetslipIds = action.payload;
    },
    setShowBetslipNotification: (state, action: PayloadAction<boolean>) => {
      state.showBetslipNotification = action.payload;
    },
  },
});

export const {
  toggleBetslipType,
  setStakeType,
  setSystemBetOption,
  setAdjustedBetAmount,
  setStakes,
  removeStakeBySelection,
  setOddAskType,
  setSingleBetAmount,
  setDuplicatedMultipleBetStakes,
  setBetslipErrors,
  setBetslipProcessing,
  setBetslipProcessed,
  setBetslipProcessingFailed,
  addStake,
  setBetslipChanged,
  setIsBetslipHistoryLoading,
  setShareCode,
  setBalanceChangesOnBetData,
  setBalanceChangesMapItem,
  removeBalanceChangesMapItem,
  setStakePossibleWinLoadingMap,
  removeAll,
  setBetErrorCode,
  setBetErrorEvent,
  setBetErrorMessage,
  resetBetslipChanged,
  setEventsData,
  setRebet,
  setReloadBetslip,
  setSingleBetAmountMap,
  setAggregatedBetAmount,
  setJackpotsBetsHistory,
  setSelectedJackpotHistoryId,
  setLastPlacedBetslipIds,
  setShowBetslipNotification,
  updateSingleEvent,
} = betslipSlice.actions;

export default betslipSlice.reducer;
