import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';

export const StyledSearchResultWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  p: '$4 $5',
  '@md': {
    p: '$3 $4',
  },
  '@xs_sm': {
    p: '$4 $5',
  },
});

export const StyledResultSeparator = styled(Separator, {
  background: '$dialogBorderColor',
  width: 'calc(100% + 56px)',
  ml: '-28px',
  mt: '$5',
  mb: '$3',
});

export const StyledSearchCategoryWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: '$4',
  cursor: 'pointer',
  p: '$3',
  borderRadius: '$10',
  background: '$blueDark',
  '@xs_sm': {
    p: '$2',
  },
});

export const StyledSearchCategory = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60px',
  height: '60px',
  borderRadius: '$10',
  background: '$black',
  color: '$grayMedium',
  '& svg': {
    width: '2rem',
    height: '2rem',
  },
  '@xs_sm': {
    width: '48px',
    height: '48px',
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
});
