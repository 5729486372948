import { useRef } from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import {
  StyledBetConfirmationBtnText,
  StyledBetslipConfirmationWrapper,
  StyledConfirmationCloseButton,
} from '~components/molecules/Betslip/components/BetslipFooter/styled.components';
import { useMedia, useOuterClick, useTranslation } from '~hooks';
import { CloseIcon } from '~icons';

interface BetslipConfirmationPopoverProps {
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
}

export const BetslipConfirmationPopover = ({
  setIsOpen,
  onConfirm,
}: BetslipConfirmationPopoverProps) => {
  const { isTablet, isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();

  const popoverRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useOuterClick(popoverRef, () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setIsOpen(false), 0);
  });

  const handleNoClick = () => setIsOpen(false);
  const handleYesClick = () => {
    setIsOpen(false);
    onConfirm();
  };

  return (
    <StyledBetslipConfirmationWrapper ref={popoverRef}>
      <Text textTransform="uppercase" level={isTablet ? '17-24' : '14-20'}>
        {localized('betslip.betConfirmation')}
      </Text>
      <Box fullWidth flexRow justifyContentBetween gap={2}>
        <Button fullWidth variant="secondary" onClick={handleNoClick}>
          <StyledBetConfirmationBtnText fontWeight="medium">
            {localized('buttons.no')}
          </StyledBetConfirmationBtnText>
        </Button>
        <Button fullWidth onClick={handleYesClick}>
          <StyledBetConfirmationBtnText isConfirm fontWeight="bold">
            {localized('buttons.placeNow')}
          </StyledBetConfirmationBtnText>
        </Button>
      </Box>
      {isMobileOrTablet && (
        <StyledConfirmationCloseButton onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </StyledConfirmationCloseButton>
      )}
    </StyledBetslipConfirmationWrapper>
  );
};
