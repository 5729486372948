import React from 'react';

import { SportGroup } from '~api/category/types';
import { Box } from '~components/atoms/Box';
import { SportIconKey } from '~constants/sport';
import { useMedia } from '~hooks/useMedia';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionTrigger,
} from '../../MultiAccordion';

import { EventListMenu } from './EventListMenu';

export interface EventGroupProps {
  sportGroupsMemo: SportGroup[];
}

export const FavoritePrematchEventGroup = ({
  sportGroupsMemo,
}: EventGroupProps) => {
  const { isMobileOrTablet } = useMedia();

  return sportGroupsMemo?.map(({ id, name, favoriteEvents }) => {
    return (
      !!favoriteEvents?.length && (
        <MultiAccordionItem
          value={id.toString()}
          key={id}
          role={MULTI_ACCORDION_ROLES.CHILD}
        >
          <MultiAccordionTrigger
            isCountTransparent
            title={name}
            icon={name as SportIconKey}
            count={favoriteEvents.length}
            role={MULTI_ACCORDION_ROLES.CHILD}
            isPrefixWithPadding={isMobileOrTablet}
          />
          <MultiAccordionContent
            role={MULTI_ACCORDION_ROLES.CHILD}
            css={{ p: isMobileOrTablet ? '$2' : 'default' }}
          >
            <Box flexCol gap={1}>
              <EventListMenu events={favoriteEvents} />
            </Box>
          </MultiAccordionContent>
        </MultiAccordionItem>
      )
    );
  });
};
