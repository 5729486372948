import { Fragment, memo, useCallback, useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { Text } from '~components/atoms/Typography';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import {
  iconsMap,
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/HeaderComponent/Header/useHeaderMenu';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { QUERY_PARAMS } from '~constants/common';
import { setActiveMenu } from '~features/sportsMenu/sportsMenuSlice';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { setPreviousLocation } from '~store/slices/historySlice';
import { resetLiveMenuSlice } from '~store/slices/liveMenuSlice';
import {
  clearLocationScrollPositions,
  setActiveSportTabGroup,
  setMobileActiveTab,
} from '~store/slices/mobileSlice';
import { resetPrematchData } from '~store/slices/prematchMenuSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { getIsMenuItemActive } from '~utils/menu';
import { QueryParams } from '~utils/url';

export type MenuItemProps = {
  name: string;
  url: string;
  icon: string;
  isLaptop: boolean;
  isMenuItemActive: boolean;
};

const MenuItem = memo(
  ({ name, url, icon, isLaptop, isMenuItemActive }: MenuItemProps) => {
    const dispatch = useAppDispatch();
    const Icon = iconsMap[icon as MENU_ICON_TYPES];
    const { isMobileOrTablet } = useMedia();
    const { updateQueryParams } = useRouterQuery();
    const { activeMenu = SPORT_MENUS.PREMATCH } = useAppSelector(
      (state) => state.sportsMenuState,
    );

    const { menu: menuQuery } = useQueryParams();
    const { mobileActiveTab } = useAppSelector((state) => state.mobileState);

    const desktopHandler = (value: SPORT_MENUS) => {
      if (
        (value === activeMenu && activeMenu === SPORT_MENUS.PREMATCH) ||
        !value.length
      ) {
        dispatch(resetLiveMenuSlice());

        updateQueryParams(
          {
            [QUERY_PARAMS.MENU]: SPORT_MENUS.PREMATCH,
          } as QueryParams<typeof QUERY_PARAMS>,
          true,
        );

        return;
      }

      if (value === SPORT_MENUS.LIVE) {
        dispatch(resetPrematchData());
        updateQueryParams(
          {
            [QUERY_PARAMS.MENU]: SPORT_MENUS.LIVE,
            [QUERY_PARAMS.GROUP]: undefined,
          } as QueryParams<typeof QUERY_PARAMS>,
          true,
        );
      } else {
        updateQueryParams(
          {
            [QUERY_PARAMS.MENU]: SPORT_MENUS.PREMATCH,
            [QUERY_PARAMS.EVENT_ID]: undefined,
            [QUERY_PARAMS.LEAGUE_ID]: undefined,
            [QUERY_PARAMS.GROUP]: undefined,
          } as QueryParams<typeof QUERY_PARAMS>,
          true,
        );
      }

      dispatch(setActiveMenu(value));
    };

    const mobileHandler = useCallback(
      (value: string) => {
        if (menuQuery === value) return;

        dispatch(clearLocationScrollPositions());
        window.scrollTo(0, 0);

        if (mobileActiveTab === MOBILE_MENU.LIVE) {
          dispatch(resetLiveMenuSlice());
          dispatch(setPreviousLocation(null));
        }

        if (mobileActiveTab === MOBILE_MENU.SPORT) {
          dispatch(resetPrematchData());
          dispatch(setPreviousLocation(null));
          dispatch(setActiveSportTabGroup(SPORT_TAB_GROUPS.UPCOMMING));
        }

        dispatch(setMobileActiveTab(value as MOBILE_MENU));
      },
      [menuQuery, mobileActiveTab],
    );

    const handleMenuItemClick = () => {
      if (isMobileOrTablet) {
        let menuValue: MOBILE_MENU | undefined;

        if (icon === MENU_ICON_TYPES.Sports) {
          menuValue = MOBILE_MENU.SPORT;
        }

        if (icon === MENU_ICON_TYPES.Inplay) {
          menuValue = MOBILE_MENU.LIVE;
        }

        if (menuValue) {
          mobileHandler(menuValue as MOBILE_MENU);
        } else {
          dispatch(setMobileActiveTab(null));
        }
      } else {
        let menuValue: SPORT_MENUS | undefined;

        if (icon === MENU_ICON_TYPES.Sports) {
          menuValue = SPORT_MENUS.PREMATCH;
        }

        if (icon === MENU_ICON_TYPES.Inplay) {
          menuValue = SPORT_MENUS.LIVE;
        }

        if (menuValue) {
          desktopHandler(menuValue as SPORT_MENUS);
        }
      }
    };

    // To test navigation locally, use urlValue
    // const urlValue = url.replace(
    //   'https://sport.dev.localhost',
    //   'http://localhost:5000/',
    // );

    return (
      <NavigationLink
        style={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: isMobileOrTablet ? 0 : isLaptop ? '10px' : '12px',
          color: isMenuItemActive ? '$yellow' : '$white',
          pointerEvents: isMenuItemActive ? 'none' : 'auto',
        }}
        to={url || ''}
        onClick={handleMenuItemClick}
        key={name}
      >
        <Box
          css={{
            mt: '$1',
            color: isMenuItemActive ? '$yellow' : '$white',
          }}
          as={'span'}
        >
          {Icon && !isMobileOrTablet ? (
            <Icon width={isLaptop ? 20 : 24} height={isLaptop ? 20 : 24} />
          ) : null}
        </Box>
        <Text
          textTransform={isMobileOrTablet ? 'none' : 'uppercase'}
          color={isMenuItemActive ? 'yellow' : 'white'}
          css={{
            fontWeight: isMenuItemActive ? '$medium' : '$normal',
            whiteSpace: 'nowrap',
          }}
          level={isLaptop ? '14-20' : '18-24'}
        >
          {name}
        </Text>
      </NavigationLink>
    );
  },
);

export const HeaderMenu = () => {
  const { menu: menuQuery } = useQueryParams();
  const { mobileActiveTab } = useAppSelector((state) => state.mobileState);
  const { menu } = useHeaderMenu();
  const { isLaptop, isMobileOrTablet } = useMedia();

  const menuContent = useMemo(() => {
    return menu.map(({ name, url = '', icon = '' }, index) => {
      const isMenuItemActive = getIsMenuItemActive(
        icon as MENU_ICON_TYPES,
        url || '',
        menuQuery ?? '',
      );

      return (
        <Fragment key={name}>
          <MenuItem
            key={name}
            name={name}
            url={url}
            icon={icon}
            isLaptop={isLaptop}
            isMenuItemActive={isMenuItemActive}
          />
          {isMobileOrTablet && index < menu.length - 1 ? (
            <Box
              css={{
                width: '1px',
                height: '12px',
                backgroundColor: '$gray',
              }}
            />
          ) : null}
        </Fragment>
      );
    });
  }, [
    menu,
    menuQuery,
    mobileActiveTab,
    isLaptop,
    isMobileOrTablet,
    window.location.href,
  ]);

  return isMobileOrTablet ? (
    <TouchSlider type="menu" noGradient>
      {menuContent}
    </TouchSlider>
  ) : (
    <Box
      flexRow
      css={{
        gap: isLaptop ? '36px' : '24px',
        '& a': {
          cursor: 'pointer',
        },
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {menuContent}
    </Box>
  );
};
