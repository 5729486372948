import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';

import { MainMarket } from '~api/market/types';
import { InPlaySportEvents } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { getParamFromQuery } from '~utils/url';

import { LiveEventCard } from './LiveEventCard';

interface LiveEventListProps {
  countryId: number;
  sportId: number;
  leagueId: number;
  events: InPlaySportEvents;
  mainSportMarket: MainMarket | undefined;
}

export const LiveEventList = memo(
  ({
    countryId,
    sportId,
    leagueId,
    events,
    mainSportMarket,
  }: LiveEventListProps) => {
    const { search } = useLocation();
    const { isMobile } = useMedia();
    const activeEvent = getParamFromQuery(search, QUERY_PARAMS.EVENT_ID);

    const { selections = [] } = mainSportMarket || {};

    return (
      <Box flexCol>
        {isMobile && (
          <Box fullWidth flexCol justifyCenter alignEnd>
            <Box
              flexRow
              fullWidth
              justifyContentEnd
              css={{ marginRight: '$5', paddingBottom: '$1' }}
            >
              {selections.map(({ name }) => {
                return (
                  <Box
                    key={name}
                    fullWidth
                    css={{ maxWidth: '53px' }}
                    flexRow
                    alignCenter
                    justifyCenter
                  >
                    <Text ellipsis level="12-20">
                      {name}
                    </Text>
                  </Box>
                );
              })}
            </Box>
            <Separator
              shrinkOut={1}
              verticalSpace={0}
              css={{
                backgroundColor: '$black',
                mb: '$2',
              }}
            />
          </Box>
        )}
        <Box
          flexCol
          css={{
            '@xs_sm': {
              p: '0 $2 $2',
            },
          }}
        >
          {events.map((event) => {
            const { id: eventId } = event;

            return (
              <LiveEventCard
                key={eventId}
                eventData={event}
                sportId={sportId}
                countryId={countryId}
                leagueId={leagueId}
                eventId={eventId}
                mainSportMarket={mainSportMarket}
                isActive={activeEvent ? eventId === activeEvent : false}
              />
            );
          })}
        </Box>
      </Box>
    );
  },
);
