import { ArrowOddIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { LockIcon } from '~icons';

import { LEAGUE_SELECTION_STATUS } from '../constants';

interface LeagueOddContentProps {
  oddContentStatus: LEAGUE_SELECTION_STATUS;
  odd: number;
}

export const LeagueOddContent = ({
  oddContentStatus,
  odd,
}: LeagueOddContentProps) => {
  const { getFormattedOdd, odds } = useWebsiteSettings();

  if (oddContentStatus === LEAGUE_SELECTION_STATUS.DISABLED) {
    return (
      <Box css={{ color: 'white' }}>
        <LockIcon />
      </Box>
    );
  }

  if (
    [
      LEAGUE_SELECTION_STATUS.TOTAL,
      LEAGUE_SELECTION_STATUS.PLACEHOLDER_TOTAL,
    ].includes(oddContentStatus)
  ) {
    return (
      <Text
        level="16-28"
        fontWeight="medium"
        color="grayMedium"
        css={{
          '@xs_sm': {
            fontSize: '$13 !important',
            lineHeight: '$20 !important',
          },
        }}
      >
        {oddContentStatus === LEAGUE_SELECTION_STATUS.TOTAL
          ? getFormattedOdd(odd, odds)
          : '-'}
      </Text>
    );
  }

  if (oddContentStatus === LEAGUE_SELECTION_STATUS.PLACEHOLDER) {
    return (
      <Text
        level="16-28"
        fontWeight="medium"
        color="yellow"
        css={{
          '@xs_sm': {
            fontSize: '$13 !important',
            lineHeight: '$20 !important',
          },
        }}
      >
        {'-'}
      </Text>
    );
  }

  return (
    <Box as="span" flexRow justifyCenter alignCenter css={{ width: '84px' }}>
      <ArrowOddIndicator odd={odd} isLeagueEvents />
    </Box>
  );
};
