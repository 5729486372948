import { CURRENCY_SYMBOLS } from '~constants/common';
import { useAppSelector } from '~store';
import {
  commaSeparatedNumber,
  formatNumberToStringWithSpaces,
} from '~utils/numberUtils';

export const useFormatAmountWithCurrency = () => {
  const { currency } = useAppSelector((state) => state.settings);
  const currencySymbol = CURRENCY_SYMBOLS[currency];

  const getCommaSeparatedAmount = (number?: number | null): string =>
    `${commaSeparatedNumber(number ?? 0)} ${currencySymbol}`;

  const getSpacesSeparatedAmount = (number: number | null): string =>
    `${formatNumberToStringWithSpaces(number ?? 0)} ${currencySymbol}`;

  return {
    getCommaSeparatedAmount,
    getSpacesSeparatedAmount,
  };
};
