import {
  InPlayMenuCountries,
  InPlayMenuCountry,
  InPlayMenuLeague,
  InPlayMenuLeagues,
  InPlayMenuSport,
  InPlayMenuSports,
} from '~api/sportEvent/types';
import { QUERY_PARAMS, QueryParameters } from '~constants/common';
import { getItemFromArrayById } from '~utils/arrayHelpers';
import { QueryParams } from '~utils/url';

const getActiveSport = (
  sports: InPlayMenuSports,
  sportId: string | undefined,
) => {
  return getItemFromArrayById(sports, sportId ? parseInt(sportId) : sportId);
};

const getActiveCountry = (
  countries: InPlayMenuCountries,
  countryId: string | undefined,
): InPlayMenuCountry => {
  return getItemFromArrayById(
    countries,
    countryId ? parseInt(countryId) : countryId,
  ) as InPlayMenuCountry;
};

const getActiveLeague = (
  leagues: InPlayMenuLeagues,
  leagueId: string | undefined,
): InPlayMenuLeague => {
  return getItemFromArrayById(
    leagues,
    leagueId ? parseInt(leagueId) : leagueId,
  ) as InPlayMenuLeague;
};

export const resolveRedirectPath = (
  liveData: InPlayMenuSports,
  queryParams: QueryParameters,
): QueryParams<typeof QUERY_PARAMS> => {
  const { sportId, countryId, leagueId, eventId, menu } = queryParams;
  const activeSport: InPlayMenuSport = getActiveSport(liveData, sportId)!;
  const activeCountry = getActiveCountry(
    activeSport?.countries || [],
    countryId,
  )!;
  const activeLeague = getActiveLeague(activeCountry?.leagues || [], leagueId)!;

  let activeEventId = (activeLeague?.events || []).find(
    ({ id }) => id === eventId,
  )?.id;

  if (!activeEventId) {
    const firstEvent = activeLeague.events[0];

    if (firstEvent) {
      activeEventId = firstEvent.id;
    }
  }

  return {
    [QUERY_PARAMS.MENU]: menu,
    [QUERY_PARAMS.SPORT_ID]: activeSport?.id,
    [QUERY_PARAMS.COUNTRY_ID]: activeCountry?.id,
    [QUERY_PARAMS.LEAGUE_ID]: activeLeague?.id,
    [QUERY_PARAMS.EVENT_ID]: activeEventId,
  } as QueryParams<typeof QUERY_PARAMS>;
};
