import React from 'react';
import { CellProps } from 'react-table';

import { BetslipHistoryItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { StakeBalancesInfo } from '~components/atoms/StakeBalancesInfo/StakeBalancesInfo';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useAppSelector } from '~store';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const StakeCell = ({ value, row }: CellProps<BetslipHistoryItem>) => {
  const { currency } = useAppSelector((state) => state.settings);
  const { bonusBalanceUsages, stake } = row.original;

  return (
    <Box flexRow gap={2} alignCenter>
      <Text level="12-20">
        {commaSeparatedNumber(value)} {CURRENCY_SYMBOLS[currency]}
      </Text>
      {!!bonusBalanceUsages?.length && (
        <StakeBalancesInfo
          balanceChanges={bonusBalanceUsages}
          fullStake={stake}
        />
      )}
    </Box>
  );
};
