import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const PrimitiveFileUploadWrapper = styled(Box, {
  display: 'block',
  outline: 'none',
  fontFamily: 'inherit',
  border: '1px solid transparent',
  borderRadius: '$6',
  color: '$grayMedium',
  backgroundColor: '$gray',
  fontWeight: '$normal',
  p: '3px 42px 3px $3',
  fontSize: '$sm',
  lineHeight: '$24',
  '&:hover': {
    cursor: 'pointer',
    color: '$white',
    borderColor: '$gray1',
  },
  '&:hover .icon': {
    color: '$white',
  },
});
