import React, { useState } from 'react';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { LabelWithOppositeText } from '~components/atoms/LabelWithOppositeText';
import { PhoneNumberField } from '~components/atoms/PhoneNumberField';
import { Text } from '~components/atoms/Typography';
import { usePhoneNumber } from '~hooks/usePhoneNumber';
import { useTranslation } from '~hooks/useTranslation';

import {
  RESET_PASSWORD_FORM_FIELDS,
  ResetPasswordFormInputs,
} from '../../types';

import { ResetPasswordTabList } from './ResetPasswordTabList';

interface ProvideNumberTabProps {
  phoneNumberInput: UseFormRegisterReturn<RESET_PASSWORD_FORM_FIELDS.PHONE_NUMBER_FIELD>;
  resetPasswordCodeIsLoading: boolean;
  onBack: () => void;
  onReset: () => void;
}

export const ProvideNumberTab = ({
  phoneNumberInput,
  resetPasswordCodeIsLoading,
  onBack,
  onReset,
}: ProvideNumberTabProps) => {
  const { formState, setValue } = useFormContext<ResetPasswordFormInputs>();
  const { errors } = formState;
  const { localized } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const { getPhoneNumberWithCode, getPhoneNumberWithoutCode } =
    usePhoneNumber();

  const updatePhoneNumber = (input: string): void => {
    const phoneNumberValueWithoutCode = input.startsWith('+')
      ? getPhoneNumberWithoutCode(input)
      : input;

    const startsWithZero = phoneNumberValueWithoutCode.startsWith('0');

    const phoneNumber = startsWithZero
      ? phoneNumberValueWithoutCode.slice(1)
      : phoneNumberValueWithoutCode;

    setPhoneNumber(getPhoneNumberWithCode(phoneNumber));
    setValue(
      RESET_PASSWORD_FORM_FIELDS.PHONE_NUMBER_FIELD,
      getPhoneNumberWithCode(phoneNumber),
    );
  };

  return (
    <>
      <Text
        textAlign="center"
        textTransform="uppercase"
        fontWeight="medium"
        css={{
          fontSize: '$xl',
          lineHeight: '$44',
          mb: '$1',
        }}
      >
        {localized('resetPassword.title')}
      </Text>
      <Text
        level="xxs-4"
        fontWeight="medium"
        textAlign="center"
        css={{
          whiteSpace: 'pre-line',
        }}
      >
        {localized('resetPassword.noProblem')}
        <br />
        {localized('resetPassword.subTitle')}
      </Text>
      <ResetPasswordTabList />
      <Box
        css={{
          mb: '$3',
        }}
      >
        <LabelWithOppositeText
          htmlFor={RESET_PASSWORD_FORM_FIELDS.PHONE_NUMBER_FIELD}
          oppositeText={
            errors.phoneNumberField && errors.phoneNumberField.message
          }
          error={!!errors.phoneNumberField}
        >
          {localized('resetPassword.fields.phoneNumber.label')}
        </LabelWithOppositeText>
        <PhoneNumberField
          phoneNumberId={RESET_PASSWORD_FORM_FIELDS.PHONE_NUMBER_FIELD}
          phoneNumber={phoneNumber}
          setPhoneNumber={updatePhoneNumber}
          isValidPhoneNumber={isValidPhoneNumber}
          setIsValidPhoneNumber={setIsValidPhoneNumber}
          {...phoneNumberInput}
        />
      </Box>
      <Box
        flexRow
        gap={2}
        css={{
          marginTop: '32px',
        }}
      >
        <Button
          variant="secondary"
          fullWidth
          onClick={onBack}
          css={{
            backgroundColor: '$authSecondaryButtonBgColor',
            color: '$authSecondaryButtonTextColor',
          }}
        >
          {localized('buttons.back')}
        </Button>
        <Button
          fullWidth
          isLoading={resetPasswordCodeIsLoading}
          onClick={onReset}
        >
          {localized('buttons.reset')}
        </Button>
      </Box>
    </>
  );
};
