import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const ProvidersModalWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$blueDark',
  borderRadius: '$10',
  gap: '$4',
  padding: '$3',
  m: '$3 $5',
  '@xl': {
    padding: '$4',
    margin: '$3 $6',
  },
  '@md': {
    padding: '$2',
  },
  '@sm': {
    padding: '$2',
  },
  '@xs': {
    padding: '$2',
    margin: '0 $2',
  },
});
