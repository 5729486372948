import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import {
  decreaseInplayCount,
  increaseInplayCount,
} from '~features/sportsMenu/sportsMenuSlice';
import { useAppDispatch, useAppSelector } from '~store';
import {
  addLiveMenuEvent,
  removeLiveMenuEvent,
} from '~store/slices/liveMenuSlice';

export const useLiveMenuUpdates = () => {
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const [socket, setSocket] = useState<HubConnection | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  useEffect(() => {
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_REMOVE_EVENT, (response) => {
      const { id: eventId, ...rest } = response;

      dispatch(removeLiveMenuEvent({ eventId, ...rest }));
      dispatch(decreaseInplayCount());
    });
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_ADD_EVENT, (response) => {
      dispatch(addLiveMenuEvent(response));
      dispatch(increaseInplayCount());
    });

    return () => {
      socket?.off(SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_REMOVE_EVENT);
      socket?.off(SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_ADD_EVENT);
    };
  }, [socket]);
};
