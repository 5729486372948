import { Box } from '~components/atoms/Box';
import {
  StyledUserTabContentSeparator,
  StyledUserTabContentTitle,
  StyledUserTabContentWrapper,
} from '~components/atoms/TabContent';
import { UserSessions } from '~components/molecules/UserProfile/components/UserSessions';
import { useTranslation } from '~hooks/useTranslation';

export const RecentSessionsContent = () => {
  const { localized } = useTranslation();

  return (
    <StyledUserTabContentWrapper
      css={{
        borderRadius: '$8',
        border: '1px solid $gray',
        p: '$2 $3',
      }}
    >
      <StyledUserTabContentTitle>
        {localized(
          'userProfile.personalProfileInfo.accountSettings.recentSessions.title',
        )}
      </StyledUserTabContentTitle>
      <StyledUserTabContentSeparator />
      <Box fullWidth>
        <UserSessions />
      </Box>
    </StyledUserTabContentWrapper>
  );
};
