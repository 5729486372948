import React, { useEffect } from 'react';

import { UserProfileData } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_METHODS,
  PAYMENT_PROVIDER,
} from '~constants/payments';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setActivePaymentTab,
  setAddingNewPaymentMethodFormStatus,
  setIsLoading,
  setSelectedPayment,
} from '~store/slices/paymentsSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';
import { selectIsUganda } from '~store/slices/selectors/settings';

import { PaymentMethod } from './components/CardMethod';
import { PaymentMethodType } from './components/PaymentMethodType';

export enum PAYMENT_TYPES {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}

interface PaymentsProps {
  paymentType?: PAYMENT_TYPES;
}

export const Payments = ({ paymentType }: PaymentsProps) => {
  const dispatch = useAppDispatch();
  const isUganda = useAppSelector(selectIsUganda);
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { selectedPayment, activePaymentTab } = useAppSelector(
    (state) => state.payments,
  );
  const { profile } = useAppSelector((state) => state.userState);
  const { personalInfoConfirmed = false } = profile || ({} as UserProfileData);

  useEffect(() => {
    if (isUganda) {
      dispatch(setSelectedPayment(PAYMENT_METHODS.INSTANT_BANK));
      dispatch(
        setActivePaymentTab({
          paymentProvider: PAYMENT_PROVIDER.RELWORX,
          paymentType: contentTab as USER_PAYMENT_TABS,
        }),
      );
    }

    if (!isUganda && contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      dispatch(setSelectedPayment(PAYMENT_METHODS.INSTANT_BANK));
    }
  }, [isUganda, contentTab]);

  useEffect(() => {
    if (paymentType === PAYMENT_TYPES.WITHDRAW) {
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.WITHDRAW,
        }),
      );
    } else if (paymentType === PAYMENT_TYPES.DEPOSIT) {
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.DEPOSIT,
        }),
      );
    }

    dispatch(setIsLoading(false));
  }, [paymentType]);

  useEffect(() => {
    dispatch(
      setAddingNewPaymentMethodFormStatus(
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
      ),
    );
  }, [activePaymentTab, selectedPayment]);

  const isNotVerfiried =
    !personalInfoConfirmed && contentTab === USER_PAYMENT_TABS.WITHDRAW;

  return (
    <Box
      fullHeight
      css={{
        borderRadius: '$8',
        border: '1px solid $gray',
        position: 'relative',
        '@xs_sm': {
          background: '$black',
          border:
            contentTab === USER_PAYMENT_TABS.DEPOSIT
              ? 'none'
              : '1px solid $grayDark',
        },
      }}
    >
      {!isUganda && contentTab === USER_PAYMENT_TABS.DEPOSIT && (
        <PaymentMethodType />
      )}
      <PaymentMethod isNotVerified={isNotVerfiried} />
    </Box>
  );
};
