import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

export const LiveLabel = () => {
  const { localized } = useTranslation();

  return (
    <Box
      flexRow
      justifyCenter
      alignCenter
      css={{
        width: '2rem',
        height: '$4',
        background: '$grayMedium',
        borderRadius: '2.5px',
      }}
    >
      <Text
        level="11-14"
        color="black"
        fontWeight="bold"
        textTransform="uppercase"
      >
        {localized('homePage.live')}
      </Text>
    </Box>
  );
};
