import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks';
import { useIsCasinoWebsite } from '~hooks/useIsCasinoWebsite';
import { useAppSelector } from '~store';
import { selectIsInviteFriendAvailable } from '~store/slices/selectors/settings';

import { getNavigationItems } from '../../constants/navigationItems';
import { NavigationItem } from '../NavigationItem';

export const UserProfileNavigation = () => {
  const isCasinoWebsite = useIsCasinoWebsite();
  const { isMobileOrTablet } = useMedia();
  const isInviteFriendAvailable = useAppSelector(selectIsInviteFriendAvailable);

  return (
    <Box
      flexCol
      alignStart
      gap={3}
      css={{
        p: '$3',
        borderRadius: '$8',
        backgroundColor: '$grayDark',
        color: '$grayMedium',
      }}
    >
      {getNavigationItems({
        isCasinoWebsite,
        isMobileOrTablet,
        isInviteFriendAvailable,
      }).map(({ icon, title, value }) => (
        <NavigationItem
          key={title}
          value={value}
          icon={icon}
          title={title}
          showActive
        />
      ))}
    </Box>
  );
};
