import React from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { CrossRedIcon } from '~icons';

import {
  StyledBetPlacedText,
  StyledBetslipInfoActionButton,
  StyledBetslipInfoActionText,
  StyledQuickBetInfoWrapper,
} from './styled.components';

interface BetslipMessageErrorProps {
  isQuickBet?: boolean;
  errorMessages?: string[];
  handleErrorMessageContinueClick: () => void;
}

export const BetslipMessageError = ({
  isQuickBet = false,
  errorMessages,
  handleErrorMessageContinueClick,
}: BetslipMessageErrorProps) => {
  const { localized } = useTranslation();
  const { isTablet } = useMedia();
  const iconStyles = isTablet
    ? { height: 40, width: 40 }
    : { height: 26, width: 26 };

  return (
    <StyledQuickBetInfoWrapper
      flexCol
      alignCenter
      gap={3}
      quickBet={isQuickBet}
    >
      <Box css={{ color: 'red', ...iconStyles }}>
        <CrossRedIcon {...iconStyles} />
      </Box>

      <Box flexCol alignCenter gap={1}>
        <StyledBetPlacedText textTransform="uppercase">
          {localized('betslip.failed')}
        </StyledBetPlacedText>

        {errorMessages && errorMessages?.length > 0 && (
          <Box flexCol alignCenter gap={1}>
            {errorMessages.map((text, index) => (
              <Text key={index} level="12-20" textAlign="center">
                {text}
              </Text>
            ))}
          </Box>
        )}
      </Box>

      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{ backgroundColor: '$grayDarker', ml: 0 }}
      />

      <StyledBetslipInfoActionButton
        variant="secondary"
        onClick={handleErrorMessageContinueClick}
      >
        <StyledBetslipInfoActionText>
          {localized('buttons.continue')}
        </StyledBetslipInfoActionText>
      </StyledBetslipInfoActionButton>
    </StyledQuickBetInfoWrapper>
  );
};
