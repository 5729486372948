import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useDislikeGame, useLikeGame } from '~api/games/gamesMutations';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { StyledButton } from '~components/atoms/Button/styled.components';
import { SvgIconComponent } from '~components/atoms/SvgIconComponent';
import { Text } from '~components/atoms/Typography';
import { StyledText } from '~components/molecules/Bonuses/components/BonusHistoryFilters/styled.components';
import {
  GameCardProps,
  GameCardVariant,
} from '~components/molecules/Games/GameCard/GameCard';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { PROVIDERS_MAP } from '~constants/providers';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { LikeIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setIsDemo,
  setIsFromSearch,
  setLaunchUrl,
  setToggleGameLike,
} from '~store/slices/gamesSlice';
import { closeDialog, openDialog } from '~store/slices/globalDialogSlice';
import { selectGamesState } from '~store/slices/settingsSlice';
import { getResponsiveProps } from '~utils/games';
import { formatNumberToStringWithSpaces } from '~utils/numberUtils';

import { StyledGameActions } from './styled.components';

export const GameActions = ({
  game,
  colSpan,
  isFromSearch = false,
  variant,
}: GameCardProps) => {
  const { localized } = useTranslation();
  const { isMobile, isTablet, isLargeDesktop } = useMedia();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { likeGameMutation } = useLikeGame();
  const { dislikeGameMutation } = useDislikeGame();

  const { currency } = useAppSelector(selectGamesState);

  const { id, name, minStake, maxStake, hasDemo, gameProviderId, isLiked } =
    game;

  const getGameCardVariant = (): GameCardVariant => {
    if (variant) {
      return variant;
    }

    if (isMobile) return 'xs';
    if (isTablet) return 'sm';
    if (isLargeDesktop) return 'xl';

    return;
  };

  const handleLikeClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isLiked) {
      dislikeGameMutation(id);
    } else {
      likeGameMutation(id);
    }

    dispatch(setToggleGameLike(id));
  };

  const handleGameClick = (event: React.MouseEvent, isDemo: boolean) => {
    event.stopPropagation();

    if (!isUserLoggedIn && !isDemo) {
      dispatch(openDialog(DIALOGS.SIGN_IN));

      return;
    }

    window.scrollTo({ top: 0 });
    dispatch(setLaunchUrl(null));
    dispatch(setIsDemo(isDemo));
    dispatch(setIsFromSearch(isFromSearch));
    dispatch(closeDialog());
    navigate(`/casino/${id || name}`);
  };

  return (
    <StyledGameActions
      absoluteCenter
      {...getResponsiveProps(getGameCardVariant())}
    >
      {isUserLoggedIn && (
        <Box
          css={{ color: isLiked ? '$yellow' : '$white' }}
          onClick={handleLikeClick}
          className={`like-icon ${colSpan === 2 ? 'large' : 'small'}`}
        >
          <LikeIcon />
        </Box>
      )}
      <Box className={`provider-icon ${colSpan === 2 ? 'large' : 'small'}`}>
        <SvgIconComponent
          iconPath={PROVIDERS_MAP[gameProviderId]?.iconPath}
          alt={PROVIDERS_MAP[gameProviderId]?.name}
        />
      </Box>

      <Text
        className={`game-name ${colSpan === 2 ? 'large' : 'small'}`}
        textAlign="center"
      >
        {name}
      </Text>

      <Box fullWidth flexRow justifyContentBetween gap={2}>
        <StyledButton
          fullWidth
          className={'game-action-button'}
          onClick={(event) => handleGameClick(event, false)}
        >
          <StyledText
            className={'game-actions play'}
            css={{
              color: '$buttonTextColor !important',
            }}
          >
            {localized('buttons.play')}
          </StyledText>
        </StyledButton>
        {hasDemo && (
          <StyledButton
            fullWidth
            css={{ backgroundColor: '$gray' }}
            className={'game-action-button'}
            onClick={(event) => handleGameClick(event, true)}
          >
            <StyledText className={'game-actions demo'}>
              {localized('buttons.demo')}
            </StyledText>
          </StyledButton>
        )}
      </Box>

      {minStake && maxStake && (
        <Text className={`stake-range ${colSpan === 2 ? 'large' : 'small'}`}>
          {`${formatNumberToStringWithSpaces(
            minStake,
          )} - ${formatNumberToStringWithSpaces(maxStake)} ${
            CURRENCY_SYMBOLS[currency]
          }`}
        </Text>
      )}
    </StyledGameActions>
  );
};
