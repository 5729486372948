import React from 'react';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import {
  StyledBetslipInfoWrapper,
  StyledBetslipText,
} from '~components/molecules/Betslip/components/styled.components';
import { WinboostPopover } from '~components/WinboostPopover';
import { useFormatAmountWithCurrency, useTranslation } from '~hooks';

interface WinboostInfo {
  winBoostAmount: number;
  winBoostMultiplier: number;
  originalWinAmount: number;
}

interface BetslipPossibleWinProps {
  possibleWin: number;
  winBoostInfo?: WinboostInfo;
  isPossibleWinLoading?: boolean;
}

export const BetslipPossibleWin = ({
  possibleWin,
  winBoostInfo,
  isPossibleWinLoading = false,
}: BetslipPossibleWinProps) => {
  const { localized } = useTranslation();
  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  return (
    <StyledBetslipInfoWrapper>
      <Box flexRow alignCenter gap={2}>
        <StyledBetslipText underline>
          {localized('betslip.multipleFooterValue.possibleWin')}
        </StyledBetslipText>
        {winBoostInfo && !!Number(winBoostInfo.winBoostAmount) && (
          <WinboostPopover
            status={1}
            winBoostAmount={winBoostInfo.winBoostAmount}
            winBoostMultiplier={winBoostInfo.winBoostMultiplier}
            originalWinAmount={winBoostInfo.originalWinAmount}
          />
        )}
      </Box>
      {isPossibleWinLoading ? (
        <Loader
          css={{
            width: '13px',
            height: '13px',
            color: '$yellow',
            display: 'flex',
            alignItems: 'center',
          }}
        />
      ) : (
        <StyledBetslipText color="yellow" underline>
          {getCommaSeparatedAmount(possibleWin)}
        </StyledBetslipText>
      )}
    </StyledBetslipInfoWrapper>
  );
};
