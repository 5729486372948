import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

export const WinAmountHeaderCell = () => {
  const { localized } = useTranslation();

  return (
    <Box flexRow gap={1}>
      <Text level="12-20">
        {localized(
          'betslipHistory.betslipHistoryTable.tableHeaders.possibleWin',
        )}
      </Text>
      <Text level="12-20" color="grayMedium">
        /
      </Text>
      <Text level="12-20" color="yellow">
        {localized('betslipHistory.betslipHistoryTable.tableHeaders.win')}
      </Text>
    </Box>
  );
};
