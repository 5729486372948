import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledHideShowPasswordWrapper = styled(Box, {
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  p: '7px $3',
  cursor: 'pointer',
  color: '$grayMedium',
  '&:hover': {
    color: '$white',
  },
  '@xs_sm': {
    py: '$2',
  },
});
