import React, { useEffect, useMemo, useState } from 'react';
import { CSS } from 'stitches.config';

import { Games } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { NumberRangeInput } from '~components/atoms/NumberRangeInput';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { CategoriesList } from '~components/molecules/GamesFilters/CategoriesList';
import { MobileCategoriesList } from '~components/molecules/GamesFilters/MobileCategoriesList';
import { FiltersFooter } from '~components/molecules/GamesModalFilters/FiltersFooter';
import { ProvidersList } from '~components/molecules/ProvidersList';
import { GAME_PROVIDERS } from '~constants/providers';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  selectGames,
  selectSortedProviders,
  setFilteredGames,
  setSelectedCategoryId,
} from '~store/slices/gamesSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { Provider } from '~types/providers';

export const PaddingWrapper = ({
  children,
  css,
}: {
  children: React.ReactNode;
  css?: CSS;
}) => (
  <Box
    css={{
      ...css,
      p: '20px 24px 12px',
      '@md': {
        p: '16px 20px 12px',
      },
      '@xs_sm': {
        p: '12px 20px 0',
      },
      overflow: 'hidden',
    }}
  >
    {children}
  </Box>
);

type ProvidersCountsMap = Record<GAME_PROVIDERS, number>;

export const Filters = () => {
  const dispatch = useAppDispatch();
  const { isMobile, isMobileOrTablet } = useMedia();
  const { isLive } = useAppSelector((state) => state.games);
  const games = useAppSelector(selectGames);
  const providers = useAppSelector(selectSortedProviders);
  const stakeRange = useMemo(() => {
    const minStake = Math.min(...games.map((game) => game.minStake));
    const maxStake = Math.max(...games.map((game) => game.maxStake));

    return [minStake, maxStake];
  }, [games]);
  const [range, setRange] = useState(stakeRange);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [filteredGamesState, setFilteredGamesState] = useState<Games>(games);
  const [selectedProvidersIds, setSelectedProvidersIds] = useState<
    GAME_PROVIDERS[]
  >([]);
  const [filteredProviders, setFilteredProviders] =
    useState<Provider[]>(providers);

  const { localized } = useTranslation();

  const handleResetFilter = () => {
    setSelectedCategory(null);
    setSelectedProvidersIds([]);
    setRange(stakeRange);
  };

  useEffect(() => {
    let filteredGames: Games = games;

    if (selectedCategory) {
      filteredGames = filteredGames.filter((game) =>
        game.categoryIds.includes(selectedCategory),
      );
    }

    filteredGames = filteredGames.filter(
      (game) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        game.minStake >= range[0] || (range[1] && game.maxStake <= range[1]),
    );

    const gamesCountsMap: ProvidersCountsMap = {} as ProvidersCountsMap;

    filteredGames.forEach((game) => {
      if (!gamesCountsMap[game.gameProviderId]) {
        gamesCountsMap[game.gameProviderId] = 1;
      } else {
        gamesCountsMap[game.gameProviderId]++;
      }
    });

    const filteredProviders = providers.filter(
      (provider) => gamesCountsMap[provider.id] > 0,
    );

    setFilteredProviders(
      filteredProviders.map((provider) => ({
        ...provider,
        gameCount: gamesCountsMap[provider.id],
      })),
    );

    if (selectedProvidersIds.length) {
      filteredGames = filteredGames.filter((game) =>
        selectedProvidersIds.includes(game.gameProviderId),
      );
    }

    setFilteredGamesState(filteredGames);
  }, [selectedCategory, range, selectedProvidersIds, providers]);

  const handleFilterGames = () => {
    dispatch(setFilteredGames(filteredGamesState));
    dispatch(setSelectedCategoryId(selectedCategory));
    dispatch(closeDialog());
  };

  const handleToggleProvider = (provider: Provider) => {
    const providerId = provider.id;

    setSelectedProvidersIds((prevState) =>
      prevState.includes(providerId)
        ? prevState.filter((id) => id !== providerId)
        : [...prevState, providerId],
    );
  };

  const isClearDisabled =
    !selectedCategory &&
    range[0] === stakeRange[0] &&
    range[1] === stakeRange[1] &&
    !selectedProvidersIds.length;

  return (
    <Box
      fullWidth
      fullHeight
      css={{
        position: 'relative',
      }}
    >
      {isMobile ? (
        <MobileCategoriesList
          value={selectedCategory}
          onChange={setSelectedCategory}
        />
      ) : (
        <PaddingWrapper>
          <Box
            flexRow
            alignCenter
            justifyCenter
            css={{
              background: '$blueDark',
              borderRadius: '$10',
              '@lg': {
                p: '28px 130px 16px',
              },
              '@xl': {
                p: '28px 130px 16px',
              },
              '@md': {
                p: '28px 50px 16px',
              },
              '@sm': {
                p: '$4 36px',
              },
            }}
          >
            <CategoriesList
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </Box>
          <Separator
            verticalSpace={0}
            css={{
              mt: '20px',
              background: '$dialogBorderColor',
              width: 'calc(100% + 56px)',
              ml: '-28px',
            }}
          />
        </PaddingWrapper>
      )}
      {isLive && (
        <PaddingWrapper>
          <Text
            level={isMobileOrTablet ? '14-20' : '16-28'}
            textTransform="uppercase"
            css={{
              pl: '$5',
              pb: '$4',
              '@md': {
                pb: '$3',
              },
              '@xs': {
                pb: '$2',
                pl: 0,
              },
            }}
          >
            {localized('casino.minimumStakeRange')}
          </Text>
          <Box
            css={{
              p: '$4',
              background: '$blueDark',
              borderRadius: '$10',
              '@md': {
                p: '$2',
              },
            }}
          >
            <NumberRangeInput
              value={range}
              onChange={setRange}
              numberRangeValidValues={
                stakeRange as [minValidValue: number, maxValidValue: number]
              }
            />
          </Box>
          <Separator
            verticalSpace={0}
            css={{
              mt: '20px',
              background: '$dialogBorderColor',
              width: 'calc(100% + 56px)',
              ml: '-28px',
            }}
          />
        </PaddingWrapper>
      )}

      <PaddingWrapper>
        <Text
          level={isMobileOrTablet ? '14-20' : '16-28'}
          textTransform="uppercase"
          css={{
            pl: '$5',
            pb: '$4',
            '@md': {
              pb: '$3',
            },
            '@xs': {
              pb: '$2',
              pl: 0,
            },
          }}
        >
          {localized('casino.providers')}
        </Text>
        <ProvidersList
          providers={filteredProviders}
          activeProviders={selectedProvidersIds}
          onProviderClick={handleToggleProvider}
          customContainerStyles={
            !isMobileOrTablet
              ? {
                  borderRadius: '$10',
                  background: '$blueDark',
                }
              : {}
          }
        />
      </PaddingWrapper>
      <FiltersFooter
        handleResetFilter={handleResetFilter}
        handleFilterGames={handleFilterGames}
        isClearDisabled={isClearDisabled}
        filteredGamesState={filteredGamesState}
      />
    </Box>
  );
};
