import { ReactNode } from 'react';
import { CSS } from '@stitches/react';

import { Button } from '~components/atoms/Button';
import { Select } from '~components/atoms/Select';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { isSavedBank } from '~utils/typeguars';

import { useAddBankPayment } from '../hooks/useAddBankPayment';

import { StyledBaseSelect } from './StyledBaseSelect';

interface ButtonWrapperProps {
  children?: ReactNode;
  isLoading?: boolean;
  css?: CSS;
  onClick?: () => void;
}

const ButtonWrapper = ({
  children,
  onClick,
  isLoading,
  css,
}: ButtonWrapperProps) => {
  return (
    <Button
      css={{
        ...css,
        p: '$1 $4',
        boxShadow: 'none',
        mt: '$2',
        '&:hover': {
          boxShadow: '$iconButtonBorderActive',
          color: '$white',
        },
        '@xs_sm': {
          height: '$7',
        },
      }}
      fullWidth
      isLoading={isLoading}
      variant="secondary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const AddBankAccountButton = () => {
  const {
    isMobileOrTablet,
    isLoading,
    paymentMethods,
    selectFooterAction,
    selectedPaymentMethod,
    renderOption,
    localized,
    handleOpenAddPayment,
    handleSelectPaymentMethod,
  } = useAddBankPayment();

  if (isLoading) {
    return (
      <ButtonWrapper
        css={{
          height: '2rem',
        }}
        isLoading={true}
      />
    );
  }

  return (
    <>
      {isMobileOrTablet && (
        <Separator
          shrinkOut={2}
          css={{ backgroundColor: '$gray', ml: 0, mt: '$1' }}
          verticalSpace={0}
        />
      )}
      {paymentMethods && !!paymentMethods.length ? (
        <StyledBaseSelect css={{ mt: '$2', height: '2rem' }}>
          <Select
            noContentPadding
            centeredValue
            disabled
            onChange={handleSelectPaymentMethod}
            renderOption={renderOption}
            contentWidth={isMobileOrTablet ? '90%' : null}
            options={paymentMethods.map((method) => {
              return {
                value: !isSavedBank(method)
                  ? method.id
                  : method.bankId.toString(),
                data: method,
              };
            })}
            value={selectedPaymentMethod || undefined}
            footerAction={selectFooterAction}
          />
        </StyledBaseSelect>
      ) : (
        <ButtonWrapper onClick={handleOpenAddPayment}>
          <Text level="sm-4" textAlign="center">
            {localized('payments.addNewBank')}
          </Text>
        </ButtonWrapper>
      )}
      {isMobileOrTablet && (
        <Separator
          shrinkOut={2}
          verticalSpace={0}
          css={{ backgroundColor: '$gray', ml: 0, mt: '$1' }}
        />
      )}
    </>
  );
};
