import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentProvider } from '~api/partner/types';
import {
  ExistingPaymentMethod,
  FlutterwavePaymentAuth,
  PaymentDetailsPayloadFlutterwave,
  SavedBank,
} from '~api/transaction/types';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_METHODS,
  PAYMENT_PROVIDER,
  providerValueMap,
} from '~constants/payments';
import { RootState } from '~store';
import { TRANSACTION_STATUS } from '~types/transactions';

export interface PaymentsState {
  availablePaymentSettings: PaymentProvider[];
  paymentSettings: PaymentProvider | null;
  selectedPayment: PAYMENT_METHODS;
  selectedPaymentMethod: ExistingPaymentMethod | SavedBank | null;
  activePaymentTab: PAYMENT_PROVIDER;
  paymentMethodAmount: string;
  cardValue: string;
  bankNumber: string;
  bankCode: string;
  cardHolderName: string;
  cardHolderEmail: string;
  cvv: string;
  expiryDate: string | null;
  saveCard: boolean;
  addingNewCard: boolean;
  addingNewPaymentMethodFormStatus: ADD_PAYMENT_ACCOUNT_FORM_STATUSES;
  isLoading: boolean;
  basePayload: PaymentDetailsPayloadFlutterwave | null;
  pinPayload: FlutterwavePaymentAuth | null;
  txRef: string;
  flwRef: string;
  code: string;
  error: string | null;
  countDown: string | null;
  transactionId?: string;
  transactionStatus?: TRANSACTION_STATUS;
  updatedTransactionId?: string;
  selectedNigeriaMethod?: string;
  selectedNigeriaDepositProvider: string;
  transactionErrorStatusCode: number;
}

const initialState: PaymentsState = {
  availablePaymentSettings: [],
  paymentSettings: null,
  selectedPayment: PAYMENT_METHODS.INSTANT_CARD,
  selectedPaymentMethod: null,
  activePaymentTab: PAYMENT_PROVIDER.PAYSTACK,
  paymentMethodAmount: '',
  cardValue: '',
  bankNumber: '',
  bankCode: '',
  cardHolderName: '',
  cardHolderEmail: '',
  cvv: '',
  saveCard: false,
  expiryDate: null,
  addingNewCard: false,
  addingNewPaymentMethodFormStatus: ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE,
  isLoading: false,
  basePayload: null,
  pinPayload: null,
  txRef: '',
  flwRef: '',
  code: '',
  error: null,
  countDown: null,
  transactionId: undefined,
  updatedTransactionId: undefined,
  transactionStatus: TRANSACTION_STATUS.PENDING,
  selectedNigeriaMethod: 'card',
  selectedNigeriaDepositProvider: 'flutterwave',
  transactionErrorStatusCode: 0,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setSelectedPayment: (state, action: PayloadAction<PAYMENT_METHODS>) => {
      state.selectedPayment = action.payload;
    },
    setSelectedPaymentMethod: (
      state,
      action: PayloadAction<ExistingPaymentMethod | null>,
    ) => {
      state.selectedPaymentMethod = action.payload;
    },
    setActivePaymentTab: (
      state,
      action: PayloadAction<{
        paymentProvider: PAYMENT_PROVIDER;
        paymentType: USER_PAYMENT_TABS;
      }>,
    ) => {
      state.addingNewPaymentMethodFormStatus =
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE;
      const { paymentProvider } = action.payload;

      if (state.availablePaymentSettings.length) {
        const providerValue = state.availablePaymentSettings.find(
          (provider) => provider.paymentProvider === paymentProvider,
        );

        if (providerValue) {
          state.paymentSettings = providerValue;
          state.activePaymentTab = paymentProvider;

          return;
        }

        const selectedNigerianProvider =
          providerValueMap[state.selectedNigeriaDepositProvider];

        const provider = state.availablePaymentSettings.find(
          ({ paymentProvider }) => paymentProvider === selectedNigerianProvider,
        ) as PaymentProvider;

        if (provider) {
          state.paymentSettings = provider;
        }
      }

      state.activePaymentTab = paymentProvider;
    },
    setPaymentSettings: (state, action: PayloadAction<PaymentProvider>) => {
      state.paymentSettings = action.payload;
    },
    setBankNumber: (state, action: PayloadAction<string>) => {
      state.bankNumber = action.payload;
    },
    setBankCode: (state, action: PayloadAction<string>) => {
      state.bankCode = action.payload;
    },
    setPaymentMethodAmount: (state, action: PayloadAction<string>) => {
      state.paymentMethodAmount = action.payload;
    },
    setAddingNew: (state, action: PayloadAction<boolean>) => {
      state.addingNewCard = action.payload;
      if (action.payload) {
        state.addingNewPaymentMethodFormStatus =
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE;
        state.selectedPaymentMethod = null;
      }
    },
    setAddingNewPaymentMethodFormStatus: (
      state,
      action: PayloadAction<ADD_PAYMENT_ACCOUNT_FORM_STATUSES>,
    ) => {
      state.addingNewPaymentMethodFormStatus = action.payload;
      if (action.payload === ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE) {
        state.code = '';
      }
    },
    setCardValue: (state, action: PayloadAction<string>) => {
      state.cardValue = action.payload;
    },
    setCardHolderName: (state, action: PayloadAction<string>) => {
      state.cardHolderName = action.payload;
    },
    setCardHolderEmail: (state, action: PayloadAction<string>) => {
      state.cardHolderEmail = action.payload;
    },
    setCvv: (state, action: PayloadAction<string>) => {
      state.cvv = action.payload.slice(0, 3);
    },
    setExpiryDate: (state, action: PayloadAction<string>) => {
      state.expiryDate = action.payload;
    },
    setSaveCard: (state, action: PayloadAction<boolean>) => {
      state.saveCard = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setBasePayload: (
      state,
      action: PayloadAction<PaymentDetailsPayloadFlutterwave>,
    ) => {
      state.basePayload = { ...state.basePayload, ...action.payload };
      state.isLoading = true;
    },
    setPinPayload: (state, action: PayloadAction<FlutterwavePaymentAuth>) => {
      state.pinPayload = action.payload;
    },
    resetCardDepositState: (state) => {
      state.cardValue = '';
      state.cardHolderName = '';
      state.cvv = '';
      state.expiryDate = null;
      state.cardHolderEmail = '';
      state.saveCard = false;
      state.addingNewCard = false;
      state.addingNewPaymentMethodFormStatus =
        ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE;
      state.isLoading = false;
      state.basePayload = null;
    },
    setTxRef: (state, action: PayloadAction<string>) => {
      state.txRef = action.payload;
    },
    setFlwRef: (state, action: PayloadAction<string>) => {
      state.flwRef = action.payload;
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setCountDown: (state, action: PayloadAction<string | null>) => {
      state.countDown = action.payload;
    },
    resetBankForm: (state) => {
      state.bankNumber = '';
      state.bankCode = '';
      state.paymentMethodAmount = '';
      state.cardHolderEmail = '';
    },
    setTransactionId: (state, action: PayloadAction<string>) => {
      state.transactionId = action.payload;
    },
    setTransactionStatus: (
      state,
      action: PayloadAction<TRANSACTION_STATUS>,
    ) => {
      state.transactionStatus = action.payload;
    },
    setUpdatedTransactionId: (state, action: PayloadAction<string>) => {
      state.updatedTransactionId = action.payload;
    },
    setSelectedNigeriaMethod: (state, action: PayloadAction<string>) => {
      state.selectedNigeriaMethod = action.payload;
    },
    setSelectedNigeriaDepositProvider: (
      state,
      action: PayloadAction<string>,
    ) => {
      const provider = providerValueMap[action.payload];

      state.paymentSettings = state.availablePaymentSettings.find(
        ({ paymentProvider }) => paymentProvider === provider,
      ) as PaymentProvider;

      state.selectedNigeriaDepositProvider = action.payload;
    },
    setTransactionErrorStatusCode: (state, action: PayloadAction<number>) => {
      state.transactionErrorStatusCode = action.payload;
    },
    setAvailablePaymentSettings: (
      state,
      action: PayloadAction<PaymentProvider[]>,
    ) => {
      state.availablePaymentSettings = action.payload;
    },
    updatePaymentSettingsOnBankChange: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.paymentSettings = state.availablePaymentSettings.find(
        ({ paymentProvider }) => paymentProvider === action.payload,
      ) as PaymentProvider;
    },
  },
});

export const selectPaymnets = (state: RootState) => state.payments;

export const {
  setSelectedPayment,
  setSelectedPaymentMethod,
  setActivePaymentTab,
  setPaymentMethodAmount,
  setAddingNew,
  setBankNumber,
  setBankCode,
  setCardHolderEmail,
  setCvv,
  setAddingNewPaymentMethodFormStatus,
  setIsLoading,
  setPinPayload,
  resetCardDepositState,
  setFlwRef,
  setCode,
  setError,
  setCountDown,
  resetBankForm,
  setTransactionStatus,
  setUpdatedTransactionId,
  setTransactionId,
  setSelectedNigeriaMethod,
  setSelectedNigeriaDepositProvider,
  setTransactionErrorStatusCode,
  setAvailablePaymentSettings,
  setPaymentSettings,
  updatePaymentSettingsOnBankChange,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
