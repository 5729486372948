import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { NumberInput } from '~components/atoms/NumberInput';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setCardHolderEmail } from '~store/slices/paymentsSlice';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { useCardPaymentForm } from '../../hooks/useCardPaymentForm';
import { AddCardAccountButton } from '../AddCardAccountButton';
import { AmountAdjustmentButtons } from '../AmountAdjustmentButtons';
import { StyledBaseInputWrapper } from '../StyledBaseInput';

import { CardSubmitButton } from './CardSubmitButton';

export const CardPaymentForm = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();

  const {
    isMobileOrTablet,
    isDeposit,
    currency,
    paymentMethodAmount,
    addingNewCard,
    cardHolderEmail,
    profile,
    min,
    max,
    handleChangeAmount,
  } = useCardPaymentForm();

  return (
    <>
      <Text level="14-20">
        {localized(
          isDeposit ? 'payments.depositAmount' : 'payments.withdrawalAmount',
        )}
      </Text>
      <NumberInput
        fullWidth
        withoutActions
        value={paymentMethodAmount}
        onChange={handleChangeAmount}
      />
      <Text level="xxs-2">
        {localized('payments.minMaxAmount', {
          min: min ? commaSeparatedNumber(min) : min,
          max: max ? commaSeparatedNumber(max) : max,
          currency: CURRENCY_SYMBOLS[currency],
        })}
      </Text>
      <AmountAdjustmentButtons />
      {!addingNewCard ? (
        <>
          {isMobileOrTablet && (
            <Separator
              shrinkOut={2}
              css={{ backgroundColor: '$gray', ml: 0, mt: '$1' }}
              verticalSpace={0}
            />
          )}
          <AddCardAccountButton />
          {isMobileOrTablet && (
            <Separator
              shrinkOut={2}
              verticalSpace={0}
              css={{ backgroundColor: '$gray', ml: 0, mt: '$1' }}
            />
          )}
        </>
      ) : (
        <Box css={{ mt: '$1' }} fullWidth flexCol gap={2}>
          {/*{activePaymentTab === PAYMENT_PROVIDER.FLUTTERWAVE && (*/}
          {/*  <>*/}
          {/*    <CardInput*/}
          {/*      cardNumber={cardValue}*/}
          {/*      onCardValueChange={(val) => dispatch(setCardValue(val))}*/}
          {/*    />*/}
          {/*    <StyledBaseInputWrapper>*/}
          {/*      <Input*/}
          {/*        onChange={(e) => dispatch(setCardHolderName(e.target.value))}*/}
          {/*        value={cardHolderName}*/}
          {/*        placeholder={localized('cardFormPlaceholders.cardHolderName')}*/}
          {/*        css={{*/}
          {/*          width: '100%',*/}
          {/*          lineHeight: '$24',*/}
          {/*          p: '0 $2',*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </StyledBaseInputWrapper>*/}
          {/*  </>*/}
          {/*)}*/}
          {(!profile || !profile?.email) && (
            <StyledBaseInputWrapper>
              <Input
                onChange={(e) => dispatch(setCardHolderEmail(e.target.value))}
                value={cardHolderEmail}
                type="email"
                placeholder={localized('cardFormPlaceholders.cardHolderEmail')}
                css={{
                  width: '100%',
                  lineHeight: '$24',
                  p: '0 $2',
                }}
              />
            </StyledBaseInputWrapper>
          )}
          {/*{activePaymentTab === PAYMENT_PROVIDER.FLUTTERWAVE && (*/}
          {/*  <Box flexRow gap={2}>*/}
          {/*    <CardExpire*/}
          {/*      expiryDate={expiryDate}*/}
          {/*      onChange={(val) => dispatch(setExpiryDate(val))}*/}
          {/*    />*/}
          {/*    <StyledBaseInputWrapper>*/}
          {/*      <Input*/}
          {/*        onChange={handleCvvChange}*/}
          {/*        autoComplete="off"*/}
          {/*        value={'*'.repeat(cvv.length)}*/}
          {/*        placeholder="CVV"*/}
          {/*        css={{*/}
          {/*          lineHeight: '$24',*/}
          {/*          p: '0 $2',*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </StyledBaseInputWrapper>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Box>
      )}
      <CardSubmitButton />
    </>
  );
};
