import { useEffect, useRef, useState } from 'react';

import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import {
  StyledDisconnectedContainer,
  StyledDisconnectedContent,
  StyledReconnectedContainer,
  StyledRedBorder,
} from '~components/molecules/ConnectionStatus/styled.components';
import { useMedia } from '~hooks/useMedia';
import { useNetworkStatus } from '~hooks/useNetworkStatus';
import { useTranslation } from '~hooks/useTranslation';
import { DisconnectedIcon } from '~icons';

export const ConnectionStatus = () => {
  const { isMobileOrTablet } = useMedia();
  const [reconnectedVisible, setReconnectedVisible] = useState(false);
  const { isReconnected, isDisconnected } = useNetworkStatus();
  const { localized } = useTranslation();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isReconnected) {
      setReconnectedVisible(true);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setReconnectedVisible(false);
      }, 3000);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isReconnected]);

  return (
    <>
      <StyledDisconnectedContainer isDisconnected={isDisconnected}>
        <StyledDisconnectedContent>
          <DisconnectedIcon />
          <Text
            fontWeight="medium"
            level={isMobileOrTablet ? '18-32' : '28-44'}
            textTransform="uppercase"
            color="white"
          >
            {localized('disconnected.title')}
          </Text>
          <Text
            level={isMobileOrTablet ? '12-20' : '16-28'}
            textTransform="uppercase"
            color="white"
            textAlign="center"
          >
            {localized('disconnected.description')}
          </Text>
          <StyledRedBorder />
          <Loader
            css={{
              color: '$white',
            }}
          />
          <Text
            level={isMobileOrTablet ? '12-20' : '16-28'}
            textTransform="uppercase"
            color="white"
          >
            {localized('disconnected.connecting')}
          </Text>
        </StyledDisconnectedContent>
      </StyledDisconnectedContainer>

      {reconnectedVisible && (
        <StyledReconnectedContainer>
          <Text
            textTransform="uppercase"
            level={isMobileOrTablet ? '12-20' : '16-28'}
            color="white"
          >
            {localized('disconnected.backOnline')}
          </Text>
        </StyledReconnectedContainer>
      )}
    </>
  );
};
