import { styled } from 'stitches.config';

import { ellipsis } from '~utils/cssUtils';

export const StyledButton = styled('button', {
  position: 'relative',
  textAlign: 'center',
  border: 'none',
  cursor: 'pointer',
  textTransform: 'uppercase',
  transition: 'box-shadow 0.3s ease',
  boxShadow: '$buttonBorderNormal',
  textShadow: '$buttonText',
  ...ellipsis,
  variants: {
    variant: {
      primary: {
        color: '$white',
        backgroundColor: '$green',
        fontSize: '$lg',
        fontWeight: '$bold',
        lineHeight: '$40',
        borderRadius: '$8',
        '&:hover': {
          boxShadow: '$buttonBorderHover',
        },
        '&:active': {
          boxShadow: '$buttonBorderActive',
        },
      },
      secondary: {
        color: '$white',
        backgroundColor: '$gray',
        fontSize: '$lg',
        fontWeight: '$medium',
        lineHeight: '$40',
        borderRadius: '$8',
        '&:hover': {
          boxShadow: '$buttonBorderHover',
        },
        '&:active': {
          boxShadow: '$buttonBorderActive',
        },
      },
      transparent: {
        color: '$white',
        backgroundColor: 'transparent',
        fontSize: '$lg',
        fontWeight: '$medium',
        lineHeight: '$40',
      },
    },
    size: {
      large: {
        p: '$1 $3',
        fontSize: '$lg',
        lineHeight: '$40',
      },
      medium: {
        p: '$2 $3',
        fontSize: '$sm',
        lineHeight: '$20',
      },
      small: {
        p: '2px $5',
        fontSize: '$sm',
        lineHeight: '$28',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    disabled: {
      true: {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: 0.4,
      },
    },
    iconButton: {
      true: {
        width: '32px',
        minWidth: '32px',
        lineHeight: 0,
        p: '$2',
        backgroundColor: '$grayDark',
        color: '$grayMedium',
        borderRadius: '$6',
        '&:hover': {
          color: '$white',
          boxShadow: '$iconButtonBorderHover',
        },
        '&:active': {
          boxShadow: '$iconButtonBorderActive',
        },
      },
    },
  },
  compoundVariants: [],
  defaultVariants: {
    variant: 'primary',
    size: 'large',
  },
});
