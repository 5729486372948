import { ReactNode } from 'react';
import * as ToggleGroupUI from '@radix-ui/react-toggle-group';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { MOBILE_TAB_HEIGHT } from '~constants/ui';
import { zIndex } from '~utils/zIndex';

export const StyledMobileTabWrapper = styled(ToggleGroupUI.Root, {
  display: 'flex',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  pt: '$1',
  background: '$blackOpacity8',
  backdropFilter: 'blur(4px)',
  '&:before': {
    content: '""',
    position: 'absolute',
    height: '1px',
    background: '$grayDark',
    left: 0,
    right: 'calc(50% + 22.5px)',
    top: '0',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    height: '1px',
    background: '$grayDark',
    right: 0,
    left: 'calc(50% + 22.5px)',
    top: '0',
  },
});

export const StyledMobileTabContainer = styled(Box, {
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: MOBILE_TAB_HEIGHT,
  zIndex: zIndex.fixed,
});

export const StyledMobileTabItem = styled(Box, {
  position: 'relative',
  display: 'flex',
  flex: '1 1 0px',
  alignItems: 'center',
  flexDirection: 'column',
  '&[data-state="on"] svg, &:active svg': {
    color: '$white',
  },
  gap: '$1',
  py: '2px',
});

export const StyledMobileTabItemCentral = styled(StyledMobileTabItem, {
  p: 0,
  gap: '$2',
  mt: '-10px',
});

export const StyledMobileTabItemText = styled(Text, {
  defaultVariants: {
    level: 'xxxxs-1',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: '$bold',
  },
});

export const StyledMobileTabItemIcon = styled(Box, {
  position: 'relative',
  color: '$grayMedium',
  height: '$6',
});

export const StyledMobileTabRoundDecoration = styled(Box, {
  position: 'fixed',
  bottom: '15px',
  left: 'calc(50% - 26px)',
  clipPath: 'polygon(0px -1px, 100% -1px, 100% 30%, 0% 30%)',
  width: '52px',
  height: '52px',
  borderRadius: '$round',
  background: '$blackOpacity8',
  border: '1px solid $grayDark',
  zIndex: -1,
});

interface MenuTabItemProps {
  value: string;
  children?: ReactNode;
}

export const MenuTabItem = ({ value, children }: MenuTabItemProps) => {
  return (
    <ToggleGroupUI.Item value={value} asChild>
      {children}
    </ToggleGroupUI.Item>
  );
};

interface MenuTabRootProps {
  value: string;
  children?: ReactNode;
  onValueChange: (value: string) => void;
}

export const MenuTabRoot = ({
  value,
  children,
  onValueChange,
}: MenuTabRootProps) => {
  return (
    <StyledMobileTabWrapper
      type="single"
      onValueChange={onValueChange}
      value={value}
      loop={false}
      rovingFocus={false}
    >
      {children}
    </StyledMobileTabWrapper>
  );
};
