import * as DropdownUI from '@radix-ui/react-dropdown-menu';
import { styled } from 'stitches.config';

import { ChevronDownIcon } from '~icons';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '~utils/keyframes';
import { zIndex } from '~utils/zIndex';

export const StyledChevron = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
});

export const StyledDropdownTrigger = styled(DropdownUI.Trigger, {
  height: '$6',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '$2',
  color: '$grayMedium',
  '&[data-state=open] .chevron': {
    transform: 'rotate(180deg)',
    color: '$white',
  },
  '&:hover': {
    color: '$white',
  },
});

export const StyledDropdownMenuContent = styled(DropdownUI.Content, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  minWidth: '260px',
  backgroundColor: '$dropdownMenuContentBgColor',
  borderRadius: '$8',
  pt: '$3',
  paddingBottom: '$4',
  border: '1px solid $dropdownMenuContentBorderColor',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: zIndex.dropdown,
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});

export const DropdownMenuItem = styled(DropdownUI.Item, {
  all: 'unset',
  maxWidth: '220px',
  gap: '$2',
  fontSize: '$md',
  lineHeight: '$24',
  color: '$white',
  borderRadius: '$3',
  display: 'flex',
  alignItems: 'center',
  height: '$6',
  position: 'relative',
  paddingLeft: '18px',
  userSelect: 'none',
  cursor: 'pointer',
  '& *': {
    color: '$grayMedium',
  },
  '&[data-highlighted] *': {
    color: '$yellow',
  },
});

export const DropdownMenuSeparator = styled(DropdownUI.Separator, {
  height: '1px',
  background: '$dropdownMenuContentBorderColor',
});
