import { useEffect, useRef } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { QUERY_PARAMS, ROUTE_TAB_NAMES } from '~constants/common';
import { useAppDispatch } from '~store';
import { setPreviousLocation } from '~store/slices/historySlice';
import { QueryParams } from '~utils/url';

import { useQueryParams } from './useQueryParams';

export const useRouterQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentLocationRef = useRef<Location | null>(location);
  const { countryId, leagueId, menu, sportId, eventId } = useQueryParams();
  const prevQueryParamsRef = useRef({
    countryId,
    leagueId,
    menu,
    sportId,
    eventId,
  });

  const pushQueryParams = (
    newParams: Record<string, string | number | null | undefined>,
  ): void => {
    const url = new URL(window.location.href);

    Object.keys(newParams).forEach((key) => {
      const value = newParams[key];

      if (value !== null && value !== undefined) {
        url.searchParams.set(key, value.toString());
      } else {
        url.searchParams.delete(key);
      }
    });

    window.history.pushState({}, '', url);
  };

  const updateQueryParams = (
    newParams: QueryParams<typeof QUERY_PARAMS>,
    override: boolean = false,
    path?: string,
  ) => {
    const initialSearch = override ? undefined : location.search;
    const searchParams = new URLSearchParams(initialSearch);

    Object.entries(newParams)
      .sort((a, b) => a.toString().localeCompare(b.toString()))
      .forEach(([key, value]) => {
        if (value !== undefined) {
          searchParams.set(key, value.toString());
        }
      });

    const pathname = location.pathname.endsWith('/')
      ? location.pathname.slice(0, -1)
      : location.pathname;
    const url = `${path ? path : pathname}?${searchParams.toString()}`;

    navigate(url, { replace: true });
  };

  const navigateWithHistory = (value: string) => {
    navigate(`/${value}`, {
      state: { hasHistory: true },
    });
  };

  const removeQueryParams = (
    values: (typeof QUERY_PARAMS)[keyof typeof QUERY_PARAMS][],
    clearHash?: boolean,
  ) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(QUERY_PARAMS).forEach((key) => {
      const enumValue = QUERY_PARAMS[key as keyof typeof QUERY_PARAMS];

      if (values.includes(enumValue)) {
        searchParams.delete(enumValue);
      }
    });

    const navObj = { ...location, search: searchParams.toString() };

    if (clearHash) {
      navObj.hash = '';
    }

    navigate(navObj, { replace: true });
  };

  const removeAllQueryParams = () => {
    navigate({ ...location, search: '' }, { replace: true });
  };

  const navigateBack = (count?: number) => {
    navigate(count || -1);
  };

  const navigateHome = () => navigate(ROUTE_TAB_NAMES.BASE);

  const navigateBackOrHome = () => {
    if (location.state?.hasHistory) navigate(-1);
    else navigate(ROUTE_TAB_NAMES.BASE);
  };

  const navigateWithQuery = (pathname: string, search?: string) => {
    navigate({
      pathname,
      search: search || new URLSearchParams(location.search).toString(),
    });
  };

  useEffect(() => {
    const queryParamsChanged = Object.entries(prevQueryParamsRef.current).some(
      ([key, oldValue]) => {
        const newValue = { countryId, leagueId, menu, sportId, eventId }[key];

        return oldValue !== newValue || (oldValue && newValue === undefined);
      },
    );

    if (queryParamsChanged) {
      prevQueryParamsRef.current = {
        countryId,
        leagueId,
        menu,
        sportId,
        eventId,
      };
      dispatch(setPreviousLocation(currentLocationRef.current));

      currentLocationRef.current = location;
    }
  }, [countryId, leagueId, menu, sportId, eventId]);

  return {
    updateQueryParams,
    navigateBack,
    navigateHome,
    navigateBackOrHome,
    removeQueryParams,
    removeAllQueryParams,
    navigateWithHistory,
    pushQueryParams,
    navigateWithQuery,
  };
};
