import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { ellipsis } from '~utils/cssUtils';
import { splitStringOnVs } from '~utils/stringUtils';

interface TwoTeamScoreProps {
  eventName: string | undefined;
  homeScore: number;
  awayScore: number;
}

export const TwoTeamScore = memo(
  ({ eventName, homeScore, awayScore }: TwoTeamScoreProps) => {
    if (!eventName) return null;

    const [homeTeamName, awayTeamName] = splitStringOnVs(eventName);

    return (
      <Box
        flexCol
        css={{
          ...ellipsis,
          gap: '2px',
          width: '100%',
        }}
      >
        <Box flexRow justifyContentBetween>
          <Text level="sm-3" ellipsis css={{ pr: '$3' }}>
            {homeTeamName}
          </Text>
          <Text
            level="sm-3"
            css={{ minWidth: '$2', pr: '$1' }}
            textAlign="center"
            color="yellow"
          >
            {homeScore}
          </Text>
        </Box>
        <Box flexRow justifyContentBetween>
          <Text level="sm-3" ellipsis css={{ pr: '$3' }}>
            {awayTeamName}
          </Text>
          <Text
            level="sm-3"
            css={{ minWidth: '$2', pr: '$1' }}
            textAlign="center"
            color="yellow"
          >
            {awayScore}
          </Text>
        </Box>
      </Box>
    );
  },
);
