import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { InfoIcon } from '~icons';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { buildQueryUrl } from '~utils/buildQueryUrl';

const StyledIconBox = styled(Box, {
  width: '20px',
  height: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$1',
  borderRadius: '$4',
  backgroundColor: '$blueDark',
  cursor: 'pointer',
  '&:hover': { color: '$white' },
});

export const VoidEventPopover = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localized } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleJackpotRulesClick = () => {
    dispatch(closeDialog());
    navigate(
      buildQueryUrl(ROUTE_TAB_NAMES.JACKPOT, {
        menu: JACKPOT_MENU_ITEMS.RULES,
      }),
    );
  };

  return (
    <Box flexRow>
      <Popover
        isOpen={isOpen}
        handleOpenChange={(e) => {
          setIsOpen(e);
        }}
      >
        <PopoverContent
          side="top"
          sideOffset={8}
          align="center"
          tone="blueDark"
          width={368}
        >
          <Text level="12-20">
            {localized('jackpot.errors.matchCancelled')}
          </Text>
          <Box flexRow gap={1}>
            <Text level="12-20">
              {localized('jackpot.errors.matchWillBeResulted')}
            </Text>
            <Box onClick={handleJackpotRulesClick}>
              <Text underline level="12-20" color="green">
                {`${localized('jackpot.jackpot')} ${localized(
                  'jackpot.rules',
                )}`}
              </Text>
            </Box>
          </Box>
        </PopoverContent>
        <PopoverTrigger asChild>
          <StyledIconBox
            css={{
              color: isOpen ? '$white' : '$grayMedium',
            }}
          >
            <InfoIcon />
          </StyledIconBox>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};
