import { Middleware } from 'redux';

import { BetslipItems } from '~api/betslip/types'; // Assuming you have a RootState type
import {
  EventsState,
  resetMapsState,
  updateMapsState,
} from '~store/slices/eventsSlice';
import { Market, StoreSportEvent } from '~types/events';

const ResetEventsMapMiddleware: Middleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    if (action.type === resetMapsState.type) {
      const state = getState();
      const { eventsMap, marketsMap }: EventsState = state.events;
      const betslipEvents: BetslipItems = state.betslip.events;
      const payload = action.payload;

      if (payload?.marketId) {
        const { marketId, eventIds, eventId: curEventId } = payload;
        const nextMarketsMap = { ...marketsMap };

        Object.keys(eventsMap).forEach((eventId) => {
          if (eventIds.includes(eventId) && eventId !== curEventId) {
            const { marketIds } = eventsMap[eventId] || {};

            let markets: Market[] = [];

            if (marketIds?.length) {
              markets = marketIds.map(
                (marketId) => nextMarketsMap[marketId],
              ) as Market[];
            }

            if (markets.length) {
              markets
                .filter((item) => Boolean(item))
                .forEach(({ id, marketId: curMarketId }: Market) => {
                  if (parseInt(curMarketId) === marketId) {
                    delete nextMarketsMap[id];
                  }
                });
            }
          }
        });
        dispatch(
          updateMapsState({
            marketsMap: nextMarketsMap,
          }),
        );
      } else if (!payload) {
        const resEventsMap: Record<string, StoreSportEvent> = {};
        const resMarketsMap: Record<string, Market> = {};

        betslipEvents.forEach(({ eventId, marketId }) => {
          const event = eventsMap[eventId];
          const market = marketsMap[marketId];

          if (event) {
            resEventsMap[eventId] = event;
          }

          if (market) {
            resMarketsMap[marketId] = market;
          }
        });
        dispatch(
          updateMapsState({
            eventsMap: resEventsMap,
            marketsMap: resMarketsMap,
          }),
        );
      }
    }

    return next(action);
  };
};

export default ResetEventsMapMiddleware;
