import { styled } from 'stitches.config';

import { Box } from './Box';

export const Panel = styled(Box, {
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  minHeight: '248px',
  backgroundColor: '$panelBgColor',
  border: '4px solid $panelBgColor',
  borderRadius: '$12',
  '@xs': {
    borderRadius: 0,
  },
  '@sm': {
    borderRadius: 0,
  },
  variants: {
    compactHeight: {
      true: {
        minHeight: '160px',
      },
    },
  },
});
