import React from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { ShareLink } from '~components/atoms/ShareLink';
import { useBetslipFooter } from '~components/molecules/Betslip/hooks';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { GreenCheckIcon, ShareIcon } from '~icons';
import { useAppSelector } from '~store';
import { getShareBetData } from '~utils/betslip';

import {
  StyledBetPlacedText,
  StyledBetslipInfoActionButton,
  StyledBetslipInfoActionText,
  StyledPlacedBetIdText,
  StyledQuickBetInfoWrapper,
  StyledShareBetButton,
  StyledShareButtonText,
} from './styled.components';

interface BetslipMessageSuccessProps {
  isQuickBet?: boolean;
}

export const BetslipMessageSuccess = ({
  isQuickBet = false,
}: BetslipMessageSuccessProps) => {
  const { lastPlacedBetslipIds } = useAppSelector((state) => state.betslip);
  const { localized } = useTranslation();
  const { isTablet } = useMedia();
  const { onClick } = useBetslipFooter(isQuickBet);

  // We show bets id only if it is ONE single/multiple/system bet
  const isSharableBet = lastPlacedBetslipIds.length === 1;
  const betslipId = lastPlacedBetslipIds[0]!;
  const iconStyles = isTablet
    ? { height: 40, width: 40 }
    : { height: 26, width: 26 };

  return (
    <StyledQuickBetInfoWrapper
      flexCol
      alignCenter
      gap={3}
      quickBet={isQuickBet}
    >
      <GreenCheckIcon {...iconStyles} />

      <Box flexCol alignCenter gap={1}>
        <StyledBetPlacedText textTransform="uppercase">
          {localized('betslip.success')}
        </StyledBetPlacedText>

        {isSharableBet && (
          <StyledPlacedBetIdText>{`ID: ${betslipId}`}</StyledPlacedBetIdText>
        )}
      </Box>

      {isSharableBet && (
        <ShareLink css={{ width: '100%' }} data={getShareBetData(betslipId)}>
          <StyledShareBetButton variant="secondary">
            <ShareIcon />
            <StyledShareButtonText>
              {localized('betslip.shareBet')}
            </StyledShareButtonText>
          </StyledShareBetButton>
        </ShareLink>
      )}

      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{ backgroundColor: '$grayDarker', ml: 0 }}
      />

      <StyledBetslipInfoActionButton>
        <StyledBetslipInfoActionText
          color="buttonTextColor"
          fontWeight="bold"
          onClick={onClick}
        >
          {localized('betslip.reuseSelections')}
        </StyledBetslipInfoActionText>
      </StyledBetslipInfoActionButton>
    </StyledQuickBetInfoWrapper>
  );
};
