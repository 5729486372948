import { memo, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks';
import { ChevronDownIcon } from '~icons';
import { Market, Selection, SportEvent } from '~types/events';

import { SelectionItem } from './SelectionItem';

interface MarketContentProps {
  marketsData: Market[];
  eventId: string;
  eventData: SportEvent;
}

const maxNotCollapsedRows = 5;

const getGroupedMarketSelections = (market: Market) => {
  const rowSelectionsMap = market.selections.reduce(
    (acc, selection) => {
      const { columnIndex = 0 } = selection;

      if (!acc[columnIndex]) {
        acc[columnIndex] = [];
      }

      acc[columnIndex].push(selection);

      return acc;
    },
    {} as { [key: number]: Selection[] },
  );

  const maxRows = Math.max(
    ...Object.values(rowSelectionsMap).map((col) => col.length),
  );

  const resultSelectionsMatrix: Selection[][] = [];

  for (let i = 0; i < maxRows; i++) {
    resultSelectionsMatrix[i] = [];
  }

  Object.keys(rowSelectionsMap)
    .sort((a, b) => Number(a) - Number(b))
    .forEach((colIndex) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      rowSelectionsMap[Number(colIndex)].forEach((selection, rowIndex) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        resultSelectionsMatrix[rowIndex].push(selection);
      });
    });

  for (let i = 0; i < resultSelectionsMatrix.length; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resultSelectionsMatrix[i] = resultSelectionsMatrix[i].filter(Boolean);
  }

  return resultSelectionsMatrix;
};

export const MarketContent = memo(
  ({ marketsData, eventId, eventData }: MarketContentProps) => {
    const { localized } = useTranslation();
    const [openedMarkets, setOpenedMarkets] = useState<string[]>([]);

    return (
      <Box
        flexCol
        css={{
          gap: '1px',
          overflow: 'hidden',
          background: '$inplayMarketsAccordionContentBgColor',
          borderBottom: '1px solid $inplayMarketsContentBorderColor',
          borderRadius: '$10',
        }}
      >
        {marketsData.map((market, index) => {
          let maxSelectionsLength = 0;

          const groupedSelections = getGroupedMarketSelections(market);

          groupedSelections.forEach((row) => {
            if (row.length > maxSelectionsLength) {
              maxSelectionsLength = row.length;
            }
          });

          const isLongerThanMaxRows =
            groupedSelections.length > maxNotCollapsedRows;

          const isOpened = openedMarkets.includes(market.id);

          const loadMoreButton = (
            <Box
              flexCol
              justifyCenter
              alignCenter
              gap={1}
              css={{
                p: '$2',
                color: '$grayMedium',
                '& > svg': {
                  width: '1rem',
                  height: '1rem',
                  transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)',
                },
                '&:active': {
                  opacity: 0.7,
                },
              }}
              onClick={() => {
                setOpenedMarkets((prev) =>
                  prev.includes(market.id)
                    ? prev.filter((id) => id !== market.id)
                    : [...prev, market.id],
                );
              }}
            >
              <Text level="14-20" color="grayMedium">
                {localized(`selection.${isOpened ? 'less' : 'more'}`)}
              </Text>
              <ChevronDownIcon />
            </Box>
          );

          if (!isOpened) {
            groupedSelections.length = maxNotCollapsedRows;
          }

          return (
            <Box
              key={market.id + '-' + index}
              fullWidth
              css={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                columnGap: '1.1px',
                rowGap: '1.1px',
              }}
            >
              {groupedSelections.map((row, rowIndex) => (
                <Box
                  key={`${market.id}-row-${rowIndex}`}
                  fullWidth
                  css={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${maxSelectionsLength}, 1fr)`,
                  }}
                >
                  {row.map((selection, index) => (
                    <SelectionItem
                      withBorderRight={index !== row.length - 1}
                      eventData={eventData}
                      status={selection.status}
                      marketId={market.id}
                      eventId={eventId}
                      key={selection.id}
                      selection={selection}
                    />
                  ))}
                </Box>
              ))}
              {isLongerThanMaxRows && loadMoreButton}
            </Box>
          );
        })}
      </Box>
    );
  },
);
