import { MainMarketSelection, SportMainMarket } from '~api/market/types';
import { InPlaySportEvent } from '~api/sportEvent/types';
import { MARKET_STATUS, SELECTION_STATUS } from '~constants/common';
import { Market } from '~types/events';
import { deepClone } from '~utils/deepClone';

interface GetMarketDataFormattedParams {
  marketOriginal: Market;
  marketNameMap: Map<number, string>;
  allSelectionsFromMainMarkets: Map<string, MainMarketSelection>;
  sportId: number;
  relevantSport: SportMainMarket;
  selectionOrderMap: Map<string, number>;
  selectionNameMap: Map<string, string>;
}

export const getMarketDataFormatted = ({
  marketOriginal,
  marketNameMap,
  allSelectionsFromMainMarkets,
  sportId,
  relevantSport,
  selectionOrderMap,
  selectionNameMap,
}: GetMarketDataFormattedParams) => {
  const market = deepClone(marketOriginal) as Market;

  const mainMarketName = marketNameMap.get(parseInt(market.marketId));

  if (mainMarketName) {
    market.name = mainMarketName;
  }

  // Add missing selections
  for (const [
    key,
    selectionFromMain,
  ] of allSelectionsFromMainMarkets.entries()) {
    const sportMarketIds = `${sportId}-${market.marketId}`;

    const [sportIdFromKey, marketIdFromKey] = key.split('-');

    const sportMarketIdsFromKey = `${sportIdFromKey}-${marketIdFromKey}`;

    if (
      sportMarketIdsFromKey === sportMarketIds &&
      !market.selections.some((s) => s.selectionId === selectionFromMain.id)
    ) {
      market.selections.push({
        selectionId: selectionFromMain.id,
        name: selectionFromMain.name,
        status: SELECTION_STATUS.PLACEHOLDER,
        id: key,
        odd: 0,
      });
    }
  }

  const correspondingMainMarket = relevantSport.markets.find(
    (m) => m.id === parseInt(market.marketId),
  );

  const selectionGeneratedId = `line-${sportId}-${market.marketId}`;

  if (
    correspondingMainMarket?.withLine &&
    !market.selections.find(
      (selection) => selection.id === selectionGeneratedId,
    )
  ) {
    market.selections.unshift({
      selectionId: `line-${market.marketId}`,
      name: '',
      status: market.line
        ? SELECTION_STATUS.TOTAL
        : SELECTION_STATUS.PLACEHOLDER_TOTAL,
      id: selectionGeneratedId,
      odd: market.line ? parseFloat(market.line) : 0,
    });
  }

  // Sort and rename
  market.selections.sort((a, b) => {
    const keyA = `${sportId}-${market.marketId}-${a.selectionId}`;
    const keyB = `${sportId}-${market.marketId}-${b.selectionId}`;

    return (
      (selectionOrderMap.get(keyA) || 0) - (selectionOrderMap.get(keyB) || 0)
    );
  });

  for (const selection of market.selections) {
    const mainSelectionName = selectionNameMap.get(selection.selectionId);

    if (mainSelectionName) {
      selection.name = mainSelectionName;
    }
  }

  return market;
};

export const filterOutNonMainMarkets = (
  markets: Market[],
  marketIdsFromMainMarkets: number[],
): Market[] => {
  return markets.filter((market) =>
    marketIdsFromMainMarkets.includes(parseInt(market.marketId)),
  );
};

export const addMissingMarkets = (
  markets: Market[],
  marketIdsFromMainMarkets: number[],
  relevantSport: SportMainMarket,
  sportId: number,
  eventId: string,
): Market[] => {
  for (const mainMarketId of marketIdsFromMainMarkets) {
    const marketExists = markets.some(
      (market) => parseInt(market.marketId) === mainMarketId,
    );

    if (!marketExists) {
      const mainMarket = relevantSport.markets.find(
        (market) => market.id === mainMarketId,
      );

      if (mainMarket) {
        markets.push({
          id: `${sportId}-${eventId}-${mainMarket.id}`,
          marketId: mainMarket.id.toString(),
          name: mainMarket.name,
          selections: [],
          status: MARKET_STATUS.PLACEHOLDER,
          argument: '',
        });
      }
    }
  }

  return markets;
};

interface FormatAndSortMarketsParams {
  marketNameMap: Map<number, string>;
  allSelectionsFromMainMarkets: Map<string, MainMarketSelection>;
  sportId: number;
  relevantSport: SportMainMarket;
  selectionOrderMap: Map<string, number>;
  selectionNameMap: Map<string, string>;
}

export const formatAndSortMarkets = (
  markets: Market[],
  marketOrderMap: Map<number, number>,
  getMarketDataFormattedParams: FormatAndSortMarketsParams,
): Market[] => {
  for (const market of markets) {
    const formattedMarket = getMarketDataFormatted({
      ...getMarketDataFormattedParams,
      marketOriginal: market,
    });

    market.status = market.selections.length
      ? formattedMarket.status
      : MARKET_STATUS.PLACEHOLDER;
    market.selections = formattedMarket.selections;
    market.name = formattedMarket.name;
    market.line = formattedMarket.line;
  }

  return markets.sort(
    (a, b) =>
      (marketOrderMap.get(parseInt(a.marketId)) || 0) -
      (marketOrderMap.get(parseInt(b.marketId)) || 0),
  );
};

interface ProcessEventMarketsParams {
  marketOrderMap: Map<number, number>;
  marketIdsFromMainMarkets: number[];
  marketNameMap: Map<number, string>;
  allSelectionsFromMainMarkets: Map<string, MainMarketSelection>;
  sportId: number;
  relevantSport: SportMainMarket;
  selectionOrderMap: Map<string, number>;
  selectionNameMap: Map<string, string>;
}

export const processEventMarkets = (
  event: InPlaySportEvent,
  params: ProcessEventMarketsParams,
): Market[] => {
  const {
    marketIdsFromMainMarkets,
    relevantSport,
    sportId,
    marketOrderMap,
    ...otherParams
  } = params;

  let processedMarkets = filterOutNonMainMarkets(
    event.markets,
    marketIdsFromMainMarkets,
  );

  processedMarkets = formatAndSortMarkets(processedMarkets, marketOrderMap, {
    ...otherParams,
    sportId,
    relevantSport,
  });

  return processedMarkets;
};
