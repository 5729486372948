import React, { lazy, Suspense } from 'react';

import { SkeletonFull } from '~components/atoms/Skeletons';
import { useGetSportGroups } from '~components/molecules/PrematchMenu/hooks/useGetSportGroups';
import { useAppSelector } from '~store';

import { MOBILE_MENU } from '../MobileTabComponent/MobileTabSport';

const LiveTab = lazy(() => import('./components/LiveTab'));
const SportTab = lazy(() => import('./components/SportTab'));

export const MobileTabContents = () => {
  const { mobileActiveTab } = useAppSelector((state) => state.mobileState);

  useGetSportGroups();

  return (
    <Suspense fallback={<SkeletonFull />}>
      {mobileActiveTab === MOBILE_MENU.LIVE ? <LiveTab /> : <SportTab />}
    </Suspense>
  );
};

export default MobileTabContents;
