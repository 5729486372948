// TODO: WIP, some differences exist between country names in our project and in this map
import { CURRENCY_CODES } from '~constants/common';

export const flagNameToAlpha2CodeMap = {
  Algeria: 'DZ',
  Angola: 'AO',
  Benin: 'BJ',
  Botswana: 'BW',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  Cameroon: 'CM',
  'Cape Verde': 'CV',
  'Central African Republic': 'CF',
  Chad: 'TD',
  'Democratic Republic of the Congo': 'CD',
  Djibouti: 'DJ',
  Egypt: 'EG',
  'Equatorial Guinea': 'GQ',
  Eritrea: 'ER',
  Ethiopia: 'ET',
  Gabon: 'GA',
  Gambia: 'GM',
  Ghana: 'GH',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  'Ivory Coast': 'CI',
  Kenya: 'KE',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Madagascar: 'MG',
  Malawi: 'MW',
  Mali: 'ML',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mayotte: 'YT',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Namibia: 'NA',
  Niger: 'NE',
  Nigeria: 'NG',
  'Republic of the Congo': 'CG',
  Reunion: 'RE',
  Rwanda: 'RW',
  'Saint Helena': 'SH',
  'São Tomé and Príncipe': 'ST',
  Senegal: 'SN',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'South Sudan': 'SS',
  Sudan: 'SD',
  Suriname: 'SR',
  Swaziland: 'SZ',
  Togo: 'TG',
  Tunisia: 'TN',
  Uganda: 'UG',
  Tanzania: 'TZ',
  'Western Sahara': 'EH',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
  Anguilla: 'AI',
  'Antigua and Barbuda': 'AG',
  Argentina: 'AR',
  Aruba: 'AW',
  Bahamas: 'BS',
  Barbados: 'BB',
  'Bonaire, Sint Eustatius and Saba': 'BQ',
  Belize: 'BZ',
  Bermuda: 'BM',
  Bolivia: 'BO',
  'Virgin Islands, British': 'VG',
  Brazil: 'BR',
  Canada: 'CA',
  Kyrgyzstan: 'KG',
  Chile: 'CL',
  Colombia: 'CO',
  Comoras: 'KM',
  'Costa Rica': 'CR',
  Cuba: 'CU',
  Curaçao: 'CW',
  Dominica: 'OM',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  'El Salvador': 'SV',
  'Falkland Islands': 'FK',
  'French Guiana': 'GF',
  Greenland: 'GL',
  Grenada: 'GD',
  Guadeloupe: 'GP',
  Guatemala: 'GT',
  Guyana: 'GY',
  Haiti: 'HT',
  Honduras: 'HN',
  Jamaica: 'JM',
  Martinique: 'MQ',
  Mexico: 'MX',
  Montserrat: 'MS',
  Nicaragua: 'NI',
  Panama: 'PA',
  Paraguay: 'PY',
  Peru: 'PE',
  'Puerto Rico': 'PR',
  'Saint Barthélemy': 'BL',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint Pierre and Miquelon': 'PM',
  'Saint Vincent and the Grenadines': 'VC',
  'Sint Maarten': 'SX',
  'Trinidad and Tobago': 'TT',
  'Turks and Caicos Islands': 'TC',
  USA: 'US',
  'United States Virgin Islands': 'VI',
  Uruguay: 'UY',
  Venezuela: 'VE',
  Abkhazia: 'AB',
  Afghanistan: 'AF',
  Azerbaijan: 'AZ',
  Bangladesh: 'BD',
  Bhutan: 'BT',
  'Brunei Darussalam': 'BN',
  Cambodia: 'KH',
  'Mainland China': 'CN',
  Georgia: 'GE',
  'Hong Kong SAR': 'HK',
  India: 'IN',
  Indonesia: 'ID',
  Japan: 'JP',
  Kazakhstan: 'KZ',
  Laos: 'LA',
  'Macao SAR': 'MO',
  Malaysia: 'MY',
  Maldives: 'MV',
  Mongolia: 'MN',
  Myanmar: 'MM',
  Nepal: 'NP',
  'Noth Korea': 'KP',
  'Northern Mariana Islands': 'MP',
  Palau: 'PW',
  'Papua New Guinea': 'PG',
  Phillipeans: 'PH',
  Singapore: 'SG',
  'Republic of Korea': 'KR',
  'Sri Lanka': 'LK',
  'Taiwan region': 'TW',
  Tajikistan: 'TJ',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  Turkmenistan: 'TM',
  Vietnam: 'VN',
  Åland: 'AX',
  Albania: 'AL',
  Andorra: 'AD',
  Armenia: 'AM',
  Austria: 'AT',
  Belarus: 'BY',
  Belgum: 'BE',
  'Bosnia & Herzegovina': 'BA',
  Bulgaria: 'BG',
  Croatia: 'HR',
  Cyprus: 'CY',
  'Czech Republic': 'CZ',
  Denmark: 'DK',
  Estonia: 'EE',
  'Faroe Islands': 'FO',
  Finland: 'FI',
  France: 'FR',
  Germany: 'DE',
  Gibraltar: 'GI',
  Greece: 'GR',
  Guernsey: 'GG',
  Hungary: 'HU',
  Iceland: 'IS',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Italy: 'IT',
  Jersey: 'JE',
  Kosovo: 'XK',
  Latvia: 'LV',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Malta: 'MT',
  Moldova: 'MD',
  Monaco: 'MC',
  Montenegro: 'ME',
  Netherlands: 'NL',
  'North Macedonia': 'MK',
  Norway: 'NO',
  Poland: 'PL',
  Portugal: 'PT',
  Romania: 'RO',
  Russia: 'RU',
  'San Marino': 'SM',
  Serbia: 'RS',
  Slovakia: 'SK',
  Slovenia: 'SI',
  Spain: 'ES',
  Sweden: 'SE',
  Switzerland: 'CH',
  Turkiye: 'TR',
  Ukraine: 'UA',
  'Great Britan': 'GB',
  'Vatican City': 'VA',
  Bahrain: 'BH',
  Iran: 'IR',
  Iraq: 'IQ',
  Israel: 'IL',
  Kuwait: 'KW',
  Jordan: 'JO',
  Lebanon: 'LB',
  Pakistan: 'PK',
  'Palestinian Territories': 'PS',
  Qatar: 'QA',
  'Saudi Arabia': 'SA',
  Syria: 'SY',
  'United Arab Emirates': 'AE',
  Uzbekistan: 'UZ',
  'American Samoa': 'AS',
  Australia: 'AU',
  'Christmas Island': 'CX',
  'Cocos (Keeling) Islands': 'CC',
  'Cook Islands': 'CK',
  Fiji: 'FJ',
  'French Polynesia': 'PF',
  Guam: 'GU',
  Kiribati: 'KI',
  'Marshall Islands': 'MH',
  'Federated States of Micronesia': 'FM',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  Nauru: 'NR',
  Niue: 'NU',
  'Norfolk Island': 'NF',
  Samoa: 'WS',
  'Solomon Islands': 'SB',
  Tokelau: 'TK',
  Tonga: 'TO',
  Tuvalu: 'TV',
  Vanuatu: 'VU',
  'Wallis and Futuna': 'WF',
  Antarctica: 'AQ',
  'European Union': 'EU',
  'Jolly Roger': 'JR',
  'United Nations': 'UN',
  England: 'GB',
};

export type CountryName = keyof typeof flagNameToAlpha2CodeMap;

export const currencyCountryMap: Record<CURRENCY_CODES, CountryName> = {
  [CURRENCY_CODES.NGN]: 'Nigeria',
  [CURRENCY_CODES.UGX]: 'Uganda',
  [CURRENCY_CODES.MZ]: 'Mozambique',
  [CURRENCY_CODES.UA]: 'Ukraine',
  [CURRENCY_CODES.TZ]: 'Tanzania',
  [CURRENCY_CODES.ZMK]: 'Zambia',
  [CURRENCY_CODES.BRL]: 'Brazil',
  [CURRENCY_CODES.INR]: 'India',
};
