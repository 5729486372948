import React from 'react';

import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { GameIcon } from '~icons';
import { SPORT_GROUPS } from '~types/sportGroups';
import { QueryParams } from '~utils/url';

import { StyledTopEvent } from './styled.components';

interface TopEventProps {
  count: number | null;
}

export const TopEvent = ({ count }: TopEventProps) => {
  const { localized } = useTranslation();
  const queryParams = useQueryParams();
  const { updateQueryParams } = useRouterQuery();

  const { menu: activeMenu, group } = queryParams;
  const isActive = group === SPORT_GROUPS.TOP_EVENTS;

  const handleTopEventClick = () => {
    updateQueryParams(
      {
        [QUERY_PARAMS.MENU]: activeMenu || undefined,
        [QUERY_PARAMS.GROUP]: SPORT_GROUPS.TOP_EVENTS,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );
  };

  return (
    <StyledTopEvent
      data-state={isActive ? 'active' : 'inActive'}
      onClick={handleTopEventClick}
    >
      <Box className="icon" css={{ marginRight: '$3', lineHeight: 0 }}>
        <GameIcon />
      </Box>
      <Text css={{ color: 'inherit' }} level="16-28">
        {localized('prematch.topEvents')}
      </Text>
      {!!count && (
        <Tag
          text={count}
          className="count"
          css={{
            position: 'absolute',
            right: '$3',
            '@xs_sm': {
              right: '$1',
            },
          }}
        />
      )}
    </StyledTopEvent>
  );
};
