import { UserSession as UserSessionType } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { NotebookIcon } from '~icons';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

export const UserSession = ({ session }: { session: UserSessionType }) => {
  const { deviceOperationSystem, deviceType, country, city, ip, createdAt } =
    session;

  return (
    <Box
      flexCol
      css={{
        backgroundColor: '$userSessionItemBgColor',
        borderRadius: '$6',
        p: '$2 $3',
      }}
    >
      <Box flexCol gap={1}>
        <Box flexRow alignCenter gap={2} css={{ color: '$white' }}>
          <NotebookIcon />
          <Text
            level="12-20"
            fontWeight="medium"
          >{`${deviceOperationSystem}, ${country}, ${city}`}</Text>
        </Box>
        <Box flexRow gap={1} css={{ color: '$grayMedium' }}>
          <Text color="grayMedium" level="12-20">{`${formatDateTime(
            createdAt,
            TIME_FORMATS.DATE_TIME_24H_WITH_SECONDS,
          )} ${deviceType}`}</Text>
        </Box>
      </Box>
      <Text color="grayMedium" level="12-20">
        {ip}
      </Text>
    </Box>
  );
};
