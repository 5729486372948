import React from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { BetslipBalancesPopover } from '~components/molecules/Betslip/components/BetslipFooter/BetslipBalancesPopover';
import { BetslipOdds } from '~components/molecules/Betslip/components/BetslipInfo/BetslipOdds';
import { BetslipPayout } from '~components/molecules/Betslip/components/BetslipInfo/BetslipPayout';
import { BetslipPossibleWin } from '~components/molecules/Betslip/components/BetslipInfo/BetslipPossibleWin';
import {
  BETSLIP_VALUES_MAP_OPTIONS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '~components/molecules/Betslip/constants';
import { useBetslipAggregatedValues } from '~components/molecules/Betslip/hooks/useBetslipAggregatedValues';
import { useFormatAmountWithCurrency } from '~hooks';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { selectPossibleWinLoadingById } from '~store/slices/selectors/betslip';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';

const BetslipInfoWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const BetslipTotalInfo = () => {
  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();
  const {
    stakeType,
    aggregatedBetAmount,
    balanceChangesOnBetData,
    balanceChangesMap,
  } = useAppSelector((state) => state.betslip);
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const isPossibleWinLoading = useAppSelector(
    selectPossibleWinLoadingById(BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED),
  );

  const {
    totalStake,
    totalOdd,
    oddsCount,
    winBoostMultiplier,
    possibleWin,
    winBoostAmount,
    originalWinAmount,
    possibleIncomeTax,
    payoutInfoItems,
    showPayoutInfo,
  } = useBetslipAggregatedValues(aggregatedBetAmount);

  const { getCommaSeparatedAmount } = useFormatAmountWithCurrency();

  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const shouldShowBetPopover =
    isUserLoggedIn &&
    (balanceChangesOnBetData?.balanceChanges?.length || isSystemBet);

  const payout = totalStake > 0 ? possibleWin - possibleIncomeTax : 0;
  const possibleWinAmount = totalStake > 0 && possibleWin ? possibleWin : 0;

  return (
    <Box flexCol gap={2}>
      {/* Stake amount */}
      <BetslipInfoWrapper css={{ mt: isMobileOrTablet ? '$1' : 'unset' }}>
        <Box flexRow alignCenter gap={2} css={{ color: '$grayMedium' }}>
          <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
            {localized('betslip.multipleFooterValue.bet')}
          </Text>
          {shouldShowBetPopover && (
            <BetslipBalancesPopover
              balanceChange={
                balanceChangesMap[BETSLIP_VALUES_MAP_OPTIONS.AGGREGATED]
              }
            />
          )}
        </Box>
        <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
          {getCommaSeparatedAmount(totalStake)}
        </Text>
      </BetslipInfoWrapper>

      {!isSystemBet && (
        <BetslipOdds isMultipleBet odd={totalOdd} oddsCount={oddsCount} />
      )}

      {showPayoutInfo ? (
        <BetslipPayout
          payout={payout}
          payoutInfoItems={payoutInfoItems}
          isPossibleWinLoading={isPossibleWinLoading}
        />
      ) : (
        <BetslipPossibleWin
          isPossibleWinLoading={isPossibleWinLoading}
          possibleWin={possibleWinAmount}
          winBoostInfo={{
            winBoostAmount: totalStake > 0 ? winBoostAmount : 0,
            winBoostMultiplier,
            originalWinAmount,
          }}
        />
      )}
    </Box>
  );
};
