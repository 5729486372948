import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';

export const EmptyMarkets = () => {
  const { navigateBackOrHome } = useRouterQuery();
  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();

  return (
    <Box
      flexCol
      gap={4}
      justifyCenter
      alignCenter
      css={{
        pt: isMobileOrTablet ? '$9' : `$11`,
        color: '$grayMedium',
        fontSize: '$20',
        lineHeight: '$32',
        px: '$4',
        '@xs_sm': {
          fontSize: '$12',
          lineHeight: '$20',
          pb: '96px',
        },
      }}
    >
      <Text level="14-20">{localized('inplayEvent.emptyMarkets')}</Text>
      {isMobileOrTablet && (
        <Button
          size="small"
          onClick={() => {
            navigateBackOrHome();
          }}
        >
          {localized('inplayEvent.backToLive')}
        </Button>
      )}
    </Box>
  );
};
