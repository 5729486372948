import { useCallback, useEffect, useState } from 'react';

import {
  useLazyGetAuthorizedDepositFlutterwave,
  useLazyGetAuthorizedDepositPaystack,
} from '~api/transaction/transactionQueries';
import { ExistingPaymentMethod } from '~api/transaction/types';
import { Box } from '~components/atoms/Box';
import { CardMask } from '~components/atoms/CardMask';
import { FooterAction } from '~components/atoms/Select';
import { Text } from '~components/atoms/Typography';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { CARD_TYPES, PAYMENT_PROVIDER } from '~constants/payments';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { MasterCardIcon, VisaIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNew,
  setSelectedPaymentMethod,
} from '~store/slices/paymentsSlice';
import { SVGElement } from '~types/general';

const sortPayments = (a: ExistingPaymentMethod) => {
  return a.isDefault ? -1 : 1;
};

export const useAddCardPayment = () => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const [paymentMethods, setPaymentMethods] = useState<
    ExistingPaymentMethod[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { activePaymentTab, selectedPaymentMethod } = useAppSelector(
    (state) => state.payments,
  );
  const { localized } = useTranslation();
  const { lazyGetAuthorizedDepositPaystackQuery } =
    useLazyGetAuthorizedDepositPaystack();
  const { lazyGetAuthorizedDepositFlutterwaveQuery } =
    useLazyGetAuthorizedDepositFlutterwave();

  const handleOpenAddPayment = () => {
    dispatch(setAddingNew(true));
  };

  const handleSelectPaymentMethod = (value: string) => {
    const paymentMethod = paymentMethods?.find(({ id }) => id === value);

    dispatch(setSelectedPaymentMethod(paymentMethod || null));
  };

  const selectFooterAction: FooterAction = {
    label: localized(
      contentTab === USER_PAYMENT_TABS.DEPOSIT
        ? 'payments.addNewCard'
        : 'payments.addNewBank',
    ),
    onClick: handleOpenAddPayment,
  };

  const renderOption = (option: ExistingPaymentMethod): JSX.Element => {
    const { cardType: initialCardType, expiry, lastDigits } = option;
    const cardType = initialCardType?.toLowerCase().trim();

    const cardsIconMap: Record<CARD_TYPES, SVGElement> = {
      [CARD_TYPES.VISA]: VisaIcon,
      [CARD_TYPES.MASTERCARD]: MasterCardIcon,
    };
    const Icon = cardsIconMap[cardType as CARD_TYPES];
    const cardDescription = `${lastDigits}, ${localized(
      'payments.expires',
    )} ${expiry}`;

    return (
      <Box
        flexRow
        justifyCenter
        alignCenter
        gap={1}
        css={isMobileOrTablet ? { paddingLeft: '$2' } : {}}
      >
        {Icon && <Icon />}
        <CardMask />
        <Text level="14-20">{cardDescription}</Text>
      </Box>
    );
  };

  const loadPaymentMethods = useCallback(
    async (activePaymentTab: PAYMENT_PROVIDER) => {
      try {
        setIsLoading(true);
        let response;

        if (
          activePaymentTab === PAYMENT_PROVIDER.FLUTTERWAVE &&
          contentTab === USER_PAYMENT_TABS.DEPOSIT
        ) {
          response = await lazyGetAuthorizedDepositFlutterwaveQuery().unwrap();
        } else if (
          activePaymentTab === PAYMENT_PROVIDER.PAYSTACK &&
          contentTab === USER_PAYMENT_TABS.DEPOSIT
        ) {
          response = await lazyGetAuthorizedDepositPaystackQuery().unwrap();
        } else {
          return;
        }

        const sorted = [...response].sort(sortPayments);

        if (sorted && sorted.length) {
          setPaymentMethods(sorted);
          if (sorted[0]?.id) {
            dispatch(setAddingNew(false));
            dispatch(setSelectedPaymentMethod(sorted[0] || null));
          }
        } else {
          dispatch(setAddingNew(true));
          dispatch(setSelectedPaymentMethod(null));
        }
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    loadPaymentMethods(activePaymentTab);

    return () => {
      setPaymentMethods([]);
    };
  }, [activePaymentTab]);

  return {
    isMobileOrTablet,
    isLoading,
    paymentMethods,
    isDeposit: contentTab === USER_PAYMENT_TABS.DEPOSIT,
    selectFooterAction,
    selectedPaymentMethod: selectedPaymentMethod?.id || null,
    renderOption,
    localized,
    handleOpenAddPayment,
    handleSelectPaymentMethod,
  };
};
