import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

export const StyledCloseButton = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  top: '$3',
  right: '$3',
  color: '$grayMedium',
});

export const StyledInfoTextWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '$gray',
  borderRadius: '$6',
  p: '$2 $3',
  variants: {
    color: {
      red: {
        backgroundColor: '$redDark',
      },
    },
  },
});

export const StyledInfoText = styled(Text, {
  fontSize: '13px',
  lineHeight: '16px',
  '@xs_sm': {
    fontSize: '14px',
    lineHeight: '20px',
  },
  variants: {
    color: {
      red: {
        color: '$red',
      },
      yellow: {
        color: '$yellow',
      },
    },
  },
});

export const StyledPopoverTrigger = styled(Box, {
  cursor: 'pointer',
  color: '$grayMedium',
  lineHeight: 0,
  '&:hover': { color: '$white' },
});
