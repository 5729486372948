import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { Box } from '~components/atoms/Box';
import { EventNavigationLink } from '~components/atoms/EventNavigationLink';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { FavoriteIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';
import { SportEventMainData, StoreSportEvent } from '~types/events';
import { ellipsis } from '~utils/cssUtils';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getFormattedMarketsCount } from '~utils/numberUtils';
import { splitStringOnVs } from '~utils/stringUtils';

export interface MainEventDataProps {
  eventData: StoreSportEvent | undefined;
}

export const MainEventPrematchData = ({ eventData }: MainEventDataProps) => {
  const dispatch = useAppDispatch();

  const {
    marketsCount,
    startDate = '',
    name,
    id: eventId = '',
    countryId,
    leagueId,
    sportId,
  } = eventData || {};

  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { favoritePrematchEvents } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const isEventFavorite = !!favoritePrematchEvents?.find((favoriteEvent) => {
    return favoriteEvent.id === eventId;
  });
  const time = formatDateTime(startDate, TIME_FORMATS.TIME_12H);
  const [homeTeamName, awayTeamName] = splitStringOnVs(name || '');

  const { isLaptopOrDesktop, isLaptop } = useMedia();
  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();

  const handleFavoriteClick = async (isEventFavorite: boolean) => {
    try {
      if (isEventFavorite) {
        await deleteFavoriteEventMutation({
          eventId,
        });
        dispatch(
          setFavoritePrematchEvents(
            favoritePrematchEvents.filter(
              (favoriteEvent) => favoriteEvent.id !== eventData?.id,
            ),
          ),
        );
      } else {
        await addFavoriteEventMutation({
          eventId,
        });
        if (eventData) {
          dispatch(
            setFavoritePrematchEvents([
              ...favoritePrematchEvents,
              eventData as SportEventMainData,
            ]),
          );
        }
      }
    } catch (e) {
      console.error('Failed to click favorite event', e);
    }
  };

  return (
    <Box
      flexRow
      alignCenter
      justifyContentBetween
      css={{ flexGrow: 1, pl: '$2' }}
    >
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Text color="grayMedium" level="14-20">
          {time}
        </Text>
        <EventNavigationLink
          eventId={eventId}
          countryId={countryId}
          leagueId={leagueId}
          sportId={sportId}
        >
          <Box
            flexCol
            css={{
              flexShrink: 0,
              ml: '$3',
              textDecoration: 'inherit',
              color: '$white',
              ...ellipsis,
              '&:hover': {
                color: '$green',
              },
              '@media (min-width: 1070px) and (max-width: 1150px)': {
                maxWidth: '130px',
              },
              '@media (min-width: 1024px) and (max-width: 1070px)': {
                maxWidth: '100px',
              },
            }}
          >
            <Text
              level={isLaptop ? '12-16' : '14-24'}
              ellipsis
              css={{ color: 'unset' }}
            >
              {homeTeamName}
            </Text>
            <Text
              level={isLaptop ? '12-16' : '14-24'}
              ellipsis
              css={{ color: 'unset' }}
            >
              {awayTeamName}
            </Text>
          </Box>
        </EventNavigationLink>
      </Box>
      <Box
        alignCenter
        gap={isLaptopOrDesktop ? (isLaptop ? 0 : 1) : 2}
        css={{
          display: 'flex',
          flexDirection: isLaptopOrDesktop ? 'column' : 'row',
        }}
      >
        <Box
          gap={isLaptopOrDesktop ? 1 : 3}
          alignCenter
          css={{
            display: 'flex',
            flexDirection: isLaptop ? 'column' : 'row',
          }}
        >
          <Box css={{ color: '$grayMedium', '&:hover': { color: '$white' } }}>
            {isUserLoggedIn && (
              <Box
                css={{
                  color: isEventFavorite ? '$white ' : '$grayMedium ',
                  width: isLaptopOrDesktop ? '14px' : '16px',
                  height: isLaptopOrDesktop ? '14px' : '16px',
                  '&:hover': { color: '$white' },
                }}
                onClick={async () => {
                  await handleFavoriteClick(isEventFavorite);
                }}
              >
                <FavoriteIcon
                  width={isLaptopOrDesktop ? 14 : 16}
                  height={isLaptopOrDesktop ? 14 : 16}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box flexRow css={{ order: isLaptopOrDesktop ? -1 : 'unset' }}>
          <EventNavigationLink
            eventId={eventId}
            countryId={countryId}
            leagueId={leagueId}
            sportId={sportId}
          >
            <Text
              level={isLaptopOrDesktop ? '12-16' : '14-24'}
              color="grayMedium"
              underline
              textAlign="center"
              css={{ width: '$8', '&:hover': { color: '$white' } }}
            >
              {getFormattedMarketsCount(marketsCount)}
            </Text>
          </EventNavigationLink>
        </Box>
      </Box>
    </Box>
  );
};
