import {
  PHONE_FIELD_COUNTRY_CODES,
  PHONE_FIELD_COUNTRY_LENGTH,
} from '~constants/auth';
import { useAppSelector } from '~store';

export const usePhoneNumber = () => {
  const { currency } = useAppSelector((state) => state.settings);

  const getPhoneNumberWithCode = (phoneNumber: string) => {
    return `${PHONE_FIELD_COUNTRY_CODES[currency]}${phoneNumber}`;
  };

  const getPhoneNumberWithoutCode = (phoneNumber: string) => {
    if (phoneNumber.startsWith('+')) {
      const codeLength = `${PHONE_FIELD_COUNTRY_CODES[currency]}`.length;

      return phoneNumber.slice(codeLength);
    }

    return phoneNumber;
  };

  const maskPhoneNumber = (phoneNumber: number): string => {
    const phoneString = getPhoneNumberWithoutCode(phoneNumber.toString());

    if (phoneString.length !== PHONE_FIELD_COUNTRY_LENGTH[currency]) {
      throw new Error(
        `Phone number should have ${PHONE_FIELD_COUNTRY_LENGTH[currency]} digits`,
      );
    }

    const suffix = phoneString.substring(
      7,
      PHONE_FIELD_COUNTRY_LENGTH[currency],
    );

    return `+${PHONE_FIELD_COUNTRY_CODES[currency]} xxx-xxx-${suffix}`;
  };

  const formatPhoneNumberPayload = (phoneNumber: string) => {
    const phoneWithoutCode = getPhoneNumberWithoutCode(phoneNumber);
    const startsWithZero = phoneWithoutCode.startsWith('0');
    const formattedPhoneNumber = startsWithZero
      ? phoneWithoutCode.slice(1)
      : phoneWithoutCode;

    return getPhoneNumberWithCode(formattedPhoneNumber);
  };

  return {
    maskPhoneNumber,
    getPhoneNumberWithCode,
    getPhoneNumberWithoutCode,
    formatPhoneNumberPayload,
  };
};
