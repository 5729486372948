import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { Select } from '~components/atoms/Select';
import { Text } from '~components/atoms/Typography';

import { useBonusHistoryFilters } from './useBonusHistoryFilters';

export const BonusHistoryFilters = () => {
  const {
    selectedDates,
    statusesOptions,
    statusValue,
    minimumAge,
    handleReset,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  } = useBonusHistoryFilters();

  return (
    <Box flexRow justifyContentBetween>
      <Box flexRow gap={2}>
        <Box flexRow gap={3}>
          <DateRangePicker
            isFutureDatesDisabled
            legalAge={minimumAge}
            onOkRange={(dates) => setSelectedDates(dates)}
            value={selectedDates}
          />
        </Box>
        <Select
          ariaLabel={localized('bonuses.status.all')}
          placeholder={localized('bonuses.status.all')}
          value={statusValue.toString()}
          onChange={(value) => setStatusValue(value as string)}
          options={statusesOptions}
          css={{
            width: '180px',
            height: '32px',
            borderRadius: '$8',
            backgroundColor: '$blueDark',
          }}
        />
      </Box>
      <Box flexRow gap={2}>
        <Button
          variant="secondary"
          size="small"
          css={{
            width: '100px',
            height: '32px',
            backgroundColor: '$historyFiltersResetButtonDesktopBgColor',
          }}
          onClick={handleReset}
        >
          <Text level="14-16" fontWeight="bold" textAlign="center">
            {localized('bonuses.button.reset')}
          </Text>
        </Button>
        <Button
          size="small"
          css={{ width: '100px', height: '32px' }}
          onClick={handleUpdateHistory}
        >
          <Text
            level="14-16"
            fontWeight="bold"
            textAlign="center"
            color="buttonTextColor"
          >
            {localized('bonuses.button.show')}
          </Text>
        </Button>
      </Box>
    </Box>
  );
};
