import { useEffect, useMemo } from 'react';

import { SportGroups } from '~api/category/types';
import { TopEvents } from '~api/sportEvent/types';
import { QUERY_PARAMS } from '~constants/common';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import {
  FavoriteGameIcon,
  FavoriteTournamentIcon,
  GameIcon,
  TournamentIcon,
} from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import { setGroups, setGroupsData } from '~store/slices/sportGroupsSlice';
import { SportEventsGroups } from '~types/events';
import { SPORT_GROUPS } from '~types/sportGroups';
import { QueryParams } from '~utils/url';

import { SPORT_GROUP_COMPONENTS } from '../constants';
import { FavoriteGroupSportMobile } from '../SportMenu/FavoriteEventGroupSportMobile';
import { MobileTournamentsGroup } from '../SportMenu/MobileTournamentsGroup';
import { TopEventGroupMobile } from '../SportMenu/TopEventGroupMobile';

const groupComponentsMap = {
  [SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT]: MobileTournamentsGroup,
  [SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT]: MobileTournamentsGroup,
  [SPORT_GROUP_COMPONENTS.TOP_EVENT]: TopEventGroupMobile,
  [SPORT_GROUP_COMPONENTS.FAVORITE_EVENT]: FavoriteGroupSportMobile,
};

export const useSportMenuGroupsMobile = ({
  topSportEvents = [],
}: TopEvents) => {
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { localized } = useTranslation();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const {
    favoriteTournaments = [],
    topTournaments = [],
    groups = [],
    groupsData: sportGroupsMemo = [],
  } = useAppSelector((state) => state.sportGroupsState);
  const { breadcrumbLabel } = useAppSelector((state) => state.menus);
  const dispatch = useAppDispatch();
  const { updateQueryParams, removeQueryParams } = useRouterQuery();
  const { favoritePrematchEvents = [] } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const handleTopEventsClickMobile = () => {
    dispatch(setBreadcrumbsLabel(SPORT_GROUP_COMPONENTS.TOP_EVENT));
    updateQueryParams({
      [QUERY_PARAMS.GROUP]: SPORT_GROUPS.TOP_EVENTS,
    } as QueryParams<typeof QUERY_PARAMS>);
  };

  const handleOnGroupValueChange = (group: SPORT_GROUP_COMPONENTS) => {
    const breadcrumbLabel = groups.find(({ name }) => name === group)?.name;

    dispatch(setBreadcrumbsLabel(breadcrumbLabel || null));

    if (group) {
      removeQueryParams([QUERY_PARAMS.GROUP]);
    }
  };

  useEffect(() => {
    const resultGroupsArray: SportEventsGroups = [];

    if (favoriteTournaments.length && isUserLoggedIn) {
      resultGroupsArray.push({
        name: SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT,
        icon: FavoriteTournamentIcon,
        label: localized('prematch.favoriteTournaments'),
        id: 3,
      });
    }

    if (favoritePrematchEvents?.length && isUserLoggedIn) {
      resultGroupsArray.push({
        name: SPORT_GROUP_COMPONENTS.FAVORITE_EVENT,
        label: localized('prematch.favoriteEvents'),
        id: 4,
        icon: FavoriteGameIcon,
      });
    }

    resultGroupsArray.push({
      name: SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT,
      icon: TournamentIcon,
      label: localized('prematch.topTournaments'),
      id: 1,
    });

    resultGroupsArray.push({
      name: SPORT_GROUP_COMPONENTS.TOP_EVENT,
      label: localized('prematch.topEvents'),
      id: 5,
      icon: GameIcon,
      onClick: handleTopEventsClickMobile,
    });

    dispatch(setGroups(resultGroupsArray));
  }, [
    topTournaments,
    favoriteTournaments,
    isUserLoggedIn,
    favoritePrematchEvents,
    topSportEvents,
  ]);

  const groupCountsMemo = useMemo(() => {
    const favoriteTournamentsCount = favoriteTournaments.reduce(
      (total, tournament) => total + tournament.events,
      0,
    );
    const topTournamentsCount = topTournaments.reduce(
      (total, tournament) => total + tournament.events,
      0,
    );

    const favoriteEventsCount = favoritePrematchEvents?.length || null;

    const topEventsCount = topSportEvents.reduce(
      (total, sport) =>
        total +
        sport.dateGroups.reduce(
          (count, dateGroup) => count + dateGroup.matches.length,
          0,
        ),
      0,
    );

    return {
      [SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT]:
        favoriteTournamentsCount || null,
      [SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT]: topTournamentsCount || null,
      [SPORT_GROUP_COMPONENTS.FAVORITE_EVENT]: favoriteEventsCount || null,
      [SPORT_GROUP_COMPONENTS.TOP_EVENT]: topEventsCount || null,
    };
  }, [
    favoriteTournaments,
    topTournaments,
    favoritePrematchEvents,
    topSportEvents,
  ]);

  useEffect(() => {
    const sportGroups: SportGroups = (mainMarkets || [])?.map(
      ({ id, name }) => ({
        id,
        name,
        favoriteTournaments: [],
        topTournaments: [],
        topEvents: [],
        favoriteEvents: [],
      }),
    );

    favoriteTournaments.forEach((tournament) => {
      sportGroups?.forEach((sportGroup) => {
        if (sportGroup.id === tournament.sportId) {
          sportGroup.favoriteTournaments = [
            ...sportGroup.favoriteTournaments!,
            tournament,
          ];
        }
      });
    });

    topTournaments.forEach((tournament) => {
      sportGroups?.forEach((sportGroup) => {
        if (sportGroup.id === tournament.sportId) {
          sportGroup.topTournaments = [
            ...sportGroup.topTournaments,
            tournament,
          ];
        }
      });
    });

    favoritePrematchEvents.forEach((event) => {
      sportGroups?.forEach((sportGroup) => {
        if (sportGroup.id === event.sportId) {
          sportGroup.favoriteEvents = [...sportGroup.favoriteEvents, event];
        }
      });
    });

    dispatch(setGroupsData(sportGroups));
  }, [
    mainMarkets,
    favoriteTournaments,
    topTournaments,
    favoritePrematchEvents,
  ]);

  useEffect(() => {
    if (
      breadcrumbLabel === SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT &&
      !favoriteTournaments.length
    ) {
      dispatch(setBreadcrumbsLabel(null));
    }
  }, [favoriteTournaments]);

  return {
    localized,
    groups,
    sportGroupsMemo,
    eventsSum: groupCountsMemo[breadcrumbLabel as SPORT_GROUP_COMPONENTS],
    groupComponent:
      groupComponentsMap[breadcrumbLabel as SPORT_GROUP_COMPONENTS],
    handleOnGroupValueChange,
  };
};
