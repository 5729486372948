import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledGameActions = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1,
  transform: 'translate(-50%, -50%)',
  borderRadius: '$8',
  background: 'linear-gradient(180deg, rgba(10, 15, 20, 0.70) 0%, $black 100%)',
  backdropFilter: 'blur(4px)',
  border: '2px solid $yellow',

  svg: { width: 'auto', height: '100%' },

  padding: '$3',
  '.like-icon': {
    position: 'fixed',
    top: '$3',
    left: '$3',
    '&.large': {
      height: '24px',
    },
    '&.small': {
      height: '16px',
    },
  },
  '.provider-icon': {
    '&.large': {
      width: '100px',
      height: '22px',
      img: {
        height: '22px',
        width: '100%',
        objectFit: 'contain',
      },
    },
    '&.small': {
      width: '75px',
      height: '16px',
      img: {
        height: '16px',
        width: '100%',
        objectFit: 'contain',
      },
    },
  },
  '.game-name': {
    fontWeight: '$bold',
    '&.large': {
      fontSize: '18px',
      lineHeight: '28px',
    },
    '&.small': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },

  '.game-action-button': {
    display: 'flex',
    justifyContent: 'center',
  },
  // Range stake number
  '.stake-range': {
    color: '$yellow',
    '&.large': {
      fontSize: '12px',
      lineHeight: '16px',
    },
    '&.small': {
      fontSize: '11px',
      lineHeight: '16px',
    },
  },

  '.game-actions': {
    fontSize: '14px !important',
    lineHeight: '20px !important',
    '&.play': { fontWeight: '$bold !important' },
  },

  variants: {
    xs: {
      true: {
        padding: '$2',
        '.like-icon': {
          top: '$2',
          left: '$2',
          '&.large': {
            height: '18px',
          },
          '&.small': {
            height: '14px',
          },
        },
        '.provider-icon': {
          '&.large': {
            width: '65px',
            height: '14px',
            img: {
              width: '100%',
              height: '14px',
              objectFit: 'contain',
            },
          },
          '&.small': {
            width: '55px',
            height: '12px',
            img: {
              width: '100%',
              height: '12px',
              objectFit: 'contain',
            },
          },
        },
        '.game-name': {
          '&.large': {
            fontSize: '13px',
            lineHeight: '20px',
            fontWeight: '$normal',
          },
          '&.small': {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '$normal',
          },
        },
        '.stake-range': {
          '&.large': {
            fontSize: '10px',
            lineHeight: '12px',
          },
          '&.small': {
            fontSize: '9px',
            lineHeight: '12px',
          },
        },
        '.game-actions': {
          fontSize: '11px !important',
          lineHeight: '20px !important',
        },
      },
    },
    sm: {
      true: {
        padding: '10px',
        '.like-icon': {
          top: '10px',
          left: '10px',
          '&.large': {
            height: '20px',
          },
          '&.small': {
            height: '16px',
          },
        },
        '.provider-icon': {
          '&.large': {
            width: '75px',
            height: '16px',
            img: {
              width: '100%',
              height: '16px',
              objectFit: 'contain',
            },
          },
          '&.small': {
            width: '65px',
            height: '14px',
            img: {
              width: '100%',
              height: '14px',
              objectFit: 'contain',
            },
          },
        },
        '.game-name': {
          '&.large': {
            fontSize: '16px',
            lineHeight: '24px',
          },
          '&.small': {
            fontSize: '13px',
            lineHeight: '20px',
          },
        },
        '.stake-range': {
          '&.small': {
            fontSize: '10px',
            lineHeight: '16px',
          },
        },
      },
    },
    xl: {
      true: {
        padding: '$4',
        '.like-icon': {
          top: '$4',
          left: '$4',
          '&.large': {
            height: '28px',
          },
          '&.small': {
            height: '24px',
          },
        },
        '.provider-icon': {
          '&.large': {
            width: '150px',
            height: '32px',
            img: {
              width: '100%',
              height: '32px',
              objectFit: 'contain',
            },
          },
          '&.small': {
            width: '100px',
            height: '26px',
            img: {
              width: '100%',
              height: '26px',
              objectFit: 'contain',
            },
          },
        },
        '.game-name': {
          '&.large': {
            fontSize: '24px',
            lineHeight: '40px',
          },
          '&.small': {
            fontSize: '20px',
            lineHeight: '28px',
          },
        },
        '.stake-range': {
          '&.large': {
            fontSize: '16px',
            lineHeight: '20px',
          },
          '&.small': {
            fontSize: '14px',
            lineHeight: '24px',
          },
        },
        '.game-actions': {
          fontSize: '18px !important',
          lineHeight: '24px !important',
        },
      },
    },
  },
});
