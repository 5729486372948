import * as TooltipUI from '@radix-ui/react-tooltip';
import { styled } from 'stitches.config';

import { zIndex } from '~utils/zIndex';

import { Text } from '../Typography';

export const StyledTooltipContent = styled(TooltipUI.Content, {
  borderRadius: '$6',
  border: '1px solid $grayDark',
  p: '$3 $4',
  zIndex: zIndex.tooltip,
  variants: {
    tone: {
      black: {
        backgroundColor: '$black',
      },
    },
  },
});

export const StyledTooltipContentText = styled(Text, {
  fontSize: '$sm !important',
  lineHeight: '$20 !important',
  variants: {
    tone: {
      black: {
        color: '$white',
      },
    },
  },
});

export const StyledTooltipArrow = styled(TooltipUI.Arrow, {
  transform: 'scale(2.4) translateY(-0.9px)',
  transformOrigin: 'top',
  variants: {
    tone: {
      black: {
        fill: '$black',
      },
    },
  },
});

export const StyledTooltipArrowBorder = styled(TooltipUI.Arrow, {
  transform: 'scale(2.4) translateY(-0.4px)',
  transformOrigin: 'top',
  variants: {
    tone: {
      black: {
        fill: '$grayDark',
      },
    },
  },
});
