import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { CopyToClipboardIcon, GreenCheckIcon } from '~icons';
import {
  TRANSACTION_STATUS_ICONS,
  TransactionHistoryCellProps,
} from '~types/transactions';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

export const DateIdCell = ({
  row: { original },
}: TransactionHistoryCellProps) => {
  const { status, id, createdAt } = original;
  const StatusIcon = TRANSACTION_STATUS_ICONS[status];
  const { handleCopyId, isCopied } = useCopyToClipboard();

  return (
    <Box
      flexRow
      css={{
        p: '0 $3',
        maxWidth: '200px',
      }}
    >
      <Box flexRow alignCenter gap={3}>
        <Box flexRow css={{ color: '$grayMedium' }}>
          {StatusIcon && <StatusIcon />}
        </Box>
        <Box flexCol gap={1}>
          <Text level="12-20">
            {formatDateTime(createdAt, TIME_FORMATS.DATE_TIME_24H_WITH_SECONDS)}
          </Text>
          <Text
            level="12-20"
            ellipsis
            css={{
              width: '150px',
            }}
          >
            ID: {id.replace(/-/g, '')}
          </Text>
        </Box>
      </Box>
      <Box
        flexRow
        css={{
          position: 'relative',
          top: '$6',
          right: '-$1',
          cursor: 'pointer',
          color: '$grayMedium',
          width: '$3',
        }}
        onClick={handleCopyId(id)}
      >
        {isCopied ? <GreenCheckIcon /> : <CopyToClipboardIcon />}
      </Box>
    </Box>
  );
};
