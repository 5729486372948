import { useState } from 'react';

import { Text } from '~/components/atoms/Typography';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Dialog, DialogContent } from '~components/atoms/Modal';
import { Separator } from '~components/atoms/Separator';
import { DeactivateAccountModalContent } from '~components/molecules/UserProfile/components/DeactivateAccount/DeactivateAccountModalContent';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { InfoIcon } from '~icons';

export const DeactivateAccount = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const toggleDialog = () => {
    setDeactivateOpen(!deactivateOpen);
  };

  return (
    <>
      <Box
        css={{
          '@xs_sm': {
            p: '$3',
            borderRadius: '$8',
            background: '$liveEventCardBgColor',
          },
        }}
      >
        <Box
          flexRow={!isMobileOrTablet}
          flexCol={isMobileOrTablet}
          justifyContentBetween
          alignCenter
          gap={2}
        >
          <Box
            flexRow
            fullWidth
            alignCenter
            gap={2}
            css={{
              p: '$2',
              background: '$blueDark',
              borderRadius: '$8',
              color: '$white',
              '& > svg': {
                width: '1rem',
                height: '1rem',
              },
            }}
          >
            <InfoIcon />
            <Box flexCol fullWidth>
              <Text level="12-20" fontWeight="bold">
                {localized(
                  'userProfile.personalProfileInfo.accountSettings.deactivateAccount.warrning',
                )}
              </Text>
              <Text level="12-20">
                {localized(
                  'userProfile.personalProfileInfo.accountSettings.deactivateAccount.description',
                )}
              </Text>
            </Box>
          </Box>
          {isMobileOrTablet && (
            <Separator
              shrinkOut={3}
              css={{ backgroundColor: '$gray', ml: 0, mt: '$1' }}
              verticalSpace={0}
            />
          )}
          <Button
            variant="secondary"
            fullWidth={isMobileOrTablet}
            css={{
              flexDirection: 'column',
              padding: '$2 $6',
              height: isMobileOrTablet ? 'unset' : '100%',
              '@md_lg_xl': {
                width: '168px',
              },
            }}
            onClick={toggleDialog}
          >
            {isMobileOrTablet ? (
              <Text level="14-20">Close Account</Text>
            ) : (
              <>
                <Text level="14-20">Close</Text>
                <Text level="14-20">Account</Text>
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Dialog open={deactivateOpen} toggleDialog={toggleDialog}>
        <DialogContent hasOverlay top>
          <DeactivateAccountModalContent onClose={toggleDialog} />
        </DialogContent>
      </Dialog>
    </>
  );
};
