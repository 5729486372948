import { ComponentProps } from 'react';
import { styled } from 'stitches.config';

const StyledTag = styled('span', {
  display: 'block',
  backgroundColor: '$grayDarkMain2',
  color: '$grayMedium',
  lineHeight: '$20',
  borderRadius: '$4',
  width: '$8',
  textAlign: 'center',
  fontSize: '$xxs',
  fontWeight: '$medium',
  textTransform: 'uppercase',
  variants: {
    color: {
      transparent: {
        backgroundColor: 'transparent',
      },
      grayDark: {
        backgroundColor: '$grayDarkMain2',
      },
      yellow: {
        backgroundColor: '$yellow',
      },
      grayMedium: {
        backgroundColor: '$grayMedium',
      },
      gray: {
        backgroundColor: '$gray',
      },
    },
    size: {
      small: {
        borderRadius: '$2',
        color: '$grayDark',
        fontSize: '$xxxs',
        fontWeight: '$bold',
        lineHeight: '$14',
        width: '32px',
      },
    },
  },
});

interface TagProps extends ComponentProps<typeof StyledTag> {
  text?: string | number;
}

export const Tag = ({ text, ...props }: TagProps) => {
  return text?.toString().length ? (
    <StyledTag {...props}>{text}</StyledTag>
  ) : null;
};
