import * as AccordionUI from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { Text } from '~components/atoms/Typography';
import { ChevronDownIcon } from '~icons';
import { slideDown, slideUp } from '~utils/keyframes';

export const StyledTrigger = styled(AccordionUI.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  flex: 1,
  p: '$2 $3',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '$md',
  cursor: 'pointer',
  color: '$grayMedium',
  animationFillMode: 'forwards',
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '&[data-state=open] *, &:hover *': {
    color: '$white',
    'svg path': {
      fill: '$white', // Set the fill color of the SVG path to white
    },
  },
  '&[data-state=open] > svg': {
    transform: 'rotate(180deg)',
  },
});

export const StyledHeader = styled(AccordionUI.Header, {
  all: 'unset',
  display: 'flex',
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
});

export const StyledContent = styled(AccordionUI.Content, {
  color: '$blackDarker',
  backgroundColor: '$inplayMarketsAccordionContentBgColor',
  transition: 'border-radius 700ms cubic-bezier(0.87, 0, 0.13, 1)',
  '& .contentWrapper': {
    p: '0 $3 $2',
  },
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    transitionDelay: 0,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export const StyledChevron = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  width: '$4',
  color: '$grayMedium',
  position: 'relative',
  mx: '$3',
  '&:hover *, &[data-state="open"] *': {
    color: '$white',
  },
});

export const StyledSelectionItemOddWrapper = styled('div', {
  minWidth: '3rem',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const StyledSelectionItemLabelWrapper = styled(Text, {
  textOverflow: 'none',
  flexShrink: 0,
});
