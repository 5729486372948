import React from 'react';
import { COLOR_KEYS } from 'stitches.config';

import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import {
  TransactionHistoryCellProps,
  transactionsTranslationsMap,
  transactionsTypeColorsMap,
} from '~types/transactions';

export const TypeCell = ({
  row: {
    original: { type },
  },
}: TransactionHistoryCellProps) => {
  const { localized } = useTranslation();

  return (
    <Text level="12-20" color={transactionsTypeColorsMap[type] as COLOR_KEYS}>
      {localized(transactionsTranslationsMap[type])}
    </Text>
  );
};
