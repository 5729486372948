import { NavLink } from 'react-router-dom';
import { styled } from 'stitches.config';

import { ellipsis } from '~utils/cssUtils';

export const NavigationLink = styled(NavLink, {
  display: 'block',
  textDecoration: 'none',
  '&:hover': {
    '@xs_sm': {
      textDecoration: 'none',
    },
  },
  variants: {
    ellipsis: {
      true: {
        ...ellipsis,
      },
    },
  },
});
