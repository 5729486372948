import { ComponentProps } from 'react';
import { CSS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { LoadingIcon } from '~icons';
import { spin } from '~utils/keyframes';

export const StyledLoadingIcon = styled(LoadingIcon, {
  animation: `${spin} 1300ms linear infinite`,
});

interface LoaderProps extends ComponentProps<typeof Box> {
  css?: CSS;
}

export const Loader = ({ css, ...props }: LoaderProps) => {
  return (
    <Box flexRow css={{ color: '$loadingIconColor', ...css }} {...props}>
      <StyledLoadingIcon />
    </Box>
  );
};
