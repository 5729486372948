import React, { ComponentType, useCallback } from 'react';

import { Box } from '~components/atoms/Box';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Text } from '~components/atoms/Typography';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import { StyledLinkContentMenuText } from '~components/organisms/FooterComponent/FooterDesktop/styled.components';
import {
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/HeaderComponent/Header/useHeaderMenu';
import { MOBILE_MENU } from '~components/organisms/MobileTabComponent/MobileTabSport';
import { useQueryParams } from '~hooks';
import { useTranslation } from '~hooks/useTranslation';
import {
  AviatorIcon,
  CasinoIcon,
  InplayIcon,
  JackpotIcon,
  LiveCasinoIcon,
  PromotionIcon,
  SportsIcon,
  VirtualSportsIcon,
} from '~icons/menu';
import { useAppDispatch, useAppSelector } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { setPreviousLocation } from '~store/slices/historySlice';
import { resetLiveMenuSlice } from '~store/slices/liveMenuSlice';
import {
  clearLocationScrollPositions,
  setActiveSportTabGroup,
  setMobileActiveTab,
} from '~store/slices/mobileSlice';
import { resetPrematchData } from '~store/slices/prematchMenuSlice';
import { getIsMenuItemActive } from '~utils/menu';

export const MobileMenu = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { menu } = useHeaderMenu();

  type IconsMap = {
    [key in MENU_ICON_TYPES]: ComponentType;
  };

  const iconsMap: IconsMap = {
    [MENU_ICON_TYPES.Aviator]: AviatorIcon,
    [MENU_ICON_TYPES.Casino]: CasinoIcon,
    [MENU_ICON_TYPES.Inplay]: InplayIcon,
    [MENU_ICON_TYPES.LiveCasino]: LiveCasinoIcon,
    [MENU_ICON_TYPES.Sports]: SportsIcon,
    [MENU_ICON_TYPES.VirtualSports]: VirtualSportsIcon,
    [MENU_ICON_TYPES.Promotion]: PromotionIcon,
    [MENU_ICON_TYPES.Jackpot]: JackpotIcon,
  };
  const { menu: menuQuery } = useQueryParams();
  const { mobileActiveTab } = useAppSelector((state) => state.mobileState);

  const mobileHandler = useCallback(
    (value: string) => {
      if (menuQuery === value) return;

      dispatch(clearLocationScrollPositions());
      window.scrollTo(0, 0);

      if (mobileActiveTab === MOBILE_MENU.LIVE) {
        dispatch(resetLiveMenuSlice());
        dispatch(setPreviousLocation(null));
      }

      if (mobileActiveTab === MOBILE_MENU.SPORT) {
        dispatch(resetPrematchData());
        dispatch(setPreviousLocation(null));
        dispatch(setActiveSportTabGroup(SPORT_TAB_GROUPS.UPCOMMING));
      }

      dispatch(setMobileActiveTab(value as MOBILE_MENU));
    },
    [menuQuery, mobileActiveTab],
  );

  const handleMenuItemClick = (icon: MENU_ICON_TYPES | undefined) => {
    let menuValue: MOBILE_MENU | undefined;

    if (icon === MENU_ICON_TYPES.Sports) {
      menuValue = MOBILE_MENU.SPORT;
    }

    if (icon === MENU_ICON_TYPES.Inplay) {
      menuValue = MOBILE_MENU.LIVE;
    }

    if (menuValue) {
      mobileHandler(menuValue as MOBILE_MENU);
    } else {
      dispatch(setMobileActiveTab(null));
    }

    dispatch(closeDialog());
  };

  return (
    <DialogMobileContent isContentState={false}>
      <Box
        css={{
          p: '$2 $1',
        }}
      >
        <Text
          level="18-24"
          textTransform="uppercase"
          css={{
            mb: '$2',
            ml: '$4',
          }}
        >
          {localized('menu')}
        </Text>
        <Box
          flexCol
          gap={2}
          css={{
            p: '$2',
            borderRadius: '$8',
            border: '1px solid $iconButtonBorderHover',
            background: '$htmlBgColor',
          }}
        >
          {menu.map(({ name, url, icon }) => {
            const Icon = iconsMap[icon as MENU_ICON_TYPES];
            const isActive = getIsMenuItemActive(
              icon as MENU_ICON_TYPES,
              url || '',
              menuQuery ?? '',
            );
            const color = isActive ? 'yellow' : 'white';
            const fontWeight = isActive ? '$medium' : '$normal';

            return (
              <Box
                key={name}
                css={{
                  background: '$inplayMarketsAccordionContentBgColor',
                  p: '$3',
                  borderRadius: '$8',
                }}
              >
                <NavigationLink
                  to={url || ''}
                  onClick={() => handleMenuItemClick(icon)}
                >
                  <StyledLinkContentMenuText as="span">
                    <Box
                      flexRow
                      alignCenter
                      gap={2}
                      css={{
                        color: `${color}`,
                      }}
                    >
                      {Icon && <Icon />}
                      <Text
                        css={{
                          fontWeight,
                          color,
                        }}
                        level={'16-20'}
                      >
                        {name}
                      </Text>
                    </Box>
                  </StyledLinkContentMenuText>
                </NavigationLink>
              </Box>
            );
          })}
        </Box>
      </Box>
    </DialogMobileContent>
  );
};
