import { useEffect, useState } from 'react';

import { useAuthorizedDepositFlutterwave } from '~api/flutterwave/flutterwaveMutations';
import {
  useAuthorizedDepositPaystack,
  usePaystackCardCharge,
} from '~api/paystack/paystackMutations';
import {
  AuthorizedDepositPayload,
  ExistingPaymentMethod,
} from '~api/transaction/types';
import { UserProfileData } from '~api/user/types';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { CURRENCY_CODES } from '~constants/common';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_PROVIDER,
} from '~constants/payments';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNewPaymentMethodFormStatus,
  setIsLoading,
  setTransactionStatus,
} from '~store/slices/paymentsSlice';
import { TRANSACTION_STATUS } from '~types/transactions';
import { validateEmail } from '~utils/validateEmail';

export const useCardSubmitButton = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.userState);
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const { paystackCardChargeIsLoading } = usePaystackCardCharge();
  const { authorizedDepositPaystackIsLoading } = useAuthorizedDepositPaystack();
  const {
    authorizedDepositFlutterwaveMutation,
    authorizedDepositFlutterwaveIsLoading,
  } = useAuthorizedDepositFlutterwave();
  const [isDisabled, setIsDisabled] = useState(false);

  const { email } = profile as UserProfileData;
  const {
    selectedPaymentMethod,
    addingNewCard,
    activePaymentTab,
    paymentMethodAmount,
    cardHolderEmail,
    cardValue,
    cvv,
    expiryDate,
    bankCode,
    bankNumber,
    paymentSettings,
  } = useAppSelector((state) => state.payments);

  const handleUnauthorizedCardCharge = async () => {
    try {
      dispatch(setIsLoading(true));
      if (activePaymentTab === PAYMENT_PROVIDER.FLUTTERWAVE) {
        dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
          ),
        );
      } else if (activePaymentTab === PAYMENT_PROVIDER.PAYSTACK) {
        dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
        dispatch(
          setAddingNewPaymentMethodFormStatus(
            ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
          ),
        );
      }
    } catch (e) {
      dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
      dispatch(
        setAddingNewPaymentMethodFormStatus(
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
        ),
      );
      console.error('Deposit failed:', e);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleAuthorizedCardCharge = async () => {
    try {
      if (contentTab === USER_PAYMENT_TABS.WITHDRAW) {
        console.log('withdraw');
      } else {
        dispatch(setIsLoading(true));
        const { email, cardToken } =
          (selectedPaymentMethod as ExistingPaymentMethod) || {};

        if (email && email.length && cardToken) {
          const payload: AuthorizedDepositPayload = {
            amount: +paymentMethodAmount,
            currency: CURRENCY_CODES.NGN,
            email: email,
          };

          if (activePaymentTab === PAYMENT_PROVIDER.PAYSTACK) {
            payload.authorizationCode = cardToken;
            payload.callbackUrl = `${window.location.origin}`;

            dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
            dispatch(
              setAddingNewPaymentMethodFormStatus(
                ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
              ),
            );
          } else {
            payload.token = cardToken;
            const response =
              await authorizedDepositFlutterwaveMutation(payload);

            if ('data' in response && !!response.data.status) {
              dispatch(setTransactionStatus(TRANSACTION_STATUS.SUCCESS));
            } else {
              dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
            }

            dispatch(
              setAddingNewPaymentMethodFormStatus(
                ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
              ),
            );
          }
        }
      }
    } catch (e) {
      dispatch(setTransactionStatus(TRANSACTION_STATUS.FAILED));
      dispatch(
        setAddingNewPaymentMethodFormStatus(
          ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE,
        ),
      );
      console.error('Deposit failed', e);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleCardCharge = async () => {
    if (selectedPaymentMethod) {
      await handleAuthorizedCardCharge();
    } else {
      await handleUnauthorizedCardCharge();
    }
  };

  useEffect(() => {
    const { minDepositAmount = 0, maxDepositAmount = 0 } =
      paymentSettings || {};

    if (!addingNewCard) {
      if (
        !selectedPaymentMethod ||
        +paymentMethodAmount < minDepositAmount ||
        +paymentMethodAmount > maxDepositAmount
      ) {
        setIsDisabled(true);

        return;
      }

      setIsDisabled(false);

      return;
    }

    if (contentTab === USER_PAYMENT_TABS.WITHDRAW) {
      if (
        +paymentMethodAmount < minDepositAmount ||
        +paymentMethodAmount > maxDepositAmount
      ) {
        setIsDisabled(true);

        return;
      }

      if (!bankCode.length || !bankNumber.length) {
        setIsDisabled(true);

        return;
      }

      setIsDisabled(false);
    } else {
      if (activePaymentTab === PAYMENT_PROVIDER.FLUTTERWAVE) {
        if (
          +paymentMethodAmount < minDepositAmount ||
          +paymentMethodAmount > maxDepositAmount
        ) {
          setIsDisabled(true);

          return;
        }

        if (!validateEmail(email || '') && !validateEmail(cardHolderEmail)) {
          setIsDisabled(true);

          return;
        }

        setIsDisabled(false);

        return;
      } else {
        if (
          +paymentMethodAmount < minDepositAmount ||
          +paymentMethodAmount > maxDepositAmount
        ) {
          setIsDisabled(true);

          return;
        }

        if (!validateEmail(email || '') && !validateEmail(cardHolderEmail)) {
          setIsDisabled(true);

          return;
        }

        setIsDisabled(false);
      }
    }
  }, [
    contentTab,
    paymentSettings,
    cvv,
    expiryDate,
    cardValue,
    paymentMethodAmount,
    cardHolderEmail,
    email,
    addingNewCard,
    bankCode,
    bankNumber,
    selectedPaymentMethod,
  ]);

  return {
    isLoading:
      paystackCardChargeIsLoading ||
      authorizedDepositPaystackIsLoading ||
      authorizedDepositFlutterwaveIsLoading,
    isDisabled,
    handleCardCharge,
  };
};
