import { useEffect, useState } from 'react';

import {
  useLazyGetWithdrawalBanks,
  useLazyGetWithdrawalLimits,
} from '~api/transaction/transactionQueries';
import { LimitsResponse } from '~api/transaction/types';
import { UserProfileData } from '~api/user/types';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { CURRENCY_SYMBOLS, MESSAGE_TYPES } from '~constants/common';
import { PAYMENT_PROVIDER } from '~constants/payments';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  resetBankForm,
  setAddingNew,
  setPaymentMethodAmount,
  updatePaymentSettingsOnBankChange,
} from '~store/slices/paymentsSlice';

export const useBankPaymentForm = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.userState);
  const [withdrawalLimits, setWithdrawalLimits] = useState<LimitsResponse>();
  const {
    paymentMethodAmount,
    activePaymentTab,
    cardHolderEmail,
    bankNumber,
    bankCode,
    saveCard,
    addingNewCard,
    paymentSettings,
    selectedNigeriaMethod,
    selectedPaymentMethod,
  } = useAppSelector((state) => state.payments);
  const { localized } = useTranslation();
  const { paymentSettings: paymentPartnerSettings, userLimits } =
    useAppSelector((state) => state.settings);

  const { balance } = profile as UserProfileData;

  const { lazyGetWithdrawalLimitsQuery } = useLazyGetWithdrawalLimits();
  const { isMobileOrTablet } = useMedia();
  const { currency } = useAppSelector((state) => state.settings);
  const { lazyGetWithdrawalBanksQuery } = useLazyGetWithdrawalBanks();

  const { contentTab, mainTab } = useAppSelector(
    (state) => state.personalDetails,
  );

  const [banks, setBanks] = useState<
    { label: string; value: string; provider: number }[]
  >([]);

  const handleChangeAmount = (amount: string) => {
    dispatch(setPaymentMethodAmount(amount));
  };

  useEffect(() => {
    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) return;
    const loadBanks = async () => {
      try {
        const banks = await lazyGetWithdrawalBanksQuery().unwrap();

        if (banks) {
          setBanks(
            banks.map(({ name, bankId, provider }) => ({
              label: name,
              value: bankId.toString(),
              provider: provider,
            })),
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    loadBanks();
  }, [activePaymentTab, contentTab]);

  useEffect(() => {
    if (activePaymentTab !== PAYMENT_PROVIDER.RELWORX) {
      dispatch(setAddingNew(false));
    }

    dispatch(resetBankForm());
  }, [mainTab, contentTab, activePaymentTab]);

  useEffect(() => {
    if (
      (contentTab === USER_PAYMENT_TABS.DEPOSIT &&
        activePaymentTab === PAYMENT_PROVIDER.PAYSTACK) ||
      activePaymentTab === PAYMENT_PROVIDER.RELWORX
    ) {
      dispatch(setAddingNew(true));
    }
  }, [contentTab, activePaymentTab]);

  useEffect(() => {
    const loadLimits = async () => {
      try {
        const limits = await lazyGetWithdrawalLimitsQuery().unwrap();

        if (limits) {
          setWithdrawalLimits(limits);
        }
      } catch (e) {
        console.log(e);
      }
    };

    loadLimits();
  }, []);

  useEffect(() => {
    const providerId = banks.find((bank) => bank.value === bankCode)
      ?.provider as number;

    const provider = selectedPaymentMethod?.provider || providerId;

    if (provider) {
      dispatch(updatePaymentSettingsOnBankChange(provider));
    }
  }, [bankCode, selectedPaymentMethod, banks]);

  const getMinValue = () => {
    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      if (userLimits) {
        const { depositLimitMin } = userLimits;

        if (depositLimitMin !== null) return depositLimitMin;
      }

      if (paymentSettings) {
        const { minDepositAmount } = paymentSettings;

        if (minDepositAmount) return minDepositAmount;
      }

      if (paymentPartnerSettings) {
        const { depositLimitMin } = paymentPartnerSettings;

        if (depositLimitMin !== null) return depositLimitMin;
      }
    }

    if (paymentPartnerSettings) {
      const { withdrawalLimitMin } = paymentPartnerSettings;

      if (withdrawalLimitMin !== null) return withdrawalLimitMin;
    }

    return 0;
  };

  const getMaxValue = () => {
    if (contentTab === USER_PAYMENT_TABS.DEPOSIT) {
      if (userLimits) {
        const { depositLimitMax } = userLimits;

        if (depositLimitMax !== null) return depositLimitMax;
      }

      if (paymentSettings) {
        const { maxDepositAmount } = paymentSettings;

        if (maxDepositAmount) return maxDepositAmount;
      }

      if (paymentPartnerSettings) {
        const { depositLimitMax } = paymentPartnerSettings;

        if (depositLimitMax !== null) return depositLimitMax;
      }
    }

    if (userLimits) {
      const { withdrawalLimitMax } = userLimits;

      if (withdrawalLimitMax !== null) return withdrawalLimitMax;
    }

    if (paymentSettings) {
      const { maxWithdrawAmount } = paymentSettings;

      if (maxWithdrawAmount !== null) return maxWithdrawAmount;
    }

    if (paymentPartnerSettings) {
      const { withdrawalLimitMax } = paymentPartnerSettings;

      if (withdrawalLimitMax !== null) return withdrawalLimitMax;
    }

    return Infinity;
  };

  function daysLeftInMonth(): number {
    const today: Date = new Date();
    const endOfMonth: Date = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
    );

    return endOfMonth.getDate() - today.getDate();
  }

  const getMessage = (): {
    messageText: string[];
    messageType: MESSAGE_TYPES;
  } => {
    const defaultReturn = {
      messageText: [],
      messageType: MESSAGE_TYPES.WARNING,
    };

    if (!withdrawalLimits) return defaultReturn;

    const {
      allowedWithdrawLimit,
      possibleWithdrawMonthAmount,
      possibleWithdrawTodayAmount,
      possibleWithdrawTodayCount,
      possibleWithdrawMonthCount,
      withdrawalAmountLimitPerMonth,
      withdrawalAmountLimitPerDay,
      withdrawalCountLimitPerMonth,
      withdrawalCountLimitPerDay,
      withdrawalTimeFrom,
      withdrawalTimeTo,
      isWithdrawalAvailable,
    } = withdrawalLimits;

    if (allowedWithdrawLimit && allowedWithdrawLimit < +paymentMethodAmount) {
      return {
        messageText: [
          localized('withdrawalLimits.withdrawWegeredTitle'),
          localized('withdrawalLimits.withdrawWegered', {
            amount: allowedWithdrawLimit,
            currency: CURRENCY_SYMBOLS[currency],
          }),
        ],
        messageType: MESSAGE_TYPES.WARNING,
      };
    }

    if (possibleWithdrawTodayAmount <= 0 && withdrawalAmountLimitPerDay) {
      return {
        messageText: [
          localized('withdrawalLimits.dailyExceed'),
          localized('withdrawalLimits.possibleDayAmountExceeded', {
            amount: withdrawalAmountLimitPerDay,
            currency: CURRENCY_SYMBOLS[currency],
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (possibleWithdrawMonthAmount <= 0 && withdrawalAmountLimitPerMonth) {
      return {
        messageText: [
          localized('withdrawalLimits.monthlyExceed'),
          localized('withdrawalLimits.possibleMonthAmountExceeded', {
            amount: withdrawalAmountLimitPerMonth,
            currency: CURRENCY_SYMBOLS[currency],
            daysLeft: daysLeftInMonth(),
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (possibleWithdrawTodayCount <= 0 && withdrawalCountLimitPerDay) {
      return {
        messageText: [
          localized('withdrawalLimits.dailyReached'),
          localized('withdrawalLimits.possibleDayCountExceeded', {
            count: withdrawalCountLimitPerDay,
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (possibleWithdrawMonthCount <= 0 && withdrawalCountLimitPerMonth) {
      return {
        messageText: [
          localized('withdrawalLimits.monthlyReached'),
          localized('withdrawalLimits.possibleMonthCountExceeded', {
            count: withdrawalCountLimitPerMonth,
            daysLeft: daysLeftInMonth(),
          }),
        ],
        messageType: MESSAGE_TYPES.ERROR,
      };
    }

    if (contentTab === USER_PAYMENT_TABS.DEPOSIT)
      return { messageType: MESSAGE_TYPES.WARNING, messageText: [] };

    if (paymentPartnerSettings.withdrawalLimitMax < +paymentMethodAmount) {
      return {
        messageText: [
          localized('withdrawalLimits.withdrawalRequestCreated'),
          localized('withdrawalLimits.withdrawalRequest', {
            amount: paymentPartnerSettings.withdrawalLimitMax,
            currency: CURRENCY_SYMBOLS[currency],
          }),
        ],
        messageType: MESSAGE_TYPES.WARNING,
      };
    }

    if (withdrawalTimeFrom && withdrawalTimeTo && !isWithdrawalAvailable) {
      return {
        messageText: [
          localized('withdrawalLimits.withdrawHoursLimit', {
            withdrawalTimeFrom,
            withdrawalTimeTo,
          }),
        ],
        messageType: MESSAGE_TYPES.WARNING,
      };
    }

    return defaultReturn;
  };

  const isProviderSettingsExist = paymentSettings;

  const isLowerOrIsBigger =
    (+paymentMethodAmount > getMaxValue() ||
      +paymentMethodAmount < getMinValue()) &&
    +paymentMethodAmount > 0;

  const isAmountBiggerThenBalance =
    balance < +paymentMethodAmount && contentTab !== USER_PAYMENT_TABS.DEPOSIT;

  const { messageText, messageType } = getMessage();
  const isDeposit = contentTab === USER_PAYMENT_TABS.DEPOSIT;

  const isWithdrawUnavailable =
    withdrawalLimits?.withdrawalTimeFrom &&
    withdrawalLimits?.withdrawalTimeTo &&
    !withdrawalLimits?.isWithdrawalAvailable;

  const isSubmitButtonDisabled =
    (messageType === MESSAGE_TYPES.ERROR && !isDeposit) ||
    (contentTab !== USER_PAYMENT_TABS.DEPOSIT && isWithdrawUnavailable) ||
    isAmountBiggerThenBalance;

  return {
    isDeposit,
    isPaystack: activePaymentTab === PAYMENT_PROVIDER.PAYSTACK,
    activePaymentTab,
    currency,
    profile,
    saveCard,
    isMobileOrTablet,
    addingNewCard,
    contentTab,
    cardHolderEmail,
    banks,
    bankNumber,
    bankCode,
    paymentMethodAmount,
    selectedNigeriaMethod,
    isProviderSettingsExist,
    isAmountBiggerThenBalance,
    messageText,
    messageType,
    min: getMinValue(),
    max: getMaxValue(),
    handleChangeAmount,
    isLowerOrIsBigger,
    isSubmitButtonDisabled,
  };
};
