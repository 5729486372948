import { Box } from '~components/atoms/Box';
import {
  ADD_PAYMENT_ACCOUNT_FORM_STATUSES,
  PAYMENT_METHODS,
} from '~constants/payments';
import { useAppSelector } from '~store';
import { ContentsMap } from '~types/general';

import { BankPaymentForm } from './BankMethod/BankPaymentForm';
import { AddCardAddressForm } from './CardMethod/AddCardAddressForm';
import { AddCardPinForm } from './CardMethod/AddCardPinForm';
import { CardPaymentForm } from './CardMethod/CardPaymentForm';
import { Loader } from './CardMethod/Loader';
import { PaymentDone } from './CardMethod/PaymentDone';

export const DepositForm = () => {
  const { addingNewPaymentMethodFormStatus, selectedPayment, isLoading } =
    useAppSelector((state) => state.payments);

  const formContentsMap: ContentsMap = {
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.BASE]:
      selectedPayment === PAYMENT_METHODS.INSTANT_CARD ? (
        <CardPaymentForm />
      ) : (
        <BankPaymentForm />
      ),
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.ADDRESS]: <AddCardAddressForm />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.OTP]: <AddCardPinForm />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.PIN]: <AddCardPinForm />,
    [ADD_PAYMENT_ACCOUNT_FORM_STATUSES.DONE]: <PaymentDone />,
  };

  return (
    <Box
      fullWidth
      fullHeight
      flexRow
      alignCenter
      justifyCenter
      css={{
        pt: '$3',
        '@xs_sm': {
          pt: '$2',
          paddingBottom: '$4',
        },
      }}
    >
      <Box
        flexCol
        gap={2}
        alignCenter
        justifyCenter
        css={{
          maxWidth: '362px',
          width: '100%',
          '@xs_sm': {
            maxWidth: 'unset',
            p: '0 $2',
          },
        }}
      >
        {!isLoading ? (
          formContentsMap[addingNewPaymentMethodFormStatus]
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};
