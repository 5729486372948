import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { ExtraData } from '~types/sportExtraData';

import { getScoreDetailsBySportMap } from './utils/scoreDetails';

interface ScoreDetailsMobileProps {
  sportName: string | undefined;
  extraData: ExtraData | null;
}

export const ScoreDetailsMobile = ({
  sportName,
  extraData,
}: ScoreDetailsMobileProps) => {
  const scoreDetails = getScoreDetailsBySportMap(sportName, extraData);

  return (
    <>
      {scoreDetails && (
        <Box
          css={{
            backgroundColor: '$mainScoreBgColorOpacity',
            p: '$2 $4',
          }}
        >
          <Text level="12-20">{scoreDetails}</Text>
        </Box>
      )}
    </>
  );
};
