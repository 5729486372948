import { ReactNode } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useAppSelector } from '~store';

interface BetslipTabItemProps {
  children: ReactNode;
}
export const BetslipTabItem = ({ children }: BetslipTabItemProps) => {
  const { events } = useAppSelector((state) => state.betslip);

  return (
    <>
      {events.length > 0 && (
        <Box
          css={{
            position: 'absolute',
            left: '50%',
            bottom: '3px',
            transform: 'translateX(-50%)',
            width: '$5',
            backgroundColor: '$green',
            borderRadius: '$2',
          }}
        >
          <Text
            level="12-16"
            fontWeight="bold"
            textAlign="center"
            color="oddTextColorActive"
          >
            {events.length}
          </Text>
        </Box>
      )}
      {children}
    </>
  );
};
