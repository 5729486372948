import React, { useRef, useState } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { useNumberInput } from '~components/atoms/NumberInput/useNumberInput';
import { PureIconButton } from '~components/atoms/PureIconButton';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { useMedia } from '~hooks/useMedia';
import { MinusIcon, PlusIcon } from '~icons';
import { useAppSelector } from '~store';

const sharedActionsCss = {
  width: '$8',
  height: '$8',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const StyledValueMultiplierPlaceholder = styled(Box, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '$3',
  color: '$grayMedium',
});

interface NumberInputProps {
  value: string;
  onChange: (value: string) => void;
  withoutActions?: boolean;
  fullWidth?: boolean;
  textColor?: string;
}

export const NumberInput = ({
  onChange: onSuccess,
  fullWidth = false,
  value: initialValue,
  withoutActions = false,
  textColor,
}: NumberInputProps) => {
  const { isMobileOrTablet } = useMedia();
  const numberInputRef = useRef<HTMLDivElement>();
  const [isFocused, setIsFocused] = useState(false);

  const { enableDecimals } = useAppSelector((state) => state.settings);
  const { stakeType, systemBetOption } = useAppSelector(
    (state) => state.betslip,
  );

  const { value, onIncrement, onDecrement, onChange } = useNumberInput({
    onSuccess,
    initialValue,
    enableDecimals,
  });

  const showMultiplier =
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM && systemBetOption;

  const onClick = () => {
    if (numberInputRef.current && isMobileOrTablet) {
      numberInputRef?.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // Don't show 0 if user is focused on input
  const getInputValue = () => {
    if (isFocused && value === '') return '';
    if (value === '') return 0;

    return value;
  };

  return (
    <Box
      ref={numberInputRef}
      flexRow
      alignCenter
      fullWidth={fullWidth}
      css={{
        backgroundColor: '$gray',
        borderRadius: '$10',
        '@xs_sm': {
          height: '32px',
          width: fullWidth ? '100%' : '236px',
          flexGrow: 1,
        },
      }}
    >
      {!withoutActions && (
        <PureIconButton
          onClick={onDecrement}
          css={{
            ...sharedActionsCss,
            paddingRight: '$4',
            marginLeft: '$3',
            '&:before': {
              content: '',
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              width: '1px',
              height: '$4',
              backgroundColor: '$gray1',
            },
            '&:hover > svg': {
              color: '$yellow',
            },
            '@xs_sm': {
              height: '32px',
            },
          }}
        >
          <MinusIcon />
        </PureIconButton>
      )}

      <button
        onClick={onClick}
        style={{
          padding: 0,
          border: 'none',
          borderRadius: 'inherit',
          width: '100%',
          background: 'transparent',
        }}
      >
        {showMultiplier ? (
          <Box
            css={{
              position: 'relative',
            }}
          >
            <Input
              inputMode={enableDecimals ? 'decimal' : 'numeric'}
              value={getInputValue()}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              css={{
                color: textColor || '$white',
                backgroundColor: '$gray',
                fontWeight: '$medium',
                '@xs_sm': {
                  height: '32px',
                },
              }}
            />
            <StyledValueMultiplierPlaceholder>
              {`X${systemBetOption.combinationsCount}`}
            </StyledValueMultiplierPlaceholder>
          </Box>
        ) : (
          <Input
            textCentered
            inputMode={enableDecimals ? 'decimal' : 'numeric'}
            value={getInputValue()}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            css={{
              color: textColor || '$white',
              backgroundColor: '$gray',
              fontWeight: '$medium',
              '@xs_sm': {
                height: '32px',
              },
            }}
          />
        )}
      </button>
      {!withoutActions && (
        <PureIconButton
          css={{
            ...sharedActionsCss,
            paddingLeft: '$4',
            marginRight: '$3',
            '&:before': {
              content: '',
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              width: '1px',
              height: '$4',
              backgroundColor: '$gray1',
            },
            '&:hover > svg': {
              color: '$yellow',
            },
            '@xs_sm': {
              height: '32px',
            },
          }}
          onClick={onIncrement}
        >
          <PlusIcon />
        </PureIconButton>
      )}
    </Box>
  );
};
