import { ChangeEvent, useState } from 'react';

import { useSubscribeEmail } from '~api/email/emailMutations';
import { Box } from '~components/atoms/Box';
import { InputWithButton } from '~components/atoms/InputWithButton';
import { Logo } from '~components/atoms/Logo/Logo';
import { PureIconButton } from '~components/atoms/PureIconButton';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { SettingsIcon } from '~icons';
import { validateEmail } from '~utils/validateEmail';

import { StyledSubscribeText } from './FooterDesktop/styled.components';

interface SubscribeEmailProps {
  showSettings?: boolean;
}

export const SubscribeEmail = ({ showSettings }: SubscribeEmailProps) => {
  const { localized } = useTranslation();
  const { subscribeEmail, subscribeEmailIsLoading, subscribeEmailIsSuccess } =
    useSubscribeEmail();
  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const { isMobileOrTablet } = useMedia();

  const onSubscribeHandler = () => {
    const isValidated = validateEmail(email);

    if (!isValidated) {
      setIsEmailError(true);
    }

    if (email && isValidated) {
      subscribeEmail(email);
    }
  };

  const onEmailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEmailError(false);
    setEmail(e.target.value);
  };

  const inputWithButtonPayload = {
    input: {
      onChange: onEmailChangeHandler,
      placeholder: localized('footer.emailAddress'),
      isError: isEmailError,
    },
    button: {
      onClick: onSubscribeHandler,
      text: localized('footer.subscribe'),
      isLoading: subscribeEmailIsLoading,
      isDisabled: !email,
    },
    isSuccess: subscribeEmailIsSuccess,
    successText: localized('footer.thankYouForSubscription'),
  };

  return (
    <Box
      flexCol
      alignCenter
      gap={4}
      css={{
        flexShrink: 0,
        width: isMobileOrTablet ? '100%' : '432px',
        position: 'relative',
      }}
    >
      {isMobileOrTablet && showSettings && (
        <PureIconButton
          css={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '$grayMedium',
            p: '$2',
            top: '-$1',
            right: '-6px',
          }}
        >
          <SettingsIcon width={20} height={20} />
        </PureIconButton>
      )}
      <Box
        fullWidth
        gap={1}
        justifyCenter
        css={{
          display: 'flex',
          flexDirection: isMobileOrTablet ? 'column' : 'row',
          alignItems: isMobileOrTablet ? 'center' : 'flex-start',
        }}
      >
        <StyledSubscribeText as="span">
          {localized('footer.stayInTouch')}
        </StyledSubscribeText>
        <StyledSubscribeText as="span">
          {localized('footer.subscribeToNewsletter')}
        </StyledSubscribeText>
      </Box>
      <InputWithButton {...inputWithButtonPayload} />
      <Logo size="md2" />
    </Box>
  );
};
