import React, { useEffect } from 'react';

import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAddingNew,
  setCvv,
  setPaymentMethodAmount,
} from '~store/slices/paymentsSlice';

export const useCardPaymentForm = () => {
  const dispatch = useAppDispatch();
  const {
    paymentMethodAmount,
    cardValue,
    saveCard,
    cardHolderName,
    cvv,
    expiryDate,
    addingNewCard,
    cardHolderEmail,
    activePaymentTab,
    bankNumber,
    bankCode,
    paymentSettings,
  } = useAppSelector((state) => state.payments);
  const { currency } = useAppSelector((state) => state.settings);
  const { minDepositAmount, maxDepositAmount } = paymentSettings || {};
  const { isMobileOrTablet } = useMedia();
  const { contentTab, mainTab } = useAppSelector(
    (state) => state.personalDetails,
  );
  const { profile } = useAppSelector((state) => state.userState);

  const handleChangeAmount = (amount: string) => {
    dispatch(setPaymentMethodAmount(amount));
  };

  const handleCvvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > cvv.length) {
      dispatch(setCvv(cvv + value[value.length - 1]));
    } else {
      dispatch(setCvv(cvv.slice(0, -1)));
    }
  };

  useEffect(() => {
    dispatch(setAddingNew(false));
  }, [mainTab, contentTab]);

  return {
    isMobileOrTablet,
    currency,
    isDeposit: contentTab === USER_PAYMENT_TABS.DEPOSIT,
    activePaymentTab,
    bankNumber,
    bankCode,
    paymentMethodAmount,
    cardValue,
    saveCard,
    cardHolderName,
    cvv,
    expiryDate,
    addingNewCard,
    cardHolderEmail,
    profile,
    paymentSettings,
    min: minDepositAmount,
    max: maxDepositAmount,
    handleChangeAmount,
    handleCvvChange,
  };
};
