import { Autocomplete } from '~components/atoms/Autocomplete';
import { Box } from '~components/atoms/Box';
import { Input } from '~components/atoms/Input';
import { Message } from '~components/atoms/Message';
import { NumberInput } from '~components/atoms/NumberInput';
import { Text } from '~components/atoms/Typography';
import { AddBankAccountButton } from '~components/molecules/UserProfile/components/Payments/components/AddBankAccountButton';
import { USER_PAYMENT_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import {
  CURRENCY_CODES,
  CURRENCY_SYMBOLS,
  MESSAGE_TYPES,
} from '~constants/common';
import { PAYMENT_PROVIDER } from '~constants/payments';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setBankCode,
  setBankNumber,
  setCardHolderEmail,
} from '~store/slices/paymentsSlice';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { useBankPaymentForm } from '../../hooks/useBankPaymentForm';
import { AmountAdjustmentButtons } from '../AmountAdjustmentButtons';
import { StyledBaseInputWrapper } from '../StyledBaseInput';
import { StyledBaseSelect } from '../StyledBaseSelect';

import { BankSubmitButton } from './BankSubmitButton';

const cardImages = [
  {
    name: 'visa',
    src: '/visa.png',
  },
  {
    name: 'mastercard',
    src: '/mastercard.png',
  },
  {
    name: 'verve',
    src: '/verve.png',
  },
];

export const BankPaymentForm = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const {
    isDeposit,
    activePaymentTab,
    addingNewCard,
    currency,
    contentTab,
    cardHolderEmail,
    banks,
    bankNumber,
    bankCode,
    paymentMethodAmount,
    min,
    max,
    selectedNigeriaMethod,
    messageText,
    messageType,
    isLowerOrIsBigger,
    isAmountBiggerThenBalance,
    handleChangeAmount,
    isSubmitButtonDisabled,
  } = useBankPaymentForm();
  const { profile } = useAppSelector((state) => state.userState);

  const userProfileEmail = profile?.email;

  const showAddButton =
    (!addingNewCard && activePaymentTab === PAYMENT_PROVIDER.RELWORX) ||
    (!addingNewCard &&
      contentTab === USER_PAYMENT_TABS.WITHDRAW &&
      [PAYMENT_PROVIDER.PAYSTACK, PAYMENT_PROVIDER.FLUTTERWAVE].includes(
        activePaymentTab,
      ));

  const showFillBankDataForm =
    contentTab === USER_PAYMENT_TABS.WITHDRAW &&
    activePaymentTab !== PAYMENT_PROVIDER.RELWORX;

  const isNigeria = currency === CURRENCY_CODES.NGN;
  const isZambia = currency === CURRENCY_CODES.ZMK;

  return (
    <>
      <Text level="14-20">
        {localized(
          isDeposit ? 'payments.depositAmount' : 'payments.withdrawalAmount',
        )}
      </Text>
      <NumberInput
        fullWidth
        withoutActions
        value={paymentMethodAmount}
        onChange={handleChangeAmount}
        textColor={isLowerOrIsBigger ? '$red' : '$white'}
      />
      <Text level="12-20" color={isLowerOrIsBigger ? 'red' : 'white'}>
        {localized('payments.minMaxAmount', {
          min: min ? commaSeparatedNumber(min, true) : min,
          max: max ? commaSeparatedNumber(max, true) : max,
          currency: CURRENCY_SYMBOLS[currency],
        })}
      </Text>
      <AmountAdjustmentButtons />
      {showAddButton ? (
        <AddBankAccountButton />
      ) : (
        <Box css={{ mt: '$3' }} fullWidth flexCol gap={2}>
          {!userProfileEmail?.length &&
            activePaymentTab !== PAYMENT_PROVIDER.RELWORX && (
              <StyledBaseInputWrapper>
                <Input
                  onChange={(e) => dispatch(setCardHolderEmail(e.target.value))}
                  value={cardHolderEmail}
                  type="email"
                  placeholder={localized(
                    'cardFormPlaceholders.cardHolderEmail',
                  )}
                  css={{
                    width: '100%',
                    lineHeight: '$24',
                    p: '0 $2',
                  }}
                />
              </StyledBaseInputWrapper>
            )}
          {showFillBankDataForm ? (
            <>
              <StyledBaseInputWrapper>
                <Input
                  onChange={(e) => dispatch(setBankNumber(e.target.value))}
                  value={bankNumber}
                  placeholder={localized('withdrawForm.bankNumber')}
                  css={{
                    width: '100%',
                    lineHeight: '$24',
                    p: '0 $2',
                  }}
                />
              </StyledBaseInputWrapper>
              <StyledBaseSelect>
                <Autocomplete
                  placeholder={localized('withdrawForm.bankCode')}
                  value={bankCode}
                  onChange={(value) => dispatch(setBankCode(value))}
                  options={banks}
                />
              </StyledBaseSelect>
            </>
          ) : null}
        </Box>
      )}
      {selectedNigeriaMethod === 'card' &&
        contentTab === USER_PAYMENT_TABS.DEPOSIT &&
        (isNigeria || isZambia) && (
          <Box
            flexRow
            fullWidth
            alignCenter
            justifyContentBetween
            gap={5}
            css={{
              border: '1px solid $grayMain',
              borderRadius: '$10',
              padding: '$4 54px',
              mt: '$5',
              position: 'relative',
            }}
          >
            <Text
              css={{
                p: '$0 $3',
                position: 'absolute',
                transform: 'translateX(-50%)',
                left: '50%',
                top: -10,
                background: '$grayDark',
              }}
              level={'12-16'}
            >
              {localized('payments.paymentsBy')}
            </Text>
            {cardImages.map(({ name, src }) => (
              <img src={src} alt={name} key={name} />
            ))}
          </Box>
        )}
      <BankSubmitButton disabled={isSubmitButtonDisabled} />
      {!!messageText?.length && !isDeposit && !isAmountBiggerThenBalance ? (
        <Box
          css={
            isMobileOrTablet
              ? {
                  position: 'relative',
                  width: '100%',
                }
              : {
                  position: 'absolute',
                  bottom: '12px',
                  left: '12px',
                  width: 'calc(100% - 24px)',
                }
          }
        >
          <Message
            type={messageType as MESSAGE_TYPES}
            textArray={messageText}
            css={{ mt: '$3' }}
          />
        </Box>
      ) : null}
    </>
  );
};
