import React, { Fragment } from 'react';
import { Cell, useTable, UseTableOptions } from 'react-table';

import { BetslipHistoryItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { ExpandRowCell } from '~components/molecules/BetslipHistorySection/components/ExpandRowCell';
import { TableDetailsExpander } from '~components/molecules/BetslipHistorySection/components/TableDetailsExpander';
import {
  StyledDataTableContainer,
  StyledTable,
  StyledTableRow,
} from '~components/molecules/BetslipHistorySection/styled.components';
import { useAppSelector } from '~store';
import { getJackpotsBetsHistory } from '~store/slices/selectors/betslip';
import { JackpotBetsHistory } from '~types/jackpot';

import { TableHeader } from '../../components/TableHeader';

import { useNewJackpotBetHistoryTable } from './hooks/useJackpotBetslipsTable';

export const columnWidths: Record<number, string> = {
  0: '240px',
  1: '80px',
  2: '55px',
  3: '80px',
  4: '70px',
  5: '80px',
  6: '110px',
};

const SCROLL_CONTAINER_ID = 'jackpot-betslips-table-scroll-container';

export const JackpotBetslipsTable = () => {
  const jackpotsBetsHistory = useAppSelector(getJackpotsBetsHistory);
  const {
    columns,
    openRows,
    getOpenRowsValue,
    handleOuterTableRowClick,
    renderExpandedRowContent,
  } = useNewJackpotBetHistoryTable();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: jackpotsBetsHistory || [],
    } as UseTableOptions<JackpotBetsHistory>);

  return (
    <StyledDataTableContainer
      css={{
        maxHeight: '520px',
        height: 'fit-content',
      }}
    >
      <ScrollArea id={SCROLL_CONTAINER_ID}>
        <StyledTable {...getTableProps()}>
          <TableHeader
            headerGroups={headerGroups}
            columnWidths={columnWidths}
          />
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);

              const isRowOpened = Boolean(openRows[row.id]);

              return (
                <Fragment key={rowIndex}>
                  <StyledTableRow
                    {...row.getRowProps()}
                    className={isRowOpened ? 'expanded' : ''}
                    key={rowIndex}
                    onClick={handleOuterTableRowClick(rowIndex)}
                  >
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        key={cellIndex}
                        style={{
                          maxWidth: columnWidths[cellIndex]
                            ? columnWidths[cellIndex]
                            : 'none',
                        }}
                      >
                        <Box
                          flexRow
                          alignCenter
                          css={{
                            background: '$blueDark',
                            height: '100%',
                            minHeight: '76.5px',
                          }}
                        >
                          <ExpandRowCell
                            cell={
                              cell as Cell<
                                BetslipHistoryItem | JackpotBetsHistory
                              >
                            }
                            isOpen={isRowOpened}
                          />
                        </Box>
                      </td>
                    ))}
                  </StyledTableRow>
                  {renderExpandedRowContent && isRowOpened && (
                    <TableDetailsExpander
                      columnsOuterLength={columns.length}
                      getOpenRowsValue={getOpenRowsValue}
                      rowIndex={rowIndex}
                      openRows={openRows}
                    >
                      {renderExpandedRowContent(row.original)}
                    </TableDetailsExpander>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </StyledTable>
      </ScrollArea>
    </StyledDataTableContainer>
  );
};
