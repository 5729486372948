import React, { useMemo } from 'react';
import { useTable } from 'react-table';

import { UserDocumentResponse } from '~api/user/types';
import { Box } from '~components/atoms/Box';
import { PureIconButton } from '~components/atoms/PureIconButton';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Tooltip } from '~components/atoms/Tooltip';
import { DownloadFileIcon } from '~icons';

import {
  StyledCell,
  StyledCellContent,
  StyledDocumentStatusValue,
  StyledHeaderCell,
  StyledPlaceholderCell,
  StyledTable,
  StyledTableHeader,
} from '../components';
import { useUserVerificationData } from '../hooks/useUserVerificationData';
import { DataRow } from '../types';

interface UserVerificationTableProps {
  data: UserDocumentResponse[];
}

export const UserVerificationTable = ({ data }: UserVerificationTableProps) => {
  const { localized, preparedData, downloadFile } =
    useUserVerificationData(data);

  const columns = useMemo(
    () => [
      {
        Header: localized('userProfile.personalProfileInfo.documentType'),
        accessor: 'documentType' as keyof DataRow,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <>
              {localized(
                `userProfile.personalProfileInfo.accountVerification.documentTypeSelect.options.${original.documentType}`,
              )}
            </>
          );
        },
      },
      {
        Header: localized('userProfile.personalProfileInfo.date'),
        accessor: 'date' as keyof DataRow,
      },
      {
        Header: localized('userProfile.personalProfileInfo.status'),
        accessor: 'status' as keyof DataRow,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <StyledDocumentStatusValue status={original.status}>
              {localized(
                `userProfile.personalProfileInfo.accountVerification.documentStatus.${original.status}`,
              )}
            </StyledDocumentStatusValue>
          );
        },
      },
      {
        Header: localized('userProfile.personalProfileInfo.documentName'),
        accessor: 'documentName' as keyof DataRow,
      },
      {
        Header: '',
        accessor: 'id' as keyof DataRow,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <Box flexRow alignCenter justifyContentEnd fullHeight>
              <Tooltip
                text={localized(
                  'userProfile.personalProfileInfo.accountVerification.downLoad.tooltip',
                )}
              >
                <Box>
                  <PureIconButton
                    css={{
                      display: 'block',
                      color: '$grayMedium',
                      p: 0,
                      '&:hover': { color: '$white' },
                    }}
                    onClick={async () => {
                      await downloadFile(original.url, original.documentName);
                    }}
                  >
                    <DownloadFileIcon />
                  </PureIconButton>
                </Box>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: preparedData });

  return (
    <ScrollArea>
      <StyledTable {...getTableProps()}>
        <StyledTableHeader>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledHeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </StyledHeaderCell>
              ))}
            </tr>
          ))}
        </StyledTableHeader>
        {rows.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => (
                    <StyledCell {...cell.getCellProps()}>
                      <StyledCellContent
                        css={{
                          ...(cellIndex === 0
                            ? {
                                borderTopLeftRadius: '$6',
                                borderBottomLeftRadius: '$6',
                              }
                            : {}),
                          ...(cellIndex === row.cells.length - 1
                            ? {
                                borderTopRightRadius: '$6',
                                borderBottomRightRadius: '$6',
                              }
                            : {}),
                        }}
                      >
                        {cell.render('Cell')}
                      </StyledCellContent>
                    </StyledCell>
                  ))}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <StyledPlaceholderCell colSpan={5}>
                {localized('userProfile.personalProfileInfo.noAttachments')}
              </StyledPlaceholderCell>
            </tr>
          </tbody>
        )}
      </StyledTable>
    </ScrollArea>
  );
};
