import React, { useEffect, useState } from 'react';
import { styled } from 'stitches.config';

import { Categories, Games } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { SearchDefaultState } from '~components/molecules/GamesModalFilters/components/SearchDefaultState';
import { SearchEmptyResult } from '~components/molecules/GamesModalFilters/components/SearchEmptyResult';
import { SearchResultCategories } from '~components/molecules/GamesModalFilters/components/SearchResultCategories';
import { SearchResultGames } from '~components/molecules/GamesModalFilters/components/SearchResultGames';
import { SearchResultProviders } from '~components/molecules/GamesModalFilters/components/SearchResultProviders';
import { SearchField } from '~components/molecules/GamesModalFilters/SearchField';
import { GAME_PROVIDERS } from '~constants/providers';
import { useAppSelector } from '~store';
import {
  selectGames,
  selectNotEmptyCategories,
  selectSortedProviders,
} from '~store/slices/gamesSlice';
import { Providers } from '~types/providers';

export const ScrollableBox = styled(Box, {
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-webkit-overflow-scrolling': 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const Search = () => {
  const providers = useAppSelector(selectSortedProviders);
  const categories = useAppSelector(selectNotEmptyCategories);
  const games = useAppSelector(selectGames);

  const [searchValue, setSearchValue] = useState('');
  const [foundCategories, setFoundCategories] = useState<Categories>([]);
  const [foundGames, setFoundGames] = useState<Games>([]);
  const [foundProviders, setFoundProviders] = useState<Providers>([]);
  const [isEmptyResult, setIsEmptyResult] = useState(false);
  const [filteredDefaultProviders, setFilteredDefaultProviders] =
    useState<Providers>([]);

  useEffect(() => {
    if (!searchValue.length || !searchValue) return;

    const foundCategories = categories.filter((category) => {
      return category.name.toLowerCase().includes(searchValue.toLowerCase());
    });

    setFoundCategories(foundCategories);

    const foundGames = games.filter((game) => {
      return game.name.toLowerCase().includes(searchValue.toLowerCase());
    });

    setFoundGames(foundGames);
    const foundProviders = providers
      .filter((provider) => {
        return (
          provider.name &&
          provider.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      })
      .filter(({ gameCount }) => !!gameCount);

    setFoundProviders(foundProviders);

    const isEmptyResult =
      !foundCategories.length && !foundGames.length && !foundProviders.length;

    setIsEmptyResult(isEmptyResult);
  }, [searchValue, categories, games, providers]);

  useEffect(() => {
    type ProvidersCountsMap = Record<GAME_PROVIDERS, number>;
    const gamesCountsMap: ProvidersCountsMap = {} as ProvidersCountsMap;

    games.forEach((game) => {
      if (!gamesCountsMap[game.gameProviderId]) {
        gamesCountsMap[game.gameProviderId] = 1;
      } else {
        gamesCountsMap[game.gameProviderId]++;
      }
    });

    const filteredProviders = providers.filter(
      (provider) => gamesCountsMap[provider.id] > 0,
    );

    setFilteredDefaultProviders(
      filteredProviders.map((provider) => ({
        ...provider,
        gameCount: gamesCountsMap[provider.id],
      })),
    );
  }, [games]);

  return (
    <Box
      flexCol
      css={{
        overflow: 'hidden',
        '@xs_sm': {
          background: '$dialogBgColor',
        },
      }}
    >
      <SearchField value={searchValue} onChange={setSearchValue} />
      <Separator
        verticalSpace={0}
        css={{
          background: '$dialogBorderColor',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <ScrollableBox
        css={{
          height: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          mb: '$4',
          '@xs_sm': {
            paddingBottom: '90px',
          },
        }}
      >
        {!searchValue.length ? (
          <SearchDefaultState
            filteredDefaultProviders={filteredDefaultProviders}
          />
        ) : isEmptyResult ? (
          <SearchEmptyResult />
        ) : (
          <>
            {!!foundCategories.length && (
              <SearchResultCategories categories={foundCategories} />
            )}
            {!!foundProviders.length && (
              <SearchResultProviders providers={foundProviders} />
            )}
            {!!foundGames.length && <SearchResultGames games={foundGames} />}
          </>
        )}
      </ScrollableBox>
    </Box>
  );
};
