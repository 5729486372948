import { Box } from '~components/atoms/Box';
import { SkeletonFull } from '~components/atoms/Skeletons';
import { LiveSportMenuMobile } from '~components/molecules/LiveMenu/components/LiveSportMenuMobile';
import { useMedia } from '~hooks/useMedia';

import { useLiveMenu } from '../hooks/useLiveMenu';

import { LiveSportMenu } from './LiveSportMenu';

export const LiveMenu = () => {
  const { liveSports, isLoaded, isLoadedOpenedCountries } = useLiveMenu();
  const { isMobileOrTablet } = useMedia();

  return (
    <>
      {(!isLoaded || !isLoadedOpenedCountries) && (
        <SkeletonFull theme="light" isSingleColumn />
      )}
      <Box
        css={{
          visibility: !isLoadedOpenedCountries ? 'hidden' : 'visible',
        }}
      >
        {isMobileOrTablet ? (
          <LiveSportMenuMobile sports={liveSports} />
        ) : (
          <LiveSportMenu inPlayMenuSports={liveSports} />
        )}
      </Box>
    </>
  );
};
