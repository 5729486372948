import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useLoginUser, useSignInWhatsApp } from '~api/auth/authMutations';
import { LoginUserInput } from '~api/auth/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { SIGNIN_FORM_DEFAULT_VALUES } from '~constants/auth';
import { HTTP_STATUS_CODES } from '~constants/common';
import { usePhoneNumber } from '~hooks/usePhoneNumber';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { closeDialog, openDialog } from '~store/slices/globalDialogSlice';
import { setSessionId } from '~store/slices/signalRSocketsSlice';
import { setIsUserBlocked } from '~store/slices/userSlice';

import { SIGNIN_FORM_FIELDS, SigninFormInputs } from '../../types';
import { AuthTabs, StyledAuthTabsContent } from '../AuthTabs';
import { AuthTemplate } from '../AuthTemplate';

import { SignInTab } from './SignInTab';
import { SIGN_IN_TABS } from './SignInTabList';

interface ErrorWithData extends Error {
  data?: {
    message?: string[];
  };
}

const isErrorWithData = (error: unknown): error is ErrorWithData =>
  Array.isArray((error as ErrorWithData).data?.message);

export const SignInContent = () => {
  const dispatch = useAppDispatch();
  const [activeTab] = useState(SIGN_IN_TABS.SIGN_IN);
  const defaultRemebmerMe = !!localStorage.getItem('rememberMe');
  const signInButtonRef = useRef<HTMLButtonElement>(null);
  const [rememberMe, setRememberMe] = useState(defaultRemebmerMe);
  const [isValidationError, setIsValidationError] = useState('');
  const { getPhoneNumberWithCode, getPhoneNumberWithoutCode } =
    usePhoneNumber();
  const { localized, localizedError } = useTranslation();

  let defaultFormValues = SIGNIN_FORM_DEFAULT_VALUES;

  if (defaultRemebmerMe) {
    defaultFormValues = {
      ...defaultFormValues,
      [SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD]:
        localStorage.getItem('phone') || '',
      [SIGNIN_FORM_FIELDS.PASSWORD_FIELD]:
        localStorage.getItem('password') || '',
    };
  }

  const formMethods = useForm<SigninFormInputs>({
    defaultValues: defaultFormValues,
  });
  const { getValues, setError, handleSubmit } = formMethods;

  const { loginUserMutation, loginUserIsLoading } = useLoginUser();

  const { signInWhatsAppIsLoading, signInWhatsAppMutation } =
    useSignInWhatsApp();

  const [isSigningUserLoading, setIsSigningUserLoading] = useState(false);

  const handleSignIn = async () => {
    const phoneNumberVal = getValues(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD);

    const phoneNumberValueWithoutCode = phoneNumberVal.startsWith('+')
      ? getPhoneNumberWithoutCode(phoneNumberVal)
      : phoneNumberVal;

    const startsWithZero = phoneNumberValueWithoutCode.startsWith('0');

    const phoneNumber = startsWithZero
      ? phoneNumberValueWithoutCode.slice(1)
      : phoneNumberValueWithoutCode;

    const payload: Record<string, string> = {
      email: getValues(SIGNIN_FORM_FIELDS.EMAIL_FIELD),
      phoneNumber: getPhoneNumberWithCode(phoneNumber),
      userName: getValues(SIGNIN_FORM_FIELDS.USERNAME_FIELD),
      password: getValues(SIGNIN_FORM_FIELDS.PASSWORD_FIELD),
    };

    const resultPayload: Record<string, string> = {};

    const parsedPayloadKeys = Object.keys(payload).filter(
      (key) => (payload[key] || '').length,
    );

    parsedPayloadKeys.forEach((key) => {
      resultPayload[key] = payload[key] || '';
    });

    try {
      await loginUserMutation(
        resultPayload as unknown as LoginUserInput,
      ).unwrap();

      if (rememberMe) {
        const { phoneNumber, password } = resultPayload;

        localStorage.setItem(
          'phone',
          getPhoneNumberWithoutCode(phoneNumber as string),
        );
        localStorage.setItem('password', password as string);
        localStorage.setItem('rememberMe', 'true');
      } else {
        localStorage.removeItem('phone');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }

      dispatch(closeDialog());
    } catch (err) {
      if (isErrorWithData(err)) {
        const message = err.data?.message?.[0];

        if (
          typeof message === 'string' &&
          ['userDeactivated', 'userBlocked'].includes(message)
        ) {
          dispatch(openDialog(DIALOGS.DEACTIVATED_ACCOUNT));
          dispatch(setIsUserBlocked(message === 'userBlocked'));

          return;
        }

        if (typeof message === 'string') {
          setIsValidationError(localizedError(`root.${message}`));

          return;
        }
      }

      console.error('Failed to sign in: ', err);
    }
  };

  const handleSignInWithWhatsApp = async () => {
    const phoneNumberVal = getValues(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD);

    const phoneNumberValueWithoutCode = phoneNumberVal.startsWith('+')
      ? getPhoneNumberWithoutCode(phoneNumberVal)
      : phoneNumberVal;

    if (!phoneNumberValueWithoutCode.length) {
      setError(SIGNIN_FORM_FIELDS.PHONE_NUMBER_FIELD, {
        type: 'required',
        message: localizedError('phoneNumber.required'),
      });

      return;
    }

    const startsWithZero = phoneNumberValueWithoutCode.startsWith('0');

    const phoneNumber = startsWithZero
      ? phoneNumberValueWithoutCode.slice(1)
      : phoneNumberValueWithoutCode;

    const payload: { phoneNumber: string } = {
      phoneNumber: getPhoneNumberWithCode(phoneNumber),
    };

    try {
      const response = await signInWhatsAppMutation(payload);

      if ('error' in response) {
        setError('root.serverError', {
          type: HTTP_STATUS_CODES.BAD_REQUEST,
          message: localizedError('root.serverError'),
        });

        return;
      }

      const { sessionId } = response.data;

      dispatch(setSessionId(sessionId));

      setIsSigningUserLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRegisterUser = () => {
    dispatch(openDialog(DIALOGS.SIGN_UP));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (signInButtonRef.current) {
          signInButtonRef.current.focus();
          signInButtonRef.current.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (isSigningUserLoading) {
    return (
      <AuthTemplate>
        <Box flexCol gap={3} alignCenter justifyCenter fullWidth>
          <Loader
            css={{
              color: 'white',
              '& > svg': {
                width: '28px',
                height: '28px',
              },
            }}
          />
          <Text
            level="14-24"
            textTransform="uppercase"
            textAlign="center"
            css={{
              maxWidth: '419px',
            }}
          >
            {localized('signIn.followLink1')}{' '}
            <Text
              as="span"
              level="14-24"
              textTransform="uppercase"
              color="success"
            >
              {localized('signUp.whatsApp')}
            </Text>{' '}
            {localized('signIn.followLink2')}
          </Text>
        </Box>
      </AuthTemplate>
    );
  }

  return (
    <AuthTemplate>
      <AuthTabs activeTab={activeTab}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSignIn)}>
            <StyledAuthTabsContent value={SIGN_IN_TABS.SIGN_IN}>
              <SignInTab
                rememberMe={Boolean(rememberMe)}
                isValidationError={isValidationError}
                onRememberMeChange={setRememberMe}
                onRegisterUser={handleRegisterUser}
                isLoginLoading={loginUserIsLoading}
                signInButtonRef={signInButtonRef}
                handleSignInWithWhatsApp={handleSignInWithWhatsApp}
                isSigningUserLoading={signInWhatsAppIsLoading}
              />
            </StyledAuthTabsContent>
          </form>
        </FormProvider>
      </AuthTabs>
    </AuthTemplate>
  );
};
