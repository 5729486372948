import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { SPORT_TAB_CONTENTS } from '~components/organisms/MobileTabContents';
import { useAppDispatch } from '~store';
import { setSelectedFavoriteEventId } from '~store/slices/eventsSlice';
import { setSportTabActiveContent } from '~store/slices/mobileSlice';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';
import {
  SportEvent,
  SportEventMainData,
  SportEventsMainData,
} from '~types/events';

export const useEventList = (events: SportEventsMainData) => {
  const dispatch = useAppDispatch();
  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();

  const handleFavoriteClick = async (
    isEventFavorite: boolean,
    eventData: SportEvent,
  ) => {
    if (isEventFavorite) {
      await deleteFavoriteEventMutation({
        eventId: eventData.id,
      }).then(() => {
        dispatch(
          setFavoritePrematchEvents(
            events.filter((event) => event.id !== eventData.id),
          ),
        );
      });
    } else {
      await addFavoriteEventMutation({
        eventId: eventData.id,
      }).then(() => {
        if (eventData) {
          dispatch(
            setFavoritePrematchEvents([
              ...events,
              eventData as SportEventMainData,
            ]),
          );
        }
      });
    }
  };

  const handleLoadEventData = async (id: string) => {
    dispatch(setSelectedFavoriteEventId(id));
    dispatch(
      setSportTabActiveContent(SPORT_TAB_CONTENTS.PREMATCH_EVENT_MARKETS),
    );
  };

  return {
    setSelectedFavoriteEventId,
    handleFavoriteClick,
    handleLoadEventData,
  };
};
