import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';

import { useTransactionHistory } from './hooks/useTransactionHistory';
import { TransactionHistoryFilter } from './TransactionHistoryFilter';
import { TransactionHistoryTable } from './TransactionHistoryTable';

interface TransactionHistoryProps {
  isAllTransactions?: boolean;
}

export const TransactionHistory = ({
  isAllTransactions = false,
}: TransactionHistoryProps) => {
  const { transactions, total, isLoading, handleLoadMore } =
    useTransactionHistory(isAllTransactions);

  return (
    <Box
      fullWidth
      fullHeight
      flexCol
      css={{
        p: '$3 $2 0',
        border: '1px solid $gray',
        borderRadius: '$6',
      }}
    >
      {!isAllTransactions && (
        <>
          <TransactionHistoryFilter />
          <Separator
            shrinkOut={2}
            verticalSpace={2}
            css={{
              backgroundColor: '$gray',
            }}
          />
        </>
      )}
      <Box
        css={{
          maxHeight: isAllTransactions ? '540px' : '440px',
        }}
      >
        <TransactionHistoryTable
          isAllTransactions={isAllTransactions}
          data={transactions}
          loadMore={handleLoadMore}
          dataLength={total}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
