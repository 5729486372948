import React from 'react';

import { SportGroup, Sports } from '~api/category/types';
import { Box } from '~components/atoms/Box';
import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionRoot,
} from '~components/molecules/MultiAccordion';

import { FavoritePrematchEventGroup } from './FavoritePrematchEventGroup';

export interface FavoriteGroupSportMobileProps {
  sportGroupsMemo: SportGroup[];
  sports: Sports;
}

export const FavoriteGroupSportMobile = ({
  sportGroupsMemo,
  sports,
}: FavoriteGroupSportMobileProps) => {
  return (
    <Box flexCol css={{ p: '$2 $1', backgroundColor: '$black' }}>
      <MultiAccordionRoot
        disabled
        type="multiple"
        value={(sports || []).map(({ id }) => id.toString())}
        role={MULTI_ACCORDION_ROLES.CHILD}
      >
        <FavoritePrematchEventGroup sportGroupsMemo={sportGroupsMemo} />
      </MultiAccordionRoot>
    </Box>
  );
};
