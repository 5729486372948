import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';

import { ScoreDateContent } from './ScoreDateContent';

export enum SEPARATOR {
  COLON = ':',
}

interface ScoreProps {
  firstTeam: string | null | undefined;
  secondTeam: string | null | undefined;
  firstScore: string | number | null | undefined;
  secondScore: string | number | null | undefined;
  separator?: SEPARATOR;
  isMatchStarted?: boolean;
  formattedStartDate?: string;
}

export const MainScore = ({
  firstTeam,
  secondTeam,
  firstScore,
  secondScore,
  separator = SEPARATOR.COLON,
  isMatchStarted = true,
  formattedStartDate,
}: ScoreProps) => {
  const { isMobileOrTablet } = useMedia();
  const plateWidth = isMatchStarted ? 104 : 156;

  return (
    <Box
      flexRow={!isMobileOrTablet}
      flexCol={isMobileOrTablet}
      alignCenter
      css={{
        backgroundColor: '$mainScoreBgColorOpacity',
        p: isMatchStarted ? '$5 0' : '$5 0 52px',
        '@xs_sm': {
          gap: '$1',
          p: 0,
        },
      }}
    >
      <Box
        justifyContentBetween={isMobileOrTablet}
        justifyContentStart={!isMobileOrTablet}
        css={{
          position: 'relative',
          flexGrow: 1,
          width: isMobileOrTablet
            ? '100%'
            : `calc((100% - ${plateWidth}px) / 2)`,
          backgroundColor: '$mainScoreHomeTeamColorOpacity',
          p: '$1 18px',
          '@xs_sm': {
            display: 'flex',
            p: '$1 $4',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            backgroundColor: '$mainScoreHomeTeamColor',
            right: -1,
            top: isMobileOrTablet ? -1 : 0,
            height: isMobileOrTablet ? '2px' : '100%',
            bottom: 0,
            width: isMobileOrTablet ? 'auto' : '2px',
            borderRadius: '$2',
            '@xs_sm': {
              left: -1,
            },
          },
        }}
      >
        <Text
          level="15-24"
          css={{
            '@xs_sm': {
              fontSize: '$12',
              lineHeight: '$20',
            },
          }}
          textAlign="right"
          fontWeight="medium"
        >
          {firstTeam}
        </Text>
        {isMobileOrTablet && (
          <Text
            level="15-24"
            css={{
              '@xs_sm': {
                lineHeight: '$20',
              },
            }}
            fontWeight="medium"
          >
            {firstScore}
          </Text>
        )}
      </Box>
      {!isMobileOrTablet && (
        <ScoreDateContent
          firstScore={firstScore}
          secondScore={secondScore}
          separator={separator}
          plateWidth={plateWidth}
          isMatchStarted={isMatchStarted}
          formattedStartDate={formattedStartDate}
        />
      )}
      <Box
        justifyContentBetween={isMobileOrTablet}
        justifyContentStart={!isMobileOrTablet}
        css={{
          position: 'relative',
          flexGrow: 1,
          width: isMobileOrTablet
            ? '100%'
            : `calc((100% - ${plateWidth}px) / 2)`,
          backgroundColor: '$mainScoreAwayTeamColorOpacity',
          p: '$1 18px',
          '@xs_sm': {
            display: 'flex',
            p: '$1 $4',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            backgroundColor: '$mainScoreAwayTeamColor',
            left: -1,
            top: isMobileOrTablet ? -1 : 0,
            bottom: 0,
            width: isMobileOrTablet ? 'auto' : '2px',
            height: isMobileOrTablet ? '2px' : '100%',
            borderRadius: '$2',
            '@xs_sm': {
              right: -1,
            },
          },
        }}
      >
        <Text
          level="15-24"
          css={{
            '@xs_sm': {
              fontSize: '$12',
              lineHeight: '$20',
            },
          }}
          fontWeight="medium"
        >
          {secondTeam}
        </Text>
        {isMobileOrTablet && (
          <Text
            level="15-24"
            css={{
              '@xs_sm': {
                lineHeight: '$20',
              },
            }}
            fontWeight="medium"
          >
            {secondScore}
          </Text>
        )}
      </Box>
    </Box>
  );
};
