import React from 'react';

import { Categories } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  StyledResultSeparator,
  StyledSearchCategory,
  StyledSearchCategoryWrapper,
  StyledSearchResultWrapper,
} from '~components/molecules/GamesModalFilters/components/styled.components';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setSelectedCategoryId } from '~store/slices/gamesSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { getSanitizedHTML } from '~utils/htmlUtils';

interface SearchResultCategoriesProps {
  categories: Categories;
}

export const SearchResultCategories = ({
  categories,
}: SearchResultCategoriesProps) => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  const handleCategoryClick = (id: string) => {
    dispatch(setSelectedCategoryId(id));
    dispatch(closeDialog());
  };

  return (
    <>
      <StyledSearchResultWrapper>
        <Box
          flexRow
          alignCenter
          fullWidth
          justifyContentBetween
          css={{
            pl: '$3',
          }}
        >
          <Text
            level={isMobileOrTablet ? '14-20' : '16-28'}
            textTransform="uppercase"
          >
            {localized('casino.categories')}
          </Text>
          <Text level={isMobileOrTablet ? '14-20' : '16-28'} color="grayMedium">
            {categories.length}
          </Text>
        </Box>
        <Box
          flexCol
          gap={3}
          css={{
            pt: '$4',
          }}
        >
          {categories.map(({ id, name, iconSvg }) => (
            <StyledSearchCategoryWrapper
              key={id}
              onClick={() => handleCategoryClick(id)}
            >
              <StyledSearchCategory>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      iconSvg &&
                      getSanitizedHTML(
                        iconSvg.replace(
                          /fill="#[0-9a-fA-F]+"/g,
                          'fill="currentColor"',
                        ),
                      ),
                  }}
                />
              </StyledSearchCategory>
              <Text level="14-24">{name}</Text>
            </StyledSearchCategoryWrapper>
          ))}
        </Box>
      </StyledSearchResultWrapper>
      <StyledResultSeparator />
    </>
  );
};
