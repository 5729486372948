import React from 'react';

import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useAppSelector } from '~store';
import { TransactionHistoryCellProps } from '~types/transactions';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const AmountCell = ({
  row: {
    original: { amount },
  },
}: TransactionHistoryCellProps) => {
  const { currency } = useAppSelector((state) => state.settings);

  return (
    <Text level="12-20">
      {commaSeparatedNumber(amount)} {CURRENCY_SYMBOLS[currency]}
    </Text>
  );
};
