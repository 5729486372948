import psl from 'psl';

import { THEMES } from '~constants/ui';

export const getTheme = (): THEMES => {
  const pslData = psl.parse(window.location.hostname);

  if (pslData && 'domain' in pslData) {
    if (!pslData.domain) {
      return THEMES.DEFAULT;
    }

    switch (pslData.domain) {
      case 'semabet.ug':
      case 'semabet.co.mz':
      case 'semabet.co.zm':
      case 'semabet.co.tz':
        return THEMES.SEMA_BET;
      case 'betonly.com':
        return THEMES.BET_ONLY;
      default:
        return THEMES.DEFAULT;
    }
  }

  return THEMES.DEFAULT;
};
