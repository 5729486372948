import React from 'react';

import { Games } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { PaddingWrapper } from '~components/molecules/GamesModalFilters/Filters';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';

interface FiltersFooterProps {
  isClearDisabled: boolean;
  handleResetFilter: () => void;
  handleFilterGames: () => void;
  filteredGamesState: Games;
}

export const FiltersFooter = ({
  isClearDisabled,
  handleResetFilter,
  handleFilterGames,
  filteredGamesState,
}: FiltersFooterProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();

  return !isMobileOrTablet ? (
    <PaddingWrapper
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '$5',
      }}
    >
      <Separator
        verticalSpace={0}
        css={{
          mt: '20px',
          background: '$dialogBorderColor',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <Box
        css={{
          background: '$blueDark',
          p: '$2 340px',
          '@md': {
            p: '$2 170px',
          },
          borderRadius: '$10',
        }}
      >
        <Box
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '$2',
          }}
        >
          <Button
            variant="secondary"
            fullWidth
            size={'small'}
            css={{
              background: '$grayDark',
              border: '1px solid transparent',
              '&:hover': {
                boxShadow: 'none',
                ' & p': {
                  color: '$red !important',
                },
              },
              '&:active': {
                boxShadow: 'none',
                border: '1px solid $red',
              },
            }}
            onClick={handleResetFilter}
            disabled={isClearDisabled}
          >
            <Text level={'12-20'} fontWeight={'medium'}>
              {localized('casino.clearAllFilters')}
            </Text>
          </Button>
          <Button
            fullWidth
            size={'small'}
            disabled={!filteredGamesState.length}
            onClick={handleFilterGames}
          >
            show {filteredGamesState.length} games
          </Button>
        </Box>
      </Box>
    </PaddingWrapper>
  ) : (
    <Box>
      <Separator
        verticalSpace={0}
        css={{
          mt: '20px',
          background: '$dialogBorderColor',
          width: 'calc(100% + 56px)',
          ml: '-28px',
        }}
      />
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '$2',
          p: '$2 50px',
          '@xs_sm': {
            p: '$4',
          },
        }}
      >
        <Button
          variant="secondary"
          fullWidth
          size={'small'}
          css={{
            fontWeight: '$medium',
            background: '$blueDark',
            border: '1px solid transparent',
            '&:hover': {
              boxShadow: 'none',
              ' & p': {
                color: '$red !important',
              },
            },
            '&:active': {
              boxShadow: 'none',
              border: '1px solid $red',
            },
          }}
          onClick={handleResetFilter}
          disabled={isClearDisabled}
        >
          {localized('casino.clearAllFilters')}
        </Button>
        <Button
          fullWidth
          size={'small'}
          disabled={!filteredGamesState.length}
          onClick={handleFilterGames}
        >
          show {filteredGamesState.length} games
        </Button>
      </Box>
    </Box>
  );
};
