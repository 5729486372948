import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledCategoryItem = styled(Box, {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
  position: 'relative',
  backgroundColor: '$blueDark',
  span: {
    display: 'inline-block',
    textAlign: 'center',
    maxWidth: '60px',
  },
  svg: {
    width: '2rem',
    height: '2rem',
    color: '$grayMedium',
  },
  '& > div:nth-child(1)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '$10',
    width: '60px',
    height: '60px',
    background: '$grayDark',
    border: '1px solid transparent',
    '@xs_sm': {
      background: '$black',
    },
  },
  '@xs_sm': {
    backgroundColor: 'transparent',
    span: {
      maxWidth: '54px',
    },
  },
  '&[data-active="true"]': {
    position: 'relative',
    '@xs_sm': {
      position: 'sticky',
    },
    right: 0,
    left: 0,
    zIndex: 1,
    background: '$blueDark',
    svg: {
      color: '$yellow !important',
    },
    span: {
      fontWeight: '$bold',
      color: '$yellow !important',
    },
    '& > div:nth-child(1)': {
      border: '1px solid $grayDarker',
    },
  },
  '&:hover': {
    svg: {
      color: '$yellowLight',
    },
    span: {
      color: '$yellowLight',
    },
    '& > div:nth-child(1)': {
      border: '1px solid $grayDarker',
    },
  },
  variants: {
    mobile: {
      true: {
        padding: '$2 $4',
        svg: {
          width: '1rem',
          height: '1rem',
        },
        '& > div:nth-child(1)': {
          width: '40px',
          height: '40px',
        },
      },
    },
  },
});

export const StyledFilterButton = styled(Box, {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$grayDark',
  padding: '10px',
  gap: '$1',
  borderRadius: '$10',
  '*': {
    whiteSpace: 'nowrap',
    color: '$grayMedium !important',
  },
  '&:hover': {
    '*': {
      color: '$white !important',
    },
    outline: '1px solid $white',
  },
  '& > svg': {
    width: '$5',
    height: '$5',
  },
  variants: {
    mobile: {
      true: {
        background: '$black',
        borderRadius: '$4',
        minWidth: '40px',
        height: '28px',
        '& > svg': {
          width: '$4',
          height: '$4',
        },
      },
    },
    isActive: {
      true: {
        '*': {
          color: '$white !important',
        },
      },
    },
  },
});
