import { Box } from '~components/atoms/Box';
import { StyledLoadingIcon } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

export const Loader = () => {
  const { localized } = useTranslation();

  return (
    <Box
      flexCol
      gap={2}
      alignCenter
      justifyCenter
      css={{ color: '$grayMedium' }}
    >
      <StyledLoadingIcon />
      <Text level="15-24" color="grayMedium" noWrap>
        {localized('loading')}
      </Text>
    </Box>
  );
};
