import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledBoardComponentWrapper = styled(Box, {
  display: 'flex',
  backgroundColor: '$panelBgColor',
  width: '100%',
  overflow: 'auto',
  borderRadius: '$8',
  p: '$1',
  '@xs_sm': {
    borderRadius: 0,
    backgroundColor: '$gray',
  },
  variants: {
    noBackgroundColor: {
      true: {
        backgroundColor: 'transparent',
      },
    },
  },
});
