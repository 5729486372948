import React, { useEffect, useState } from 'react';

import { useLazyGetJackpotsBetsHistory } from '~api/jackpot/jackpotQueries';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { JackpotBetslipsTable } from '~components/molecules/BetslipHistorySection/Jackpot/JackpotBetslipsDesktopTable/JackpotBetslipsTable';
import { NoBets } from '~components/molecules/BetslipHistorySection/NoBets';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setJackpotsBetsHistory } from '~store/slices/betslipSlice';
import { Jackpots } from '~types/jackpot';

export const JackpotBetslipHistory = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const [jackpotBetslips, setJackpotBetslips] = useState<Jackpots>([]);

  const {
    lazyGetJackpotsBetsHistoryQuery,
    lazyGetJackpotsBetsHistoryIsLoading,
  } = useLazyGetJackpotsBetsHistory();

  useEffect(() => {
    loadJackpotsBetsHistory();
  }, []);

  const loadJackpotsBetsHistory = async () => {
    const jackpotsBetsHistory =
      await lazyGetJackpotsBetsHistoryQuery().unwrap();

    setJackpotBetslips(jackpotsBetsHistory);
    dispatch(setJackpotsBetsHistory(jackpotsBetsHistory));
  };

  return (
    <Box
      flexCol
      fullHeight
      gap={3}
      css={{
        backgroundColor: '$grayDark',
        borderRadius: '$8',
      }}
    >
      {lazyGetJackpotsBetsHistoryIsLoading ? (
        <Box flexCol alignCenter gap={3} css={{ paddingTop: '32px' }}>
          <Loader css={{ color: '$grayMedium' }} />
          <Text color="grayMedium" level="sm-3" fontWeight="medium">
            {localized('betslipHistory.loading')}
          </Text>
        </Box>
      ) : !jackpotBetslips.length ? (
        <NoBets />
      ) : (
        <JackpotBetslipsTable />
      )}
    </Box>
  );
};
