import { useEffect, useMemo, useState } from 'react';

import { TransactionHistoryFilter } from '~api/transaction/types';
import { RangeValue } from '~components/atoms/DateRangePicker';
import { Option } from '~components/atoms/Select';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  resetFilters,
  setRange,
  setTransactions,
  setTransactionStatus,
  setTransactionType,
} from '~store/slices/transactionHistorySlice';
import {
  statusTranslationsMap,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  transactionStatusOptions,
  transactionsTranslationsMap,
  transactionTypeOptions,
} from '~types/transactions';
import {
  formatDateToEndOfDayISOString,
  formatDateToStartOfDayISOString,
} from '~utils/formatDateTime';
import { conditionalAdd } from '~utils/objectHelpers';

import { useTransactionHistory } from '../hooks/useTransactionHistory';

export const useTransactionHistoryFilter = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const { loadTransactions } = useTransactionHistory();
  const { transactionType, transactionStatus, range } = useAppSelector(
    (state) => state.transactionHistory,
  );
  const { minimumAge } = useAppSelector((state) => state.settings);

  const statusesOptions: Option[] = useMemo(
    () =>
      transactionStatusOptions.map((status) => ({
        value: status.toString(),
        label:
          status.toString().length && status !== 'ALL'
            ? localized(statusTranslationsMap[status as TRANSACTION_STATUS])
            : localized('transactionHistory.all'),
      })),
    [],
  );

  const typesOptions: Option[] = useMemo(
    () =>
      transactionTypeOptions.map((type) => ({
        value: type.toString(),
        label:
          type.toString().length && type !== 'ALL'
            ? localized(
                transactionsTranslationsMap[
                  type as TRANSACTION_STATUS | TRANSACTION_TYPE
                ],
              )
            : localized('transactionHistory.all'),
      })),
    [],
  );

  const handleResetFilter = () => {
    dispatch(resetFilters());
    loadTransactions();
  };

  const handleApplyFilter = () => {
    dispatch(setTransactions([]));
    const params: TransactionHistoryFilter = {};
    const parsedStatus = transactionStatus || 0;

    conditionalAdd(
      params,
      'status',
      parsedStatus > 0 ? parsedStatus : undefined,
    );
    conditionalAdd(
      params,
      'types',
      transactionType ? transactionType : undefined,
    );

    if (range) {
      const [dateFrom, dateTo] = range;

      if (dateFrom && dateTo) {
        const dateFromISO = formatDateToStartOfDayISOString(dateFrom);
        const dateToISO = formatDateToEndOfDayISOString(dateTo);

        conditionalAdd(params, 'dateFrom', dateFromISO);
        conditionalAdd(params, 'dateTo', dateToISO);
      }
    }

    loadTransactions(params);
  };

  useEffect(() => {
    let count = 0;

    if (range && range.length) {
      count++;
    }

    if (transactionType) {
      count++;
    }

    if (transactionStatus) {
      count++;
    }

    setActiveFilterCount(count);
  }, [transactionStatus, range, transactionType]);

  return {
    activeFilterCount,
    transactionStatus,
    transactionType,
    range,
    statusesOptions,
    typesOptions,
    minimumAge,
    onRangeChange: (value: RangeValue) => {
      dispatch(setRange(value));
    },
    onTransactionTypeChange: (value: string) => {
      dispatch(
        setTransactionType(
          Object.values(TRANSACTION_TYPE).includes(+value)
            ? (value as unknown as TRANSACTION_TYPE)
            : null,
        ),
      );
    },
    onTransactionStatusChange: (value: string) => {
      dispatch(
        setTransactionStatus(
          Object.values(TRANSACTION_TYPE).includes(+value)
            ? (value as unknown as TRANSACTION_STATUS)
            : null,
        ),
      );
    },
    onReset: handleResetFilter,
    onApply: handleApplyFilter,
  };
};
