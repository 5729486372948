import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { INPUT_CODE_LENGTH } from '~constants/auth';
import { getNumbersFromStr } from '~utils/numberUtils';

import { StyledInput } from './styled.components';
import { useNumberCodeFieldInput } from './useNumberCodeFieldInput';

interface NumberCodeFieldInputProps {
  code: string;
  labelText: string;
  setCode: (input: string) => void;
  size?: number;
  error?: boolean;
  clearError?: () => void;
}

export const NumberCodeFieldInput = ({
  code,
  setCode,
  labelText,
  size = INPUT_CODE_LENGTH,
  error,
  clearError,
}: NumberCodeFieldInputProps) => {
  const { inputs, inputsContainerRef, handlePaste, handleChange } =
    useNumberCodeFieldInput(size, setCode, clearError);

  return (
    <Box
      flexCol
      alignCenter
      gap={2}
      css={{
        p: '$2 0 $4',
        borderRadius: '$8',
        backgroundColor: '$authWidgetBgColor',
        width: '100%',
      }}
    >
      <Text
        level="12-4"
        textAlign="center"
        css={{
          color: error ? '$red' : '$white',
        }}
      >
        {labelText}
      </Text>
      <Box flexRow gap={2} ref={inputsContainerRef}>
        {inputs.map((input, i) => (
          <StyledInput
            inputMode="numeric"
            key={i}
            type="text"
            placeholder="_"
            maxLength={1}
            id={input && input.id.toString()}
            onChange={(e) => {
              e.target.value = getNumbersFromStr(e.target.value);
              setCode(code + e.target.value);
            }}
            onKeyUp={handleChange(i)}
            onFocus={(e) => e.target.select()}
            css={{ color: error ? '$red' : '$white' }}
            onPaste={handlePaste}
          />
        ))}
      </Box>
    </Box>
  );
};
