import { useMemo } from 'react';
import { styled } from 'stitches.config';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import {
  USER_BONUSES_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog';
import { BetHistoryIcon, BonusesIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setContentTab, setMainTab } from '~store/slices/personalDetailsSlice';

import { IframeSidebarItem } from './IframeSidebarItem';

const StyledWrapper = styled('div', {
  width: '212px',
  background: '$grayDark',
  borderRadius: '8px',
  padding: '16px',
  height: '540px',
  overflowY: 'auto',
});

export const IframeSidebar = () => {
  const dispatch = useAppDispatch();

  const { mainTab, contentTab } = useAppSelector(
    (state) => state.personalDetails,
  );

  const items = useMemo(() => {
    return [
      {
        icon: BetHistoryIcon,
        title: 'userProfile.menuTitles.history',
        onClick: () => {
          dispatch(openDialog(DIALOGS.USER_PROFILE));
          dispatch(setMainTab({ tab: USER_PROFILE_NAVIGATION_TABS.HISTORY }));
          dispatch(setContentTab(null));
        },
        value: USER_PROFILE_NAVIGATION_TABS.HISTORY,
        subitems: [],
      },
      {
        icon: BonusesIcon,
        title: 'userProfile.menuTitles.bonuses',
        onClick: () => {},
        value: USER_PROFILE_NAVIGATION_TABS.BONUSES,
        subitems: [
          {
            title: USER_BONUSES_TABS.ACTIVE_BONUSES,
            onClick: () => {
              dispatch(openDialog(DIALOGS.USER_PROFILE));
              dispatch(
                setMainTab({ tab: USER_PROFILE_NAVIGATION_TABS.BONUSES }),
              );
              dispatch(setContentTab(USER_BONUSES_TABS.ACTIVE_BONUSES));
            },
            isActive: contentTab === USER_BONUSES_TABS.ACTIVE_BONUSES,
          },
          {
            title: USER_BONUSES_TABS.BONUS_HISTORY,
            onClick: () => {
              dispatch(openDialog(DIALOGS.USER_PROFILE));
              dispatch(
                setMainTab({ tab: USER_PROFILE_NAVIGATION_TABS.BONUSES }),
              );
              dispatch(setContentTab(USER_BONUSES_TABS.BONUS_HISTORY));
            },
            isActive: contentTab === USER_BONUSES_TABS.BONUS_HISTORY,
          },
        ],
      },
    ];
  }, [dispatch, mainTab, contentTab]);

  return (
    <StyledWrapper>
      {items.map((item, index) => (
        <IframeSidebarItem
          icon={item.icon}
          title={item.title}
          key={item.title}
          onClick={item.onClick}
          withoutBorder={index === 0}
          isActive={mainTab === item.value}
          subitems={item.subitems}
        />
      ))}
    </StyledWrapper>
  );
};
