import { ReactNode } from 'react';
import { CSS } from '@stitches/react';

import { Button } from '~components/atoms/Button';
import { Select } from '~components/atoms/Select';
import { Text } from '~components/atoms/Typography';

import { useAddCardPayment } from '../hooks/useAddCardPayment';

import { StyledBaseSelect } from './StyledBaseSelect';

interface ButtonWrapperProps {
  children?: ReactNode;
  isLoading?: boolean;
  css?: CSS;
  onClick?: () => void;
}

const ButtonWrapper = ({
  children,
  onClick,
  isLoading,
  css,
}: ButtonWrapperProps) => {
  return (
    <Button
      css={{
        ...css,
        p: '$1 $4',
        boxShadow: 'none',
        mt: '$2',
        '&:hover': {
          boxShadow: '$iconButtonBorderActive',
          color: '$white',
        },
        '@xs_sm': {
          height: '$7',
        },
      }}
      fullWidth
      isLoading={isLoading}
      variant="secondary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const AddCardAccountButton = () => {
  const {
    isMobileOrTablet,
    isLoading,
    paymentMethods,
    selectFooterAction,
    selectedPaymentMethod,
    renderOption,
    localized,
    handleOpenAddPayment,
    handleSelectPaymentMethod,
  } = useAddCardPayment();

  if (isLoading) {
    return (
      <ButtonWrapper
        css={{
          height: '2rem',
        }}
        isLoading={true}
      />
    );
  }

  return paymentMethods && !!paymentMethods.length ? (
    <StyledBaseSelect css={{ mt: '$2', height: '2rem' }}>
      <Select
        noContentPadding
        centeredValue
        onChange={handleSelectPaymentMethod}
        renderOption={renderOption}
        contentWidth={isMobileOrTablet ? '90%' : null}
        options={paymentMethods.map((method) => ({
          value: method.id,
          data: method,
        }))}
        value={selectedPaymentMethod || undefined}
        footerAction={selectFooterAction}
      />
    </StyledBaseSelect>
  ) : (
    <ButtonWrapper onClick={handleOpenAddPayment}>
      <Text level="sm-4" textAlign="center">
        {localized('payments.addNewCard')}
      </Text>
    </ButtonWrapper>
  );
};
