import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const ProviderCardWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  height: 'max-content',

  // Text styles
  span: {
    '&.games-count': {
      color: '$grayMedium',
    },

    '@xl': {
      fontSize: '$4 !important',
      lineHeight: '$28 !important',
    },
    '@lg': {
      fontSize: '14px !important',
      lineHeight: '$20 !important',
    },
    '@xs': {
      fontSize: '11px !important',
      width: 'min-content !important',
    },
  },

  '&:hover': {
    cursor: 'pointer',
    span: {
      color: '$yellow',

      '&.games-count': {
        color: '$yellowLight',
      },
    },
  },

  // Selected provider text styles
  variants: {
    active: {
      true: {
        span: {
          color: '$yellow',

          '&.games-count': {
            color: '$yellowLight',
          },
        },
      },
    },
  },
});
