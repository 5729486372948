import React, { ComponentPropsWithRef } from 'react';
import { styled } from 'stitches.config';
interface IconButtonProps extends ComponentPropsWithRef<'button'> {
  children: React.ReactNode;
}

const IconButtonPrimitive = ({ children, ...rest }: IconButtonProps) => {
  return <button {...rest}>{children}</button>;
};

export const PureIconButton = styled(IconButtonPrimitive, {
  position: 'relative',
  textAlign: 'center',
  border: 'none',
  cursor: 'pointer',
  textTransform: 'uppercase',
  overflow: 'hidden',
  transition: 'box-shadow 0.3s ease',
  boxShadow: '$buttonBorderNormal',
  textShadow: '$buttonText',
  color: '$white',
  backgroundColor: 'transparent',
});
