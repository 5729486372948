import React, { memo } from 'react';

import { OddCrossedIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import {
  StyledBetslipInfoWrapper,
  StyledBetslipText,
} from '~components/molecules/Betslip/components/styled.components';
import { useTranslation } from '~hooks';
import { formatNumber } from '~utils/numberUtils';

interface BetslipOddsProps {
  odd: number;
  oddsCount?: number;
  isMultipleBet?: boolean;
  handleOddChange?: (odd: number) => void;
}

export const BetslipOdds = memo(
  ({
    odd,
    oddsCount,
    isMultipleBet = false,
    handleOddChange,
  }: BetslipOddsProps) => {
    const { localized } = useTranslation();

    return (
      <StyledBetslipInfoWrapper>
        <StyledBetslipText>
          {localized('betslip.multipleFooterValue.odds')}
        </StyledBetslipText>

        <Box flexRow alignCenter gap={1}>
          {isMultipleBet ? (
            <StyledBetslipText color="yellow">
              {oddsCount &&
                `${localized('betslip.multipleFooterValue.multiple')} (${oddsCount}) x `}
              {formatNumber(odd)}
            </StyledBetslipText>
          ) : (
            <OddCrossedIndicator
              odd={odd}
              sidePrevValue
              onOddChange={handleOddChange}
            />
          )}
        </Box>
      </StyledBetslipInfoWrapper>
    );
  },
);
