import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledJackpotListWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  background: '$black',
  borderRadius: '$10',
});
