import { ReactNode } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

interface CountLabelProps {
  children: ReactNode;
  count: number;
}
export const CountLabel = ({ count, children }: CountLabelProps) => {
  return (
    <>
      {count > 0 && (
        <Box
          css={{
            position: 'absolute',
            left: '50%',
            bottom: '10px',
            transform: 'translateX(20%)',
            p: '2px',
            backgroundColor: '$green',
            borderRadius: '$4',
          }}
        >
          <Text
            level="10-12"
            fontWeight="bold"
            textAlign="center"
            color="buttonTextColor"
          >
            {count}
          </Text>
        </Box>
      )}
      {children}
    </>
  );
};
