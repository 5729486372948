import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

export const NoBets = () => {
  const { localized } = useTranslation();

  return (
    <Box flexRow justifyCenter css={{ paddingTop: '32px' }}>
      <Box flexCol alignCenter>
        <Text color="grayMedium" level="lg-6" fontWeight="medium">
          {localized('betslipHistory.betslipHistoryEmpty.title')}
        </Text>
        <Text color="grayMedium" level="sm-3">
          {localized('betslipHistory.betslipHistoryEmpty.description')}
        </Text>
      </Box>
    </Box>
  );
};
