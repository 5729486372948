import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledTopEvent = styled(Box, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  py: 0,
  pl: '$3',
  pr: '52px',
  my: '$2',
  borderRadius: '$6',
  color: '$white',
  cursor: 'pointer',
  '& .icon': {
    color: '$grayMedium',
  },
  '&:hover, &:hover .count, &:hover .icon': {
    color: '$white',
  },
  '&[data-state=active]': {
    color: '$yellow',
    '& .count': {
      color: '$yellow',
    },
    '& .icon': {
      color: '$yellowLight',
    },
  },
});
