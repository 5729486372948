import {
  fontSizes,
  fontSizesArray,
  lineHeights,
  lineHeightsArray,
} from 'stitches.config';

export const generateLevels = () => {
  const levels: {
    [key: string]: {
      fontSize: string;
      lineHeight: string;
    };
  } = {};

  Object.values(fontSizes).forEach(({ token: fontSizeToken }) => {
    Object.values(lineHeights).forEach(({ token: lineHeightToken }) => {
      levels[`${fontSizeToken}-${lineHeightToken}`] = {
        fontSize: `$${fontSizeToken}`,
        lineHeight: `$${lineHeightToken}`,
      };
    });
  });

  return levels;
};

export const generateLevelsPxToRem = () => {
  const levels: {
    [key: string]: {
      fontSize: string;
      lineHeight: string;
    };
  } = {};

  fontSizesArray.forEach((fontSize) => {
    lineHeightsArray.forEach((lineHeight) => {
      levels[`${fontSize}-${lineHeight}`] = {
        fontSize: `${fontSize / 16}rem`,
        lineHeight: `${lineHeight / 16}rem`,
      };
    });
  });

  return levels;
};
