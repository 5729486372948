import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

export const StyledUserFileUploadCard = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
});

export const StyledUserFileUploadCardRow = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '$userFileUploadCardRowBgColor',
  p: '$2 $3',
  '&:first-child': {
    borderTopRightRadius: '$6',
    borderTopLeftRadius: '$6',
  },
  '&:last-child': {
    borderBottomRightRadius: '$6',
    borderBottomLeftRadius: '$6',
    p: '$3',
  },
  '&:not(:last-child)': {
    borderBottom: '1px solid $grayDarker',
  },
  '& a': {
    flexGrow: 1,
  },
});

export const StyledUserFileUploadCardRowKey = styled(Text, {
  pr: '$2',
  flexShrink: 0,
  defaultVariants: {
    color: 'white',
    level: '14-20',
    ellipsis: true,
  },
});

export const StyledUserFileUploadCardRowValue = styled(
  StyledUserFileUploadCardRowKey,
  {
    flexShrink: 1,
    defaultVariants: {
      fontWeight: 'medium',
    },
  },
);
