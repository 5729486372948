import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledVerifyEmailToken = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '418px',
  height: '228px',
  p: '28px',
  textTransform: 'uppercase',
  '@xs_sm': {
    height: '169px',
    width: '100%',
    borderRadius: '$8',
    backgroundColor: '$grayDark',
    p: '$3',
  },
});
