import { styled } from 'stitches.config';

import { BasicButton } from '~components/atoms/Button';

export const StyledScrollToTopButton = styled(BasicButton, {
  position: 'fixed',
  p: '10px',
  bottom: '100px',
  left: '50%',
  transform: 'translateX(-50%)',
  border: 'none',
  borderRadius: '$round',
  lineHeight: 0,
  backgroundColor: '$blackOpacity4',
  backdropFilter: 'blur(4px)',
  color: '$grayMedium',
  pointerEvents: 'auto',
  cursor: 'pointer',
});
