// source: messages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

import jspb from 'google-protobuf';

var goog = jspb;
var global =
  (typeof globalThis !== 'undefined' && globalThis) ||
  (typeof window !== 'undefined' && window) ||
  (typeof global !== 'undefined' && global) ||
  (typeof self !== 'undefined' && self) ||
  function () {
    return this;
  }.call(null) ||
  Function('return this')();

goog.exportSymbol('proto.ActionType', null, global);
goog.exportSymbol('proto.BetSlipType', null, global);
goog.exportSymbol('proto.CategoryMap', null, global);
goog.exportSymbol('proto.CreateShareBetEventRequest', null, global);
goog.exportSymbol('proto.CreateShareBetRequest', null, global);
goog.exportSymbol('proto.Event', null, global);
goog.exportSymbol('proto.EventMarket', null, global);
goog.exportSymbol('proto.EventMarketStatus', null, global);
goog.exportSymbol('proto.EventPrematchRequest', null, global);
goog.exportSymbol('proto.EventSelection', null, global);
goog.exportSymbol('proto.EventStatus', null, global);
goog.exportSymbol('proto.EventWithMainMarkets', null, global);
goog.exportSymbol('proto.EventsWithMainMarketsResponse', null, global);
goog.exportSymbol('proto.FavoriteTournament', null, global);
goog.exportSymbol('proto.GetCountsRequest', null, global);
goog.exportSymbol('proto.GetCountsResponse', null, global);
goog.exportSymbol('proto.GetInPlayEventsByMarketIdRequest', null, global);
goog.exportSymbol('proto.GetInPlayRequest', null, global);
goog.exportSymbol('proto.GetPrematchResponse', null, global);
goog.exportSymbol('proto.GetShareBetResponse', null, global);
goog.exportSymbol('proto.GetTopGamesResponse', null, global);
goog.exportSymbol('proto.GetTopTournamentsResponse', null, global);
goog.exportSymbol('proto.GetUpcomingEvents', null, global);
goog.exportSymbol('proto.IdFilter', null, global);
goog.exportSymbol('proto.InPlayMenuCountry', null, global);
goog.exportSymbol('proto.InPlayMenuEvent', null, global);
goog.exportSymbol('proto.InPlayMenuLeague', null, global);
goog.exportSymbol('proto.InPlayMenuResponse', null, global);
goog.exportSymbol('proto.InPlayMenuSport', null, global);
goog.exportSymbol('proto.Language', null, global);
goog.exportSymbol('proto.LanguageFilter', null, global);
goog.exportSymbol('proto.LimitFilter', null, global);
goog.exportSymbol('proto.MainMarket', null, global);
goog.exportSymbol('proto.MainMarketSelection', null, global);
goog.exportSymbol('proto.MainMarketsResponse', null, global);
goog.exportSymbol('proto.MarketGroup', null, global);
goog.exportSymbol('proto.PingPong', null, global);
goog.exportSymbol('proto.PrematchCountry', null, global);
goog.exportSymbol('proto.PrematchFilter', null, global);
goog.exportSymbol('proto.PrematchLeague', null, global);
goog.exportSymbol('proto.PrematchMenuCategory', null, global);
goog.exportSymbol('proto.PrematchMenuCountry', null, global);
goog.exportSymbol('proto.PrematchMenuSport', null, global);
goog.exportSymbol('proto.PrematchSport', null, global);
goog.exportSymbol('proto.PrematchSportPaged', null, global);
goog.exportSymbol('proto.SelectionStatus', null, global);
goog.exportSymbol('proto.ShareBetDto', null, global);
goog.exportSymbol('proto.SportGroupPrematch', null, global);
goog.exportSymbol('proto.SportMainMarkets', null, global);
goog.exportSymbol('proto.SubMenuCategoriesResponse', null, global);
goog.exportSymbol('proto.SubMenuCategoriesResponseDto', null, global);
goog.exportSymbol('proto.SubMenuCategory', null, global);
goog.exportSymbol('proto.SubMenuFilter', null, global);
goog.exportSymbol('proto.TopGamesRequest', null, global);
goog.exportSymbol('proto.TopMatch', null, global);
goog.exportSymbol('proto.TopTournamentRequest', null, global);
goog.exportSymbol('proto.UpcomingResponse', null, global);
goog.exportSymbol('proto.UpdateMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateMessage.displayName = 'proto.UpdateMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventsWithMainMarketsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.EventsWithMainMarketsResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.EventsWithMainMarketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventsWithMainMarketsResponse.displayName =
    'proto.EventsWithMainMarketsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PingPong = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PingPong, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PingPong.displayName = 'proto.PingPong';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetUpcomingEvents = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetUpcomingEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetUpcomingEvents.displayName = 'proto.GetUpcomingEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetCountsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetCountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetCountsRequest.displayName = 'proto.GetCountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventSelection = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EventSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventSelection.displayName = 'proto.EventSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventMarket = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.EventMarket.repeatedFields_,
    null,
  );
};
goog.inherits(proto.EventMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventMarket.displayName = 'proto.EventMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TopMatch = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.TopMatch.repeatedFields_,
    null,
  );
};
goog.inherits(proto.TopMatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TopMatch.displayName = 'proto.TopMatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SportGroupPrematch = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.SportGroupPrematch.repeatedFields_,
    null,
  );
};
goog.inherits(proto.SportGroupPrematch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SportGroupPrematch.displayName = 'proto.SportGroupPrematch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CategoryMap = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CategoryMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CategoryMap.displayName = 'proto.CategoryMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpcomingResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.UpcomingResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.UpcomingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpcomingResponse.displayName = 'proto.UpcomingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LanguageFilter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LanguageFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LanguageFilter.displayName = 'proto.LanguageFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MainMarketSelection = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MainMarketSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MainMarketSelection.displayName = 'proto.MainMarketSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MainMarket = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.MainMarket.repeatedFields_,
    null,
  );
};
goog.inherits(proto.MainMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MainMarket.displayName = 'proto.MainMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SportMainMarkets = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.SportMainMarkets.repeatedFields_,
    null,
  );
};
goog.inherits(proto.SportMainMarkets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SportMainMarkets.displayName = 'proto.SportMainMarkets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MainMarketsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.MainMarketsResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.MainMarketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MainMarketsResponse.displayName = 'proto.MainMarketsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchFilter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PrematchFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchFilter.displayName = 'proto.PrematchFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchMenuCategory = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PrematchMenuCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchMenuCategory.displayName = 'proto.PrematchMenuCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchMenuCountry = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.PrematchMenuCountry.repeatedFields_,
    null,
  );
};
goog.inherits(proto.PrematchMenuCountry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchMenuCountry.displayName = 'proto.PrematchMenuCountry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchMenuSport = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.PrematchMenuSport.repeatedFields_,
    null,
  );
};
goog.inherits(proto.PrematchMenuSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchMenuSport.displayName = 'proto.PrematchMenuSport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetPrematchResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.GetPrematchResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.GetPrematchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetPrematchResponse.displayName = 'proto.GetPrematchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubMenuFilter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SubMenuFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubMenuFilter.displayName = 'proto.SubMenuFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubMenuCategory = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SubMenuCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubMenuCategory.displayName = 'proto.SubMenuCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubMenuCategoriesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.SubMenuCategoriesResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.SubMenuCategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubMenuCategoriesResponse.displayName =
    'proto.SubMenuCategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InPlayMenuEvent = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.InPlayMenuEvent.repeatedFields_,
    null,
  );
};
goog.inherits(proto.InPlayMenuEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InPlayMenuEvent.displayName = 'proto.InPlayMenuEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InPlayMenuLeague = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.InPlayMenuLeague.repeatedFields_,
    null,
  );
};
goog.inherits(proto.InPlayMenuLeague, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InPlayMenuLeague.displayName = 'proto.InPlayMenuLeague';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InPlayMenuCountry = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.InPlayMenuCountry.repeatedFields_,
    null,
  );
};
goog.inherits(proto.InPlayMenuCountry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InPlayMenuCountry.displayName = 'proto.InPlayMenuCountry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InPlayMenuSport = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.InPlayMenuSport.repeatedFields_,
    null,
  );
};
goog.inherits(proto.InPlayMenuSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InPlayMenuSport.displayName = 'proto.InPlayMenuSport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InPlayMenuResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.InPlayMenuResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.InPlayMenuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InPlayMenuResponse.displayName = 'proto.InPlayMenuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FavoriteTournament = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FavoriteTournament, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FavoriteTournament.displayName = 'proto.FavoriteTournament';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TopTournamentRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TopTournamentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TopTournamentRequest.displayName = 'proto.TopTournamentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTopTournamentsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.GetTopTournamentsResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.GetTopTournamentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTopTournamentsResponse.displayName =
    'proto.GetTopTournamentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LimitFilter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LimitFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LimitFilter.displayName = 'proto.LimitFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TopGamesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TopGamesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TopGamesRequest.displayName = 'proto.TopGamesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetTopGamesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.GetTopGamesResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.GetTopGamesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetTopGamesResponse.displayName = 'proto.GetTopGamesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IdFilter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IdFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IdFilter.displayName = 'proto.IdFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MarketGroup = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MarketGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MarketGroup.displayName = 'proto.MarketGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Event = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Event.repeatedFields_,
    null,
  );
};
goog.inherits(proto.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Event.displayName = 'proto.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventPrematchRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EventPrematchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventPrematchRequest.displayName = 'proto.EventPrematchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventWithMainMarkets = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.EventWithMainMarkets.repeatedFields_,
    null,
  );
};
goog.inherits(proto.EventWithMainMarkets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventWithMainMarkets.displayName = 'proto.EventWithMainMarkets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchLeague = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.PrematchLeague.repeatedFields_,
    null,
  );
};
goog.inherits(proto.PrematchLeague, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchLeague.displayName = 'proto.PrematchLeague';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchCountry = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.PrematchCountry.repeatedFields_,
    null,
  );
};
goog.inherits(proto.PrematchCountry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchCountry.displayName = 'proto.PrematchCountry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchSport = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.PrematchSport.repeatedFields_,
    null,
  );
};
goog.inherits(proto.PrematchSport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchSport.displayName = 'proto.PrematchSport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PrematchSportPaged = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.PrematchSportPaged.repeatedFields_,
    null,
  );
};
goog.inherits(proto.PrematchSportPaged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PrematchSportPaged.displayName = 'proto.PrematchSportPaged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SubMenuCategoriesResponseDto = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.SubMenuCategoriesResponseDto.repeatedFields_,
    null,
  );
};
goog.inherits(proto.SubMenuCategoriesResponseDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SubMenuCategoriesResponseDto.displayName =
    'proto.SubMenuCategoriesResponseDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetCountsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetCountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetCountsResponse.displayName = 'proto.GetCountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetInPlayEventsByMarketIdRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.GetInPlayEventsByMarketIdRequest.repeatedFields_,
    null,
  );
};
goog.inherits(proto.GetInPlayEventsByMarketIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetInPlayEventsByMarketIdRequest.displayName =
    'proto.GetInPlayEventsByMarketIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetInPlayRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetInPlayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetInPlayRequest.displayName = 'proto.GetInPlayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateShareBetRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.CreateShareBetRequest.repeatedFields_,
    null,
  );
};
goog.inherits(proto.CreateShareBetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateShareBetRequest.displayName = 'proto.CreateShareBetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateShareBetEventRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateShareBetEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateShareBetEventRequest.displayName =
    'proto.CreateShareBetEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ShareBetDto = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ShareBetDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ShareBetDto.displayName = 'proto.ShareBetDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetShareBetResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.GetShareBetResponse.repeatedFields_,
    null,
  );
};
goog.inherits(proto.GetShareBetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetShareBetResponse.displayName = 'proto.GetShareBetResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateMessage.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateMessage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.UpdateMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        type: jspb.Message.getFieldWithDefault(msg, 1, 0),
        data: msg.getData_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateMessage}
 */
proto.UpdateMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateMessage();
  return proto.UpdateMessage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateMessage}
 */
proto.UpdateMessage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setData(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateMessage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
};

/**
 * optional int32 Type = 1;
 * @return {number}
 */
proto.UpdateMessage.prototype.getType = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.UpdateMessage} returns this
 */
proto.UpdateMessage.prototype.setType = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional bytes Data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.UpdateMessage.prototype.getData = function () {
  return /** @type {!(string|Uint8Array)} */ (
    jspb.Message.getFieldWithDefault(this, 2, '')
  );
};

/**
 * optional bytes Data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.UpdateMessage.prototype.getData_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getData()));
};

/**
 * optional bytes Data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.UpdateMessage.prototype.getData_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getData()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.UpdateMessage} returns this
 */
proto.UpdateMessage.prototype.setData = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventsWithMainMarketsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.EventsWithMainMarketsResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.EventsWithMainMarketsResponse.toObject(
      opt_includeInstance,
      this,
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.EventsWithMainMarketsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.EventsWithMainMarketsResponse.toObject = function (
    includeInstance,
    msg,
  ) {
    var f,
      obj = {
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.EventWithMainMarkets.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventsWithMainMarketsResponse}
 */
proto.EventsWithMainMarketsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventsWithMainMarketsResponse();
  return proto.EventsWithMainMarketsResponse.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventsWithMainMarketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventsWithMainMarketsResponse}
 */
proto.EventsWithMainMarketsResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.EventWithMainMarkets();
        reader.readMessage(
          value,
          proto.EventWithMainMarkets.deserializeBinaryFromReader,
        );
        msg.addEvents(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventsWithMainMarketsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.EventsWithMainMarketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventsWithMainMarketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventsWithMainMarketsResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EventWithMainMarkets.serializeBinaryToWriter,
    );
  }
};

/**
 * repeated EventWithMainMarkets Events = 1;
 * @return {!Array<!proto.EventWithMainMarkets>}
 */
proto.EventsWithMainMarketsResponse.prototype.getEventsList = function () {
  return /** @type{!Array<!proto.EventWithMainMarkets>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventWithMainMarkets, 1)
  );
};

/**
 * @param {!Array<!proto.EventWithMainMarkets>} value
 * @return {!proto.EventsWithMainMarketsResponse} returns this
 */
proto.EventsWithMainMarketsResponse.prototype.setEventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.EventWithMainMarkets=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventWithMainMarkets}
 */
proto.EventsWithMainMarketsResponse.prototype.addEvents = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.EventWithMainMarkets,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventsWithMainMarketsResponse} returns this
 */
proto.EventsWithMainMarketsResponse.prototype.clearEventsList = function () {
  return this.setEventsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PingPong.prototype.toObject = function (opt_includeInstance) {
    return proto.PingPong.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PingPong} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PingPong.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PingPong}
 */
proto.PingPong.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PingPong();
  return proto.PingPong.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PingPong} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PingPong}
 */
proto.PingPong.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PingPong.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PingPong.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PingPong} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingPong.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetUpcomingEvents.prototype.toObject = function (opt_includeInstance) {
    return proto.GetUpcomingEvents.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetUpcomingEvents} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetUpcomingEvents.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
        page: jspb.Message.getFieldWithDefault(msg, 3, 0),
        language: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetUpcomingEvents}
 */
proto.GetUpcomingEvents.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetUpcomingEvents();
  return proto.GetUpcomingEvents.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetUpcomingEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetUpcomingEvents}
 */
proto.GetUpcomingEvents.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSportid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPage(value);
        break;
      case 4:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetUpcomingEvents.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetUpcomingEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetUpcomingEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetUpcomingEvents.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSportid();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }
};

/**
 * optional int32 SportId = 1;
 * @return {number}
 */
proto.GetUpcomingEvents.prototype.getSportid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetUpcomingEvents} returns this
 */
proto.GetUpcomingEvents.prototype.setSportid = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 Limit = 2;
 * @return {number}
 */
proto.GetUpcomingEvents.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetUpcomingEvents} returns this
 */
proto.GetUpcomingEvents.prototype.setLimit = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 Page = 3;
 * @return {number}
 */
proto.GetUpcomingEvents.prototype.getPage = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetUpcomingEvents} returns this
 */
proto.GetUpcomingEvents.prototype.setPage = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional Language Language = 4;
 * @return {!proto.Language}
 */
proto.GetUpcomingEvents.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.GetUpcomingEvents} returns this
 */
proto.GetUpcomingEvents.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetCountsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetCountsRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetCountsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetCountsRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dateto: jspb.Message.getFieldWithDefault(msg, 1, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetCountsRequest}
 */
proto.GetCountsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetCountsRequest();
  return proto.GetCountsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetCountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetCountsRequest}
 */
proto.GetCountsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateto(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetCountsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetCountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetCountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCountsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string DateTo = 1;
 * @return {string}
 */
proto.GetCountsRequest.prototype.getDateto = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.GetCountsRequest} returns this
 */
proto.GetCountsRequest.prototype.setDateto = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.EventSelection.prototype.toObject = function (opt_includeInstance) {
    return proto.EventSelection.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.EventSelection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.EventSelection.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        selectionid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
        odd: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
        status: jspb.Message.getFieldWithDefault(msg, 5, 0),
        rowindex: jspb.Message.getFieldWithDefault(msg, 6, 0),
        columnindex: jspb.Message.getFieldWithDefault(msg, 7, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventSelection}
 */
proto.EventSelection.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventSelection();
  return proto.EventSelection.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventSelection}
 */
proto.EventSelection.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSelectionid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readDouble());
        msg.setOdd(value);
        break;
      case 5:
        var value = /** @type {!proto.SelectionStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setRowindex(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setColumnindex(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventSelection.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.EventSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSelection.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getSelectionid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getOdd();
  if (f !== 0.0) {
    writer.writeDouble(4, f);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(5, f);
  }
  f = message.getRowindex();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
  f = message.getColumnindex();
  if (f !== 0) {
    writer.writeInt32(7, f);
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.EventSelection.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventSelection} returns this
 */
proto.EventSelection.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string SelectionId = 2;
 * @return {string}
 */
proto.EventSelection.prototype.getSelectionid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventSelection} returns this
 */
proto.EventSelection.prototype.setSelectionid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.EventSelection.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventSelection} returns this
 */
proto.EventSelection.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional double Odd = 4;
 * @return {number}
 */
proto.EventSelection.prototype.getOdd = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
  );
};

/**
 * @param {number} value
 * @return {!proto.EventSelection} returns this
 */
proto.EventSelection.prototype.setOdd = function (value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};

/**
 * optional SelectionStatus Status = 5;
 * @return {!proto.SelectionStatus}
 */
proto.EventSelection.prototype.getStatus = function () {
  return /** @type {!proto.SelectionStatus} */ (
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};

/**
 * @param {!proto.SelectionStatus} value
 * @return {!proto.EventSelection} returns this
 */
proto.EventSelection.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};

/**
 * optional int32 RowIndex = 6;
 * @return {number}
 */
proto.EventSelection.prototype.getRowindex = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventSelection} returns this
 */
proto.EventSelection.prototype.setRowindex = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional int32 ColumnIndex = 7;
 * @return {number}
 */
proto.EventSelection.prototype.getColumnindex = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventSelection} returns this
 */
proto.EventSelection.prototype.setColumnindex = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventMarket.repeatedFields_ = [8, 9];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.EventMarket.prototype.toObject = function (opt_includeInstance) {
    return proto.EventMarket.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.EventMarket} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.EventMarket.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        eventid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        marketid: jspb.Message.getFieldWithDefault(msg, 3, 0),
        name: jspb.Message.getFieldWithDefault(msg, 4, ''),
        line: jspb.Message.getFieldWithDefault(msg, 5, ''),
        orderid: jspb.Message.getFieldWithDefault(msg, 6, 0),
        status: jspb.Message.getFieldWithDefault(msg, 7, 0),
        marketgroupidsList:
          (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
        selectionsList: jspb.Message.toObjectList(
          msg.getSelectionsList(),
          proto.EventSelection.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventMarket}
 */
proto.EventMarket.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventMarket();
  return proto.EventMarket.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventMarket}
 */
proto.EventMarket.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEventid(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMarketid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setLine(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setOrderid(value);
        break;
      case 7:
        var value = /** @type {!proto.EventMarketStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.addMarketgroupids(value);
        break;
      case 9:
        var value = new proto.EventSelection();
        reader.readMessage(
          value,
          proto.EventSelection.deserializeBinaryFromReader,
        );
        msg.addSelections(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventMarket.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.EventMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventMarket.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMarketid();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getLine();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getOrderid();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(7, f);
  }
  f = message.getMarketgroupidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(8, f);
  }
  f = message.getSelectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.EventSelection.serializeBinaryToWriter,
    );
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.EventMarket.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string EventId = 2;
 * @return {string}
 */
proto.EventMarket.prototype.getEventid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setEventid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 MarketId = 3;
 * @return {number}
 */
proto.EventMarket.prototype.getMarketid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setMarketid = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string Name = 4;
 * @return {string}
 */
proto.EventMarket.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string Line = 5;
 * @return {string}
 */
proto.EventMarket.prototype.getLine = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setLine = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional int32 OrderId = 6;
 * @return {number}
 */
proto.EventMarket.prototype.getOrderid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setOrderid = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional EventMarketStatus Status = 7;
 * @return {!proto.EventMarketStatus}
 */
proto.EventMarket.prototype.getStatus = function () {
  return /** @type {!proto.EventMarketStatus} */ (
    jspb.Message.getFieldWithDefault(this, 7, 0)
  );
};

/**
 * @param {!proto.EventMarketStatus} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};

/**
 * repeated string MarketGroupIds = 8;
 * @return {!Array<string>}
 */
proto.EventMarket.prototype.getMarketgroupidsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setMarketgroupidsList = function (value) {
  return jspb.Message.setField(this, 8, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.addMarketgroupids = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.clearMarketgroupidsList = function () {
  return this.setMarketgroupidsList([]);
};

/**
 * repeated EventSelection selections = 9;
 * @return {!Array<!proto.EventSelection>}
 */
proto.EventMarket.prototype.getSelectionsList = function () {
  return /** @type{!Array<!proto.EventSelection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventSelection, 9)
  );
};

/**
 * @param {!Array<!proto.EventSelection>} value
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.setSelectionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};

/**
 * @param {!proto.EventSelection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventSelection}
 */
proto.EventMarket.prototype.addSelections = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    9,
    opt_value,
    proto.EventSelection,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventMarket} returns this
 */
proto.EventMarket.prototype.clearSelectionsList = function () {
  return this.setSelectionsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TopMatch.repeatedFields_ = [8];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.TopMatch.prototype.toObject = function (opt_includeInstance) {
    return proto.TopMatch.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.TopMatch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.TopMatch.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        countryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
        startdate: jspb.Message.getFieldWithDefault(msg, 4, ''),
        leagueid: jspb.Message.getFieldWithDefault(msg, 5, 0),
        status: jspb.Message.getFieldWithDefault(msg, 6, 0),
        marketscount: jspb.Message.getFieldWithDefault(msg, 7, 0),
        marketsList: jspb.Message.toObjectList(
          msg.getMarketsList(),
          proto.EventMarket.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TopMatch}
 */
proto.TopMatch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TopMatch();
  return proto.TopMatch.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TopMatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TopMatch}
 */
proto.TopMatch.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCountryid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLeagueid(value);
        break;
      case 6:
        var value = /** @type {!proto.EventStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMarketscount(value);
        break;
      case 8:
        var value = new proto.EventMarket();
        reader.readMessage(
          value,
          proto.EventMarket.deserializeBinaryFromReader,
        );
        msg.addMarkets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TopMatch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.TopMatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TopMatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TopMatch.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCountryid();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getLeagueid();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(6, f);
  }
  f = message.getMarketscount();
  if (f !== 0) {
    writer.writeInt32(7, f);
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.EventMarket.serializeBinaryToWriter,
    );
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.TopMatch.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string Name = 2;
 * @return {string}
 */
proto.TopMatch.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 CountryId = 3;
 * @return {number}
 */
proto.TopMatch.prototype.getCountryid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setCountryid = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string StartDate = 4;
 * @return {string}
 */
proto.TopMatch.prototype.getStartdate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setStartdate = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional int32 LeagueId = 5;
 * @return {number}
 */
proto.TopMatch.prototype.getLeagueid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setLeagueid = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional EventStatus Status = 6;
 * @return {!proto.EventStatus}
 */
proto.TopMatch.prototype.getStatus = function () {
  return /** @type {!proto.EventStatus} */ (
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};

/**
 * @param {!proto.EventStatus} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};

/**
 * optional int32 MarketsCount = 7;
 * @return {number}
 */
proto.TopMatch.prototype.getMarketscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setMarketscount = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * repeated EventMarket Markets = 8;
 * @return {!Array<!proto.EventMarket>}
 */
proto.TopMatch.prototype.getMarketsList = function () {
  return /** @type{!Array<!proto.EventMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventMarket, 8)
  );
};

/**
 * @param {!Array<!proto.EventMarket>} value
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.setMarketsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};

/**
 * @param {!proto.EventMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventMarket}
 */
proto.TopMatch.prototype.addMarkets = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    8,
    opt_value,
    proto.EventMarket,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TopMatch} returns this
 */
proto.TopMatch.prototype.clearMarketsList = function () {
  return this.setMarketsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SportGroupPrematch.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SportGroupPrematch.prototype.toObject = function (opt_includeInstance) {
    return proto.SportGroupPrematch.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SportGroupPrematch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SportGroupPrematch.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        matchesList: jspb.Message.toObjectList(
          msg.getMatchesList(),
          proto.TopMatch.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SportGroupPrematch}
 */
proto.SportGroupPrematch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SportGroupPrematch();
  return proto.SportGroupPrematch.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SportGroupPrematch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SportGroupPrematch}
 */
proto.SportGroupPrematch.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 2:
        var value = new proto.TopMatch();
        reader.readMessage(value, proto.TopMatch.deserializeBinaryFromReader);
        msg.addMatches(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SportGroupPrematch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SportGroupPrematch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SportGroupPrematch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SportGroupPrematch.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getMatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.TopMatch.serializeBinaryToWriter);
  }
};

/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.SportGroupPrematch.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.SportGroupPrematch} returns this
 */
proto.SportGroupPrematch.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * repeated TopMatch Matches = 2;
 * @return {!Array<!proto.TopMatch>}
 */
proto.SportGroupPrematch.prototype.getMatchesList = function () {
  return /** @type{!Array<!proto.TopMatch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TopMatch, 2)
  );
};

/**
 * @param {!Array<!proto.TopMatch>} value
 * @return {!proto.SportGroupPrematch} returns this
 */
proto.SportGroupPrematch.prototype.setMatchesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.TopMatch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TopMatch}
 */
proto.SportGroupPrematch.prototype.addMatches = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.TopMatch,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SportGroupPrematch} returns this
 */
proto.SportGroupPrematch.prototype.clearMatchesList = function () {
  return this.setMatchesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CategoryMap.prototype.toObject = function (opt_includeInstance) {
    return proto.CategoryMap.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CategoryMap} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.CategoryMap.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        code: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CategoryMap}
 */
proto.CategoryMap.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CategoryMap();
  return proto.CategoryMap.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CategoryMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CategoryMap}
 */
proto.CategoryMap.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CategoryMap.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CategoryMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CategoryMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CategoryMap.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
};

/**
 * optional string Name = 1;
 * @return {string}
 */
proto.CategoryMap.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.CategoryMap} returns this
 */
proto.CategoryMap.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string Code = 2;
 * @return {string}
 */
proto.CategoryMap.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.CategoryMap} returns this
 */
proto.CategoryMap.prototype.setCode = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.CategoryMap} returns this
 */
proto.CategoryMap.prototype.clearCode = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CategoryMap.prototype.hasCode = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpcomingResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpcomingResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.UpcomingResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpcomingResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.UpcomingResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportsList: jspb.Message.toObjectList(
          msg.getSportsList(),
          proto.SportGroupPrematch.toObject,
          includeInstance,
        ),
        categoriesMap: (f = msg.getCategoriesMap())
          ? f.toObject(includeInstance, proto.CategoryMap.toObject)
          : [],
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpcomingResponse}
 */
proto.UpcomingResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpcomingResponse();
  return proto.UpcomingResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpcomingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpcomingResponse}
 */
proto.UpcomingResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.SportGroupPrematch();
        reader.readMessage(
          value,
          proto.SportGroupPrematch.deserializeBinaryFromReader,
        );
        msg.addSports(value);
        break;
      case 2:
        var value = msg.getCategoriesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readInt32,
            jspb.BinaryReader.prototype.readMessage,
            proto.CategoryMap.deserializeBinaryFromReader,
            0,
            new proto.CategoryMap(),
          );
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpcomingResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpcomingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpcomingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpcomingResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SportGroupPrematch.serializeBinaryToWriter,
    );
  }
  f = message.getCategoriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      2,
      writer,
      jspb.BinaryWriter.prototype.writeInt32,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.CategoryMap.serializeBinaryToWriter,
    );
  }
};

/**
 * repeated SportGroupPrematch Sports = 1;
 * @return {!Array<!proto.SportGroupPrematch>}
 */
proto.UpcomingResponse.prototype.getSportsList = function () {
  return /** @type{!Array<!proto.SportGroupPrematch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SportGroupPrematch, 1)
  );
};

/**
 * @param {!Array<!proto.SportGroupPrematch>} value
 * @return {!proto.UpcomingResponse} returns this
 */
proto.UpcomingResponse.prototype.setSportsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.SportGroupPrematch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SportGroupPrematch}
 */
proto.UpcomingResponse.prototype.addSports = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.SportGroupPrematch,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpcomingResponse} returns this
 */
proto.UpcomingResponse.prototype.clearSportsList = function () {
  return this.setSportsList([]);
};

/**
 * map<int32, CategoryMap> Categories = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.CategoryMap>}
 */
proto.UpcomingResponse.prototype.getCategoriesMap = function (
  opt_noLazyCreate,
) {
  return /** @type {!jspb.Map<number,!proto.CategoryMap>} */ (
    jspb.Message.getMapField(this, 2, opt_noLazyCreate, proto.CategoryMap)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.UpcomingResponse} returns this
 */
proto.UpcomingResponse.prototype.clearCategoriesMap = function () {
  this.getCategoriesMap().clear();
  return this;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.LanguageFilter.prototype.toObject = function (opt_includeInstance) {
    return proto.LanguageFilter.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.LanguageFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.LanguageFilter.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        language: jspb.Message.getFieldWithDefault(msg, 1, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LanguageFilter}
 */
proto.LanguageFilter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LanguageFilter();
  return proto.LanguageFilter.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LanguageFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LanguageFilter}
 */
proto.LanguageFilter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LanguageFilter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.LanguageFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LanguageFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LanguageFilter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
};

/**
 * optional Language Language = 1;
 * @return {!proto.Language}
 */
proto.LanguageFilter.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.LanguageFilter} returns this
 */
proto.LanguageFilter.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.MainMarketSelection.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.MainMarketSelection.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.MainMarketSelection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.MainMarketSelection.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MainMarketSelection}
 */
proto.MainMarketSelection.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MainMarketSelection();
  return proto.MainMarketSelection.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MainMarketSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MainMarketSelection}
 */
proto.MainMarketSelection.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MainMarketSelection.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.MainMarketSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MainMarketSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MainMarketSelection.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.MainMarketSelection.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.MainMarketSelection} returns this
 */
proto.MainMarketSelection.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string Name = 2;
 * @return {string}
 */
proto.MainMarketSelection.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.MainMarketSelection} returns this
 */
proto.MainMarketSelection.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MainMarket.repeatedFields_ = [5];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.MainMarket.prototype.toObject = function (opt_includeInstance) {
    return proto.MainMarket.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.MainMarket} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.MainMarket.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        shortname: jspb.Message.getFieldWithDefault(msg, 3, ''),
        withline: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
        selectionsList: jspb.Message.toObjectList(
          msg.getSelectionsList(),
          proto.MainMarketSelection.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MainMarket}
 */
proto.MainMarket.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MainMarket();
  return proto.MainMarket.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MainMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MainMarket}
 */
proto.MainMarket.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setShortname(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setWithline(value);
        break;
      case 5:
        var value = new proto.MainMarketSelection();
        reader.readMessage(
          value,
          proto.MainMarketSelection.deserializeBinaryFromReader,
        );
        msg.addSelections(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MainMarket.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.MainMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MainMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MainMarket.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(3, f);
  }
  f = message.getWithline();
  if (f) {
    writer.writeBool(4, f);
  }
  f = message.getSelectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.MainMarketSelection.serializeBinaryToWriter,
    );
  }
};

/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.MainMarket.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.MainMarket} returns this
 */
proto.MainMarket.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string Name = 2;
 * @return {string}
 */
proto.MainMarket.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.MainMarket} returns this
 */
proto.MainMarket.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string ShortName = 3;
 * @return {string}
 */
proto.MainMarket.prototype.getShortname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.MainMarket} returns this
 */
proto.MainMarket.prototype.setShortname = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.MainMarket} returns this
 */
proto.MainMarket.prototype.clearShortname = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MainMarket.prototype.hasShortname = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool WithLine = 4;
 * @return {boolean}
 */
proto.MainMarket.prototype.getWithline = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 4, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.MainMarket} returns this
 */
proto.MainMarket.prototype.setWithline = function (value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * repeated MainMarketSelection Selections = 5;
 * @return {!Array<!proto.MainMarketSelection>}
 */
proto.MainMarket.prototype.getSelectionsList = function () {
  return /** @type{!Array<!proto.MainMarketSelection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.MainMarketSelection, 5)
  );
};

/**
 * @param {!Array<!proto.MainMarketSelection>} value
 * @return {!proto.MainMarket} returns this
 */
proto.MainMarket.prototype.setSelectionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.MainMarketSelection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MainMarketSelection}
 */
proto.MainMarket.prototype.addSelections = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    5,
    opt_value,
    proto.MainMarketSelection,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MainMarket} returns this
 */
proto.MainMarket.prototype.clearSelectionsList = function () {
  return this.setSelectionsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SportMainMarkets.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SportMainMarkets.prototype.toObject = function (opt_includeInstance) {
    return proto.SportMainMarkets.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SportMainMarkets} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SportMainMarkets.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        marketsList: jspb.Message.toObjectList(
          msg.getMarketsList(),
          proto.MainMarket.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SportMainMarkets}
 */
proto.SportMainMarkets.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SportMainMarkets();
  return proto.SportMainMarkets.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SportMainMarkets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SportMainMarkets}
 */
proto.SportMainMarkets.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = new proto.MainMarket();
        reader.readMessage(value, proto.MainMarket.deserializeBinaryFromReader);
        msg.addMarkets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SportMainMarkets.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SportMainMarkets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SportMainMarkets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SportMainMarkets.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.MainMarket.serializeBinaryToWriter);
  }
};

/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.SportMainMarkets.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.SportMainMarkets} returns this
 */
proto.SportMainMarkets.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string Name = 2;
 * @return {string}
 */
proto.SportMainMarkets.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.SportMainMarkets} returns this
 */
proto.SportMainMarkets.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * repeated MainMarket Markets = 3;
 * @return {!Array<!proto.MainMarket>}
 */
proto.SportMainMarkets.prototype.getMarketsList = function () {
  return /** @type{!Array<!proto.MainMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.MainMarket, 3)
  );
};

/**
 * @param {!Array<!proto.MainMarket>} value
 * @return {!proto.SportMainMarkets} returns this
 */
proto.SportMainMarkets.prototype.setMarketsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.MainMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MainMarket}
 */
proto.SportMainMarkets.prototype.addMarkets = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.MainMarket,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SportMainMarkets} returns this
 */
proto.SportMainMarkets.prototype.clearMarketsList = function () {
  return this.setMarketsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MainMarketsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.MainMarketsResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.MainMarketsResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.MainMarketsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.MainMarketsResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportsList: jspb.Message.toObjectList(
          msg.getSportsList(),
          proto.SportMainMarkets.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MainMarketsResponse}
 */
proto.MainMarketsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MainMarketsResponse();
  return proto.MainMarketsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MainMarketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MainMarketsResponse}
 */
proto.MainMarketsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.SportMainMarkets();
        reader.readMessage(
          value,
          proto.SportMainMarkets.deserializeBinaryFromReader,
        );
        msg.addSports(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MainMarketsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.MainMarketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MainMarketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MainMarketsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SportMainMarkets.serializeBinaryToWriter,
    );
  }
};

/**
 * repeated SportMainMarkets Sports = 1;
 * @return {!Array<!proto.SportMainMarkets>}
 */
proto.MainMarketsResponse.prototype.getSportsList = function () {
  return /** @type{!Array<!proto.SportMainMarkets>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SportMainMarkets, 1)
  );
};

/**
 * @param {!Array<!proto.SportMainMarkets>} value
 * @return {!proto.MainMarketsResponse} returns this
 */
proto.MainMarketsResponse.prototype.setSportsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.SportMainMarkets=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SportMainMarkets}
 */
proto.MainMarketsResponse.prototype.addSports = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.SportMainMarkets,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MainMarketsResponse} returns this
 */
proto.MainMarketsResponse.prototype.clearSportsList = function () {
  return this.setSportsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchFilter.prototype.toObject = function (opt_includeInstance) {
    return proto.PrematchFilter.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchFilter.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
        countryid: jspb.Message.getFieldWithDefault(msg, 2, 0),
        dateto: jspb.Message.getFieldWithDefault(msg, 3, ''),
        language: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchFilter}
 */
proto.PrematchFilter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchFilter();
  return proto.PrematchFilter.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchFilter}
 */
proto.PrematchFilter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSportid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCountryid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateto(value);
        break;
      case 4:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchFilter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchFilter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(2, f);
  }
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }
};

/**
 * optional int32 SportId = 1;
 * @return {number}
 */
proto.PrematchFilter.prototype.getSportid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchFilter} returns this
 */
proto.PrematchFilter.prototype.setSportid = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.PrematchFilter} returns this
 */
proto.PrematchFilter.prototype.clearSportid = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PrematchFilter.prototype.hasSportid = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int32 CountryId = 2;
 * @return {number}
 */
proto.PrematchFilter.prototype.getCountryid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchFilter} returns this
 */
proto.PrematchFilter.prototype.setCountryid = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.PrematchFilter} returns this
 */
proto.PrematchFilter.prototype.clearCountryid = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PrematchFilter.prototype.hasCountryid = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string DateTo = 3;
 * @return {string}
 */
proto.PrematchFilter.prototype.getDateto = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchFilter} returns this
 */
proto.PrematchFilter.prototype.setDateto = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional Language Language = 4;
 * @return {!proto.Language}
 */
proto.PrematchFilter.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.PrematchFilter} returns this
 */
proto.PrematchFilter.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchMenuCategory.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.PrematchMenuCategory.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchMenuCategory} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchMenuCategory.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        events: jspb.Message.getFieldWithDefault(msg, 1, 0),
        id: jspb.Message.getFieldWithDefault(msg, 2, 0),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchMenuCategory}
 */
proto.PrematchMenuCategory.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchMenuCategory();
  return proto.PrematchMenuCategory.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchMenuCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchMenuCategory}
 */
proto.PrematchMenuCategory.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEvents(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchMenuCategory.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchMenuCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchMenuCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchMenuCategory.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getEvents();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional int32 Events = 1;
 * @return {number}
 */
proto.PrematchMenuCategory.prototype.getEvents = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchMenuCategory} returns this
 */
proto.PrematchMenuCategory.prototype.setEvents = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 Id = 2;
 * @return {number}
 */
proto.PrematchMenuCategory.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchMenuCategory} returns this
 */
proto.PrematchMenuCategory.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.PrematchMenuCategory.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchMenuCategory} returns this
 */
proto.PrematchMenuCategory.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PrematchMenuCountry.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchMenuCountry.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.PrematchMenuCountry.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchMenuCountry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchMenuCountry.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ''),
        leaguesList: jspb.Message.toObjectList(
          msg.getLeaguesList(),
          proto.PrematchMenuCategory.toObject,
          includeInstance,
        ),
        events: jspb.Message.getFieldWithDefault(msg, 3, 0),
        id: jspb.Message.getFieldWithDefault(msg, 4, 0),
        name: jspb.Message.getFieldWithDefault(msg, 5, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchMenuCountry}
 */
proto.PrematchMenuCountry.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchMenuCountry();
  return proto.PrematchMenuCountry.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchMenuCountry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchMenuCountry}
 */
proto.PrematchMenuCountry.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 2:
        var value = new proto.PrematchMenuCategory();
        reader.readMessage(
          value,
          proto.PrematchMenuCategory.deserializeBinaryFromReader,
        );
        msg.addLeagues(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEvents(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchMenuCountry.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchMenuCountry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchMenuCountry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchMenuCountry.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getLeaguesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.PrematchMenuCategory.serializeBinaryToWriter,
    );
  }
  f = message.getEvents();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
};

/**
 * optional string Code = 1;
 * @return {string}
 */
proto.PrematchMenuCountry.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchMenuCountry} returns this
 */
proto.PrematchMenuCountry.prototype.setCode = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.PrematchMenuCountry} returns this
 */
proto.PrematchMenuCountry.prototype.clearCode = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PrematchMenuCountry.prototype.hasCode = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated PrematchMenuCategory Leagues = 2;
 * @return {!Array<!proto.PrematchMenuCategory>}
 */
proto.PrematchMenuCountry.prototype.getLeaguesList = function () {
  return /** @type{!Array<!proto.PrematchMenuCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PrematchMenuCategory, 2)
  );
};

/**
 * @param {!Array<!proto.PrematchMenuCategory>} value
 * @return {!proto.PrematchMenuCountry} returns this
 */
proto.PrematchMenuCountry.prototype.setLeaguesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.PrematchMenuCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PrematchMenuCategory}
 */
proto.PrematchMenuCountry.prototype.addLeagues = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.PrematchMenuCategory,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PrematchMenuCountry} returns this
 */
proto.PrematchMenuCountry.prototype.clearLeaguesList = function () {
  return this.setLeaguesList([]);
};

/**
 * optional int32 Events = 3;
 * @return {number}
 */
proto.PrematchMenuCountry.prototype.getEvents = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchMenuCountry} returns this
 */
proto.PrematchMenuCountry.prototype.setEvents = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 Id = 4;
 * @return {number}
 */
proto.PrematchMenuCountry.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchMenuCountry} returns this
 */
proto.PrematchMenuCountry.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional string Name = 5;
 * @return {string}
 */
proto.PrematchMenuCountry.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchMenuCountry} returns this
 */
proto.PrematchMenuCountry.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PrematchMenuSport.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchMenuSport.prototype.toObject = function (opt_includeInstance) {
    return proto.PrematchMenuSport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchMenuSport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchMenuSport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        countriesList: jspb.Message.toObjectList(
          msg.getCountriesList(),
          proto.PrematchMenuCountry.toObject,
          includeInstance,
        ),
        events: jspb.Message.getFieldWithDefault(msg, 2, 0),
        id: jspb.Message.getFieldWithDefault(msg, 3, 0),
        name: jspb.Message.getFieldWithDefault(msg, 4, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchMenuSport}
 */
proto.PrematchMenuSport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchMenuSport();
  return proto.PrematchMenuSport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchMenuSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchMenuSport}
 */
proto.PrematchMenuSport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.PrematchMenuCountry();
        reader.readMessage(
          value,
          proto.PrematchMenuCountry.deserializeBinaryFromReader,
        );
        msg.addCountries(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEvents(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchMenuSport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchMenuSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchMenuSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchMenuSport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PrematchMenuCountry.serializeBinaryToWriter,
    );
  }
  f = message.getEvents();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * repeated PrematchMenuCountry Countries = 1;
 * @return {!Array<!proto.PrematchMenuCountry>}
 */
proto.PrematchMenuSport.prototype.getCountriesList = function () {
  return /** @type{!Array<!proto.PrematchMenuCountry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PrematchMenuCountry, 1)
  );
};

/**
 * @param {!Array<!proto.PrematchMenuCountry>} value
 * @return {!proto.PrematchMenuSport} returns this
 */
proto.PrematchMenuSport.prototype.setCountriesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.PrematchMenuCountry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PrematchMenuCountry}
 */
proto.PrematchMenuSport.prototype.addCountries = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.PrematchMenuCountry,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PrematchMenuSport} returns this
 */
proto.PrematchMenuSport.prototype.clearCountriesList = function () {
  return this.setCountriesList([]);
};

/**
 * optional int32 Events = 2;
 * @return {number}
 */
proto.PrematchMenuSport.prototype.getEvents = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchMenuSport} returns this
 */
proto.PrematchMenuSport.prototype.setEvents = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 Id = 3;
 * @return {number}
 */
proto.PrematchMenuSport.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchMenuSport} returns this
 */
proto.PrematchMenuSport.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string Name = 4;
 * @return {string}
 */
proto.PrematchMenuSport.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchMenuSport} returns this
 */
proto.PrematchMenuSport.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetPrematchResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetPrematchResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.GetPrematchResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetPrematchResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetPrematchResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportsList: jspb.Message.toObjectList(
          msg.getSportsList(),
          proto.PrematchMenuSport.toObject,
          includeInstance,
        ),
        eventscount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetPrematchResponse}
 */
proto.GetPrematchResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetPrematchResponse();
  return proto.GetPrematchResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetPrematchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetPrematchResponse}
 */
proto.GetPrematchResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.PrematchMenuSport();
        reader.readMessage(
          value,
          proto.PrematchMenuSport.deserializeBinaryFromReader,
        );
        msg.addSports(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEventscount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetPrematchResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetPrematchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetPrematchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetPrematchResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PrematchMenuSport.serializeBinaryToWriter,
    );
  }
  f = message.getEventscount();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * repeated PrematchMenuSport Sports = 1;
 * @return {!Array<!proto.PrematchMenuSport>}
 */
proto.GetPrematchResponse.prototype.getSportsList = function () {
  return /** @type{!Array<!proto.PrematchMenuSport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PrematchMenuSport, 1)
  );
};

/**
 * @param {!Array<!proto.PrematchMenuSport>} value
 * @return {!proto.GetPrematchResponse} returns this
 */
proto.GetPrematchResponse.prototype.setSportsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.PrematchMenuSport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PrematchMenuSport}
 */
proto.GetPrematchResponse.prototype.addSports = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.PrematchMenuSport,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetPrematchResponse} returns this
 */
proto.GetPrematchResponse.prototype.clearSportsList = function () {
  return this.setSportsList([]);
};

/**
 * optional int32 EventsCount = 2;
 * @return {number}
 */
proto.GetPrematchResponse.prototype.getEventscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetPrematchResponse} returns this
 */
proto.GetPrematchResponse.prototype.setEventscount = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubMenuFilter.prototype.toObject = function (opt_includeInstance) {
    return proto.SubMenuFilter.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubMenuFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubMenuFilter.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
        countryid: jspb.Message.getFieldWithDefault(msg, 2, 0),
        dateto: jspb.Message.getFieldWithDefault(msg, 3, ''),
        language: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubMenuFilter}
 */
proto.SubMenuFilter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubMenuFilter();
  return proto.SubMenuFilter.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubMenuFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubMenuFilter}
 */
proto.SubMenuFilter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSportid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCountryid(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateto(value);
        break;
      case 4:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubMenuFilter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubMenuFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubMenuFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubMenuFilter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(2, f);
  }
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }
};

/**
 * optional int32 SportId = 1;
 * @return {number}
 */
proto.SubMenuFilter.prototype.getSportid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.SubMenuFilter} returns this
 */
proto.SubMenuFilter.prototype.setSportid = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.SubMenuFilter} returns this
 */
proto.SubMenuFilter.prototype.clearSportid = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SubMenuFilter.prototype.hasSportid = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional int32 CountryId = 2;
 * @return {number}
 */
proto.SubMenuFilter.prototype.getCountryid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.SubMenuFilter} returns this
 */
proto.SubMenuFilter.prototype.setCountryid = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.SubMenuFilter} returns this
 */
proto.SubMenuFilter.prototype.clearCountryid = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SubMenuFilter.prototype.hasCountryid = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string DateTo = 3;
 * @return {string}
 */
proto.SubMenuFilter.prototype.getDateto = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.SubMenuFilter} returns this
 */
proto.SubMenuFilter.prototype.setDateto = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional Language Language = 4;
 * @return {!proto.Language}
 */
proto.SubMenuFilter.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.SubMenuFilter} returns this
 */
proto.SubMenuFilter.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubMenuCategory.prototype.toObject = function (opt_includeInstance) {
    return proto.SubMenuCategory.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubMenuCategory} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubMenuCategory.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        name: jspb.Message.getFieldWithDefault(msg, 1, ''),
        code: jspb.Message.getFieldWithDefault(msg, 2, ''),
        id: jspb.Message.getFieldWithDefault(msg, 3, 0),
        events: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubMenuCategory}
 */
proto.SubMenuCategory.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubMenuCategory();
  return proto.SubMenuCategory.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubMenuCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubMenuCategory}
 */
proto.SubMenuCategory.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEvents(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubMenuCategory.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubMenuCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubMenuCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubMenuCategory.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getEvents();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
};

/**
 * optional string Name = 1;
 * @return {string}
 */
proto.SubMenuCategory.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.SubMenuCategory} returns this
 */
proto.SubMenuCategory.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string Code = 2;
 * @return {string}
 */
proto.SubMenuCategory.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.SubMenuCategory} returns this
 */
proto.SubMenuCategory.prototype.setCode = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.SubMenuCategory} returns this
 */
proto.SubMenuCategory.prototype.clearCode = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SubMenuCategory.prototype.hasCode = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int32 Id = 3;
 * @return {number}
 */
proto.SubMenuCategory.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.SubMenuCategory} returns this
 */
proto.SubMenuCategory.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 Events = 4;
 * @return {number}
 */
proto.SubMenuCategory.prototype.getEvents = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.SubMenuCategory} returns this
 */
proto.SubMenuCategory.prototype.setEvents = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubMenuCategoriesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubMenuCategoriesResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.SubMenuCategoriesResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubMenuCategoriesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubMenuCategoriesResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        categoriesList: jspb.Message.toObjectList(
          msg.getCategoriesList(),
          proto.SubMenuCategory.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubMenuCategoriesResponse}
 */
proto.SubMenuCategoriesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubMenuCategoriesResponse();
  return proto.SubMenuCategoriesResponse.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubMenuCategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubMenuCategoriesResponse}
 */
proto.SubMenuCategoriesResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.SubMenuCategory();
        reader.readMessage(
          value,
          proto.SubMenuCategory.deserializeBinaryFromReader,
        );
        msg.addCategories(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubMenuCategoriesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubMenuCategoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubMenuCategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubMenuCategoriesResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SubMenuCategory.serializeBinaryToWriter,
    );
  }
};

/**
 * repeated SubMenuCategory Categories = 1;
 * @return {!Array<!proto.SubMenuCategory>}
 */
proto.SubMenuCategoriesResponse.prototype.getCategoriesList = function () {
  return /** @type{!Array<!proto.SubMenuCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubMenuCategory, 1)
  );
};

/**
 * @param {!Array<!proto.SubMenuCategory>} value
 * @return {!proto.SubMenuCategoriesResponse} returns this
 */
proto.SubMenuCategoriesResponse.prototype.setCategoriesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.SubMenuCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubMenuCategory}
 */
proto.SubMenuCategoriesResponse.prototype.addCategories = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.SubMenuCategory,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubMenuCategoriesResponse} returns this
 */
proto.SubMenuCategoriesResponse.prototype.clearCategoriesList = function () {
  return this.setCategoriesList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InPlayMenuEvent.repeatedFields_ = [6];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.InPlayMenuEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.InPlayMenuEvent.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.InPlayMenuEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.InPlayMenuEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        startdate: jspb.Message.getFieldWithDefault(msg, 2, ''),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
        extradata: jspb.Message.getFieldWithDefault(msg, 4, ''),
        marketscount: jspb.Message.getFieldWithDefault(msg, 5, 0),
        marketsList: jspb.Message.toObjectList(
          msg.getMarketsList(),
          proto.EventMarket.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InPlayMenuEvent}
 */
proto.InPlayMenuEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InPlayMenuEvent();
  return proto.InPlayMenuEvent.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InPlayMenuEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InPlayMenuEvent}
 */
proto.InPlayMenuEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtradata(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMarketscount(value);
        break;
      case 6:
        var value = new proto.EventMarket();
        reader.readMessage(
          value,
          proto.EventMarket.deserializeBinaryFromReader,
        );
        msg.addMarkets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InPlayMenuEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.InPlayMenuEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InPlayMenuEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InPlayMenuEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(4, f);
  }
  f = message.getMarketscount();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.EventMarket.serializeBinaryToWriter,
    );
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.InPlayMenuEvent.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string StartDate = 2;
 * @return {string}
 */
proto.InPlayMenuEvent.prototype.getStartdate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.setStartdate = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.InPlayMenuEvent.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string ExtraData = 4;
 * @return {string}
 */
proto.InPlayMenuEvent.prototype.getExtradata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.setExtradata = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.clearExtradata = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InPlayMenuEvent.prototype.hasExtradata = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional int32 MarketsCount = 5;
 * @return {number}
 */
proto.InPlayMenuEvent.prototype.getMarketscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.setMarketscount = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * repeated EventMarket Markets = 6;
 * @return {!Array<!proto.EventMarket>}
 */
proto.InPlayMenuEvent.prototype.getMarketsList = function () {
  return /** @type{!Array<!proto.EventMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventMarket, 6)
  );
};

/**
 * @param {!Array<!proto.EventMarket>} value
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.setMarketsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.EventMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventMarket}
 */
proto.InPlayMenuEvent.prototype.addMarkets = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    6,
    opt_value,
    proto.EventMarket,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InPlayMenuEvent} returns this
 */
proto.InPlayMenuEvent.prototype.clearMarketsList = function () {
  return this.setMarketsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InPlayMenuLeague.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.InPlayMenuLeague.prototype.toObject = function (opt_includeInstance) {
    return proto.InPlayMenuLeague.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.InPlayMenuLeague} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.InPlayMenuLeague.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.InPlayMenuEvent.toObject,
          includeInstance,
        ),
        id: jspb.Message.getFieldWithDefault(msg, 2, 0),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InPlayMenuLeague}
 */
proto.InPlayMenuLeague.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InPlayMenuLeague();
  return proto.InPlayMenuLeague.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InPlayMenuLeague} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InPlayMenuLeague}
 */
proto.InPlayMenuLeague.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.InPlayMenuEvent();
        reader.readMessage(
          value,
          proto.InPlayMenuEvent.deserializeBinaryFromReader,
        );
        msg.addEvents(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InPlayMenuLeague.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.InPlayMenuLeague.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InPlayMenuLeague} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InPlayMenuLeague.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.InPlayMenuEvent.serializeBinaryToWriter,
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * repeated InPlayMenuEvent Events = 1;
 * @return {!Array<!proto.InPlayMenuEvent>}
 */
proto.InPlayMenuLeague.prototype.getEventsList = function () {
  return /** @type{!Array<!proto.InPlayMenuEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InPlayMenuEvent, 1)
  );
};

/**
 * @param {!Array<!proto.InPlayMenuEvent>} value
 * @return {!proto.InPlayMenuLeague} returns this
 */
proto.InPlayMenuLeague.prototype.setEventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.InPlayMenuEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InPlayMenuEvent}
 */
proto.InPlayMenuLeague.prototype.addEvents = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.InPlayMenuEvent,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InPlayMenuLeague} returns this
 */
proto.InPlayMenuLeague.prototype.clearEventsList = function () {
  return this.setEventsList([]);
};

/**
 * optional int32 Id = 2;
 * @return {number}
 */
proto.InPlayMenuLeague.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.InPlayMenuLeague} returns this
 */
proto.InPlayMenuLeague.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.InPlayMenuLeague.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuLeague} returns this
 */
proto.InPlayMenuLeague.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InPlayMenuCountry.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.InPlayMenuCountry.prototype.toObject = function (opt_includeInstance) {
    return proto.InPlayMenuCountry.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.InPlayMenuCountry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.InPlayMenuCountry.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ''),
        leaguesList: jspb.Message.toObjectList(
          msg.getLeaguesList(),
          proto.InPlayMenuLeague.toObject,
          includeInstance,
        ),
        id: jspb.Message.getFieldWithDefault(msg, 3, 0),
        name: jspb.Message.getFieldWithDefault(msg, 4, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InPlayMenuCountry}
 */
proto.InPlayMenuCountry.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InPlayMenuCountry();
  return proto.InPlayMenuCountry.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InPlayMenuCountry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InPlayMenuCountry}
 */
proto.InPlayMenuCountry.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 2:
        var value = new proto.InPlayMenuLeague();
        reader.readMessage(
          value,
          proto.InPlayMenuLeague.deserializeBinaryFromReader,
        );
        msg.addLeagues(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InPlayMenuCountry.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.InPlayMenuCountry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InPlayMenuCountry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InPlayMenuCountry.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getLeaguesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.InPlayMenuLeague.serializeBinaryToWriter,
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string Code = 1;
 * @return {string}
 */
proto.InPlayMenuCountry.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuCountry} returns this
 */
proto.InPlayMenuCountry.prototype.setCode = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.InPlayMenuCountry} returns this
 */
proto.InPlayMenuCountry.prototype.clearCode = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InPlayMenuCountry.prototype.hasCode = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated InPlayMenuLeague Leagues = 2;
 * @return {!Array<!proto.InPlayMenuLeague>}
 */
proto.InPlayMenuCountry.prototype.getLeaguesList = function () {
  return /** @type{!Array<!proto.InPlayMenuLeague>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InPlayMenuLeague, 2)
  );
};

/**
 * @param {!Array<!proto.InPlayMenuLeague>} value
 * @return {!proto.InPlayMenuCountry} returns this
 */
proto.InPlayMenuCountry.prototype.setLeaguesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.InPlayMenuLeague=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InPlayMenuLeague}
 */
proto.InPlayMenuCountry.prototype.addLeagues = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.InPlayMenuLeague,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InPlayMenuCountry} returns this
 */
proto.InPlayMenuCountry.prototype.clearLeaguesList = function () {
  return this.setLeaguesList([]);
};

/**
 * optional int32 Id = 3;
 * @return {number}
 */
proto.InPlayMenuCountry.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.InPlayMenuCountry} returns this
 */
proto.InPlayMenuCountry.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string Name = 4;
 * @return {string}
 */
proto.InPlayMenuCountry.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuCountry} returns this
 */
proto.InPlayMenuCountry.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InPlayMenuSport.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.InPlayMenuSport.prototype.toObject = function (opt_includeInstance) {
    return proto.InPlayMenuSport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.InPlayMenuSport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.InPlayMenuSport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        countriesList: jspb.Message.toObjectList(
          msg.getCountriesList(),
          proto.InPlayMenuCountry.toObject,
          includeInstance,
        ),
        id: jspb.Message.getFieldWithDefault(msg, 2, 0),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InPlayMenuSport}
 */
proto.InPlayMenuSport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InPlayMenuSport();
  return proto.InPlayMenuSport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InPlayMenuSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InPlayMenuSport}
 */
proto.InPlayMenuSport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.InPlayMenuCountry();
        reader.readMessage(
          value,
          proto.InPlayMenuCountry.deserializeBinaryFromReader,
        );
        msg.addCountries(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InPlayMenuSport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.InPlayMenuSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InPlayMenuSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InPlayMenuSport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.InPlayMenuCountry.serializeBinaryToWriter,
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * repeated InPlayMenuCountry Countries = 1;
 * @return {!Array<!proto.InPlayMenuCountry>}
 */
proto.InPlayMenuSport.prototype.getCountriesList = function () {
  return /** @type{!Array<!proto.InPlayMenuCountry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InPlayMenuCountry, 1)
  );
};

/**
 * @param {!Array<!proto.InPlayMenuCountry>} value
 * @return {!proto.InPlayMenuSport} returns this
 */
proto.InPlayMenuSport.prototype.setCountriesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.InPlayMenuCountry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InPlayMenuCountry}
 */
proto.InPlayMenuSport.prototype.addCountries = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.InPlayMenuCountry,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InPlayMenuSport} returns this
 */
proto.InPlayMenuSport.prototype.clearCountriesList = function () {
  return this.setCountriesList([]);
};

/**
 * optional int32 Id = 2;
 * @return {number}
 */
proto.InPlayMenuSport.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.InPlayMenuSport} returns this
 */
proto.InPlayMenuSport.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.InPlayMenuSport.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.InPlayMenuSport} returns this
 */
proto.InPlayMenuSport.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InPlayMenuResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.InPlayMenuResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.InPlayMenuResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.InPlayMenuResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.InPlayMenuResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.InPlayMenuSport.toObject,
          includeInstance,
        ),
        eventscount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InPlayMenuResponse}
 */
proto.InPlayMenuResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InPlayMenuResponse();
  return proto.InPlayMenuResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InPlayMenuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InPlayMenuResponse}
 */
proto.InPlayMenuResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.InPlayMenuSport();
        reader.readMessage(
          value,
          proto.InPlayMenuSport.deserializeBinaryFromReader,
        );
        msg.addEvents(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEventscount(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InPlayMenuResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.InPlayMenuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InPlayMenuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InPlayMenuResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.InPlayMenuSport.serializeBinaryToWriter,
    );
  }
  f = message.getEventscount();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * repeated InPlayMenuSport Events = 1;
 * @return {!Array<!proto.InPlayMenuSport>}
 */
proto.InPlayMenuResponse.prototype.getEventsList = function () {
  return /** @type{!Array<!proto.InPlayMenuSport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.InPlayMenuSport, 1)
  );
};

/**
 * @param {!Array<!proto.InPlayMenuSport>} value
 * @return {!proto.InPlayMenuResponse} returns this
 */
proto.InPlayMenuResponse.prototype.setEventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.InPlayMenuSport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.InPlayMenuSport}
 */
proto.InPlayMenuResponse.prototype.addEvents = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.InPlayMenuSport,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InPlayMenuResponse} returns this
 */
proto.InPlayMenuResponse.prototype.clearEventsList = function () {
  return this.setEventsList([]);
};

/**
 * optional int32 EventsCount = 2;
 * @return {number}
 */
proto.InPlayMenuResponse.prototype.getEventscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.InPlayMenuResponse} returns this
 */
proto.InPlayMenuResponse.prototype.setEventscount = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.FavoriteTournament.prototype.toObject = function (opt_includeInstance) {
    return proto.FavoriteTournament.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.FavoriteTournament} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.FavoriteTournament.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
        countryid: jspb.Message.getFieldWithDefault(msg, 3, 0),
        countryname: jspb.Message.getFieldWithDefault(msg, 4, ''),
        countrycode: jspb.Message.getFieldWithDefault(msg, 5, ''),
        sportid: jspb.Message.getFieldWithDefault(msg, 6, 0),
        sportname: jspb.Message.getFieldWithDefault(msg, 7, ''),
        events: jspb.Message.getFieldWithDefault(msg, 8, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FavoriteTournament}
 */
proto.FavoriteTournament.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FavoriteTournament();
  return proto.FavoriteTournament.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FavoriteTournament} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FavoriteTournament}
 */
proto.FavoriteTournament.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCountryid(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountryname(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountrycode(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSportid(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setSportname(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setEvents(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FavoriteTournament.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.FavoriteTournament.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FavoriteTournament} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FavoriteTournament.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCountryid();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getCountryname();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(5, f);
  }
  f = message.getSportid();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
  f = message.getSportname();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getEvents();
  if (f !== 0) {
    writer.writeInt32(8, f);
  }
};

/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.FavoriteTournament.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string Name = 2;
 * @return {string}
 */
proto.FavoriteTournament.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 CountryId = 3;
 * @return {number}
 */
proto.FavoriteTournament.prototype.getCountryid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setCountryid = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string CountryName = 4;
 * @return {string}
 */
proto.FavoriteTournament.prototype.getCountryname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setCountryname = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string CountryCode = 5;
 * @return {string}
 */
proto.FavoriteTournament.prototype.getCountrycode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setCountrycode = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.clearCountrycode = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FavoriteTournament.prototype.hasCountrycode = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional int32 SportId = 6;
 * @return {number}
 */
proto.FavoriteTournament.prototype.getSportid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setSportid = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional string SportName = 7;
 * @return {string}
 */
proto.FavoriteTournament.prototype.getSportname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setSportname = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional int32 Events = 8;
 * @return {number}
 */
proto.FavoriteTournament.prototype.getEvents = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.FavoriteTournament} returns this
 */
proto.FavoriteTournament.prototype.setEvents = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.TopTournamentRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.TopTournamentRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.TopTournamentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.TopTournamentRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        language: jspb.Message.getFieldWithDefault(msg, 1, 0),
        dateto: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TopTournamentRequest}
 */
proto.TopTournamentRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TopTournamentRequest();
  return proto.TopTournamentRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TopTournamentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TopTournamentRequest}
 */
proto.TopTournamentRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateto(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TopTournamentRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.TopTournamentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TopTournamentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TopTournamentRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional Language Language = 1;
 * @return {!proto.Language}
 */
proto.TopTournamentRequest.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.TopTournamentRequest} returns this
 */
proto.TopTournamentRequest.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string DateTo = 2;
 * @return {string}
 */
proto.TopTournamentRequest.prototype.getDateto = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.TopTournamentRequest} returns this
 */
proto.TopTournamentRequest.prototype.setDateto = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetTopTournamentsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetTopTournamentsResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.GetTopTournamentsResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetTopTournamentsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetTopTournamentsResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        tournamentsList: jspb.Message.toObjectList(
          msg.getTournamentsList(),
          proto.FavoriteTournament.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTopTournamentsResponse}
 */
proto.GetTopTournamentsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTopTournamentsResponse();
  return proto.GetTopTournamentsResponse.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTopTournamentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTopTournamentsResponse}
 */
proto.GetTopTournamentsResponse.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.FavoriteTournament();
        reader.readMessage(
          value,
          proto.FavoriteTournament.deserializeBinaryFromReader,
        );
        msg.addTournaments(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTopTournamentsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetTopTournamentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTopTournamentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTopTournamentsResponse.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getTournamentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.FavoriteTournament.serializeBinaryToWriter,
    );
  }
};

/**
 * repeated FavoriteTournament Tournaments = 1;
 * @return {!Array<!proto.FavoriteTournament>}
 */
proto.GetTopTournamentsResponse.prototype.getTournamentsList = function () {
  return /** @type{!Array<!proto.FavoriteTournament>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.FavoriteTournament, 1)
  );
};

/**
 * @param {!Array<!proto.FavoriteTournament>} value
 * @return {!proto.GetTopTournamentsResponse} returns this
 */
proto.GetTopTournamentsResponse.prototype.setTournamentsList = function (
  value,
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.FavoriteTournament=} opt_value
 * @param {number=} opt_index
 * @return {!proto.FavoriteTournament}
 */
proto.GetTopTournamentsResponse.prototype.addTournaments = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.FavoriteTournament,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetTopTournamentsResponse} returns this
 */
proto.GetTopTournamentsResponse.prototype.clearTournamentsList = function () {
  return this.setTournamentsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.LimitFilter.prototype.toObject = function (opt_includeInstance) {
    return proto.LimitFilter.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.LimitFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.LimitFilter.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
        language: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LimitFilter}
 */
proto.LimitFilter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LimitFilter();
  return proto.LimitFilter.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LimitFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LimitFilter}
 */
proto.LimitFilter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value);
        break;
      case 2:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LimitFilter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.LimitFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LimitFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LimitFilter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * optional int32 Limit = 1;
 * @return {number}
 */
proto.LimitFilter.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.LimitFilter} returns this
 */
proto.LimitFilter.prototype.setLimit = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional Language Language = 2;
 * @return {!proto.Language}
 */
proto.LimitFilter.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.LimitFilter} returns this
 */
proto.LimitFilter.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.TopGamesRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.TopGamesRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.TopGamesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.TopGamesRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        language: jspb.Message.getFieldWithDefault(msg, 1, 0),
        dateto: jspb.Message.getFieldWithDefault(msg, 2, ''),
        islive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TopGamesRequest}
 */
proto.TopGamesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TopGamesRequest();
  return proto.TopGamesRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TopGamesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TopGamesRequest}
 */
proto.TopGamesRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateto(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIslive(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TopGamesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.TopGamesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TopGamesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TopGamesRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(3, f);
  }
};

/**
 * optional Language Language = 1;
 * @return {!proto.Language}
 */
proto.TopGamesRequest.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.TopGamesRequest} returns this
 */
proto.TopGamesRequest.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string DateTo = 2;
 * @return {string}
 */
proto.TopGamesRequest.prototype.getDateto = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.TopGamesRequest} returns this
 */
proto.TopGamesRequest.prototype.setDateto = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional bool IsLive = 3;
 * @return {boolean}
 */
proto.TopGamesRequest.prototype.getIslive = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.TopGamesRequest} returns this
 */
proto.TopGamesRequest.prototype.setIslive = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.TopGamesRequest} returns this
 */
proto.TopGamesRequest.prototype.clearIslive = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TopGamesRequest.prototype.hasIslive = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetTopGamesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetTopGamesResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.GetTopGamesResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetTopGamesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetTopGamesResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sportsList: jspb.Message.toObjectList(
          msg.getSportsList(),
          proto.SportGroupPrematch.toObject,
          includeInstance,
        ),
        categoriesMap: (f = msg.getCategoriesMap())
          ? f.toObject(includeInstance, proto.CategoryMap.toObject)
          : [],
        islive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetTopGamesResponse}
 */
proto.GetTopGamesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetTopGamesResponse();
  return proto.GetTopGamesResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetTopGamesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetTopGamesResponse}
 */
proto.GetTopGamesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.SportGroupPrematch();
        reader.readMessage(
          value,
          proto.SportGroupPrematch.deserializeBinaryFromReader,
        );
        msg.addSports(value);
        break;
      case 2:
        var value = msg.getCategoriesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readInt32,
            jspb.BinaryReader.prototype.readMessage,
            proto.CategoryMap.deserializeBinaryFromReader,
            0,
            new proto.CategoryMap(),
          );
        });
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIslive(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetTopGamesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetTopGamesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetTopGamesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetTopGamesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SportGroupPrematch.serializeBinaryToWriter,
    );
  }
  f = message.getCategoriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      2,
      writer,
      jspb.BinaryWriter.prototype.writeInt32,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.CategoryMap.serializeBinaryToWriter,
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(3, f);
  }
};

/**
 * repeated SportGroupPrematch Sports = 1;
 * @return {!Array<!proto.SportGroupPrematch>}
 */
proto.GetTopGamesResponse.prototype.getSportsList = function () {
  return /** @type{!Array<!proto.SportGroupPrematch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SportGroupPrematch, 1)
  );
};

/**
 * @param {!Array<!proto.SportGroupPrematch>} value
 * @return {!proto.GetTopGamesResponse} returns this
 */
proto.GetTopGamesResponse.prototype.setSportsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.SportGroupPrematch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SportGroupPrematch}
 */
proto.GetTopGamesResponse.prototype.addSports = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.SportGroupPrematch,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetTopGamesResponse} returns this
 */
proto.GetTopGamesResponse.prototype.clearSportsList = function () {
  return this.setSportsList([]);
};

/**
 * map<int32, CategoryMap> Categories = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.CategoryMap>}
 */
proto.GetTopGamesResponse.prototype.getCategoriesMap = function (
  opt_noLazyCreate,
) {
  return /** @type {!jspb.Map<number,!proto.CategoryMap>} */ (
    jspb.Message.getMapField(this, 2, opt_noLazyCreate, proto.CategoryMap)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.GetTopGamesResponse} returns this
 */
proto.GetTopGamesResponse.prototype.clearCategoriesMap = function () {
  this.getCategoriesMap().clear();
  return this;
};

/**
 * optional bool IsLive = 3;
 * @return {boolean}
 */
proto.GetTopGamesResponse.prototype.getIslive = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.GetTopGamesResponse} returns this
 */
proto.GetTopGamesResponse.prototype.setIslive = function (value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.GetTopGamesResponse} returns this
 */
proto.GetTopGamesResponse.prototype.clearIslive = function () {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetTopGamesResponse.prototype.hasIslive = function () {
  return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.IdFilter.prototype.toObject = function (opt_includeInstance) {
    return proto.IdFilter.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.IdFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.IdFilter.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        language: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IdFilter}
 */
proto.IdFilter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IdFilter();
  return proto.IdFilter.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IdFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IdFilter}
 */
proto.IdFilter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IdFilter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.IdFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IdFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IdFilter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.IdFilter.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.IdFilter} returns this
 */
proto.IdFilter.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Language Language = 2;
 * @return {!proto.Language}
 */
proto.IdFilter.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.IdFilter} returns this
 */
proto.IdFilter.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.MarketGroup.prototype.toObject = function (opt_includeInstance) {
    return proto.MarketGroup.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.MarketGroup} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.MarketGroup.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        name: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MarketGroup}
 */
proto.MarketGroup.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MarketGroup();
  return proto.MarketGroup.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MarketGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MarketGroup}
 */
proto.MarketGroup.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MarketGroup.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.MarketGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MarketGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MarketGroup.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.MarketGroup.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.MarketGroup} returns this
 */
proto.MarketGroup.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string Name = 2;
 * @return {string}
 */
proto.MarketGroup.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.MarketGroup} returns this
 */
proto.MarketGroup.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Event.repeatedFields_ = [14, 15];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Event.prototype.toObject = function (opt_includeInstance) {
    return proto.Event.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Event} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Event.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        startdate: jspb.Message.getFieldWithDefault(msg, 2, ''),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
        sportid: jspb.Message.getFieldWithDefault(msg, 4, 0),
        sportname: jspb.Message.getFieldWithDefault(msg, 5, ''),
        countryid: jspb.Message.getFieldWithDefault(msg, 6, 0),
        countryname: jspb.Message.getFieldWithDefault(msg, 7, ''),
        leagueid: jspb.Message.getFieldWithDefault(msg, 8, 0),
        leaguename: jspb.Message.getFieldWithDefault(msg, 9, ''),
        status: jspb.Message.getFieldWithDefault(msg, 10, 0),
        extradata: jspb.Message.getFieldWithDefault(msg, 11, ''),
        marketscount: jspb.Message.getFieldWithDefault(msg, 12, 0),
        key: jspb.Message.getFieldWithDefault(msg, 13, ''),
        marketgroupsList: jspb.Message.toObjectList(
          msg.getMarketgroupsList(),
          proto.MarketGroup.toObject,
          includeInstance,
        ),
        marketsList: jspb.Message.toObjectList(
          msg.getMarketsList(),
          proto.EventMarket.toObject,
          includeInstance,
        ),
        countrycode: jspb.Message.getFieldWithDefault(msg, 16, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Event}
 */
proto.Event.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Event();
  return proto.Event.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Event}
 */
proto.Event.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSportid(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setSportname(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setCountryid(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountryname(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLeagueid(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setLeaguename(value);
        break;
      case 10:
        var value = /** @type {!proto.EventStatus} */ (reader.readEnum());
        msg.setStatus(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtradata(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMarketscount(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setKey(value);
        break;
      case 14:
        var value = new proto.MarketGroup();
        reader.readMessage(
          value,
          proto.MarketGroup.deserializeBinaryFromReader,
        );
        msg.addMarketgroups(value);
        break;
      case 15:
        var value = new proto.EventMarket();
        reader.readMessage(
          value,
          proto.EventMarket.deserializeBinaryFromReader,
        );
        msg.addMarkets(value);
        break;
      case 16:
        var value = /** @type {string} */ (reader.readString());
        msg.setCountrycode(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Event.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Event.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getSportid();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(5, f);
  }
  f = message.getCountryid();
  if (f !== 0) {
    writer.writeInt32(6, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(7, f);
  }
  f = message.getLeagueid();
  if (f !== 0) {
    writer.writeInt32(8, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(9, f);
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(10, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(11, f);
  }
  f = message.getMarketscount();
  if (f !== 0) {
    writer.writeInt32(12, f);
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(13, f);
  }
  f = message.getMarketgroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.MarketGroup.serializeBinaryToWriter,
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.EventMarket.serializeBinaryToWriter,
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(16, f);
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.Event.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string StartDate = 2;
 * @return {string}
 */
proto.Event.prototype.getStartdate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setStartdate = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.Event.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional int32 SportId = 4;
 * @return {number}
 */
proto.Event.prototype.getSportid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setSportid = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional string SportName = 5;
 * @return {string}
 */
proto.Event.prototype.getSportname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setSportname = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.clearSportname = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Event.prototype.hasSportname = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional int32 CountryId = 6;
 * @return {number}
 */
proto.Event.prototype.getCountryid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setCountryid = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional string CountryName = 7;
 * @return {string}
 */
proto.Event.prototype.getCountryname = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setCountryname = function (value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.clearCountryname = function () {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Event.prototype.hasCountryname = function () {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional int32 LeagueId = 8;
 * @return {number}
 */
proto.Event.prototype.getLeagueid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setLeagueid = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional string LeagueName = 9;
 * @return {string}
 */
proto.Event.prototype.getLeaguename = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setLeaguename = function (value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.clearLeaguename = function () {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Event.prototype.hasLeaguename = function () {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional EventStatus Status = 10;
 * @return {!proto.EventStatus}
 */
proto.Event.prototype.getStatus = function () {
  return /** @type {!proto.EventStatus} */ (
    jspb.Message.getFieldWithDefault(this, 10, 0)
  );
};

/**
 * @param {!proto.EventStatus} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};

/**
 * optional string ExtraData = 11;
 * @return {string}
 */
proto.Event.prototype.getExtradata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setExtradata = function (value) {
  return jspb.Message.setField(this, 11, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.clearExtradata = function () {
  return jspb.Message.setField(this, 11, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Event.prototype.hasExtradata = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional int32 MarketsCount = 12;
 * @return {number}
 */
proto.Event.prototype.getMarketscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};

/**
 * @param {number} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setMarketscount = function (value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};

/**
 * optional string Key = 13;
 * @return {string}
 */
proto.Event.prototype.getKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setKey = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};

/**
 * repeated MarketGroup MarketGroups = 14;
 * @return {!Array<!proto.MarketGroup>}
 */
proto.Event.prototype.getMarketgroupsList = function () {
  return /** @type{!Array<!proto.MarketGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.MarketGroup, 14)
  );
};

/**
 * @param {!Array<!proto.MarketGroup>} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setMarketgroupsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};

/**
 * @param {!proto.MarketGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MarketGroup}
 */
proto.Event.prototype.addMarketgroups = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    14,
    opt_value,
    proto.MarketGroup,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.clearMarketgroupsList = function () {
  return this.setMarketgroupsList([]);
};

/**
 * repeated EventMarket Markets = 15;
 * @return {!Array<!proto.EventMarket>}
 */
proto.Event.prototype.getMarketsList = function () {
  return /** @type{!Array<!proto.EventMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventMarket, 15)
  );
};

/**
 * @param {!Array<!proto.EventMarket>} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setMarketsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};

/**
 * @param {!proto.EventMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventMarket}
 */
proto.Event.prototype.addMarkets = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    15,
    opt_value,
    proto.EventMarket,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.clearMarketsList = function () {
  return this.setMarketsList([]);
};

/**
 * optional string CountryCode = 16;
 * @return {string}
 */
proto.Event.prototype.getCountrycode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ''));
};

/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setCountrycode = function (value) {
  return jspb.Message.setField(this, 16, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.clearCountrycode = function () {
  return jspb.Message.setField(this, 16, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Event.prototype.hasCountrycode = function () {
  return jspb.Message.getField(this, 16) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.EventPrematchRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.EventPrematchRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.EventPrematchRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.EventPrematchRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dateto: jspb.Message.getFieldWithDefault(msg, 1, ''),
        leagueid: jspb.Message.getFieldWithDefault(msg, 2, 0),
        language: jspb.Message.getFieldWithDefault(msg, 3, 0),
        page: jspb.Message.getFieldWithDefault(msg, 4, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
        sortorder: jspb.Message.getFieldWithDefault(msg, 6, ''),
        sortby: jspb.Message.getFieldWithDefault(msg, 7, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventPrematchRequest}
 */
proto.EventPrematchRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventPrematchRequest();
  return proto.EventPrematchRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventPrematchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventPrematchRequest}
 */
proto.EventPrematchRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateto(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLeagueid(value);
        break;
      case 3:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPage(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setSortorder(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setSortby(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventPrematchRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.EventPrematchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventPrematchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventPrematchRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getDateto();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(2, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(4, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(5, f);
  }
  f = message.getSortorder();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getSortby();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
};

/**
 * optional string DateTo = 1;
 * @return {string}
 */
proto.EventPrematchRequest.prototype.getDateto = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.setDateto = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 LeagueId = 2;
 * @return {number}
 */
proto.EventPrematchRequest.prototype.getLeagueid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.setLeagueid = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.clearLeagueid = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventPrematchRequest.prototype.hasLeagueid = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional Language Language = 3;
 * @return {!proto.Language}
 */
proto.EventPrematchRequest.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

/**
 * optional int32 Page = 4;
 * @return {number}
 */
proto.EventPrematchRequest.prototype.getPage = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.setPage = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.clearPage = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventPrematchRequest.prototype.hasPage = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional int32 Limit = 5;
 * @return {number}
 */
proto.EventPrematchRequest.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.setLimit = function (value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.clearLimit = function () {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventPrematchRequest.prototype.hasLimit = function () {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string SortOrder = 6;
 * @return {string}
 */
proto.EventPrematchRequest.prototype.getSortorder = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.setSortorder = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string SortBy = 7;
 * @return {string}
 */
proto.EventPrematchRequest.prototype.getSortby = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventPrematchRequest} returns this
 */
proto.EventPrematchRequest.prototype.setSortby = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventWithMainMarkets.repeatedFields_ = [6];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.EventWithMainMarkets.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.EventWithMainMarkets.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.EventWithMainMarkets} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.EventWithMainMarkets.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        startdate: jspb.Message.getFieldWithDefault(msg, 2, ''),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
        extradata: jspb.Message.getFieldWithDefault(msg, 4, ''),
        marketscount: jspb.Message.getFieldWithDefault(msg, 5, 0),
        marketsList: jspb.Message.toObjectList(
          msg.getMarketsList(),
          proto.EventMarket.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventWithMainMarkets}
 */
proto.EventWithMainMarkets.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventWithMainMarkets();
  return proto.EventWithMainMarkets.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventWithMainMarkets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventWithMainMarkets}
 */
proto.EventWithMainMarkets.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setStartdate(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setExtradata(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMarketscount(value);
        break;
      case 6:
        var value = new proto.EventMarket();
        reader.readMessage(
          value,
          proto.EventMarket.deserializeBinaryFromReader,
        );
        msg.addMarkets(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventWithMainMarkets.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.EventWithMainMarkets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventWithMainMarkets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventWithMainMarkets.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(4, f);
  }
  f = message.getMarketscount();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.EventMarket.serializeBinaryToWriter,
    );
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.EventWithMainMarkets.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string StartDate = 2;
 * @return {string}
 */
proto.EventWithMainMarkets.prototype.getStartdate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.setStartdate = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.EventWithMainMarkets.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string ExtraData = 4;
 * @return {string}
 */
proto.EventWithMainMarkets.prototype.getExtradata = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.setExtradata = function (value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.clearExtradata = function () {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventWithMainMarkets.prototype.hasExtradata = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional int32 MarketsCount = 5;
 * @return {number}
 */
proto.EventWithMainMarkets.prototype.getMarketscount = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.setMarketscount = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * repeated EventMarket Markets = 6;
 * @return {!Array<!proto.EventMarket>}
 */
proto.EventWithMainMarkets.prototype.getMarketsList = function () {
  return /** @type{!Array<!proto.EventMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventMarket, 6)
  );
};

/**
 * @param {!Array<!proto.EventMarket>} value
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.setMarketsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.EventMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventMarket}
 */
proto.EventWithMainMarkets.prototype.addMarkets = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    6,
    opt_value,
    proto.EventMarket,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventWithMainMarkets} returns this
 */
proto.EventWithMainMarkets.prototype.clearMarketsList = function () {
  return this.setMarketsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PrematchLeague.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchLeague.prototype.toObject = function (opt_includeInstance) {
    return proto.PrematchLeague.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchLeague} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchLeague.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.EventWithMainMarkets.toObject,
          includeInstance,
        ),
        id: jspb.Message.getFieldWithDefault(msg, 2, 0),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchLeague}
 */
proto.PrematchLeague.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchLeague();
  return proto.PrematchLeague.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchLeague} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchLeague}
 */
proto.PrematchLeague.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.EventWithMainMarkets();
        reader.readMessage(
          value,
          proto.EventWithMainMarkets.deserializeBinaryFromReader,
        );
        msg.addEvents(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchLeague.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchLeague.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchLeague} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchLeague.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EventWithMainMarkets.serializeBinaryToWriter,
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * repeated EventWithMainMarkets Events = 1;
 * @return {!Array<!proto.EventWithMainMarkets>}
 */
proto.PrematchLeague.prototype.getEventsList = function () {
  return /** @type{!Array<!proto.EventWithMainMarkets>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventWithMainMarkets, 1)
  );
};

/**
 * @param {!Array<!proto.EventWithMainMarkets>} value
 * @return {!proto.PrematchLeague} returns this
 */
proto.PrematchLeague.prototype.setEventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.EventWithMainMarkets=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventWithMainMarkets}
 */
proto.PrematchLeague.prototype.addEvents = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.EventWithMainMarkets,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PrematchLeague} returns this
 */
proto.PrematchLeague.prototype.clearEventsList = function () {
  return this.setEventsList([]);
};

/**
 * optional int32 Id = 2;
 * @return {number}
 */
proto.PrematchLeague.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchLeague} returns this
 */
proto.PrematchLeague.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.PrematchLeague.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchLeague} returns this
 */
proto.PrematchLeague.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PrematchCountry.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchCountry.prototype.toObject = function (opt_includeInstance) {
    return proto.PrematchCountry.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchCountry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchCountry.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ''),
        leaguesList: jspb.Message.toObjectList(
          msg.getLeaguesList(),
          proto.PrematchLeague.toObject,
          includeInstance,
        ),
        id: jspb.Message.getFieldWithDefault(msg, 3, 0),
        name: jspb.Message.getFieldWithDefault(msg, 4, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchCountry}
 */
proto.PrematchCountry.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchCountry();
  return proto.PrematchCountry.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchCountry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchCountry}
 */
proto.PrematchCountry.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 2:
        var value = new proto.PrematchLeague();
        reader.readMessage(
          value,
          proto.PrematchLeague.deserializeBinaryFromReader,
        );
        msg.addLeagues(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchCountry.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchCountry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchCountry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchCountry.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(1, f);
  }
  f = message.getLeaguesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.PrematchLeague.serializeBinaryToWriter,
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string Code = 1;
 * @return {string}
 */
proto.PrematchCountry.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchCountry} returns this
 */
proto.PrematchCountry.prototype.setCode = function (value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.PrematchCountry} returns this
 */
proto.PrematchCountry.prototype.clearCode = function () {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PrematchCountry.prototype.hasCode = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated PrematchLeague Leagues = 2;
 * @return {!Array<!proto.PrematchLeague>}
 */
proto.PrematchCountry.prototype.getLeaguesList = function () {
  return /** @type{!Array<!proto.PrematchLeague>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PrematchLeague, 2)
  );
};

/**
 * @param {!Array<!proto.PrematchLeague>} value
 * @return {!proto.PrematchCountry} returns this
 */
proto.PrematchCountry.prototype.setLeaguesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.PrematchLeague=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PrematchLeague}
 */
proto.PrematchCountry.prototype.addLeagues = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.PrematchLeague,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PrematchCountry} returns this
 */
proto.PrematchCountry.prototype.clearLeaguesList = function () {
  return this.setLeaguesList([]);
};

/**
 * optional int32 Id = 3;
 * @return {number}
 */
proto.PrematchCountry.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchCountry} returns this
 */
proto.PrematchCountry.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string Name = 4;
 * @return {string}
 */
proto.PrematchCountry.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchCountry} returns this
 */
proto.PrematchCountry.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PrematchSport.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchSport.prototype.toObject = function (opt_includeInstance) {
    return proto.PrematchSport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchSport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchSport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        countriesList: jspb.Message.toObjectList(
          msg.getCountriesList(),
          proto.PrematchCountry.toObject,
          includeInstance,
        ),
        id: jspb.Message.getFieldWithDefault(msg, 2, 0),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchSport}
 */
proto.PrematchSport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchSport();
  return proto.PrematchSport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchSport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchSport}
 */
proto.PrematchSport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.PrematchCountry();
        reader.readMessage(
          value,
          proto.PrematchCountry.deserializeBinaryFromReader,
        );
        msg.addCountries(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setName(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchSport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchSport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchSport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchSport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCountriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PrematchCountry.serializeBinaryToWriter,
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * repeated PrematchCountry Countries = 1;
 * @return {!Array<!proto.PrematchCountry>}
 */
proto.PrematchSport.prototype.getCountriesList = function () {
  return /** @type{!Array<!proto.PrematchCountry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PrematchCountry, 1)
  );
};

/**
 * @param {!Array<!proto.PrematchCountry>} value
 * @return {!proto.PrematchSport} returns this
 */
proto.PrematchSport.prototype.setCountriesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.PrematchCountry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PrematchCountry}
 */
proto.PrematchSport.prototype.addCountries = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.PrematchCountry,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PrematchSport} returns this
 */
proto.PrematchSport.prototype.clearCountriesList = function () {
  return this.setCountriesList([]);
};

/**
 * optional int32 Id = 2;
 * @return {number}
 */
proto.PrematchSport.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchSport} returns this
 */
proto.PrematchSport.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string Name = 3;
 * @return {string}
 */
proto.PrematchSport.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.PrematchSport} returns this
 */
proto.PrematchSport.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PrematchSportPaged.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.PrematchSportPaged.prototype.toObject = function (opt_includeInstance) {
    return proto.PrematchSportPaged.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.PrematchSportPaged} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.PrematchSportPaged.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dataList: jspb.Message.toObjectList(
          msg.getDataList(),
          proto.PrematchSport.toObject,
          includeInstance,
        ),
        total: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PrematchSportPaged}
 */
proto.PrematchSportPaged.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PrematchSportPaged();
  return proto.PrematchSportPaged.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PrematchSportPaged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PrematchSportPaged}
 */
proto.PrematchSportPaged.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.PrematchSport();
        reader.readMessage(
          value,
          proto.PrematchSport.deserializeBinaryFromReader,
        );
        msg.addData(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PrematchSportPaged.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.PrematchSportPaged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PrematchSportPaged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PrematchSportPaged.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PrematchSport.serializeBinaryToWriter,
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * repeated PrematchSport Data = 1;
 * @return {!Array<!proto.PrematchSport>}
 */
proto.PrematchSportPaged.prototype.getDataList = function () {
  return /** @type{!Array<!proto.PrematchSport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PrematchSport, 1)
  );
};

/**
 * @param {!Array<!proto.PrematchSport>} value
 * @return {!proto.PrematchSportPaged} returns this
 */
proto.PrematchSportPaged.prototype.setDataList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.PrematchSport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PrematchSport}
 */
proto.PrematchSportPaged.prototype.addData = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.PrematchSport,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PrematchSportPaged} returns this
 */
proto.PrematchSportPaged.prototype.clearDataList = function () {
  return this.setDataList([]);
};

/**
 * optional int32 Total = 2;
 * @return {number}
 */
proto.PrematchSportPaged.prototype.getTotal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.PrematchSportPaged} returns this
 */
proto.PrematchSportPaged.prototype.setTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SubMenuCategoriesResponseDto.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SubMenuCategoriesResponseDto.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.SubMenuCategoriesResponseDto.toObject(
      opt_includeInstance,
      this,
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SubMenuCategoriesResponseDto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.SubMenuCategoriesResponseDto.toObject = function (
    includeInstance,
    msg,
  ) {
    var f,
      obj = {
        categoriesList: jspb.Message.toObjectList(
          msg.getCategoriesList(),
          proto.SubMenuCategory.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SubMenuCategoriesResponseDto}
 */
proto.SubMenuCategoriesResponseDto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SubMenuCategoriesResponseDto();
  return proto.SubMenuCategoriesResponseDto.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SubMenuCategoriesResponseDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SubMenuCategoriesResponseDto}
 */
proto.SubMenuCategoriesResponseDto.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.SubMenuCategory();
        reader.readMessage(
          value,
          proto.SubMenuCategory.deserializeBinaryFromReader,
        );
        msg.addCategories(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SubMenuCategoriesResponseDto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SubMenuCategoriesResponseDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SubMenuCategoriesResponseDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SubMenuCategoriesResponseDto.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SubMenuCategory.serializeBinaryToWriter,
    );
  }
};

/**
 * repeated SubMenuCategory Categories = 1;
 * @return {!Array<!proto.SubMenuCategory>}
 */
proto.SubMenuCategoriesResponseDto.prototype.getCategoriesList = function () {
  return /** @type{!Array<!proto.SubMenuCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SubMenuCategory, 1)
  );
};

/**
 * @param {!Array<!proto.SubMenuCategory>} value
 * @return {!proto.SubMenuCategoriesResponseDto} returns this
 */
proto.SubMenuCategoriesResponseDto.prototype.setCategoriesList = function (
  value,
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.SubMenuCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SubMenuCategory}
 */
proto.SubMenuCategoriesResponseDto.prototype.addCategories = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.SubMenuCategory,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SubMenuCategoriesResponseDto} returns this
 */
proto.SubMenuCategoriesResponseDto.prototype.clearCategoriesList = function () {
  return this.setCategoriesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetCountsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.GetCountsResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetCountsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetCountsResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        prematch: jspb.Message.getFieldWithDefault(msg, 1, 0),
        inplay: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetCountsResponse}
 */
proto.GetCountsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetCountsResponse();
  return proto.GetCountsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetCountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetCountsResponse}
 */
proto.GetCountsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPrematch(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setInplay(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetCountsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetCountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetCountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCountsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPrematch();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getInplay();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional int32 PreMatch = 1;
 * @return {number}
 */
proto.GetCountsResponse.prototype.getPrematch = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetCountsResponse} returns this
 */
proto.GetCountsResponse.prototype.setPrematch = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 InPlay = 2;
 * @return {number}
 */
proto.GetCountsResponse.prototype.getInplay = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetCountsResponse} returns this
 */
proto.GetCountsResponse.prototype.setInplay = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetInPlayEventsByMarketIdRequest.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetInPlayEventsByMarketIdRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.GetInPlayEventsByMarketIdRequest.toObject(
      opt_includeInstance,
      this,
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetInPlayEventsByMarketIdRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetInPlayEventsByMarketIdRequest.toObject = function (
    includeInstance,
    msg,
  ) {
    var f,
      obj = {
        idsList:
          (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
        marketid: jspb.Message.getFieldWithDefault(msg, 2, 0),
        language: jspb.Message.getFieldWithDefault(msg, 3, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetInPlayEventsByMarketIdRequest}
 */
proto.GetInPlayEventsByMarketIdRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetInPlayEventsByMarketIdRequest();
  return proto.GetInPlayEventsByMarketIdRequest.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetInPlayEventsByMarketIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetInPlayEventsByMarketIdRequest}
 */
proto.GetInPlayEventsByMarketIdRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.addIds(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMarketid(value);
        break;
      case 3:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetInPlayEventsByMarketIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetInPlayEventsByMarketIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetInPlayEventsByMarketIdRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
  f = message.getMarketid();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
};

/**
 * repeated string Ids = 1;
 * @return {!Array<string>}
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.getIdsList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.GetInPlayEventsByMarketIdRequest} returns this
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.setIdsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.GetInPlayEventsByMarketIdRequest} returns this
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.addIds = function (
  value,
  opt_index,
) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetInPlayEventsByMarketIdRequest} returns this
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.clearIdsList = function () {
  return this.setIdsList([]);
};

/**
 * optional int32 MarketId = 2;
 * @return {number}
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.getMarketid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetInPlayEventsByMarketIdRequest} returns this
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.setMarketid = function (
  value,
) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional Language Language = 3;
 * @return {!proto.Language}
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.GetInPlayEventsByMarketIdRequest} returns this
 */
proto.GetInPlayEventsByMarketIdRequest.prototype.setLanguage = function (
  value,
) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetInPlayRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetInPlayRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetInPlayRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetInPlayRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        language: jspb.Message.getFieldWithDefault(msg, 1, 0),
        sportid: jspb.Message.getFieldWithDefault(msg, 2, 0),
        ismultiview: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetInPlayRequest}
 */
proto.GetInPlayRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetInPlayRequest();
  return proto.GetInPlayRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetInPlayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetInPlayRequest}
 */
proto.GetInPlayRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.Language} */ (reader.readEnum());
        msg.setLanguage(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSportid(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsmultiview(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetInPlayRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetInPlayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetInPlayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetInPlayRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(2, f);
  }
  f = message.getIsmultiview();
  if (f) {
    writer.writeBool(3, f);
  }
};

/**
 * optional Language Language = 1;
 * @return {!proto.Language}
 */
proto.GetInPlayRequest.prototype.getLanguage = function () {
  return /** @type {!proto.Language} */ (
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};

/**
 * @param {!proto.Language} value
 * @return {!proto.GetInPlayRequest} returns this
 */
proto.GetInPlayRequest.prototype.setLanguage = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional int32 SportId = 2;
 * @return {number}
 */
proto.GetInPlayRequest.prototype.getSportid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.GetInPlayRequest} returns this
 */
proto.GetInPlayRequest.prototype.setSportid = function (value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.GetInPlayRequest} returns this
 */
proto.GetInPlayRequest.prototype.clearSportid = function () {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetInPlayRequest.prototype.hasSportid = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional bool IsMultiView = 3;
 * @return {boolean}
 */
proto.GetInPlayRequest.prototype.getIsmultiview = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};

/**
 * @param {boolean} value
 * @return {!proto.GetInPlayRequest} returns this
 */
proto.GetInPlayRequest.prototype.setIsmultiview = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CreateShareBetRequest.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateShareBetRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.CreateShareBetRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateShareBetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.CreateShareBetRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.CreateShareBetEventRequest.toObject,
          includeInstance,
        ),
        betsliptype: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateShareBetRequest}
 */
proto.CreateShareBetRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateShareBetRequest();
  return proto.CreateShareBetRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateShareBetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateShareBetRequest}
 */
proto.CreateShareBetRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.CreateShareBetEventRequest();
        reader.readMessage(
          value,
          proto.CreateShareBetEventRequest.deserializeBinaryFromReader,
        );
        msg.addEvents(value);
        break;
      case 2:
        var value = /** @type {!proto.BetSlipType} */ (reader.readEnum());
        msg.setBetsliptype(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateShareBetRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateShareBetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateShareBetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateShareBetRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.CreateShareBetEventRequest.serializeBinaryToWriter,
    );
  }
  f = message.getBetsliptype();
  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};

/**
 * repeated CreateShareBetEventRequest Events = 1;
 * @return {!Array<!proto.CreateShareBetEventRequest>}
 */
proto.CreateShareBetRequest.prototype.getEventsList = function () {
  return /** @type{!Array<!proto.CreateShareBetEventRequest>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.CreateShareBetEventRequest,
      1,
    )
  );
};

/**
 * @param {!Array<!proto.CreateShareBetEventRequest>} value
 * @return {!proto.CreateShareBetRequest} returns this
 */
proto.CreateShareBetRequest.prototype.setEventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.CreateShareBetEventRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CreateShareBetEventRequest}
 */
proto.CreateShareBetRequest.prototype.addEvents = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.CreateShareBetEventRequest,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateShareBetRequest} returns this
 */
proto.CreateShareBetRequest.prototype.clearEventsList = function () {
  return this.setEventsList([]);
};

/**
 * optional BetSlipType BetSlipType = 2;
 * @return {!proto.BetSlipType}
 */
proto.CreateShareBetRequest.prototype.getBetsliptype = function () {
  return /** @type {!proto.BetSlipType} */ (
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};

/**
 * @param {!proto.BetSlipType} value
 * @return {!proto.CreateShareBetRequest} returns this
 */
proto.CreateShareBetRequest.prototype.setBetsliptype = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateShareBetEventRequest.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.CreateShareBetEventRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateShareBetEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.CreateShareBetEventRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, ''),
        selectionid: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateShareBetEventRequest}
 */
proto.CreateShareBetEventRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateShareBetEventRequest();
  return proto.CreateShareBetEventRequest.deserializeBinaryFromReader(
    msg,
    reader,
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateShareBetEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateShareBetEventRequest}
 */
proto.CreateShareBetEventRequest.deserializeBinaryFromReader = function (
  msg,
  reader,
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSelectionid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateShareBetEventRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateShareBetEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateShareBetEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateShareBetEventRequest.serializeBinaryToWriter = function (
  message,
  writer,
) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getSelectionid();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string Id = 1;
 * @return {string}
 */
proto.CreateShareBetEventRequest.prototype.getId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.CreateShareBetEventRequest} returns this
 */
proto.CreateShareBetEventRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string SelectionId = 2;
 * @return {string}
 */
proto.CreateShareBetEventRequest.prototype.getSelectionid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.CreateShareBetEventRequest} returns this
 */
proto.CreateShareBetEventRequest.prototype.setSelectionid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ShareBetDto.prototype.toObject = function (opt_includeInstance) {
    return proto.ShareBetDto.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ShareBetDto} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.ShareBetDto.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ShareBetDto}
 */
proto.ShareBetDto.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ShareBetDto();
  return proto.ShareBetDto.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ShareBetDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ShareBetDto}
 */
proto.ShareBetDto.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ShareBetDto.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ShareBetDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ShareBetDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ShareBetDto.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
};

/**
 * optional string Code = 1;
 * @return {string}
 */
proto.ShareBetDto.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.ShareBetDto} returns this
 */
proto.ShareBetDto.prototype.setCode = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetShareBetResponse.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetShareBetResponse.prototype.toObject = function (
    opt_includeInstance,
  ) {
    return proto.GetShareBetResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetShareBetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.GetShareBetResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, ''),
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.Event.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetShareBetResponse}
 */
proto.GetShareBetResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetShareBetResponse();
  return proto.GetShareBetResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetShareBetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetShareBetResponse}
 */
proto.GetShareBetResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setCode(value);
        break;
      case 2:
        var value = new proto.Event();
        reader.readMessage(value, proto.Event.deserializeBinaryFromReader);
        msg.addEvents(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetShareBetResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetShareBetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetShareBetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetShareBetResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.Event.serializeBinaryToWriter);
  }
};

/**
 * optional string Code = 1;
 * @return {string}
 */
proto.GetShareBetResponse.prototype.getCode = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.GetShareBetResponse} returns this
 */
proto.GetShareBetResponse.prototype.setCode = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated Event Events = 2;
 * @return {!Array<!proto.Event>}
 */
proto.GetShareBetResponse.prototype.getEventsList = function () {
  return /** @type{!Array<!proto.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Event, 2)
  );
};

/**
 * @param {!Array<!proto.Event>} value
 * @return {!proto.GetShareBetResponse} returns this
 */
proto.GetShareBetResponse.prototype.setEventsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Event}
 */
proto.GetShareBetResponse.prototype.addEvents = function (
  opt_value,
  opt_index,
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.Event,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetShareBetResponse} returns this
 */
proto.GetShareBetResponse.prototype.clearEventsList = function () {
  return this.setEventsList([]);
};

/**
 * @enum {number}
 */
proto.Language = {
  NONE: 0,
  ENGLISH: 1,
  FRENCH: 2,
  SPANISH: 3,
  PORTUGUESE: 4,
  HINDI: 5,
  FARSI: 6,
};

/**
 * @enum {number}
 */
proto.EventStatus = {
  NONE_STATUS: 0,
  ACTIVE: 1,
  IN_PROGRESS: 2,
  FINISHED: 3,
  CANCELLED: 4,
  POSTPONED: 5,
  INTERRUPTED: 6,
  ABANDONED: 7,
  COVERAGE_LOST: 8,
  ABOUT_TO_START: 9,
};

/**
 * @enum {number}
 */
proto.SelectionStatus = {
  NONE_SELECTION_STATUS: 0,
  OPEN: 1,
  SUSPENED: 2,
  SETTLED: 3,
};

/**
 * @enum {number}
 */
proto.EventMarketStatus = {
  NONE_MARKET_STATUS: 0,
  ACTIVE_MARKET: 1,
  INACTIVE_MARKET: 2,
  SUSPENDED_MARKET: 4,
};

/**
 * @enum {number}
 */
proto.ActionType = {
  PING: 0,
  PONG: 1,
  GETINPLAY: 2,
  GETUPCOMING: 3,
  GETMAINMARKETS: 4,
  GETPREMATCHMENU: 5,
  GETTOPTOURNAMENTS: 6,
  GETTOPGAMES: 7,
  GETPREMATCHEVENT: 8,
  GETINPLAYEVENT: 9,
  GETCOUNTS: 10,
  GETINPLAYEVENTSBYMARKETID: 11,
  GETPREMATCHEVENTS: 12,
  GETPREMATCHSUBMENU: 13,
  GETSHAREBET: 14,
  CREATESHAREBET: 15,
};

/**
 * @enum {number}
 */
proto.BetSlipType = {
  UNSPECIFIED: 0,
  SINGLE: 1,
  MULTI: 2,
  JACKPOT: 3,
  SYSTEM: 4,
};

export default proto;
