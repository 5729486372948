import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';

export const BetslipPartialWinboost = () => {
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();

  const textStyles = {
    color: '$white',
    fontSize: '$xxs',
    lineHeight: '$20',
    display: 'inline',
    cursor: 'pointer',
  };

  const linkStyles = {
    margin: '0 $1',
    cursor: 'pointer',
    fontWeight: '$medium',
    textTransform: 'uppercase',
  };

  const handleRulesClick = () => {
    dispatch(openDialog(DIALOGS.BONUS_RULES));
  };

  return (
    <Text css={{ lineHeight: '0' }}>
      <Text as="span" css={{ ...textStyles }}>
        {localized('betslip.winboostRules.partial')}
      </Text>
      <Text
        as="span"
        underline
        css={{ ...textStyles, ...linkStyles }}
        onClick={handleRulesClick}
      >
        {localized('buttons.rules')}
      </Text>
      <Text as="span" css={{ ...textStyles }}>
        {localized('betslip.winboostRules.notMet')}
      </Text>
    </Text>
  );
};
