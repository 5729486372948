import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useAppSelector } from '~store';
import { TransactionHistoryCellProps } from '~types/transactions';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const BalanceCell = ({
  cell: { value },
}: TransactionHistoryCellProps) => {
  const { currency } = useAppSelector((state) => state.settings);

  return (
    <Text level="12-20">
      {commaSeparatedNumber(value)} {CURRENCY_SYMBOLS[currency]}
    </Text>
  );
};
