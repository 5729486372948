import { useEffect, useState } from 'react';

import { useLazyGetFavoriteEventInplay } from '~api/sportEvent/sportEventQueries';
import { useLiveMenuHubConnection, useLiveMenuUpdates } from '~hooks';
import { useEventsLoadingSocket } from '~hooks/useEventsLoadingSocket';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useReconnectedRequest } from '~hooks/useReconnectedRequest';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoriteInplayEvents } from '~store/slices/liveGroupsSlice';
import {
  resetAddedLeagueId,
  resetAddedSportId,
  resetLiveMenuSlice,
  resetUpdatedSportMarket,
  setIsLiveMenuLoaded,
  setIsLiveMenuLoading,
} from '~store/slices/liveMenuSlice';
import { selectLanguage } from '~store/slices/websiteSettings';
import { SPORT_GROUPS } from '~types/sportGroups';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

import {
  getMainMarketId,
  reduceEventsByLeague,
  reduceEventsBySport,
  reduceOpenedSportEventsBySport,
} from '../utils/eventsParsing';
import { resolveRedirectPath } from '../utils/helpers';

export const useLiveMenu = () => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const search = useQueryParams();
  const { group } = search;
  const { isMobileOrTablet } = useMedia();

  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const {
    liveMenuSports: liveMenu,
    addedLeagueId,
    addedSportId,
    mainMarketsSelected,
    updatedSportMarket,
    openedCountryLeagues,
    isLiveMenuLoaded,
    isLiveMenuLoading,
  } = useAppSelector((state) => state.liveMenu);
  const { isPrimaryDataLoaded, sendWsMessage } = useEventsLoadingSocket();
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const [isLoadedOpenedCountries, setIsLoadedOpenedCountries] =
    useState(isLiveMenuLoaded);
  const language = useAppSelector(selectLanguage);
  const { lazyGetFavoriteEventInplayQuery } = useLazyGetFavoriteEventInplay();

  useLiveMenuUpdates();
  useLiveMenuHubConnection();

  useEffect(() => {
    const loadFavoriteInplayEvents = async () => {
      try {
        const favoriteInplayEvents =
          await lazyGetFavoriteEventInplayQuery().unwrap();

        dispatch(setFavoriteInplayEvents(favoriteInplayEvents));
      } catch (e) {
        console.error('Failed to load favorite inplay events: ', e);
      }
    };

    if (!isUserLoggedIn) return;
    loadFavoriteInplayEvents();
  }, [isUserLoggedIn]);

  useReconnectedRequest(() => {
    dispatch(setIsLiveMenuLoaded(false));
  });

  useEffect(() => {
    if (isLiveMenuLoaded) return;

    if (isPrimaryDataLoaded) {
      sendWsMessage(ACTION_TYPE.GET_IN_PLAY, {
        Language: language,
      });
    }
  }, [isLiveMenuLoaded, isPrimaryDataLoaded, language]);

  useEffect(() => {
    if (isLiveMenuLoaded && isMobileOrTablet) {
      sendWsMessage(ACTION_TYPE.GET_IN_PLAY, {
        Language: language,
      });
    }

    return () => {
      if (!isMobileOrTablet) {
        dispatch(resetLiveMenuSlice());
      }
    };
  }, []);

  useEffect(() => {
    if (!isPrimaryDataLoaded) return;
    if (isMobileOrTablet && addedSportId) {
      dispatch(resetAddedSportId());

      return;
    }

    if (addedSportId === null) return;

    dispatch(setIsLiveMenuLoading(true));

    const events = reduceEventsBySport({
      liveMenu,
      openedCountryLeagues,
      sportId: parseInt(addedSportId),
    });

    if (!events.length) return;
    const numberSportId = parseInt(addedSportId);
    const marketId =
      mainMarketsSelected[numberSportId] ||
      getMainMarketId({ mainMarkets, sportId: numberSportId });

    sendWsMessage(ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID, {
      Ids: events,
      MarketId: marketId,
      Language: language,
    });
  }, [
    addedSportId,
    liveMenu,
    mainMarkets,
    openedCountryLeagues,
    isMobileOrTablet,
    mainMarketsSelected,
    isPrimaryDataLoaded,
    language,
  ]);

  useEffect(() => {
    if (!isPrimaryDataLoaded) return;
    if (isMobileOrTablet && addedLeagueId) {
      dispatch(resetAddedLeagueId());

      return;
    }

    if (addedLeagueId === null) return;
    dispatch(setIsLiveMenuLoading(true));
    const { sportId, events } = reduceEventsByLeague(liveMenu, addedLeagueId);

    if (!events.length) return;
    const marketId =
      mainMarketsSelected[sportId] || getMainMarketId({ mainMarkets, sportId });

    sendWsMessage(ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID, {
      Ids: events,
      MarketId: marketId,
      Language: language,
    });
  }, [
    addedLeagueId,
    liveMenu,
    mainMarkets,
    mainMarketsSelected,
    isPrimaryDataLoaded,
    language,
  ]);

  useEffect(() => {
    if (!isPrimaryDataLoaded) return;
    if (updatedSportMarket) {
      const { sportId, marketId } = updatedSportMarket;

      dispatch(resetUpdatedSportMarket());

      const eventIds = reduceOpenedSportEventsBySport({
        liveMenu,
        sportId,
        openedCountryLeagues,
      });

      if (!eventIds.length) return;

      sendWsMessage(ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID, {
        Ids: eventIds,
        MarketId: marketId,
        Language: language,
      });
    }
  }, [
    liveMenu,
    mainMarkets,
    updatedSportMarket,
    openedCountryLeagues,
    search,
    isPrimaryDataLoaded,
    language,
  ]);

  useEffect(() => {
    if (
      group !== SPORT_GROUPS.TOP_EVENTS &&
      liveMenu.length &&
      !isMobileOrTablet
    ) {
      updateQueryParams(resolveRedirectPath(liveMenu, search), true);
    }
  }, [liveMenu, isMobileOrTablet, group, search]);

  useEffect(() => {
    if (openedCountryLeagues.length && !isLoadedOpenedCountries) {
      setTimeout(() => {
        setIsLoadedOpenedCountries(true);
      }, 300);
    }
  }, [openedCountryLeagues]);

  return {
    liveSports: liveMenu,
    isLoadedOpenedCountries: isLoadedOpenedCountries,
    isLoaded: isLiveMenuLoaded,
    isLoading: isLiveMenuLoading,
  };
};
