import { CURRENCY_SYMBOLS } from '~constants/common';
import { useAppSelector } from '~store';
import {
  getFormattedBalance,
  INVISIBLE_BALANCE_PLACEHOLDER,
} from '~utils/userBalance';

export const useUserBalance = (
  mainBalance: number = 0,
  freeBetsBalance: number = 0,
) => {
  const { currency, enableDecimals } = useAppSelector(
    (state) => state.settings,
  );
  const { isBalanceVisible } = useAppSelector((state) => state.userUIState);
  const { isUserProfileLoaded } = useAppSelector((state) => state.userState);

  const totalFormattedBalance = getFormattedBalance(
    mainBalance + freeBetsBalance,
    enableDecimals,
  );
  const mainFormattedBalance = getFormattedBalance(mainBalance, enableDecimals);
  const freeBetsFormattedBalance = getFormattedBalance(
    freeBetsBalance,
    enableDecimals,
  );

  return {
    isUserProfileLoaded,
    totalBalanceText: isBalanceVisible
      ? `${totalFormattedBalance}  ${CURRENCY_SYMBOLS[currency] || ''}`
      : INVISIBLE_BALANCE_PLACEHOLDER,
    mainBalanceText: isBalanceVisible
      ? `${mainFormattedBalance}  ${CURRENCY_SYMBOLS[currency] || ''}`
      : INVISIBLE_BALANCE_PLACEHOLDER,
    freeBetBalanceText: isBalanceVisible
      ? `${freeBetsFormattedBalance} FB`
      : INVISIBLE_BALANCE_PLACEHOLDER,
  };
};
