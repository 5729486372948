import 'swiper/css';
import 'swiper/css/pagination'; // Import Swiper styles for pagination

import { useRef } from 'react';
import { CSS } from 'stitches.config';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Game } from '~api/games/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { GameCard } from '~components/molecules/Games/GameCard';
import { useMedia } from '~hooks/useMedia';
import { NextIcon, PrevIcon } from '~icons';

interface GamesSliderProps {
  label: string;
  slidesPerView: number;
  slideHeight: number;
  games: Game[];
  withContainer?: boolean;
  withDots?: boolean;
  css?: CSS;
  titleLevel?: string;
  colSpan?: number;
  gameCardVariant?: 'xs' | 'xl' | 'sm' | undefined;
}

export const GamesSlider = ({
  label,
  slidesPerView,
  slideHeight,
  games,
  withContainer,
  withDots,
  css,
  titleLevel,
  colSpan,
  gameCardVariant,
}: GamesSliderProps) => {
  const { isMobileOrTablet } = useMedia();
  const swiperRef = useRef(null);

  return (
    <Box fullWidth flexCol gap={4} css={css}>
      <Box
        flexRow
        fullWidth
        justifyContentBetween={!isMobileOrTablet}
        justifyContentStart={isMobileOrTablet}
      >
        <Text
          level={titleLevel ? titleLevel : isMobileOrTablet ? '14-24' : '20-32'}
          css={{
            pl: withContainer ? '$3' : 0,
          }}
          textTransform="uppercase"
        >
          {label}
        </Text>
        {!isMobileOrTablet && (
          <Box flexRow gap={6} alignCenter>
            <Box
              flexRow
              css={{
                color: '$white',
                cursor: 'pointer',
                '&:hover': {
                  color: '$yellowLight',
                },
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={() => swiperRef.current?.swiper.slidePrev()}
            >
              <PrevIcon />
            </Box>
            <Box
              css={{
                backgroundColor: '$white',
                height: '12px',
                width: '1px',
                borderRadius: '2px',
              }}
            />
            <Box
              flexRow
              css={{
                color: '$white',
                cursor: 'pointer',
                '&:hover': {
                  color: '$yellowLight',
                },
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onClick={() => swiperRef.current?.swiper.slideNext()}
            >
              <NextIcon />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        fullWidth
        css={{
          '& .swiper-pagination-bullet': {
            width: '24px',
            height: '2px',
            background: '$grayMedium',
            opacity: 1,
            borderRadius: '$2',
          },
          '& .swiper-pagination-bullet-active': {
            background: '$white',
          },
          '& .swiper-pagination': {
            bottom: '0',
          },
          ...(withDots
            ? {
                '& .swiper-wrapper': {
                  paddingBottom: '20px',
                },
              }
            : {}),
          ...(withContainer
            ? {
                p: '$3',
                borderRadius: '$10',
                background: '$blueDark',
              }
            : {}),
        }}
      >
        <Swiper
          ref={swiperRef}
          loop
          pagination={{ clickable: true }}
          modules={withDots ? [Pagination] : []}
          spaceBetween={6}
          slidesPerView={slidesPerView}
        >
          {games.map((game) => (
            <SwiperSlide
              key={game.id}
              style={{
                height: slideHeight + 'px',
              }}
            >
              <GameCard
                game={game}
                colSpan={colSpan}
                customStyles={{ height: slideHeight + 'px' }}
                variant={gameCardVariant}
                noAspectRatio
                sliderCard
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
