import { Bonus } from '~api/bonus/types';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import {
  calculateTimeRemainingInSeconds,
  formatDateTime,
  getDaysHoursMinutesFromSeconds,
  TIME_FORMATS,
} from '~utils/formatDateTime';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const useActiveBonusCard = ({ bonus }: { bonus: Bonus }) => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);
  const { isMobileOrTablet } = useMedia();

  const {
    title,
    totalAmount,
    remainingAmount,
    won,
    maxWin,
    createDate,
    expireDate,
    betSlipCodes,
  } = bonus || {};
  const { days, hours, minutes } = getDaysHoursMinutesFromSeconds(
    calculateTimeRemainingInSeconds(expireDate),
  );
  const progress = (1 - remainingAmount / totalAmount) * 100;
  const remainingAmountText = `${commaSeparatedNumber(
    totalAmount - remainingAmount,
  )} / ${commaSeparatedNumber(totalAmount)} ${CURRENCY_SYMBOLS[currency]}`;
  const givenDate = `${localized('bonuses.givenDate')} ${formatDateTime(
    createDate,
    TIME_FORMATS.DATE,
  )}`;
  const maxWinText =
    maxWin === null
      ? `${commaSeparatedNumber(won)} ${CURRENCY_SYMBOLS[currency]}`
      : `${commaSeparatedNumber(won)} / ${commaSeparatedNumber(maxWin)} ${
          CURRENCY_SYMBOLS[currency]
        }`;

  return {
    betSlipCodes,
    days,
    givenDate,
    hours,
    isMobileOrTablet,
    maxWinText,
    minutes,
    progress,
    remainingAmountText,
    title,
    localized,
  };
};
