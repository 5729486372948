import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const HeaderTopStyled = styled(Box, {
  background: '$gray',
  height: '44px',
  pr: '$5',
  pl: '$4',
  borderTopRightRadius: '$6',
  borderTopLeftRadius: '$6',
  borderBottom: '1px solid $black',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const HeaderBottomStyled = styled(Box, {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: '$gray',
  height: '44px',
  pr: '$5',
});
