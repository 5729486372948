import { useEffect } from 'react';

import { Box } from '~components/atoms/Box';
import { SkeletonFull } from '~components/atoms/Skeletons';
import { BreadcrumbEventWrapper } from '~components/molecules/InplayEvents/components/InplayEventMarkets';
import { InplayEventOverview } from '~components/molecules/InplayEvents/components/InplayEventOverview';
import { InplayMarketEventGroups } from '~components/molecules/InplayEvents/InplayMarketEventGroups';
import { useInitializeBreadcrumbForSport } from '~hooks/useInitializeBreadcrumbsForSport';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';
import { SportEvent } from '~types/events';

import { usePrematchEvent } from '../hooks/usePrematchEvent';

export const PrematchEventMarkets = () => {
  const {
    marketGroups,
    eventData,
    isMobileOrTablet,
    isEventLoaded,
    loadingEventId,
    handleBackPress,
  } = usePrematchEvent();

  const { countryName, leagueName, name } = eventData || {};
  const { mobileHeaderHeight } = useAppSelector((state) => state.mobileState);
  const isBreadcrumbAvailable =
    isMobileOrTablet && countryName && leagueName && name;
  const mainMarkets = useAppSelector((state) => state.mainMarkets.mainMarkets);

  const { sportId } = useQueryParams();
  const { initializeBreadcrumb } = useInitializeBreadcrumbForSport(
    parseInt(sportId!),
    countryName,
    eventData as SportEvent,
  );

  useEffect(() => {
    if (isMobileOrTablet) {
      initializeBreadcrumb(sportId, countryName);
    }
  }, [isMobileOrTablet, eventData, countryName, sportId, mainMarkets]);

  return (
    <Box
      flexCol
      gap={isMobileOrTablet ? 0 : 1}
      css={{
        minHeight: 'calc(100vh - 270px)',
        pt: isBreadcrumbAvailable ? '52px' : 0,
      }}
    >
      {!isEventLoaded || loadingEventId ? (
        <SkeletonFull />
      ) : (
        <>
          {isBreadcrumbAvailable && (
            <BreadcrumbEventWrapper
              mobileHeaderHeight={mobileHeaderHeight}
              handleBackPress={handleBackPress}
            />
          )}

          {eventData && (
            <InplayEventOverview eventData={eventData as SportEvent} />
          )}
          <InplayMarketEventGroups
            eventData={eventData as SportEvent}
            marketGroups={marketGroups}
            isEventLoaded={isEventLoaded}
          />
        </>
      )}
    </Box>
  );
};
