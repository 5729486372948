import { useEffect } from 'react';

import { useLazyGetMenuContent } from '~api/content/contentQueries';
import { useLazySettings } from '~api/partner/partnerQueries';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { useAppDispatch, useAppSelector } from '~store';
import { setStakeType } from '~store/slices/betslipSlice';
import {
  selectPaymnets,
  setAvailablePaymentSettings,
  setPaymentSettings,
} from '~store/slices/paymentsSlice';
import { getBetslip } from '~store/slices/selectors/betslip';
import { setWebsiteSettings } from '~store/slices/settingsSlice';
import { selectIsUserLoggedIn } from '~store/slices/userSlice';
import { setMenuContents } from '~store/slices/userUISlice';
import { selectLanguage } from '~store/slices/websiteSettings';

export const useLoadSettings = (
  setIsSettingsLoaded: (isLoaded: boolean) => void,
) => {
  const dispatch = useAppDispatch();

  const { lazySettingsQuery } = useLazySettings();
  const { lazyGetMenuContentQuery } = useLazyGetMenuContent();

  const { stakeType } = useAppSelector(getBetslip);
  const { activePaymentTab } = useAppSelector(selectPaymnets);
  const language = useAppSelector(selectLanguage);
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn);

  useEffect(() => {
    const loadSettings = async () => {
      const websiteSettings = await lazySettingsQuery().unwrap();
      const { paymentProviderSettings } = websiteSettings;

      dispatch(setWebsiteSettings(websiteSettings));

      if (
        !websiteSettings.allowSystemBets &&
        stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM
      ) {
        dispatch(setStakeType(SPORT_BETSLIP_TYPE_OPTIONS.SINGLE));
      }

      if (paymentProviderSettings.length) {
        dispatch(setAvailablePaymentSettings(paymentProviderSettings));

        if (activePaymentTab) {
          const providerValue = paymentProviderSettings.find(
            (provider) => provider.paymentProvider === activePaymentTab,
          );

          if (providerValue) {
            dispatch(setPaymentSettings(providerValue)); // Setting default provider payment settings
          }
        }
      }

      setIsSettingsLoaded(true);
    };

    const loadMenuContentData = async () => {
      const data = await lazyGetMenuContentQuery(
        '?names=footer&names=header',
      ).unwrap();

      dispatch(setMenuContents(data));
    };

    loadMenuContentData();
    loadSettings();
  }, [language, isUserLoggedIn]); // Reload settings on language change and user login/logout
};
